<template>
  <input
    :value="value"
    @input="updateValue($event.target.value)"
    @keyup.enter="onEnter()"
    @blur="blur"
    :placeholder="placeholder" type="text"/>
</template>
<script>

export default {
  name: 'url',
  props: [
    'value',
    'validations',
    'blur',
    'placeholder',
    'checkTelephone',
    'checkMail'
  ],
  data () {
    return {
      validLength: 1000
    }
  },
  methods: {
    updateValue (val) {
      val = this.checkValidations(val)
      this.$emit('input', val)
    },
    onEnter () {
      this.checkValidations(this.value)
      const result = Object.keys(this.validations).every(k => this.validations[k] === false)
      this.$emit('onEnter', result)
    },
    checkValidations (val) {
      if (this.telCheck(val)) {
        this.validations.phoneValid = this.checkTel(val.substring(4))
      } else if (this.mailToCheck(val)) {
        this.validations.mailValid = this.checkEmail(val.substring(7))
      }else if (val && val.length > 8) {
        this.validations.url = false
        val = val.trim()
        const telCheck = this.checkTelephone && this.telCheck(val)
        const mailCheck = this.checkMail && this.mailToCheck(val)
        if (!telCheck && !mailCheck) {
          val = this.urlConcatenation(val)
          this.validations.urlLength = !this.maxLength(val, this.validLength)
          this.validations.urlValid = !this.isValidURL(val)
          this.$set(this.validations, 'url', false)
        }
      } else {
        this.validations.url = false
        this.validations.urlLength = false
        this.validations.urlValid = false
        this.validations.phoneValid = false
        this.validations.mailValid = false
      }
      return val
    }
  }
}
</script>
