import {mapGetters} from 'vuex'
import store from '@/store'
import router from '@/router'
import {trackCampaignCreated} from '../common/methods'
import {campaignTypes} from "@/state/modules/mutation-types";

export default ({
  computed: {
    ...mapGetters(['getComponentCampaignList', 'getCampaignAdd', 'getCampaigns', 'getCampaignLoaders', 'getCampaignSectionsValidations', 'getCampaignValidations'])
  },
  methods: {
    getCampaignName (campaignId) {
      if (!campaignId) {
        return null
      }
      return this.getComponentCampaignList.find(item => item._id === campaignId)
    },
    getCampaignType (campaignId, self = this) {
      const cmp = self.getComponentCampaignList.find(item => item._id === campaignId)
      return cmp ? cmp.type : ''
    },
    isTypeCTA (campaignId, self = this) {
      // checking if the user selected call to action id is from the retargeting or no_cta.
      if (!campaignId) {
        return false
      }
      return !['no_cta', 'retargeting'].includes(this.getCampaignType(campaignId, self))
    },
    async storeCampaign (confirmUpdate = false) {

      if (this.isBridgeCTACampaign()) {
        this.getCampaignAdd.sound = false
      }

      this.checkCampaignValidation()
      if (!Object.keys(this.getCampaignSectionsValidations).every(k => this.getCampaignSectionsValidations[k] === false)) {
        this.$store.dispatch('toastNotification', {
          message: 'Missing Fields: One of the required field is empty or contains invalid data.',
          type: 'error'
        })
        return
      }

      const editMode = !!this.getCampaignAdd._id
      // selecting default domain in case of whitelabel we select whitelabel branded domain
      this.getCampaignAdd.domainId = this.getSiteDetails.isWhiteLabel ? ((this.getCampaignAdd.domainId && this.getSiteDetails.custom_domain_id != this.getCampaignAdd.domainId) ? this.getCampaignAdd.domainId : this.getSiteDetails.custom_domain_id) : this.getCampaignAdd.domainId
      const res = await store.dispatch('storeCampaign', confirmUpdate)
      if (res && res.status) {
        if (!editMode) {
          await trackCampaignCreated()
        }
        router.push({name: 'campaigns'})
      }
    },
    /**
     * Calculates conversion rate
     * @param aggregations
     * @param digits
     * @returns {number}
     */
    computeConversionRate (aggregations, digits = 2) {
      const totalVisitors = !aggregations ? 0 : aggregations.total_visitors.value
      const clickedConversions = !aggregations ? 0 : aggregations.clicked_conversions.doc_count
      if (totalVisitors <= 0) {
        return 0
      }

      let value = (clickedConversions / totalVisitors) * 100
      value = value * Math.pow(10, digits)
      value = Math.round(value)
      return value / Math.pow(10, digits)
    },
    /**
     * checking the campaign validations
     */
    checkCampaignValidation () {
      const campaignName = this.campaignTypeValidation()
      const image = this.campaignImageValidation()
      const integration = this.campaignIntegrationValidation()
      const agreement = this.campaignAgreementValidation()
      const customMessage = this.campaignMessageFormValidation()

      this.$store.commit(campaignTypes.SET_CAMPAIGN_SECTION_VALIDATIONS, {
        campaignName: campaignName,
        image: image,
        integration: integration,
        agreement: agreement,
        customMessage: customMessage
      })
    },
    /**
     * handling the campaign Type validation
     * @param route
     */
    campaignTypeValidation (route = '') {
      this.getCampaignSectionsValidations.campaignName = false
      this.getCampaignValidations.nameValidations.name = this.requiredCheck(this.getCampaignAdd.name)
      this.getCampaignValidations.nameValidations.nameLength = !this.maxLength(this.getCampaignAdd.name, 100)
      if (!route && (this.getCampaignValidations.nameValidations.name || this.getCampaignValidations.nameValidations.nameLength)) {
        return true
      }

      if (!route) {
        return false
      }

      if (!this.getCampaignValidations.nameValidations.name && !this.getCampaignValidations.nameValidations.nameLength) {
        this.$router.push({name: route})
      }
    },
    /**
     * getting the campaign image validation
     * @returns {boolean}
     */
    campaignImageValidation () {
      this.getCampaignSectionsValidations.image = false
      if (this.getCampaignAdd.image) {
        this.getCampaignAdd.image = this.urlConcatenation(this.getCampaignAdd.image)
        this.getCampaignValidations.image.urlValid = !this.isValidURL(this.getCampaignAdd.image)
        this.getCampaignValidations.image.urlLength = !this.maxLength(this.getCampaignAdd.image, 1000)
        return !Object.keys(this.getCampaignValidations.image).every(k => this.getCampaignValidations.image[k] === false)
      }
      return false
    },
    /**
     * checking the campaign third party integrations validations
     * Following fields are required to be validate email_list, provider, provider_id, and redirect_after_submission
     * @returns {boolean}
     */
    campaignIntegrationValidation () {
      this.getCampaignSectionsValidations.integration = false
      if (this.getCampaignAdd.type === 'form') {
        const emailListId = this.getCampaignAdd.email_list._id ? this.getCampaignAdd.email_list._id : this.getCampaignAdd.email_list.id
        this.getCampaignValidations.validations.provider = this.requiredCheck(this.getCampaignAdd.email_service)
        this.getCampaignValidations.validations.provider_id = this.requiredCheckWithInteger(emailListId)

        if (this.getCampaignAdd.redirect_form_submission) {
          this.getCampaignValidations.validations.redirect_after_submission = this.getCampaignAdd.redirect_form_submission && this.requiredCheck(this.getCampaignAdd.redirect_after_submission)
          this.getCampaignValidations.validations.valid_redirect_after_submission = this.getCampaignAdd.redirect_form_submission && (!this.requiredCheck(this.getCampaignAdd.redirect_after_submission) && !this.isValidURL(this.getCampaignAdd.redirect_after_submission))
        }
        if (!this.getCampaignAdd.redirect_form_submission) {
          this.getCampaignAdd.redirect_after_submission = null
        }
        return !Object.keys(this.getCampaignValidations.validations).every(k => this.getCampaignValidations.validations[k] === false)
      }
      return false
    },
    /**
     * checking the campaign agreement
     * @returns {boolean}
     */
    campaignAgreementValidation () {
      this.getCampaignSectionsValidations.agreement = false
      if (this.getCampaignAdd.type === 'form') {
        const agreementList = this.getCampaignAdd.agreement.map(item => item.validations)
        return !!agreementList.filter(item => (Object.keys(item).every(k => item[k] === false)) === false).length
      }
      return false
    },
    /**
     * validating the campaign custom form
     * In URL input field we're allowing user to add email, url, and tel phone with bellow examples
     * For URL validation e.g https://replug.io
     * For Tel phone validation e.g tel:+012345678
     * For email validation e.g mailto:support@replug.io
     * @returns {this is string[]}
     */
    campaignMessageFormValidation () {

      if (!this.getCampaignAdd.campaign_type.cta) {
        return false
      }

      this.getCampaignSectionsValidations.customMessage = false
      const campaign = this.getCampaignAdd

      this.getCampaignValidations.customMessageValidation.message_headline = this.requiredCheck(campaign.message_headline)
      this.getCampaignValidations.customMessageValidation.messageHeadlineLength = !this.maxLength(campaign.message_headline, 100)

      this.getCampaignValidations.customMessageValidation.message = this.requiredCheck(campaign.message)
      this.getCampaignValidations.customMessageValidation.messageLength = !this.maxLength(campaign.message, 500)

      this.getCampaignValidations.customMessageValidation.button_text = this.requiredCheck(campaign.button_text)
      this.getCampaignValidations.customMessageValidation.buttonTextLength = !this.maxLength(campaign.button_text, 100)

      this.getCampaignValidations.customMessageValidation.url = false
      this.getCampaignValidations.customMessageValidation.urlValid = false
      if (this.getCampaignAdd.type !== 'form') {
        this.getCampaignValidations.customMessageValidation.url = this.requiredCheck(campaign.button_url)
        if (!this.getCampaignValidations.customMessageValidation.url) {
          if (this.telCheck(campaign.button_url)) {
            this.getCampaignValidations.customMessageValidation.urlValid = this.checkTel(campaign.button_url.substring(4))
            if (this.getCampaignValidations.customMessageValidation.urlValid) this.getCampaignValidations.customErrorMessage.urlValid = 'Please enter a valid phone number.'
          } else if (this.mailToCheck(campaign.button_url)) {
            this.getCampaignValidations.customMessageValidation.urlValid = this.checkEmail(campaign.button_url.substring(7))
            if (this.getCampaignValidations.customMessageValidation.urlValid) this.getCampaignValidations.customErrorMessage.urlValid = 'Please enter a valid email.'
          } else {
            this.getCampaignValidations.customMessageValidation.urlValid = !this.isValidURL(campaign.button_url)
            this.getCampaignValidations.customErrorMessage.urlValid = 'Button URL is invalid, please enter a correct URL.'
          }
        }
      }

      const result = !Object.keys(this.getCampaignValidations.customMessageValidation).every(k => this.getCampaignValidations.customMessageValidation[k] === false)
      this.getCampaignSectionsValidations.customMessage = result
      return result
    },
    /**
     * checking the campaign cta type is bridge_cta
     * @returns {string|*|boolean}
     */
    isBridgeCTACampaign (isCtaTypeBridge = '') {
      return isCtaTypeBridge ? isCtaTypeBridge === 'bridge_cta' : (this.getCampaignAdd.cta_type && this.getCampaignAdd.cta_type === 'bridge_cta')
    },
    /**
     * computing the message for bridge cta
     * @param message
     * @returns {string}
     */
    bridgeCtaOptionLockMessage (message = '') {
      return (this.getCampaignAdd.cta_type && this.getCampaignAdd.cta_type === 'bridge_cta') ? message : ''
    }
  }
})
