<template>
  <div class="pagination justify-content-center">
    <ul>
      <li @click="prev()">
        <span class="cursor_pointer">
          <a>Prev</a>
        </span>
      </li>
      <li v-if="length!==0" @click="next()">
        <span class="cursor_pointer">
          <a>Next</a>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default ({
  'name': 'Pagination',
  props: {
    'from': {
      default: 0
    },
    'size': {
      default: ''
    },
    'length': {
      default: 10
    }
  },
  data: function () {
    return {}
  },
  methods: {
    getPage: function (page) {
      this.clickedMethod(page)
      //                this.$parent.$options.methods.getPageValues(page)
    },
    prev() {
      if (this.from != 0) {
        this.$emit('update', this.from - this.size)
      }
    },
    next() {
      this.$emit('update', this.from + this.size)
    }

  }
})
</script>

<style scoped lang="less">
.pagination {
  padding: 0 !important;
}
</style>
