<template>

    <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
        <is-offline></is-offline>
        <div class="container h-100 d-flex align-items-center">
          <div class="authentication-component-inner w-100 m-auto">
            <div class="logo_block text-center mt-5 mb-5 m-auto">
                    <div class="logo_block">
                        <a class="active">
                          <img width="15%" :src="getSiteDetails.login_logo" :alt="getSiteDetails.agency_name"/>
                        </a>
                    </div>

                    <!--<h1>Generate More Leads with Your Links </h1>-->
                    <!--<p>Drive traffic back to your site by adding your own branded messages to any page on the web</p>-->
                </div>
            <div class="white_box m-auto w-50">
                    <div class="box_inner mt-5">
                        <div class="box_head d-flex align-items-center justify-content-center">
                            <div class="left">
                                <h2>Recover your password</h2>
                            </div>


                        </div>
                        <div class="box_content" @keyup.enter="resetPassword()">


                            <div class="text-center" v-if="invalidToken === true"  >
                                <p>{{message}}</p>
                                <button class="mt-3 btn---cta btn-blue btn-round" @click.prevent="sendNewToken()">
                                    <span>Send New Token</span>
                                </button>

                            </div>

                            <template v-else>
                                <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                                    <div class="avatar">
                                        <i class="fal fa-user"></i>
                                    </div>
                                    <div class="content">
                                        <div class="url">New Password</div>
                                        <div class="name">
                                            <div class="validation_input">
                                                <input autocomplete="false" class="form-control" type="password" v-model="password"
                                                       id="pass" placeholder="Enter New Password">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                                    <div class="avatar">
                                        <i class="fal fa-user"></i>
                                    </div>
                                    <div class="content">
                                        <div class="url">Confirm New Password</div>
                                        <div class="name">
                                            <div class="validation_input">
                                                <input autocomplete="false" class="form-control" type="password"
                                                       v-model="confirmPassword" id="cpass" placeholder="Confirm Password">

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-4 btn_block txt-center">

                                    <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                                            @click.prevent="resetPassword()">
                                        <span>Reset Your Password</span>
                                    </button>

                                </div>
                            </template>

                        </div>


                    </div>
                </div>
            </div>

        </div>

    </div>




</template>

<script>

import LoginFooter from './LoginFooter.vue'

import { recoverTokenStatusURL, resetPasswordURL } from '@/config/config.js'
import IsOffline from '@/misc/IsOffline'
import { mapActions } from 'vuex'
export default (
  {
    components: {
      IsOffline,
      LoginFooter
    },
    data () {
      return {
        email: null,
        invalidToken: null,
        message: '',
        password: '',
        confirmPassword: ''
      }
    },
    async created () {
      await this.fetchWhitelabelAppSettings()
      this.getRecoverTokenStatus()
    },
    methods: {
      ...mapActions(['setUnauthorizedException', 'fetchWhitelabelAppSettings']),
      getRecoverTokenStatus () {
        this.$http.post(recoverTokenStatusURL, { token: this.$route.params.token }).then(
          response => {
            if (this.processAjaxResponse(response)) {
              if (response.data.status == true) {
                this.invalidToken = false
              } else {
                this.invalidToken = true
                this.message = response.data.message
                this.alertMessage(response.data.message, 'error')
              }
            }
          },
          response => {
            this.alertMessage(response.data.message, 'error')
          }
        )
      },
      resetPassword () {
        if (this.password == '') {
          this.alertMessage('Please enter password.', 'error')
        } else if (this.confirmPassword == '') {
          this.alertMessage('Please confirm your password.', 'error')
        } else if (this.password != this.confirmPassword) {
          this.alertMessage('Password and confirm password does not match.', 'error')
        } else {
          this.$http.post(resetPasswordURL, {
            token: this.$route.params.token,
            password: this.password,
            confirm_password: this.confirmPassword
          }).then(
            response => {
              if (this.processAjaxResponse(response)) {
                if (response.data.status == false) {
                  this.alertMessage(response.data.message, 'error')
                } else {
                  this.alertMessage('Password is successfully changed.', 'success')
                  this.$router.push({
                    name: 'login'
                  })
                }
              }
            },
            response => {
              this.alertMessage('Unknown error occurred, support has been notified.', 'error')
            })
        }
      },
      sendNewToken () {
        this.$router.push({
          path: '/forgot_password'
        })
      }
    }
  }
)
</script>
