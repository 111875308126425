import {userMaven} from '../config/config'
import {bio_content_type} from './attributes'

/**
 * ***************************************************
 * USER MAVEN Methods
 * ***************************************************
 */
const userMavenCommonPayload = (profile) => {
  const {_id, email, first_name, last_name, created_at} = profile
  const organization = profile.organization
  return {
    // Required attributes
    id: _id, // Unique ID for the user in database.
    email: email, // Email address for the user.

    // Recommended attributes
    created_at: created_at,

    first_name: first_name,
    last_name: last_name,

    // Company Attributes
    company: {
      // Required Attributes
      id: organization._id, // Company ID
      name: organization.first_name + ' ' + organization.last_name, // Company Name.
      created_at: organization.created_at
    }
  }
}

const userMavenCommonCustomPayload = (profile) => {
  const {
    totalBrands, total_campaigns, total_links, total_pixels, totalIntegrations, totalReports,
    totalRss, totalBio
  } = profile
  return {
    total_brands: totalBrands || 0,
    total_campaigns: total_campaigns || 0,
    total_links: total_links || 0,
    total_pixels: total_pixels || 0,
    connected_integrations: totalIntegrations || 0,
    automated_reports: totalReports || 0,
    rss_automation: totalRss || 0,
    bio_links: totalBio || 0
  }
}

const AnalyticCustomEvent = async (profile, eventName, customPayload = {}) => {
  try {
    const userMavenPayload = {
      custom: customPayload
    }
    await userMaven.track(eventName, userMavenPayload)
    // await analyticsConfig.track(eventName, userMavenPayload)
  } catch (e) {
    console.debug(e)
  }
}

/**
 * Method must get this.getProfile in parameters
 * @param profile
 * @param subscription
 * @returns {{custom: {subscription_id, total_campaigns, total_links, current_plan, total_pixels, connected_integrations, bio_links, automated_reports, rss_automation, total_brands, subscription_type_of}, created_at: *, last_name: *, company: {name: string, created_at: *, id: *}, id: *, first_name: *, email: *}}
 */
const userMavenIdentityPayload = (profile = {}, subscription = {}) => {
  const {fastspring_name, _id, type_of} = subscription
  return {
    ...userMavenCommonPayload(profile),
    custom: {
      current_plan: fastspring_name,
      subscription_id: _id,
      subscription_type_of: type_of,
      ...userMavenCommonCustomPayload(profile)
    }
  }
}

export const AnalyticIdentityEvent = async (profile, subscription) => {
  try {
    const userMavenPayload = userMavenIdentityPayload(profile, subscription)
    await userMaven.id(userMavenPayload)
    // await analyticsConfig.identify(userMavenPayload.id, userMavenPayload);
  } catch (e) {
    console.debug(e)
  }
}

export const trackSignUpEvent = async (user_id, email, first_name, last_name, subscription_name) => {
  try {
    const userMavenPayload = {
      custom: {
        current_plan: subscription_name
      }
    }
    await userMaven.track('signed_up', userMavenPayload)
    // await analyticsConfig.track('signed_up', userMavenPayload)
  } catch (e) {
    console.debug(e)
  }
}

export const trackBrandCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.total_brands += 1
  await AnalyticCustomEvent(profile, 'brand_created', customPayload)
}

export const trackCampaignCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.total_campaigns += 1
  await AnalyticCustomEvent(profile, 'campaign_created')
}

export const trackLinkCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.total_links += 1
  await AnalyticCustomEvent(profile, 'link_created')
}

export const trackBioLinkCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.bio_links += 1
  await AnalyticCustomEvent(profile, 'bio_link_created')
}

export const trackRSSAutomationCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.rss_automation += 1
  await AnalyticCustomEvent(profile, 'rss_automation_created')
}

export const trackReportCreated = async (profile) => {
  const customPayload = userMavenCommonCustomPayload(profile)
  customPayload.automated_reports += 1
  await AnalyticCustomEvent(profile, 'report_created')
}

/**
 * Strip fields and pass on few data only
 * @param payload
 * @returns {{channels: *, social_color: *, show_title: *, channels_option: *, _id: *, type: *, order: *}|{schedule_enabled: *, height_enabled: *, _id: *, type: *, title: *, url: *, order: *}|{}|{show_title: *, links: *, _id: *, type: *, order: *}|{rss: *, show_title: *, _id: *, type: *, posts: *, order: *}}
 */
export const clean_payload = (payload = {}) => {
  const type = payload.type || ''
  const common = (({_id, type, order}) => ({_id, type, order}))(payload)
  // social bio link
  if (type === bio_content_type.social) {
    const clean = (({show_title, channels, channels_option, social_color}) =>
      ({show_title, channels, channels_option, social_color}))(payload)
    if (payload.show_title) clean.title = payload.title
    // clean channels
    clean.channels = Object.keys(clean.channels).reduce((obj, curr) => {
      if (clean.channels[curr]) {
        obj[curr] = clean.channels[curr]
      }
      return obj
    }, {})

    return {...common, ...clean}
  }

  // links bio link
  if (type === bio_content_type.links) {
    const clean = (({show_title, links}) => ({show_title, links}))(payload)
    if (payload.show_title) clean.title = payload.title

    clean.links = clean.links.map(link => ({
      id: link.id,
      title: link.title,
      url: link.url,
      show_links: link.show_links
    }))

    return {...common, ...clean}
  }

  // rss bio link
  if (type === bio_content_type.rss) {
    const {show_title, title, rss, posts, style, animation_enabled, card_number} = payload
    const clean = {show_title, title, rss, posts, style, animation_enabled, card_number}
    if (payload.animation_enabled) clean.animation = payload.animation
    if (payload.schedule_enabled) clean.schedule = payload.schedule
    if (payload.show_title) clean.title = payload.title

    return {...common, ...clean}
  }

  // audio bio link case
  if (type === bio_content_type.audio) {
    const clean = (({title, url, height_enabled, schedule_enabled}) =>
      ({title, url, height_enabled, schedule_enabled}))(payload)
    if (payload.height_enabled) clean.height = payload.height
    if (payload.schedule_enabled) clean.schedule = payload.schedule

    return {...common, ...clean}
  }

  // video bio link case
  if (type === bio_content_type.video) {
    const clean = (({title, url, height_enabled, schedule_enabled, embed_url}) => ({title, url, height_enabled, schedule_enabled, embed_url}))(payload)
    if (payload.height_enabled) clean.height = payload.height
    if (payload.schedule_enabled) clean.schedule = payload.schedule

    return {...common, ...clean}
  }

  // card bio link
  if (type === bio_content_type.card) {
    const {title, description, image, style, url, animation_enabled, schedule_enabled} = payload
    const clean = {title, description, image, style, url, animation_enabled, schedule_enabled}
    if (payload.animation_enabled) clean.animation = payload.animation
    if (payload.schedule_enabled) clean.schedule = payload.schedule

    return {...common, ...clean}
  }

  // card text block
  if (type === bio_content_type.text) {
    const {text, animation_enabled} = payload
    const clean = {text, animation_enabled}
    if (payload.animation_enabled) clean.animation = payload.animation
    return {...common, ...clean}
  }

  return payload
}
