<template>
  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner w-50 m-auto">
        <div class="header_content mt-5 mb-5 text-center clear">
          <div class="logo_block">
            <a class="active">
              <!--                            <img src="/assets/img/replug_logo_black_text.png" alt="replug">-->
              <img width="200" :src="getSiteDetails.login_logo" alt="replug">

            </a>
          </div>

          <!--<h1>Generate More Leads with Your Links </h1>-->
          <!--<p>Drive traffic back to your site by adding your own branded messages to any page on the web</p>-->
        </div>
        <div class="white_box">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center justify-content-center">
              <div class="left">
                <h2>Update Your Email Address</h2>
              </div>


            </div>
            <div class="box_content p-5">


              <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                <div class="avatar">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <div class="url">Username/Email</div>
                  <div class="name">
                    <div class="validation_input">
                      <input type="text" class="form-control" name="email" id="email"
                             @keyup.enter="updateEmail()"
                             placeholder="Your username or email address" v-model="email">
                      <span class="input-error" v-if="validations.email">
                                                {{ messages.email }}
                                            </span>
                      <span class="input-error" v-if="validations.validEmail">
                                                {{ messages.validEmail }}
                                            </span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="mt-4 btn_block txt-center">

                <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                        @click.prevent="updateEmail()">
                  <span>Update Email</span>

                  <clip-loader v-if="loader" :color="color" :size="size"></clip-loader>
                </button>

              </div>
              <div class="bottom_link mt-3 text-center">

                <router-link style="color: #3988fe;font-size: 16px;" :to="{ path: 'logout' }"
                             class="login_lin">
                  Log out from your account!
                </router-link>

              </div>


            </div>


          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import LoginLeft from './LoginLeft.vue'
import LoginFooter from './LoginFooter.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import {mapActions, mapGetters} from 'vuex'

export default ({
  components: {
    LoginLeft,
    ClipLoader,
    LoginFooter
  },
  data () {
    return {
      email: null,
      size: '20px',
      color: '#ffffff',
      loader: false,
      messages: {
        email: 'Please enter email address.',
        validEmail: 'Please enter valid email address'
      },
      validations: {
        email: false,
        validEmail: false
      }
    }
  },
  async created () {
    this.$store.dispatch('fetchProfile')
    await this.fetchWhitelabelAppSettings()
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    updateEmail () {
      const data = {
        email: this.email,
        verification_token: this.getProfile.verification_token
      }
      if (!this.validations.email && !this.validations.validEmail) {
        this.loader = true
        this.$store.dispatch('updateEmail', data).then(
          res => {
            this.loader = false
            if (res.data.status) {
              this.alertMessage(res.data.message, 'success')
            } else {
              this.alertMessage(res.data.message, 'error')
            }
          }, err => {
            console.warn(err)
          })
      }
    }
  },
  watch: {
    'email' (value) {
      if (value && value.trim().length > 0) {
        this.validations.validEmail = this.checkEmail(this.email)
        this.validations.email = false
      } else {
        this.validations.validEmail = false
        this.validations.email = true
      }
    },
  }
})
</script>
