<template>
  <b-dropdown right
              ref="campaigns_dropdown_quick_link"
              class="rplg_dropdown dropdown-menu-right"
              :no-caret="true">
    <div class="text-center dropdown_header no_arrow" slot="button-content">
      <div class="text">

        <div class="d-flex align-items-center profile__box">
          <div class="avatar">
            <i class="fal fa-folder"></i>
          </div>
          <div class="content">
            <div class="url">Campaigns</div>
            <div class="name">
              <p>Select your campaign</p>
            </div>
          </div>
        </div>

      </div>
      <span class="arrow_icon ml-auto">
        <i class=" fal fa-angle-down ml-3"></i>
      </span>
    </div>

    <ul class="clear inner">

      <li class="list_item_li">
        <div class="radio_input_image ">
          <div class="d-flex align-items-center profile__box">
            <div class="content">
              <div class="name">
                Marketing
              </div>
            </div>
          </div>
        </div>
      </li>

    </ul>
  </b-dropdown>
</template>

<script>
export default {
  name: 'rp-dropdown',
  props: {
    labelText: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: 'rgba(49, 58, 81, 0.6)'
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#1f216e'
    },
    color: {
      type: String,
      default: '#3988fe'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: '#3988fe'
    },
    validations: {
      type: Array,
      default: []
    },
    errorTextColor: {
      type: String,
      default: '#ffffff'
    },
    errorBgColor: {
      type: String,
      default: '#f2405d'
    }
  },
  computed: {
    cssProps () {
      return {
        '--error-bg-color': this.errorBgColor
      }
    }
  },
  created () {
    this.validations = [
      {
        message: 'URL is invalid',
        show: true
      }
    ]
  },
  methods: {}
}
</script>

<style lang="less">
.rplg_dropdown {
  width: 100%;

  .dropdown-toggle {
    padding: 0;
    background: transparent !important;
    border: 0 !important;
    width: auto;
    min-width: 100%;
    border-radius: 0;
    color: initial;
    opacity: 1 !important;
    outline: none !important;
    box-shadow: none !important;

    .arrow_icon {
      i {
        .ui_transition;
        transform: rotate(0deg);
      }
    }


    &:after {
      display: none;
    }

    .dropdown_header {
      .ui-box-shadow(0 2px 20px 0 rgba(162, 174, 209, 0.11));
      background-color: #ffffff;
      padding: 10px 15px;
      .ui-border-radius(10px);

      .profile__box {
        .avatar {
          margin-right: 15px;

          i {
            color: #3988fe;
          }
        }

        .content {
          text-align: left;

          .url {
            color: #b3b3c3;
            font-size: 12px;
          }

          .name {
            p {
              font-size: 14px;
              color: #1f216e;
            }
          }
        }
      }
    }
  }

  .no_arrow {
    .arrow_icon {
      display: none !important;
    }
  }

  .dropdown-menu {
    outline: none !important;
    width: 100%;
    margin-top: 8px;
    padding: 0;
    -webkit-box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
    -moz-box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);
    box-shadow: 0 4px 10px 0 rgba(184, 189, 209, 0.3);

    background-color: #ffffff;
    border: solid 1px rgba(102, 107, 133, 0.05);
    min-width: 180px;
    .ui-border-radius(10px);

    &:before, &:after {
      position: absolute;
      display: inline-block;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    &:before {
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #ebebeb;
    }

    &:after {
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
    }

    .inner {
      max-height: 280px;
      overflow: auto;
      padding: 10px 0;

      &::-webkit-scrollbar {
        width: 8px;
      }
    }

    li.list_item {
      a {
        padding: 7px 20px;
        display: block;
        color: #1f216e;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    li.list_item_li {
      padding: 7px 20px;
      display: block;
      color: #1f216e;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .no_text_li {
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }
  }

  .dropdown-menu.full_width {
    width: 100%;
  }

  .dropdown-menu[x-placement="bottom-start"], .dropdown-menu[x-placement="bottom-end"] {
    &:after {
      top: -6px;
    }

    &:before {
      top: -7px;
    }
  }

  .dropdown-menu[x-placement="top-start"], .dropdown-menu[x-placement="top-end"] {
    margin-top: 0;
    margin-bottom: 8px;

    &:after, &:before {
      transform: rotate(180deg);
    }

    &:after {
      bottom: -6px;
    }

    &:before {
      bottom: -7px;
    }
  }

  .dropdown_header[data-display="static"] + .dropdown-menu {
    &:after {
      top: -6px;
    }

    &:before {
      top: -7px;
    }
  }
}


.ui-box-shadow(@shadow:0 0 50px 0 rgba(162, 174, 209, 0.15)) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.ui-border-radius (@radius: 3px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.ui_transition {
  -webkit-transition: all .2s ease-in !important;
  -moz-transition: all .2s ease-in !important;
  -o-transition: all .2s ease-in !important;
  transition: all .2s ease-in !important;
}
</style>
