<template>
  <b-modal :id="modalId" hide-footer hide-header modal-class="bv-modal">
    <div class="modal-container">
      <div class="headline-section">
        <div class="headline-text">
          <h4>Confirm your password</h4>
        </div>
        <div class="headline-close">
          <i class="fal fa-times c-pointer" @click="$bvModal.hide(modalId)"></i>
        </div>
      </div>
      <div class="modal-content no-border">

        <div class="input_group password-confirmation-dialog ">
          <div class="icon-section">
            <i class="fal fa-lock"></i>
          </div>
          <div class="text-section">
            <p>Please confirm your password to make changes to your account.</p>
          </div>
        </div>

        <transition name="slide-bottom">
          <template v-if="error">
            <div class="input_group d-flex justify-center">
              <alert :variant="'danger'">{{error}}</alert>
            </div>
          </template>
        </transition>
        <div class="input_group">
          <label for="" class="d-block">Current password</label>
          <input type="password" class="input-field d-block"
                 @keyup.enter="verifyCurrentPasswordAction()"
                 v-model="password_confirmation_form.password"
                 :class="{'input-field-error': !validation_passed.password}"

          >
          <transition name="slide-bottom">
            <input-field-error v-if="!validation_passed.password">
              Current password is required.
            </input-field-error>
          </transition>
        </div>
      </div>
    </div>

    <div class="modal-content-footer">
      <div class="left-section">
        <div class="input_group">
          <button @click="$bvModal.hide(modalId)"
                  class="ia-button ia-button-grey">Cancel
          </button>
        </div>
      </div>
      <div class="right-section">
        <div class="input_group">
          <button @click="verifyCurrentPasswordAction" :disabled="password_confirmation_form.password.length === 0 || loaders.verify"
                  class="ia-button loader-right"><span><template v-if="loaders.verify">Verifying...</template><template v-else>Confirm & Save</template><clip-loader v-if="loaders.verify" :color="'#FFFFFF'"/></span>
          </button>
        </div>
      </div>

    </div>

  </b-modal>

</template>
<style lang="less" scoped>
  .password-confirmation-dialog {
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    background: #F2F9F7;
    margin: 15px 0;
    .icon-section {
      font-size: 24px;
      padding: 0 15px;
    }
    .text-section {
      padding-left: 15px;
    }
  }
</style>
<script>
  import requests from '@/mixins/http-lib'
  import {API_URL} from "../../constants";

  export default ({
    props: {
      modalId: {
        default: ''
      },
    },
    name: 'password-confirmation',
    data() {
      return {
        password_confirmation_form: {
          password: ''
        },
        validation_passed: {
          password: true
        },
        error: '',
        loaders: {
          verify: false
        }
      }
    },
    methods: {
      verifyCurrentPasswordAction() {
        this.loaders.verify = true
        this.error = ''
        requests.post(`${API_URL}auth/verify_password`, this.password_confirmation_form).then(res => {
          this.loaders.verify = false
          this.$emit('click')
          this.$bvModal.hide(this.modalId)
        }).catch(err => {
          if (err.response.status === 422) {
            this.error = err.response.data.error
            this.loaders.verify = false
          }
        })
      }
    }
  })
</script>
