import {mapActions, mapGetters} from 'vuex'
import {INTEGRATION_COOKIE} from '../../constants'
import vueCookie from 'vue-cookie'
import {countryMixin} from './CountryMixin'
import {validLength, featuresList, searchFiltersMessages, animationList, brandValidations} from '@/common/attributes'
import {brandTypes, campaignTypes} from '@/state/modules/mutation-types'
import {ROLES} from '@/common/constants'
import http from "@/mixins/http-lib";
import {saveWhiteLabel} from "@/config/config";

const helperMixin = {
  data () {
    return {
      validLength: validLength,
      featuresList: featuresList,
      searchFiltersMessages: searchFiltersMessages,
      debounce: false,
      animationList: animationList
    }
  },
  methods: {

    ...mapActions(['setProfile', 'setAllCallToActionsFetchStatus',
      'setLinksFetchStatus',
      'setAllBrandsFetchStatus',
      'setBrandsFetchStatus',
      'fetchCampaignsList',
      'setComponentCampaignList',
      'fetchBrandList',
      'setComponentBrandList',
      'storeWhiteLabel',
      'fetchWhitelabelAppSettings'
    ]),

    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    urlCheck (value) {
      const urlRegExp = /^(http\:\/\/|https\:\/\/|www|mailto\:)(.{4,})$/
      return urlRegExp.test(value)
    },
    phoneNoCheck (value) {
      const phoneRegex = /^[0-9-+]*$/
      return phoneRegex.test(value)
    },

    isValidURL (string) {
      const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
      if (res == null) return false
      return true
    },
    // In this method we are appling by forcely https to if custom domain is has a CTA and Custom widget other wise we user can add https or http
    isCTAConcatenation (url) {
      const campaign = this.getCampaignName(this.getSaveLink.call_to_action_id)
      if (!campaign) {
        return url
      }
      const {cta, third_party} = campaign.campaign_type
      if (cta || third_party) {
        if (!url.includes('https://') && !url.includes('http://')) {
          return 'https://' + url
        } else if (url.includes('http://')) {
          return 'https://' + url.substring(7)
        } else return url
      } else {
        if (!url.includes('https://') && !url.includes('http://')) {
          return 'https://' + url
        } else return url
      }
    },
    urlConcatenation (url) {
      if (!url.includes('https://') && !url.includes('http://')) {
        return 'https://' + url
      } else return url
    },
    focusoutConcatenation (url) {
      if (this.telCheck(url) || this.mailToCheck(url)) return url
      if (url) {
        if (!url.includes('https://') && !url.includes('http://')) {
          return 'https://' + url
        } else return url
      }
    },
    checkWhiteSpace (string) {
      const regx = /^\S*$/g
      return regx.test(string)
    },

    telCheck (value) {
      return value && value.substring(0, 4) === 'tel:' && value.length > 4
    },
    mailToCheck (value) {
      return value && value.substring(0, 7) === 'mailto:' && value.length > 7
    },
    trimUrl (value) {
      value = value.replace('http://', '')
      return value.replace('https://', '')
    },

    limitTextLength (text, lenghtLimit) {
      if (text) {
        if (lenghtLimit && text.length > lenghtLimit) {
          text = text.substring(0, lenghtLimit) + '...'
        }
      }

      return text
    },

    limitLengthWithoutDots (text, length) {
      if (text) {
        if (length && text.length > length) {
          text = text.substring(0, length)
        }
      }

      return text
    },

    momentCaptilize (date) {
      if (date && date.charAt(0) == 'a') {
        return date.charAt(0).toUpperCase() + date.slice(1)
      }
    },

    backgroundImage (image, isProfile = true) {
      return isProfile && image && image.profile_image ? image.profile_image : (image && !isProfile ? image : '/assets/img/profile-default.png')
    },

    stripHtmlTags (text) {
      // for decoding html entities
      const txt = document.createElement('textarea')
      txt.innerHTML = text
      text = txt.value

      text = text.replace(/(<([^>]+)>)/ig, '')
      text = text.trim()

      return text
    },

    limitTextWords (text, wordsLimit) {
      if (text) {
        text = this.stripHtmlTags(text)
        let totalWords = 0
        if (text.match(/\S+/g)) {
          totalWords = text.match(/\S+/g).length
        }

        if (totalWords > wordsLimit) {
          // Split the string on first wordsLimit words and rejoin on spaces
          return text.split(/\s+/, wordsLimit).join(' ') + '...'
        }
      }

      return text
    },

    copyInvitationLinkSuccess () {
      this.alertMessage('Invitation link has been copied to clipboard.', 'success')
    },

    copyInvitationLinkError () {
      this.alertMessage('An error ocurred while copying invitation link.', 'error')
    },

    getMemberRole (profile) {
      // NOTE: Do not remove below comments. This shall be used to map numeric values to actual roles used in legacy code.
      // switch (member.role) {
      //   case '0':
      //   case 0:
      //     return 'Admin'
      //   case 1:
      //   case '1':
      //     return 'Collaborator'
      //   case 2:
      //   case '2':
      //     return 'Guest'
      //   default:
      //     return 'Super Admin'
      // }
      return (profile && profile.role) ? profile.role : ROLES.guest
    },

    checkNotClient () {
      return !(this.getProfile.username && this.getProfile.role === '2')
    },

    getIntegrationCount () {
      let total = 0
      if (this.getProfile.constantcontact) {
        total = total + this.getProfile.constantcontact.length
      }
      if (this.getProfile.aweber) {
        total = total + this.getProfile.aweber.length
      }
      if (this.getProfile.getresponse) {
        total = total + this.getProfile.getresponse.length
      }
      if (this.getProfile.drip) {
        total = total + this.getProfile.drip.length
      }
      if (this.getProfile.convertfox) {
        total = total + this.getProfile.convertfox.length
      }
      if (this.getProfile.activecampaign) {
        total = total + this.getProfile.activecampaign.length
      }
      if (this.getProfile.mailchimp) {
        total = total + this.getProfile.mailchimp.length
      }
      if (this.getProfile.mailerlite) {
        total = total + this.getProfile.mailerlite.length
      }
      if (this.getProfile.convertkit) {
        total = total + this.getProfile.convertkit.length
      }
      if (this.getProfile.sendPulse) {
        total = total + this.getProfile.sendPulse.length
      }
      if (this.getProfile.sendlane) {
        total = total + this.getProfile.sendlane.length
      }
      if (this.getProfile.campaignmonitor) {
        total = total + this.getProfile.campaignmonitor.length
      }
      if (this.getProfile.benchmark) {
        total = total + this.getProfile.benchmark.length
      }
      return total
    },

    checkTeamLimits (data, userId, type = '') {
      let status = false
      if (type) {
        if (userId === '' && data.length > 0) {
          return true
        }
        if (data.length > 0) {
          data.forEach(item => {
            if (item._id !== userId) {
              status = true
              return false
            }
          })
        }
        return status
      }

      if (userId === '' && data.length > 0) {
        return true
      }
      if (data.length > 0) {
        data.forEach(item => {
          if (item.user_id !== userId) {
            status = true
            return false
          }
        })
      }
      return status
    },

    showUpgradeModal () {
      this.$bvModal.show('upgrade-subscription-modal')
    },

    loadSocketBroadcast () {
      const stateObject = this

      socket.on('bulk_link_complete:' + stateObject.getProfile.username, function (data) {
        stateObject.bulkLinkProcessingComplete(data)
      })
    },

    checkTeamSelection (id, data) {
      if (data && data.includes(id)) {
        return 'checked'
      } else {
        return false
      }
    },

    showLiveWidgets (signature = null) {
      // beacon
      try {
        const self = this
        setTimeout(function () {
          try {
            Beacon('identify', {
              user_id: self.getProfile._id,
              name: self.getProfile.first_name + ' ' + self.getProfile.last_name,
              first_name: self.getProfile.first_name,
              last_name: self.getProfile.last_name,
              email: self.getProfile.email,
              current_plan: self.getSubscription.fastspring_name,
              signature: signature
            })
          } catch (e) {
            console.debug(e)
          }
        }, 10000)
      } catch (m) {
        // if an exception occurs, show gosquared widget.
        console.log(m)
      }
    },

    onCopy () {
      this.alertMessage('Your link has been copied to clipboard', 'success')
    },

    onError () {
      this.alertMessage('An error ocurred while copying link.', 'error')
    },

    getMonthName (date) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]

      const d = new Date(date)
      return monthNames[d.getMonth()]
    },

    fetchBillingDate (date) {
      const d = new Date(date)
      return this.getMonthName(d) + ' ' + d.getDate() + ', ' + d.getFullYear()
    },

    numberFormatter (num, digits = 0) {
      const si = [
        {value: 1, symbol: ''},
        {value: 1E3, symbol: 'k'},
        {value: 1E6, symbol: 'M'},
        {value: 1E9, symbol: 'G'},
        {value: 1E12, symbol: 'T'},
        {value: 1E15, symbol: 'P'},
        {value: 1E18, symbol: 'E'}
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      let i
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
          break
        }
      }
      return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
    },

    getCurrentMonthDateRange () {
      let d1 = new Date()
      d1.setDate(1)
      d1.setHours(0)
      d1.setMinutes(0)
      d1.setSeconds(0)
      d1 = d1.toISOString().slice(0, -5)

      let d2 = new Date()
      d2.setHours(23)
      d2.setMinutes(59)
      d2.setSeconds(59)
      d2 = d2.toISOString().slice(0, -5)

      return d1 + ' _ ' + d2
    },
    commaSeparatedNumber (num) {
      num = num || 0
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getHostName (hostName) {
      return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
    },
    getParamsFromURL () {
      return {
        search: this.$route.query.search || '',
        archive: this.$route.query.archive === 'true',
        page: parseInt(this.$route.query.page) || 1,
        limit: parseInt(this.$route.query.limit) || 10
      }
    },
    /**
     * Integration payload is saved in cookie when "Reconnect" is clicked under "configured integrations".
     * This method retrieves those integrations
     * @returns object
     */
    getIntegrationPayload () {
      let integration = vueCookie.get(INTEGRATION_COOKIE) || {}
      if (integration && Object.keys(integration).length) {
        integration = JSON.parse(integration)
        vueCookie.delete(INTEGRATION_COOKIE)
      }
      return integration
    },
    /**
     * We need to improve this approach in future by getting all plan names that have 'trial' key true in them and
     * check if the current user's subscription plan is within the list of those plans
     * @param getSubscription
     * @returns {null|(function(*=): *)|*|boolean}
     */
    isTrialUser (getSubscription) {
      if (getSubscription && getSubscription.fastspring_name && getSubscription.fastspring_name.toLowerCase().indexOf('trial') !== -1) {
        return {
          message: 'Update your plan to edit slug',
          isTrial: true
        }
      }
      return {
        message: '',
        isTrial: false
      }
    },
    /**
     * Checking the validation of AB testing object
     * @param abTestingObj
     * @returns {boolean}
     */
    isValidWeight (split_urls) {
      if (!split_urls.length) {
        return true
      }
      return split_urls.reduce((initialValue, currentValue) => {
        initialValue += parseFloat(currentValue.weight)
        return initialValue
      }, 0) === 100 && !split_urls.find(el => el.weight == 0)
    },

    /**
     * Processing the stats (country,browser,deviceType,referral) to calculate the bar size to display in bar chart dashboard
     * @param stats
     * @param is_flag
     * @param imageMethod = name of the method used to fetch image
     * @returns {Promise<*>}
     */
    async processBarChartsStats (stats, is_flag = false, imageMethod = 'fetchBrowserIcon') {
      let totalDocsCount = 0
      if (stats && stats.length) {
        totalDocsCount = stats.reduce((initialValue, currentValue) => initialValue + currentValue.doc_count, 0)
      }

      return stats.map(item => {
        const obj = {
          doc_count: item.doc_count,
          key: item.key,
          barLength: ((item.doc_count / totalDocsCount) * 100),
          is_flag
        }
        if (!is_flag) {
          obj.image = countryMixin.methods[imageMethod](item.key)
        }
        return obj
      })
    },

    validateIpAddress (ipaddress) {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return true
      }
      return false
    },
    checkSlugLength (slug) {
      return slug.trim() && slug.length > this.validLength.slug_length
    },
    isFeatureAllowedInPlan (featureName = '', self = this) {
      return self.getSubscription && self.getSubscription.limits && self.getSubscription.limits[featureName]
    },
    computeLimitsProgressColor (userClicks, subscriptionClicks) {
      return (userClicks >= subscriptionClicks) ? 'danger' : ''
    },
    computeClickLimits (userClicks, subscriptionClicks) {
      return (userClicks >= subscriptionClicks) ? subscriptionClicks : userClicks
    },
    getFilteredMessage (called = '', search = '', label = 'All Time', archive = false, brands = [], campaign = [], tags = []) {
      if (search.length || label.trim() !== 'All Time' || brands.length || campaign.length || tags.length) return this.searchFiltersMessages('').filters
      return archive ? this.searchFiltersMessages(called).archived_list : this.searchFiltersMessages(called).empty_list
    },

    /**
     * Lazy loading on scroll
     * @param id
     * @param apiName
     * @returns {Promise<void>}
     */
    async lazyScrollHelper (id = '', apiName = '', adjust = 0) {
      const div = document.getElementById(id)

      if (this.getCampaignLoaders.retrieve) return true

      if (Math.ceil(div.scrollHeight - div.scrollTop) - adjust === div.offsetHeight) {
        if (apiName === 'fetchCampaignsList') {
          const page = this.getCampaigns.page + 1
          // setting isAllListFetched status true if all campaigns fetched from DB
          if (this.getCampaigns.isAllListFetched) return
          this.getCampaigns.isListFetched = (page <= this.getCampaigns.total_pages)

          if (page <= this.getCampaigns.total_pages) {
            await this.fetchCampaignsList({page: page})
            // setting isAllListFetched status true if all campaigns fetched from DB
            this.getCampaigns.isAllListFetched = (page >= this.getCampaigns.total_pages)
          }
        }
      }
    },
    /**
     * this will return is record fetched in periodic way
     * @param previousPage
     * @param currentPage
     * @param periodic
     * @returns {boolean}
     */
    isFetchedPeriodic (previousPage, currentPage, periodic) {
      return (((previousPage + 1) === currentPage || ((previousPage) - 1) === currentPage) && periodic)
    },

    /**
     * Reset the campagin filters if called from dropdown in filters are set from campaign
     * @param isDropdownCalled
     */
    resetCampaignFilters (isDropdownCalled = false) {
      if (!isDropdownCalled) return
      if (this.getCampaigns.archive || this.getCampaigns.date.label !== ' All Time' || this.getCampaigns.search.length || this.getCampaigns.selected_brands.length) {
        this.getCampaigns.archive = false
        this.getCampaigns.date.label = ' All Time'
        this.getCampaigns.date.value = ''
        this.getCampaigns.search = ''
        this.getCampaigns.selected_brands = []

        this.getCampaigns.isListFetched = false
        this.getCampaigns.periodic = true
        this.getCampaigns.isAllListFetched = false
      }
    },
    /**
     * fetching the campaigns on the basis of clicking on campaign dropdown
     * @returns {Promise<void>}
     */
    async fetchCampaignOnDropdown () {
      // if archive status true then we will reset the all fetch status
      this.resetCampaignFilters(true)

      // calling api for first time on clicking dropdown and other pages will be called on scrolling
      if ((this.getCampaigns.isListFetched && this.getCampaigns.periodic) || this.getCampaigns.isAllListFetched) {
        await this.setComponentCampaignList(JSON.parse(JSON.stringify(this.getCampaignsList)))
        return
      }

      if (this.getCampaignLoaders.retrieve) return

      // getting the campaigns from api and setting to the local component list
      this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, [])
      await this.fetchCampaignsList({page: 1})
      await this.setComponentCampaignList(JSON.parse(JSON.stringify(this.getCampaignsList)))
      this.$store.commit(campaignTypes.SET_IS_LIST_FETCHED, true)
    },
    /**
     * Calling the methode on scroll on campaign dropdown
     * @param id
     * @param apiName
     * @returns {Promise<void>}
     */
    async lazyScroll (id = '', apiName = '', adjust = 0) {
      await this.lazyScrollHelper(id, apiName, adjust)
      await this.setComponentCampaignList(JSON.parse(JSON.stringify(this.getCampaignsList)))
    },

    /**
     * searching campaigns in from campaign dropdown
     * @param event
     */
    debounceCampaignDropdownSearch (event) {
      this.getCampaigns.search = event.target.value

      // if all record fetched then we search locally maintained list in state
      if (this.getCampaigns.isAllListFetched) {
        const list = JSON.parse(JSON.stringify(this.getCampaignsList))
        this.setComponentCampaignList(list.filter(item => item.name.toLowerCase().includes(this.getCampaigns.search.toLowerCase())))
        return
      }
      this.getCampaignLoaders.retrieve = true
      this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_IS_LIST_FETCHED, true)
      this.$store.commit(campaignTypes.SET_IS_ALL_LIST_FETCHED, false)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        // if no record found from above then search from api
        await this.fetchCampaignsList({page: 1})
        await this.setComponentCampaignList(JSON.parse(JSON.stringify(this.getCampaignsList)))
      }, 600)
    },
    /**
     * fetching the campaigns on the basis of 'isListFetched or isAllListFetched'
     * @returns {Promise<void>}
     */
    async fetchCampaignOnFetchedStatus () {
      // chunck the local record if fetched status true
      this.getCampaignLoaders.retrieve = false
      if (this.getCampaigns.isListFetched || this.getCampaigns.isAllListFetched) {
        this.getCampaigns.page = 1
        await this.setComponentCampaignList(JSON.parse(JSON.stringify(this.getCampaignsList.slice(0, 10))))
        return
      }
      // getting the campaigns from database and setting to the local component list
      this.$store.commit(campaignTypes.SET_IS_LIST_FETCHED, true)
      this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, [])
      await this.fetchCampaignsList({page: 1})
      await this.setComponentCampaignList(this.getCampaignsList)
    },

    /**
     * fetching the brands on the basis of 'isListFetched or isAllListFetched'
     * @returns {Promise<void>}
     */
    async fetchBrandOnFetchedStatus () {
      // chunck the local record if fetched status true
      this.getBrandLoaders.retrieve = false
      if (this.getBrand.isListFetched || this.getBrand.isAllListFetched) {
        this.getBrand.page = 1
        await this.setComponentBrandList(this.getBrandList.slice(0, 10))
        return
      }

      // getting the campaigns from database and setting to the local component list
      this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
      this.$store.commit(brandTypes.SET_COMPONENT_BRAND_LIST, [])
      await this.fetchBrandList({page: 1})
      await this.setComponentBrandList(this.getBrandList)
      this.getBrand.isListFetched = true
    },

    /**
     * Reset the brand filters if called from dropdown in filters are set from campaign
     * @param isDropdownCalled
     */
    resetBrandFilters (isDropdownCalled = false) {
      if (!isDropdownCalled) return
      if (this.getBrand.archive || this.getBrand.date.label !== ' All Time' || this.getBrand.search.length) {
        this.getBrand.archive = false
        this.getBrand.date.label = ' All Time'
        this.getBrand.date.value = ''
        this.getBrand.search = ''

        this.getBrand.isListFetched = false
        this.getBrand.periodic = true
        this.getBrand.isAllListFetched = false
      }
    },
    /**
     * fetching the brands on the basis of clicking on brand dropdown
     * @returns {Promise<void>}
     */
    async fetchBrandOnDropdown () {
      // if archive status true then we will reset the all fetch status
      this.resetBrandFilters(true)
      console.debug(JSON.parse(JSON.stringify(this.getBrand)))
      // calling api for first time on clicking dropdown and other pages will be called on scrolling
      if ((this.getBrand.isListFetched && this.getBrand.periodic) || this.getBrand.isAllListFetched) {
        await this.setComponentBrandList(JSON.parse(JSON.stringify(this.getBrandList)))
        return
      }

      if (this.getBrandLoaders.retrieve) return

      // getting the campaigns from api and setting to the local component list
      this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
      this.$store.commit(brandTypes.SET_COMPONENT_BRAND_LIST, [])
      this.getBrand.isListFetched = true
      await this.fetchBrandList({page: 1})
      await this.setComponentBrandList(JSON.parse(JSON.stringify(this.getBrandList)))
    },
    /**
     * Calling the methode on scroll on brand dropdown
     * @param id
     * @param apiName
     * @returns {Promise<void>}
     */
    async lazyScrollBrand (id = '', apiName = '', adjust = 0) {
      await this.lazyScrollHelperBrand(id, apiName, adjust)
      await this.setComponentBrandList(JSON.parse(JSON.stringify(this.getBrandList)))
    },

    /**
     * Lazy loading on scroll
     * @param id
     * @param apiName
     * @returns {Promise<void>}
     */
    async lazyScrollHelperBrand (id = '', apiName = '', adjust = 0) {
      const div = document.getElementById(id)

      // check is already scrolled
      if (this.getBrandLoaders.retrieve) return

      if (Math.ceil(div.scrollHeight - div.scrollTop) - adjust === div.offsetHeight) {
        if (apiName === 'fetchBrandList') {
          const page = this.getBrand.page + 1

          // setting isAllListFetched status true if all campaigns fetched from DB
          if (this.getBrand.isAllListFetched) return
          this.getBrand.isListFetched = (page <= this.getBrand.total_pages)

          if (page <= this.getBrand.total_pages) {
            await this.fetchBrandList({page: page})
            // setting isAllListFetched status true if all campaigns fetched from DB
            this.getBrand.isAllListFetched = (page >= this.getBrand.total_pages)
          }
        }
      }
    },
    /**
     * searching brand in from campaign dropdown
     * @param event
     */
    debounceBrandDropdownSearch (event) {
      this.getBrand.search = event.target.value

      // if all record fetched then we search locally maintained list in state
      if (this.getBrand.isAllListFetched) {
        const list = JSON.parse(JSON.stringify(this.getBrandList))
        this.setComponentBrandList(list.filter(item => item.name.toLowerCase().includes(this.getBrand.search.toLowerCase())))
        return
      }
      this.getBrandLoaders.retrieve = true
      this.getBrand.isListFetched = true
      this.getBrand.isAllListFetched = false
      this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
      this.$store.commit(brandTypes.SET_COMPONENT_BRAND_LIST, [])
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        // if no record found from above then search from api
        await this.fetchBrandList({page: 1})
        await this.setComponentBrandList(JSON.parse(JSON.stringify(this.getBrandList)))
      }, 600)
    },

    getSelectedFilter (call = '', type = '') {
      if (call === 'campaign' && type === 'brand') {
        const count = this.getCampaigns.selected_brands.length
        return this.getCampaigns.select_all ? 'All Brands Selected' : (count ? `${count} Brand${count > 1 ? 's' : ''} selected` : 'Filter by Brands')
      }

      if (call === 'links' && type === 'brand') {
        const count = this.getLinks.brand.length
        return (count && this.getComponentBrandList.length === count) ? 'All Brands Selected' : (count ? count + ` Brand${count > 1 ? 's' : ''} selected` : 'Filter by Brands')
      }

      if (call === 'links' && type === 'campaign') {
        const count = this.getLinks.campaign.length
        return (count && this.getComponentCampaignList.length === count) ? 'All Campaigns Selected' : (count ? count + ` Campaign${count > 1 ? 's' : ''} selected` : 'Filter by Campaigns')
      }

      if (call === 'links' && type === 'tags') {
        const count = this.getLinks.tags.length
        return (count && this.getTagList.length === count) ? 'All Tags Selected' : (count ? count + ` Tag${count > 1 ? 's' : ''} selected` : 'Filter by Tags')
      }
    },

    getAnimation (key) {
      const animation = this.animationList.find(item => item.key === key)
      return animation ? animation.key : ''
    },

    getCampaignButtonText () {
      return this.getCampaignAdd._id ? (this.getCampaignLoaders.store ? 'Updating campaign' : 'Update campaign') : (this.getCampaignLoaders.store ? 'Saving campaign' : 'Save campaign')
    },

    /**
     * reusable method for opening brand model by pass the brand modal reference
     * @param model
     */
    saveBrand (model) {
      this.$store.commit(brandTypes.RESET_BRAND_ADD)
      model.validations = JSON.parse(JSON.stringify(brandValidations))
      this.$bvModal.show('modal-create-brand')
    },

    /**
     * get splitted string on the base of splitType with joining string
     * @param text
     * @param splitType
     * @param joinType
     * @returns {string}
     */
    getSplittedText (text = '', splitType = '', joinType = '') {
      return text.split(splitType).join(joinType)
    },

    /**
     * getting the object key on the basis of object value
     * @param obj
     * @param value
     * @returns {string}
     */
    getObjectKeyByValue (obj, value) {
      return Object.keys(obj).find(key => obj[key] === value)
    },

    /**
     * getting the google pixel on the basis of pixel type and key
     * @param type
     * @param key
     */
    getGooglePixelOnKey (type = '', key = '', googlePixelsTypeKey) {
      return (type === 'google' && key) ? '(' + this.getSplittedText(this.getObjectKeyByValue(googlePixelsTypeKey, key) + ')', '_', ' ') : (type === 'google') ? '(Google Adwords)' : ''
    },

    /**
     * if bio links first step not verified redirect it to main content section page
     */
    isBioLinkFirstStepVerified () {
      if (!this.getBioLinkAdd.first_step_verify) this.$router.push({name: 'select_content'})
    },

    /**
     * getting the customize theme colors for bio content sections
     * @param type
     * @param getBioLinkAdds
     * @returns {{backgroundColor: (string|string)}|{color: (string|string)}}
     */
    bioSectionThemeStyleBinding (type = '', getBioLinkAdds) {
      if (getBioLinkAdds.theme === 'customized' && type === 'background') {
        return {
          backgroundColor: getBioLinkAdds.custom_theme.button_color ? getBioLinkAdds.custom_theme.button_color + ' !important' : ''
        }
      }

      if (getBioLinkAdds.theme === 'customized' && type === 'text') {
        return {
          color: getBioLinkAdds.custom_theme.button_text_color ? getBioLinkAdds.custom_theme.button_text_color + ' !important' : ''
        }
      }
      return {}
    },
    /**
     * computing the width for left inner layout campaign
     * @param route
     * @returns {{width: (string)}}
     */
    computeLeftInnerWidthLayout (route = '') {
      return {
        width: route.includes('preview') ? '411px !important' : ''
      }
    },
    /**
     * computing the padding for right inner layout campaign
     * @param route
     * @returns {{width: (string)}}
     */
    computeRightInnerPaddingLayout (route = '') {
      return {
        paddingLeft: route.includes('preview') ? '413px !important' : ''
      }
    },
    /**
     * computing the branding on user profile and subscription
     * @returns {*|boolean}
     */
    computePowerBy () {
      return this.getSubscription && this.getSubscription.limits && this.getSubscription.limits.branding ? (this.getProfile && this.getProfile.poweredBy ? this.getProfile.poweredBy : false) : true
    },
    /**
     * computing the bio footer styling
     * @param getBioLinkAdd
     * @returns {{backgroundColor: (string|string), height: (string)}}
     */
    computeBioStyle (getBioLinkAdd) {
      return {
        backgroundColor: (getBioLinkAdd.theme === 'customized') ? getBioLinkAdd.custom_theme.background_color + ' !important' : '',
        height: this.computePowerBy() && !this.isWhiteLabelSetup() ? '540px' : '577px'
      }
    },
    /**
     * check the user has enabled the fallback_cta or not in Miscellaneous settings
     * @returns {*}
     */
    isFallbackCtaEnabled () {
      return !!(this.getProfile && this.getProfile.fallback_cta)
    },

    /**
     * verifying whitelabel agency name
     */
    whiteLabelAgencyNameValidation () {
      this.getWhiteLabelValidations.agency_name.name = true
      if (this.getWhiteLabel.agency_name && this.getWhiteLabel.agency_name.length > 0) {
        this.getWhiteLabelValidations.agency_name.name = false
        this.getWhiteLabelValidations.agency_name.nameLength = !this.maxLength(this.getWhiteLabel.agency_name, 100)
      }
    },

    /**
     * The method is responsible for all whitelabel url validatons
     * @param key
     */
    whiteLabelCommonUrlValidation (key = '') {
      this.getWhiteLabelValidations[key].url = this.requiredCheck(this.getWhiteLabel[key])
      if (this.getWhiteLabel[key] && this.getWhiteLabel[key].length > 8) {
        this.getWhiteLabel[key] = this.urlConcatenation(this.getWhiteLabel[key])
        this.getWhiteLabelValidations[key].urlLength = !this.maxLength(this.getWhiteLabel[key], 500)
        this.getWhiteLabelValidations[key].urlValid = !this.isValidURL(this.getWhiteLabel[key])
      }
    },

    /**
     * The method is responsible for whitelabel email validations
     * @param key
     */
    whiteLabelCommonEmailValidation (key = '') {
      this.getWhiteLabelValidations.email_validate[key] = this.requiredCheck(this.getWhiteLabel[key])
    },

    /**
     * this methode is reponsible for all whitelabel section validations
     * @param key
     */
    async applyWhiteLabelChanges (key = '') {
      this.whiteLabelAgencyNameValidation()
      this.whiteLabelCommonUrlValidation('header_logo')
      this.whiteLabelCommonUrlValidation('favicon')
      this.whiteLabelCommonUrlValidation('privacy_policy')
      this.whiteLabelCommonUrlValidation('terms_of_service')
      this.whiteLabelCommonUrlValidation('login_logo')
      this.whiteLabelCommonUrlValidation('app_loader')
      this.whiteLabelCommonUrlValidation('white_label_domain')

      this.whiteLabelCommonEmailValidation('from_email')
      this.whiteLabelCommonEmailValidation('smtp_host')
      this.whiteLabelCommonEmailValidation('smtp_port')
      this.whiteLabelCommonEmailValidation('smtp_username')
      this.whiteLabelCommonEmailValidation('smtp_password')
      this.whiteLabelCommonEmailValidation('smtp_encryption')
      this.whiteLabelCommonEmailValidation('email_sender_name')

      this.getWhiteLabelSectionValidate.whiteLabel_domain_connected = false
      this.getWhiteLabelSectionValidate.is_email_connected = false
      this.getWhiteLabelSectionValidate.email_validate = !Object.keys(this.getWhiteLabelValidations.email_validate).every(k => this.getWhiteLabelValidations.email_validate[k] === false)
      this.getWhiteLabelSectionValidate.agency_name = !Object.keys(this.getWhiteLabelValidations.agency_name).every(k => this.getWhiteLabelValidations.agency_name[k] === false)
      this.getWhiteLabelSectionValidate.header_logo = !Object.keys(this.getWhiteLabelValidations.header_logo).every(k => this.getWhiteLabelValidations.header_logo[k] === false)
      this.getWhiteLabelSectionValidate.favicon = !Object.keys(this.getWhiteLabelValidations.favicon).every(k => this.getWhiteLabelValidations.favicon[k] === false)
      this.getWhiteLabelSectionValidate.privacy_policy = !Object.keys(this.getWhiteLabelValidations.privacy_policy).every(k => this.getWhiteLabelValidations.privacy_policy[k] === false)
      this.getWhiteLabelSectionValidate.terms_of_service = !Object.keys(this.getWhiteLabelValidations.terms_of_service).every(k => this.getWhiteLabelValidations.terms_of_service[k] === false)
      this.getWhiteLabelSectionValidate.login_logo = !Object.keys(this.getWhiteLabelValidations.login_logo).every(k => this.getWhiteLabelValidations.login_logo[k] === false)
      this.getWhiteLabelSectionValidate.app_loader = !Object.keys(this.getWhiteLabelValidations.app_loader).every(k => this.getWhiteLabelValidations.app_loader[k] === false)
      this.getWhiteLabelSectionValidate.white_label_domain = !Object.keys(this.getWhiteLabelValidations.white_label_domain).every(k => this.getWhiteLabelValidations.white_label_domain[k] === false)

      this.getWhiteLabelValidations.custom_domain_id = false
      this.getWhiteLabelSectionValidate.custom_domain_id = false
      if (!this.getWhiteLabel.custom_domain_id) {
        this.getWhiteLabelValidations.custom_domain_id = true
        this.getWhiteLabelSectionValidate.custom_domain_id = true
      }

      // validating whitelabel general form settings
      if (!Object.keys(this.getWhiteLabelSectionValidate).every(k => this.getWhiteLabelSectionValidate[k] === false)) {
        this.$store.dispatch('toastNotification', {
          message: 'Missing Fields: One of the required field is empty or contains invalid data.',
          type: 'error'
        })
        return
      }


      // validating whitelabel branding domain settings
      if (!this.getWhiteLabel.whiteLabel_domain_connected) {
        this.getWhiteLabelValidations.whiteLabel_domain_connected = true
        this.getWhiteLabelSectionValidate.whiteLabel_domain_connected = true
        this.$store.dispatch('toastNotification', {
          message: 'Please verify your whitelabel domain!',
          type: 'error'
        })
        return
      }

      // validating whitelabel email settings
      if (!this.getWhiteLabel.is_email_connected) {
        this.getWhiteLabelValidations.is_email_connected = true
        this.getWhiteLabelSectionValidate.is_email_connected = true
        this.$store.dispatch('toastNotification', {
          message: 'Please verify your whitelabel email!',
          type: 'error'
        })
        return
      }

      this.getWhiteLabel.whitelabel_setup = true
      // storing the white label
      await this.storeWhiteLabel()
    },
    /**
     * setting the whitelabel favicon and title
     * @param whitelabel
     */
    setWhiteLabelTitleAndFavicon (favicon = '', agency_name = '') {
      let link = document.querySelector('link[rel~=\'icon\']')
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = favicon
      let title = document.title.split('|')
      document.title = title[0] + ' | ' + agency_name
    },

    /**
     * checking is whitelabel setup
     * @returns {boolean}
     */
    isWhiteLabelSetup () {
      return (this.getWhiteLabel.whitelabel_setup || this.getSiteDetails.isWhiteLabel)
    },
    /**
     * computing the url for whitelabel switching
     * @returns {string|boolean|*}
     */
    computeWhitelabelSwitch() {
      return {
        redirect_url: !this.getSiteDetails.isWhiteLabel ? this.getWhiteLabel.white_label_domain : process.env.VUE_APP_URL,
        name: !this.getSiteDetails.isWhiteLabel ? 'Whitelabel' : 'Replug'
      }
    },
    /**
     * check the current active user role is super_admin
     * @returns {boolean}
     */
    isSuperAdmin() {
      return this.getProfile.role == ROLES.super_admin
    },
    /**
     * capitalizing the first letter of string
     * @param str
     * @returns {string}
     */
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getSubscriptionLimits(key = '') {
      return this.getSubscription && this.getSubscription.limits && this.getSubscription.limits[key]
    }
  },
  computed: {
    ...mapGetters(['getDomains', 'getWhiteLabelLoaders', 'getWhiteLabelAppSettings', 'getDiscardWhiteLabel', 'getWhiteLabelSectionValidate', 'getWhiteLabelValidations', 'getWhiteLabel', 'getProfile', 'getCampaignLoaders', 'getTagList', 'getBrandLoaders', 'getSubscription', 'getArchiveStatus', 'getCampaigns', 'getCampaignsList', 'getComponentCampaignList', 'getBrand', 'getBrandList', 'getComponentBrandList', 'getBioLinkAdd']),
    /**
     * setting the whitelabel branding in whole app
     * @returns {{login_logo: string, agency_name: string, favicon: string, isWhiteLabel: boolean, header_logo: string, app_loader: string}|{headerLogo: string, favicon: string, name: string, logo: string, isWhiteLabel: boolean, brandDomainName: string}|(function(*): {login_logo: string, agency_name: string, whiteLabel_domain_connected: boolean, favicon: string, custom_domain_id: string, privacy_policy: string, terms_of_service: string, header_logo: string, app_loader: string, custom_domain: {}, white_label_domain: string, is_email_connected: boolean})|*}
     */
    getSiteDetails () {
      if (this.getWhiteLabelLoaders.app_fetch) {
        const data = {
          agency_name: '',
          header_logo: '',
          favicon: '',
          login_logo: '',
          app_loader: '',
          isWhiteLabel: false,
          custom_domain_id: ''
        }
        this.setWhiteLabelTitleAndFavicon()
        return data
      }

      let whitelabel = {...this.getWhiteLabelAppSettings}
      if (whitelabel && whitelabel.whitelabel_setup) {
        this.setWhiteLabelTitleAndFavicon(whitelabel.favicon, whitelabel.agency_name)
        whitelabel = JSON.parse(JSON.stringify(whitelabel))
        whitelabel.isWhiteLabel = true
        whitelabel.white_label_domain = whitelabel.white_label_domain.replace(/http(s?):\/\//, '')
        whitelabel.custom_domain_name = whitelabel.custom_domain.url.slice(-1) == '/' ? whitelabel.custom_domain.url.slice(-1) : whitelabel.custom_domain.url
        whitelabel.agency_name = ''
        return whitelabel
      }

      const data = {
        agency_name: 'Replug',
        login_logo: '/assets/img/replug_logo_black_text.png',
        header_logo: '/assets/img/replug_logo_black.png',
        isWhiteLabel: false,
        white_label_domain: 'https://replug.io/',
        favicon: '/assets/img/replug_favicon.svg'
      }

      this.setWhiteLabelTitleAndFavicon(data.favicon, data.agency_name)
      return data
    },
    /**
     * getting the whitelabel custom domain in case of whitelabel
     * @returns {*}
     */
    getBioDefaultCustomDomain() {
      return this.getDomains.items.find(item => item._id == this.getSiteDetails.custom_domain_id)
    }
  }
}

export {helperMixin}
