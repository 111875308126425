import http from '@/mixins/http-lib'
import { markReadNotificationsURL, showNotificationsURL } from '../../config/api'
import { notificationTypes } from './mutation-types'

export default {
  state: {
    list: [],
    system: [],
    team: [],
    page: 1,
    last_page: 1,
    unread_count: 0

  },
  getters: {
    getNotificationsList: state => state.list,
    getSystemNotifications: state => state.system,
    getTeamNotifications: state => state.team
  },
  actions: {
    fetchNotifications ({ state, getters, dispatch, commit }, page = 1) {
      http.post(showNotificationsURL, { page: page }).then(res => {
        if (res.data.status) {
          commit(notificationTypes.SET_NOTIFICATIONS_LIST, res.data.data.list)
          commit(notificationTypes.SET_NOTIFICATIONS_UNREAD_COUNT, res.data.data.unread_count)
        }
      }).catch(() => {
        return null
      })
    },
    markRead ({ state, getters, dispatch, commit }, notificationId) {
      return http.post(markReadNotificationsURL, { _id: notificationId }).then(res => {
        return res.data.status
      }).catch(() => {
        return null
      })
    }

  },
  mutations: {
    [notificationTypes.SET_NOTIFICATIONS_LIST] (state, value) {
      state.list = state.list.concat(value.data)
      state.page = value.current_page
      state.last_page = value.last_page
    },
    [notificationTypes.SET_NOTIFICATIONS_UNREAD_COUNT] (state, value) {
      state.unread_count = value
    }

  }
}
