<template>
  <div class="mb-3 deep-links-component">
    <upgrade-subscription-modal :self="self"></upgrade-subscription-modal>
    <div :class="[is_called_by_dashboard ? 'mt-0' : 'mt-3']" class="input_icon_text position-relative">
      <div class="setting_item"
           :title="this.widgetDisabled ? 'You cannot use Deep Link with this campaign. It is supported with shortener Campaigns' : 'Check for Deep Link by clicking here'">
        <div class="text_sec d-inline-block">
          <span :class="[is_called_by_dashboard ? 'new-feature-badge-dashboard' : 'link-new-badge']"
                class="badge">New!</span>
          <p>Deep Linking <a v-if="!getSiteDetails.isWhiteLabel" href="https://docs.replug.io/article/883-deep-linking" target="_blank">(Help)</a></p>
        </div>
        <div class="status_sec" style="float: right">
          <label class="switch">
            <input type="checkbox" class="d-none"
                   :disabled="this.widgetDisabled || !isFeatureAllowedInPlan('deep_linking',self)"
                   @click="toggleDeepLink()"
                   :value="value" :checked="value">
            <div class="slider round"></div>
          </label>
        </div>
      </div>
      <div v-if="value" class="bg-transparent mt-3 view_bottom">
        <div class="view_bottom_inner text-center">
          <template v-if="is_deep_link_allowed">
            <img src="../../../assets/img/Group 45004.png">
            <p class="mt-3">Deep-link supported for your destination URL.</p>
          </template>
          <template v-else>
            <img src="../../../assets/img/Group 45005.png">
            <p class="mt-3">Deep link is not supported for your URL.</p>
          </template>
        </div>
      </div>
      <div v-if="!isFeatureAllowedInPlan('deep_linking',self)"
           class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>Deep Linking</strong>.
          <br> <a @click="$bvModal.show('upgrade-subscription-modal')">Go to billing section.</a></p>
      </div>
      <div v-else-if="this.widgetDisabled" class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>Deep Linking</strong> with this campaign. It is supported with
          shortener campaigns.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeepLinksComponent',
  props: [
    'value',
    'is_deep_link_allowed',
    'url',
    'self',
    'deepLinksBrandsList',
    'campaignId',
    'is_called_by_dashboard'
  ],
  components: {
    'upgrade-subscription-modal': () => import('@/components/UpgradeSubscriptionModal')
  },
  computed: {
    widgetDisabled () {
      return this.isTypeCTA(this.campaignId, this.self)
    }
  },
  methods: {
    toggleDeepLink () {
      this.checkDeepLink(this.url, this.deepLinksBrandsList, this.self)
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="less" scoped>
.deep-links-component {
  .input_icon_text {
    box-shadow: none;

    .setting_item {
      .d-inline-block {
        p {
          a {
            color: #3988fe;
          }
        }
      }
    }

    .view_bottom {
      .view_bottom_inner {
        p {
          color: #1f216e;
          font-weight: bold;
          font-size: small;
        }
      }
    }
  }

  .alert-warning {
    font-size: small;
    padding: 5px;

    .fa-exclamation-triangle {
      padding: 7px 10px;
      font-size: large;
    }

    p {
      font-size: small;
      color: #856404;
      text-align: left;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
