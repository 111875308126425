import { mapGetters, mapActions } from 'vuex'

let actionConfirmationMixin = {

  data () {
    return {}
  },

  computed: {

    ...mapGetters([])

  },

  methods: {

    ...mapActions([

      'setRemoveTeamMemberId', 'setRemoveTeamMemberKeepDataStatus', 'setModalStatus', 'setModalMemberStatus'

    ]),

    confirmAction (member_id, model_id, status) {
      this.setModalMemberStatus(status)
      this.setModalStatus('')
      this.setRemoveTeamMemberId(member_id)
      this.$bvModal.show(model_id)
    },

    closeConfirmAction (model_id) {
      let stateObject = this
      setTimeout(function () {
        stateObject.setRemoveTeamMemberId('')
        stateObject.setRemoveTeamMemberKeepDataStatus(false)
        stateObject.setModalStatus('')
        stateObject.setModalMemberStatus(1)
        stateObject.$bvModal.hide(model_id)
      }, 1000)
    }

  }

}
export { actionConfirmationMixin }
