<template>
  <div class="setting_form clear">
    <!--<form class="basic_form clear">-->
    <!-- Create Client Modal -->

    <b-modal modal-class="modal_basic modal-create-client" id="modal-create-client" hide-footer hide-header>
      <img @click="$bvModal.hide('modal-create-client')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            Create Application
          </h3>
        </div>

        <!-- Form Errors -->

        <div class="text-left alert-box danger" v-if="createForm.errors.length > 0">
          <div class="inner_box">
            <h4><i class="far fa-exclamation-triangle" aria-hidden="true"></i> Whoops!</h4>
            <ul class="list mt-3">
              <li v-for="error in createForm.errors">
                <template v-if="createForm.errors.redirect && createForm.errors.redirect.length >0">
                  {{ createForm.errors.redirect[0] }}
                </template>
                <template v-else-if="createForm.errors.message">
                  {{ createForm.errors.message }}
                </template>
                <template v-else>
                  {{ error }}
                </template>

              </li>
            </ul>

          </div>
        </div>

        <!-- Create Client Form -->

        <form class=" basic_form clear" role="form">
          <!-- Name -->
          <div class=" mt-3  input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.name || validations.nameLength}">
            <div class="content">
              <div class="url">Name</div>
              <div class="name">
                <div class="validation_input">
                  <input placeholder="Your application name which your users will recognize and trust"
                         id="create-client-name"
                         type="text" class="form-control"
                         @keyup.enter="store" v-model="createForm.name"
                  >
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.name">{{ messages.name }}</span>
          <span class="input-error" v-else-if="validations.nameLength">{{ messages.nameLength }}</span>

          <!-- Redirect URL -->
          <div class=" mt-3  input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.redirect_url || validations.redirect_url_valid || validations.redirect_url_length}">
            <div class="content">
              <div class="url">Redirect URL</div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Your application's authorization callback URL i.e https://app.contentstudio.io/oauth"
                    type="text"
                    @blur="createForm.redirect = focusoutConcatenation(createForm.redirect)"
                    class="form-control" name="redirect"
                    @keyup.enter="store" v-model="createForm.redirect">

                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.redirect_url">{{ messages.redirect_url }}</span>
          <span class="input-error"v-else-if="validations.redirect_url_length">{{ messages.redirect_url_length }}</span>
          <span class="input-error" v-else-if="validations.redirect_url_valid">{{ messages.redirect_url_valid }}</span>

          <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.image}">
            <div class="content">
              <div class="url">Authorization Image (128x128)</div>
              <div class="name">
                <div class=" source_upload_btn">
                  <div class="source_upload_inner">
                    <label for="sourceFile" class="source_upload_label">Upload</label>
                    <input id="sourceFile" accept=".png,.jpg" style="display: none;" class="hide"
                           @change.prevent="uploadAppImage($event)" type="file">

                    <input name="fileName" style="display: none;" class="hide" type="hidden"
                           v-model="createForm.fileName">
                    <input name="image" style="display: none;" class="hide" type="hidden"
                           v-model="createForm.image">

                    <span class="input_file_name"
                          v-if="createForm.fileName">{{ createForm.fileName }}</span>
                    <span class="input_file_name" v-else>No file selected</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.image">
                        {{ messages.image }}
                    </span>

        </form>

        <div class=" btn_block text-left">

          <button @click="store" class="btn---cta btn-blue with-shadow btn-round  btn-bold">
                        <span>
                           Create
                        </span>

          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('modal-create-client')"><span>Close</span>
          </button>

        </div>

      </div>
    </b-modal>

    <b-modal modal-class="modal_basic modal-create-client modal-edit-client" id="modal-edit-client"
             hide-footer hide-header>
      <img @click="$bvModal.hide('modal-edit-client')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            Edit Application
          </h3>
        </div>

        <!-- Form Errors -->

        <div class="text-left alert-box danger" v-if="editForm.errors.length > 0">
          <div class="inner_box">
            <h4><i class="far fa-exclamation-triangle" aria-hidden="true"></i> Whoops!</h4>
            <ul class="list mt-3">
              <li v-for="error in editForm.errors">
                <template v-if="error.redirect && error.redirect.length >0">
                  {{ error.redirect[0] }}
                </template>
                <template v-else-if="editForm.errors.message">
                  {{ editForm.errors.message }}
                </template>
                <template v-else>
                  {{ error }}
                </template>
              </li>
            </ul>

          </div>
        </div>

        <!-- Edit Client Form -->
        <form class=" basic_form clear" role="form">
          <!-- Name -->
          <div class=" mt-3  input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.name || validations.nameLength}">
            <div class="content">
              <div class="url">Name</div>
              <div class="name">
                <div class="validation_input">
                  <input placeholder="Your application name which your users will recognize and trust"
                         id="edit-client-name" type="text" class="form-control"
                         @keyup.enter="update" v-model="editForm.name">

                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.name">{{ messages.name }}</span>
          <span class="input-error" v-else-if="validations.nameLength">{{ messages.nameLength }}</span>
          <!-- Redirect URL -->
          <div class=" mt-3  input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.redirect_url || validations.redirect_url_valid || validations.redirect_url_length}">
            <div class="content">
              <div class="url">Redirect URL</div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Your application's authorization callback URL i.e https://app.contentstudio.io/oauth"
                    type="text"
                    class="form-control" name="redirect"
                    @blur="editForm.redirect = focusoutConcatenation(editForm.redirect)"
                    @keyup.enter="update" v-model="editForm.redirect">

                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.redirect_url">{{ messages.redirect_url }}</span>
          <span class="input-error" v-else-if="validations.redirect_url_length">{{
              messages.redirect_url_length
            }}</span>
          <span class="input-error" v-else-if="validations.redirect_url_valid">{{ messages.redirect_url_valid }}</span>

          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box"
               :class="{'input-field-error': validations.image}">
            <div class="content">
              <div class="url">Authorization Image (128x128)</div>
              <div class="name">
                <div class=" source_upload_btn">
                  <div class="source_upload_inner">
                    <label for="sourceFileUpdate" class="source_upload_label">Upload</label>
                    <input id="sourceFileUpdate" accept=".png,.jpg" style="display: none;"
                           class="hide" @change.prevent="uploadAppImage($event)" type="file">

                    <input name="fileName" style="display: none;" class="hide" type="hidden"
                           v-model="editForm.fileName">
                    <input name="image" style="display: none;" class="hide" type="hidden"
                           v-model="editForm.image">

                    <span class="input_file_name"
                          v-if="editForm.fileName">{{ editForm.fileName }}</span>
                    <span class="input_file_name" v-else>No file selected</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.image">
                        {{ messages.image }}
                    </span>

        </form>

        <div class=" btn_block text-left">

          <button @click="update" class="btn---cta btn-blue with-shadow btn-round  btn-bold">
                        <span>
                           Save
                        </span>

          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('modal-edit-client')"><span>Close</span>
          </button>

        </div>

      </div>
    </b-modal>

    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Your Applications</h2>

          </div>
          <div class="right ml-auto">

            <button @click.prevent="showCreateClientForm()"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold">
              <span>New Application</span>
              <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
            </button>

          </div>

        </div>
        <div class="box_content">

          <div class="table_container">
            <table class="table team-table list-content">

              <thead class=" ">
              <tr>
                <th>Name</th>
                <th>Client ID</th>
                <th>Client Secret</th>
                <th style="text-align: right;">Action</th>
              </tr>
              </thead>

              <tbody class="list-content-body">
              <tr v-if="clients.length == 0">
                <td colspan="4">
                  <p class="p-4 txt-center">
                    You have not created any App Yet, please create one.
                  </p>
                </td>
              </tr>
              <template v-if="clients.length > 0">
                <tr v-for="client in clients">
                  <!-- Name -->
                  <td style="text-align: left;">
                    {{ client.name }}
                  </td>

                  <!-- ID -->
                  <td>
                    {{ client._id }}
                  </td>

                  <!-- Secret -->
                  <td>
                    <code>{{ client.secret }}</code>
                  </td>

                  <td class="table-action  ">
                    <!-- Edit Button -->

                    <i v-tooltip="'Edit Application'" class="far fa-pencil "
                       @click.prevent="edit(client)" aria-hidden="true"></i>
                    <!-- Delete Button -->

                    <i v-tooltip="'Remove Application'" class="far fa-trash "
                       @click.prevent="destroy(client)" aria-hidden="true"></i>

                  </td>
                </tr>
              </template>

              </tbody>

            </table>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { uploadAppPictureURL } from '@/config/config.js'
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import { API_URL_v3 } from '@/constants'

export default {
  components: {},
  /*
                                   * The component's data.
                                   */
  data () {
    return {
      clients: [],

      createForm: {
        errors: [],
        name: '',
        redirect: '',
        image: '',
        fileName: ''
      },

      editForm: {
        errors: [],
        name: '',
        redirect: '',
        image: '',
        fileName: ''
      },
      validations: {
        name: false,
        nameLength: false,
        redirect_url: false,
        redirect_url_valid: false,
        image: false
      },
      messages: {
        name: 'Name cannot be empty',
        nameLength: 'The name should not be greater than 35 characters',
        redirect_url: 'Redirect URL cannot be empty',
        redirect_url_valid: 'Please enter a valid URL',
        redirect_url_length: 'The url should not be greater than 74 characters.',
        image: 'Please upload the image for your application.'
      }

    }
  },

  /**
   * Prepare the component (Vue 1.x).
   */
  ready () {
    this.prepareComponent()
  },

  /**
   * Prepare the component (Vue 2.x).
   */
  mounted () {
    this.prepareComponent()
  },

  created () {
    this.getSiteDetails
  },

  methods: {
    /**
     * Prepare the component.
     */
    prepareComponent () {
      this.getClients()

      $('#modal-create-client').on('shown.bs.modal', () => {
        $('#create-client-name').focus()
      })

      $('#modal-edit-client').on('shown.bs.modal', () => {
        $('#edit-client-name').focus()
      })
    },

    /**
     * Get all of the OAuth clients for the user.
     */
    getClients () {
      http.get(API_URL_v3 + 'oauth/clients')
        .then(response => {
          if (response.data.tokenError && response.data.tokenError === true) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.clients = response.data
          }
        })
    },

    /**
     * Show the form for creating new clients.
     */
    showCreateClientForm () {
      this.validations = _.mapValues(this.validations, () => false)
      // $('#modal-create-client').modal('show')
      this.$bvModal.show('modal-create-client')
    },

    /**
     * Create a new OAuth client for the user.
     */
    store () {
      if (this.performValidations(this.createForm)) {
        this.persistClient('post', API_URL_v3 + 'oauth/clients', this.createForm, 'modal-create-client')
      }
    },

    performValidations (payload) {
      this.validations.name = this.requiredCheck(payload.name)
      this.validations.redirect_url = this.requiredCheck(payload.redirect)
      this.validations.redirect_url_valid = !this.urlCheck(payload.redirect)
      this.validations.image = this.requiredCheck(payload.fileName)
      return Object.keys(this.validations).every(k => this.validations[k] === false)
    },
    /**
     * Create a OAuth client image the user.
     */
    uploadAppImage (e) {
      console.debug('app picture upload')
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      files = files[0]
      let formData = new FormData()
      formData.append('app_picture', files)

      http.post(uploadAppPictureURL, formData).then(
        response => {
          if (response.data.tokenError && response.data.tokenError === true) {
            this.alertMessage(response.data.message, 'error')
          } else {
            if (response.data.status) {
              this.createForm['fileName'] = files['name']
              this.createForm['image'] = response.data.url
              this.editForm['fileName'] = files['name']
              this.editForm['image'] = response.data.url
            } else {
              this.alertMessage(response.data.message, 'error')
            }
          }
        },
        response => {

        }
      )
    },

    /**
     * Edit the given client.
     */
    edit (client) {
      this.editForm.id = client._id
      this.editForm.name = client.name
      this.editForm.redirect = client.redirect
      this.editForm.image = client.image
      this.editForm.fileName = client.filename
      this.$bvModal.show('modal-edit-client')
    },

    /**
     * Update the client being edited.
     */
    update () {
      if (this.performValidations(this.editForm)) {
        this.persistClient(
          'put', API_URL_v3 + 'oauth/clients/' + this.editForm.id,
          this.editForm, 'modal-edit-client'
        )
      }
    },

    /**
     * Persist the client to storage using the given form.
     */
    persistClient (method, uri, form, modal) {
      form.errors = []

      http[method](uri, form)
        .then(response => {
          this.getClients()

          form.name = ''
          form.redirect = ''
          form.errors = []
          this.$bvModal.hide(modal)
        })
        .catch(response => {
          if (typeof response.data === 'object') {
            form.errors = _.flatten(_.toArray(response.data))
          } else {
            form.errors = ['Something went wrong. Please try again.']
          }
        })
    },

    /**
     * Destroy the given client.
     */
    destroy (client) {
      http.delete(API_URL_v3 + 'oauth/clients/' + client._id)
        .then(response => {
          this.getClients()
        })
    }
  },

  computed: {
    ...mapGetters(['getProfile', 'getSubscription'])
  },
  watch: {
    'createForm.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 35)
      }
    },
    'editForm.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 35)
      }
    },
    'createForm.fileName' (value) {
      if (value && value.trim().length > 0) this.validations.image = false
    },
    'editForm.fileName' (value) {
      if (value && value.trim().length > 0) this.validations.image = false
    },
    'createForm.redirect' (value) {
      if (value && value.trim().length > 8) {
        this.validations.redirect_url = false
        this.createForm.redirect = this.urlConcatenation(this.createForm.redirect)
        this.validations.redirect_url_length = !this.maxLength(this.createForm.redirect, 74)
        this.validations.redirect_url_valid = !this.isValidURL(this.createForm.redirect)
      }
    },
    'editForm.redirect' (value) {
      if (value && value.trim().length > 8) {
        this.validations.redirect_url = false
        this.editForm.redirect = this.urlConcatenation(this.editForm.redirect)
        this.validations.redirect_url_length = !this.maxLength(this.editForm.redirect, 74)
        this.validations.redirect_url_valid = !this.isValidURL(this.editForm.redirect)
      }
    }
  }
}
</script>

<style scoped>
.action-link {
  cursor: pointer;
}

.m-b-none {
  margin-bottom: 0;
}
</style>
