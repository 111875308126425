import axios from 'axios'
import { cookieAppDomain, cookieDomain, JWT_CHROME_LOCAL_STORAGE } from '../constants'
import store from '@/store'
import { authenticationTypes } from '@/state/modules/mutation-types'
import router from '@/router'
import vueCookie from 'vue-cookie'
/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
const requests = axios.create({})

requests.CancelToken = axios.CancelToken
requests.isCancel = axios.isCancel
requests.interceptors.request.use(
  (config) => {
    let token = vueCookie.get(JWT_CHROME_LOCAL_STORAGE, { domain: cookieDomain })

    if (!token || token == 'null') {
      token = vueCookie.get(JWT_CHROME_LOCAL_STORAGE, { domain: cookieAppDomain })
    }

    if (!token) {
      token = localStorage.getItem(JWT_CHROME_LOCAL_STORAGE)
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)
// Add a response interceptor
requests.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error && error.response && error.response.status === 401) {
    store.commit(authenticationTypes.SET_JWT_TOKEN, null)
    store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)
    /**
     * If the current route is for "no authenticated users", then proceed
     * otherwise navigate to the login screen
     */
    const noAuthRoute = router.currentRoute.matched.length === 0 || router.currentRoute.matched.find(r => r.beforeEnter && r.beforeEnter.name === 'ifNotAuthenticated')
    const openRoutes = ['signup', 'sso']
    if (!noAuthRoute && !openRoutes.includes(router.currentRoute.name)) {
      router.push({ name: 'login' })
    }
  }
  return Promise.reject(error)
})

export default requests
