<template>
  <div class="d-flex align-items-center justify-content-center vh-100 flex-column pb-5">
    <clip-loader v-if="!error_message"></clip-loader>
    <h4 :class="!!error_message ? 'alert alert-danger' : ''">{{ !!error_message ? error_message : this.title }}</h4>
  </div>
</template>

<script>
import {JWT_CHROME_LOCAL_STORAGE, cookieAppDomain, cookieDomain} from "@/constants";
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'
import {authCheck} from "../../config/config";

export default {
  name: "social-login",
  data: () => {
    return {
      title: 'Validating Social Login...',
      error_message: ""
    };
  },
  mounted() {
    /**
     * Used to prompt error while social login and navigate to login afterwards
     */
    const showError = (message) => {
      this.error_message = message;
      setTimeout(() => this.$router.push({name: 'login'}), 3000)
    };

    if (this.$route.query && !this.$route.query.token) {
      showError("Token is missing. Please try again.")
    }

    // Setting JWT Token
    this.setSiteCookies(this.$route.query.token)

    /**
     * Check if the request is valid, navigate to dashboard
     * otherwise navigate to login
     */
    return http.post(authCheck, {}).then(
      () => {
        window.location.href = window.location.origin + '/dashboard'
      },
      () => showError("Invalid token - Please try again")
    )
  },
};
</script>
