<template>
  <div class="mb-3 ab-testing-component">
    <upgrade-subscription-modal :self="self"></upgrade-subscription-modal>
    <div :class="[is_called_by_dashboard ? 'mt-0' : 'mt-3']" class="input_icon_text position-relative">
      <div class="setting_item">
        <div class="text_sec d-inline-block">
          <span :class="[is_called_by_dashboard ? 'new-feature-badge-dashboard' : 'link-new-badge']" class="badge">New!</span>
          <p>A/B Testing <a v-if="!getSiteDetails.isWhiteLabel" href="https://docs.replug.io/article/884-a-b-testing" target="_blank">(Help)</a></p>
        </div>
        <div class="status_sec" style="float: right">
          <label class="switch">
            <input type="checkbox" class="d-none"
                   :disabled="is_routing_rule || !isFeatureAllowedInPlan('ab_testing',self)"
                   @click="showABTesting = !showABTesting" :checked="showABTesting">
            <div class="slider round"></div>
          </label>
        </div>
      </div>
      <div v-if="!isValidWeight(split_urls) && showABTesting" class="alert p-1 mb-3 mt-3 text-center alert-danger"
           role="alert">
        {{ abTestingObjMessages.weightCriteria }}
      </div>
      <div v-if="showABTesting" :class="{'mt-3':isValidWeight(split_urls)}" class="table-section">
        <table class="table mb-0">
          <thead>
          <tr>
            <td style="width: 50%;">Split Test URL</td>
            <td style="width: 30%;">Weight</td>
            <td style="width: 20%"></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in split_urls" :key="item.id ? item.id : index">
            <td>
              <span v-if="!item.isEdit" class="text-truncate d-inline-block"
                    v-tooltip="item.url">{{ item.url }} <clip-loader class="d-inline-block text-justify"
                                                                     v-if="index!=0 && item.loader" :size="'12px'"
                                                                     :color="'#3988fe'"></clip-loader></span>
              <div class="inline_editor d-inline-flex"
                   :class="{'inline_editor_border':item.validations.url || item.validations.urlValid || item.validations.urlLength || item.validations.iframe}"
                   v-else>
                <input @input="onChangeUrLField($event,item)" :disabled="index==0" type="text" v-model="item.url"
                       placeholder="https://">
                <clip-loader class="text-right ml-2 mr-1" v-if="index!=0 && item.loader" :size="'12px'"
                             :color="'#3988fe'"></clip-loader>
              </div>
              <span class="input-error" v-if="item.validations.url">{{ abTestingObjMessages.url }}</span>
              <span class="input-error" v-else-if="item.validations.urlLength">{{
                  abTestingObjMessages.urlLength
                }}</span>
              <span class="input-error" v-else-if="item.validations.urlValid">{{ abTestingObjMessages.urlValid }}</span>
              <span class="input-error" v-else-if="item.validations.iframe">{{ abTestingObjMessages.iframe }}</span>
            </td>
            <td>
              <span v-if="!item.isEdit">{{ item.weight }}%</span>
              <div class="d-inline-flex" v-else>
                <div class="inline_editor"
                     :class="{'inline_editor_border':item.validations.weight || item.validations.weightCriteria}">
                  <input maxlength="2" @input="onChangeWeight($event,item)" v-model="item.weight" type="number"
                         placeholder="10">
                </div>
                <span class="ml-1 mt-2">%</span>
              </div>
            </td>
            <td>
                <span v-if="!item.isEdit" @click="updateABTestingObj(item,1, false)" class="icon cursor-pointer"><i
                  class="far fa-pencil"></i></span>
              <span v-else class="icon green" @click="updateABTestingObj(item,index)"><i
                :class="{'mt-2':item.isEdit}"
                class="far ml-1 fa-check"></i></span>
              <span @click="removeABTestingObject(index)" v-if="index!==0" class="icon red ml-2"><i
                :class="{'mt-2':item.isEdit}"
                class="fal ml-1 fa-trash"></i></span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="showABTesting" class="bg-transparent view_bottom">
        <div v-if="isAnyFieldInEditModeStatus || !isValidWeight(split_urls)" class="view_bottom_inner text-center">
          <button @click="equalizeWeight"
                  :disabled="isValidWeight(split_urls)"
                  :title="isValidWeight(split_urls) ? 'You cannot use equalize weights option if the weights are set for your URLs are equal' : 'Distribute equal weight by clicking here'"
                  class="btn---cta  light-blue btn-round btn--loader loader--right">
            <span>Equalize Weights</span>
          </button>
        </div>
        <div v-else class="view_bottom_inner text-center">
          <a @click="addABTestingObject" class="add-destination">
            <i class="fas fa-plus-circle"></i> Add Destination Link
          </a>
        </div>
      </div>
      <div v-if="!isFeatureAllowedInPlan('ab_testing',self)"
           class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>A/B Testing</strong>.
          <br> <a @click="$bvModal.show('upgrade-subscription-modal')">Go to billing section.</a></p>
      </div>
      <div v-else-if="is_routing_rule" class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>A/B Testing</strong> if Traffic Routing Rules are activated.</p>
      </div>
    </div>
  </div>
</template>

<script>

import { abTestingObjValidations, abTestingObjMessages } from '@/common/attributes'

export default {
  name: 'ABTestingComponent',
  props: [
    'split_urls',
    'campaignId',
    'campaign',
    'url',
    'self',
    'is_ab_test',
    'is_routing_rule',
    'is_called_by_dashboard'
  ],
  data () {
    return {
      showABTesting: false,
      isAnyFieldInEditModeStatus: false,
      abTestingObjMessages: abTestingObjMessages,
      debounce: null
    }
  },
  components: {
    'upgrade-subscription-modal': () => import('@/components/UpgradeSubscriptionModal')
  },
  created () {
    this.showABTesting = this.is_ab_test
    // On updating ab testing we will reset the validations and loaders
    if (this.split_urls && this.split_urls.length > 1) {
      this.split_urls.forEach(item => {
        item.validations = { ...abTestingObjValidations }
        item.isEdit = false
        item.loader = false
        item.weight = parseInt(item.weight)
      })
    }
  },
  methods: {
    // In this method we all add the ab testing list item and we will not allow more than ten item list
    addABTestingObject () {
      if (this.split_urls.length >= 10) {
        this.$store.dispatch('toastNotification', { message: 'Only 10 splits are allowed.', type: 'error' })
        return
      }
      this.split_urls.push({
        weight: 0,
        url: '',
        validations: { ...abTestingObjValidations },
        isEdit: true,
        loader: false
      })
      this.isAnyFieldInEditMode()
    },
    // removing the list from ab testing object
    removeABTestingObject (itemIndex) {
      this.split_urls.splice(itemIndex, 1)
      this.isAnyFieldInEditMode()
    },
    // display the equalize button if any field in edit mode
    isAnyFieldInEditMode () {
      this.isAnyFieldInEditModeStatus = !!this.split_urls.filter(elem => elem.isEdit).length
    },
    // updating the item in ab testing object
    async updateABTestingObj (item, index, update = true) {
      if (!update) {
        item.isEdit = !item.isEdit
        this.isAnyFieldInEditMode()
        this.$forceUpdate()
        return
      }
      item.url = this.urlConcatenation(item.url)
      this.urlValidations(item)
      this.weightValidations(item)
      if (index && !this.requiredCheck(item.url) && this.isTypeCTA(this.campaignId, this.self)) await this.checkingIframe(item)
      let result = Object.keys(item.validations).every(k => item.validations[k] === false)
      if (result) {
        item.isEdit = !item.isEdit
        this.isAnyFieldInEditMode()
      }
      this.$forceUpdate()
    },
    onChangeUrLField (event, item) {
      this.urlValidations(item)
    },
    // checking the url validations
    urlValidations (item) {
      item.url = item.url.trim()
      if (item.url && item.url.length > 8) {
        item.url = this.urlConcatenation(item.url)
        item.validations.urlLength = !this.maxLength(item.url, 500)
      }
      item.validations.urlValid = !this.isValidURL(item.url)
      item.validations.iframe = false
      this.weightValidations(item)
    },
    onChangeWeight (event, item) {
      this.weightValidations(item)
    },
    weightValidations (item) {
      item.weight = parseFloat(item.weight)
      item.validations.weight = !(item.weight > 0 && item.weight <= 100)
      item.validations.weightCriteria = !this.isValidWeight(this.split_urls)
    },
    // Equalizing the weight of ab testing object
    equalizeWeight () {
      let splitData = this.splitInteger(100, this.split_urls.length)
      this.split_urls.forEach((item, index) => {
        item.weight = splitData[index]
        item.validations.weight = false
      })
    },
    // Checking the iframe on url
    async checkingIframe (item) {
      item.loader = true
      this.$forceUpdate()
      const response = await this.checkLinkPreview(item.url, this.campaign)
      if (!response.data.status) {
        this.abTestingObjMessages.iframe = 'We can not show CTAs on this page.'
      }
      item.validations.iframe = !response.data.status
      item.loader = false
      this.$forceUpdate()
    },
    // splitting the integers in to equal chunks
    splitInteger (num, parts) {
      let val = 0
      let mod = num % parts
      let retData = 0
      if (mod == 0) {
        val = num / parts
        retData = Array(parts).fill(val)
      } else {
        val = (num - mod) / parts
        retData = Array(parts).fill(val)
        for (let i = 0; i < mod; i++) {
          retData[i] = retData[i] + 1
        }
      }
      return retData
    }
  },
  watch: {
    'campaignId' (value) {
      const isCta = this.isTypeCTA(value)
      this.split_urls.forEach((item, index) => {
        if (index === 0) {
          return
        }
        if (isCta) {
          this.checkingIframe(item)
        } else {
          item.validations.iframe = false
        }
      })
      this.$forceUpdate()
    }
    ,
    'url' (value) {
      if (this.split_urls.length === 0) {
        this.split_urls.push({
          weight: 100,
          url: this.url,
          validations: { ...abTestingObjValidations },
          isEdit: false,
          loader: false
        })
      }

      this.split_urls[0].url = value

    },
    'showABTesting' (value) {
      this.$emit('input', value)
      if (this.url && !this.split_urls.length) {
        this.split_urls.push({
          weight: 100,
          url: this.url,
          validations: { ...abTestingObjValidations },
          isEdit: false,
          loader: false
        })
      }
    },
    'split_urls' (value) {
      this.isAnyFieldInEditModeStatus = !!this.split_urls.filter(elem => elem.isEdit).length
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.ab-testing-component {
  .input_icon_text {
    box-shadow: none;

    .setting_item {
      .d-inline-block {
        p {
          a {
            color: #3988fe;
          }
        }
      }
    }

    .table-section {
      .table {
        thead {
          tr {
            td {
              padding: 1rem 4px;
            }

            color: #1f216e;
            font-size: 0.7rem;
            font-weight: bold;
          }
        }

        tbody {
          tr {
            td {
              padding: 1rem 4px;

              .inline_editor {
                border: 1px solid lightgray;
                padding: 8.5px 0px 5px 4.25px;
                border-radius: 4px;
              }

              .inline_editor_border {
                border-color: #f76a60;
              }

              .text-truncate {
                width: 170px;
              }

              .fa-pencil {
                color: #45a2ff;
                cursor: pointer;
              }

              .fa-trash {
                color: #f76a60;
                font-size: medium;
                margin-left: 2px;
                cursor: pointer;
              }

              .fa-check {
                font-size: large;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .view_bottom {
      .view_bottom_inner {
        .add-destination {
          color: #3988fe;
          cursor: pointer;
        }
      }
    }
  }

  .alert-warning {
    font-size: small;
    padding: 5px;

    .fa-exclamation-triangle {
      padding: 7px 10px;
      font-size: large;
    }

    p {
      font-size: small;
      color: #856404;
      text-align: left;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
