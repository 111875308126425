<template>

    <div>
        <div v-if="getUnauthorizedException"  class="disconnected_overlay">
            <div class=" alert notify_bar alert-dismissable align_center"
                 style="position: fixed">
                <div class="notify_content">
                    <p>You have been disconnected due to inactivity for too long and need to reload, <a
                            @click.prevent="reload()">Reload Now</a></p>
                </div>
            </div>
        </div>
        <is-offline></is-offline>

    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IsOffline from '@/misc/IsOffline'

export default {
  components: {
    IsOffline
  },
  created () {
    let me = this
    // clearInterval(this.getCSRFInterval)
    // this.interval = setInterval(function () {
    //   me.refreshCsrf()
    // }, 150000)
    // this.setCSRFInterval(this.interval)
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['getUnauthorizedException'])
  },
  methods: {
    ...mapActions([]),
    reload () {
      this.$router.go({
        path: this.$router.path,
        query: {
          t: +new Date()
        }
      })
    }

  }
}
</script>
