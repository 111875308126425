<template>
  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div style="padding: 10% 0px" class="container h-100 ">
      <div class="authentication-component-inner m-auto">
        <div class="header_content mt-5 mb-5 text-center clear">
          <div class="logo_block">
            <a class="active">
              <!--                            <img src="/assets/img/replug_logo_black_text.png" alt="replug">-->
              <img width="6%" :src="getSiteDetails.header_logo"
                   alt="replug">

            </a>
          </div>

          <!--<h1>Generate More Leads with Your Links </h1>-->
          <!--<p>Drive traffic back to your site by adding your own branded messages to any page on the web</p>-->
        </div>
      </div>
      <div class="white_box m-auto w-50">
        <div class="box_inner">
          <div class="box_head d-flex text-center align-items-center justify-content-center">
            <div class="left">
              <h2>Forgot your password?</h2>
            </div>


          </div>
          <div class="box_content">


            <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

              <div class="avatar">
                <i class="fal fa-envelope"></i>
              </div>
              <div class="content">
                <div class="url">Username/Email</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" class="form-control" name="email" id="email"
                           @keyup.enter="forgotPassword()"
                           placeholder="Your username or email address" v-model="email">

                  </div>
                </div>
              </div>
            </div>


            <div class="mt-4 btn_block txt-center">

              <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                      :disabled="loader"
                      @click.prevent="forgotPassword();">
                <span>Reset Password</span>

                <clip-loader v-if="loader" :color="color" :size="size"></clip-loader>
              </button>

            </div>
            <div class="bottom_link mt-3 text-center">

              <router-link :to="{ path: 'login' }"
                           class="login_lin">
                Sign in to your account!
              </router-link>

            </div>


          </div>


        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapActions} from 'vuex'
import {forgotPasswordURL} from '@/config/config.js'
import LoginLeft from './LoginLeft.vue'
import LoginFooter from './LoginFooter.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default ({
  components: {
    LoginLeft,
    ClipLoader,
    LoginFooter
  },
  data () {
    return {
      email: null,
      size: '20px',
      color: '#ffffff',
      loader: false
    }
  },
  async created () {
    await this.fetchWhitelabelAppSettings()
  },
  methods: {
    ...mapActions(['setUnauthorizedException', 'fetchWhitelabelAppSettings']),
    forgotPassword () {
      this.loader = true
      this.$http.post(forgotPasswordURL, {email: this.email}).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.alertMessage(response.data.message, 'success')
              this.$router.push({name: 'login'})
            } else this.alertMessage(response.data.message, 'error')

          }
          this.loader = false
        },
        response => {
          this.loader = false
          this.alertMessage(response.data.message, 'error')
        }
      )
    }
  }

})
</script>
