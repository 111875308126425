import {
  changeHashStatusURL,
  changePoweredBySettingURL,
  checkoutURL,
  getBufferAccountsURL,
  getProfileURL,
  getUserSubscriptionURL,
  upgradeURL, changeMiscSettingURL
} from '../../config/config.js'

import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import {removeTeamMemberURL} from '../../config/api'
import http from '@/mixins/http-lib'
import {STATUS, PLAN_TYPE, ROLES} from '@/common/constants'
import {AnalyticIdentityEvent} from '../../common/methods'
import {INTERNAL_SERVER_ERROR} from '../../common/messages'
import {accountStateMessages} from '@/common/attributes'

const userMixin = {

  methods: {

    ...mapActions(['setProfile', 'setSubscription', 'setTeamBrands', 'setTeamDefault',
      'setTeamMemberLoader', 'setTeamPixels', 'setTeamDomains', 'setFetchTeamMemberLoader', 'setFetchTeamMemberStatus',
      'setTeamMember', 'setJoinTeamLoader', 'setTeamIntegrations',
      'setModalStatus', 'setBufferFetchStatus', 'setBufferData']),

    getUserProfile(loadPlugins = false) {
      return http.post(getProfileURL).then(
        async response => {
          if (!this.processAjaxResponse(response)) return
          if (!response.data.status) return

          const status = response.data.profile.state

          // if user is deleted, disabled
          if ([STATUS.deleted, STATUS.disabled].indexOf(status) !== -1) {
            this.$store.dispatch('toastNotification', {
              message: accountStateMessages(status).account_state,
              type: 'error'
            })
            this.$router.push({name: 'logout'})
            return
          }

          response.data.profile.reff = response.data.profile.reff || ''

          this.setProfile(response.data.profile)

          // if billing status is past due
          if (response.data.profile && response.data.profile.subscription_state && response.data.profile.subscription_state === 'past_due') {
            this.$router.push({name: 'billing'})
          }

          const allowedUsers = ['waqar.azeem1986@gmail.com']
          const access_password_protected = allowedUsers.includes(response.data.profile.email)
          window.localStorage.setItem('access_password_protected', access_password_protected.toString())

          const userDetail = {
            email: this.getProfile.email,
            name: this.getProfile.first_name + ' ' + this.getProfile.last_name,
            first_name: this.getProfile.first_name,
            last_name: this.getProfile.last_name,
            business_type: this.getProfile.business_type ? this.getProfile.business_type : 'N/A',
          }

          if (window.location.hostname === 'app.replug.io') {
            // passing data to gosquared.
            try {
              _gs('identify', {
                id: this.getProfile._id,
                ...userDetail,
                custom: {
                  referral_level: this.getProfile.referral_level
                }
              })
            } catch (m) {
              console.log(m)
            }

            // passing data to woopra
            try {
              woopra.identify({
                id: this.getProfile._id,
                ...userDetail
              })

              // The identify code should be added before the "track()" function
              woopra.track()
            } catch (m) {
              console.log(m)
            }

            // customer io.
            try {
              if (this.getProfile._id) {
                _cio.identify({
                  id: this.getProfile._id,
                  ...userDetail,
                  created_at: moment(this.getProfile.created_at).unix(),
                  current_plan: this.getSubscription.fastspring_name,
                  brands: response.data.profile.totalBrands,
                  campaigns: response.data.profile.totalCampaigns
                })
              }
            } catch (e) {
            }
          }

          // userMaven identity event
          await AnalyticIdentityEvent(response.data.profile, this.getSubscription)

          await this.getUserSubscription()

          if (loadPlugins) {
            this.loadSocketBroadcast()
          }

          /**
           * It is a redirection to dashboard based on the following scenarios
           * 1. Any user role except Super Admin is on billing or apps page route
           * 2. Guest user is on pixels, integrations, custom_domains or tags route
           * 3. Only Super Admin and Admin can be on teams route
           */
          const userRole = this.getMemberRole(response.data.profile)
          const route = this.$route.name
          if (
            (['billing', 'apps'].includes(route) && userRole !== ROLES.super_admin) ||
            (['pixels', 'integrations', 'custom_domains', 'tags'].includes(route) && userRole === ROLES.guest) ||
            (route === 'team' && [ROLES.super_admin, ROLES.admin].indexOf(userRole) === -1)
          ) {
            this.$router.push({name: 'dashboard'})
          }
        },
        response => {

        }
      )
    },

    getUserSubscription() {
      http.post(getUserSubscriptionURL).then(
        async response => {
          if (response.data.status) {
            this.setSubscription(response.data.subscription)
            await this.loadRequiredData()
            if (response.data.signature) {
              this.showLiveWidgets(response.data.signature)
            } else {
              this.showLiveWidgets()
            }
            console.debug('Subscription has been set for the user')

            if (window.location.hostname === 'app.replug.io') {
              // passing data to gosquared.
              try {
                _gs('identify', {
                  id: this.getProfile._id,
                  email: this.getProfile.email,
                  business_type: this.getProfile.business_type ? this.getProfile.business_type : 'N/A',
                  custom: {
                    current_plan: this.getSubscription.fastspring_name
                  }
                })
              } catch (m) {
                console.log(m)
              }

              // passing data to woopra
              try {
                woopra.identify({
                  id: this.getProfile._id,
                  email: this.getProfile.email,
                  current_plan: this.getSubscription.fastspring_name,
                  business_type: this.getProfile.business_type ? this.getProfile.business_type : 'N/A',
                })

                // The identify code should be added before the "track()" function
                woopra.track()
              } catch (m) {
                console.log(m)
              }

              // customer io.
              try {
                if (this.getProfile._id) {
                  _cio.identify({
                    id: this.getProfile._id,
                    email: this.getProfile.email,
                    first_name: this.getProfile.first_name,
                    last_name: this.getProfile.last_name,
                    business_type: this.getProfile.business_type ? this.getProfile.business_type : 'N/A',
                    created_at: moment(this.getProfile.created_at).unix(),
                    current_plan: this.getSubscription.fastspring_name,
                    brands: this.getProfile.totalBrands,
                    campaigns: this.getProfile.totalCampaigns
                  })
                }
              } catch (e) {

              }

              // Drip integration
              try {
                _dcq.push(['identify', {
                  user_id: this.getProfile._id,
                  email: this.getProfile.email,
                  current_plan: this.getSubscription.fastspring_name,
                  business_type: this.getProfile.business_type ? this.getProfile.business_type : 'N/A',
                  // other custom properties...
                  success: function (response) {
                    // Call a method with the response object
                    // Success callback is optional
                  }
                }])
              } catch (m) {
                console.log(m)
              }
            }
            // userMaven identity event
            await AnalyticIdentityEvent(this.getProfile, this.getSubscription)
          }
        },
        response => {
        }
      )
    },

    checkoutPlan(plan) {
      http.post(checkoutURL).then(response => {
        if (response.data.key && response.data.payload) {
          _f.Reset()
          fastspring.builder.secure(response.data.payload, response.data.key)
          _f.Add(plan)
          _f.Checkout()
        }
      }, response => {

      })
    },

    upgradePlan(subscription) {
      http.post(upgradeURL, {
        subscription: subscription
      }).then(
        response => {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
            this.getUserProfile()
            this.$bvModal.hide('upgrade-subscription-modal')
          }
        },
        response => {

        }
      )
    },

    disableURLHash() {
      console.debug('Method:disableURLHash')
      http.post(changeHashStatusURL, {status: this.getProfile.append_hash}).then(
        response => {

        },
        response => {

        }
      )
    },

    updateMiscSetting(key) {
      console.debug('Update Miscellaneous Settings')
      http.post(changeMiscSettingURL, {key, value: this.getProfile[key]})
        .then(
          response => {
            console.log(response)
            if (response.data && response.data.status) {
              this.alertMessage(response.data.message, 'success')
              return
            }
            this.alertMessage(response.data.message, 'error')
          },
          () => this.alertMessage(INTERNAL_SERVER_ERROR, 'error'))
    },

    TeamIntegrationPermission(accountId, type) {
      const integrations = this.getTeam.permission.integrations

      if (integrations[type] && integrations[type].includes(accountId)) {
        const index = integrations[type].indexOf(accountId)
        integrations[type].splice(index, 1)
      } else {
        integrations[type] = integrations[type] || []
        integrations[type].push(accountId)
      }
      this.setTeamIntegrations(integrations)
    },

    removeMember() {
      const stateObject = this

      const id = stateObject.getTeamMember.memberId
      http.post(removeTeamMemberURL, {
        id: id,
        keepData: !stateObject.getTeamMember.keepData
      }).then(
        response => {
          console.log('Exception in removeTeamMembers', response)
          if (stateObject.processAjaxResponse(response)) {
            if (response.data.status) {
              stateObject.setModalStatus('success')
              stateObject.alertMessage(response.data.message, 'success')
              stateObject.closeConfirmAction('remove_team_member')
              $(stateObject.getTeamMember.item).each(function (index, el) {
                if (el._id == id) {
                  stateObject.getTeamMember.item.splice(index, 1)
                  return false
                }
              })
            } else {
              stateObject.setModalStatus('fail')
              if (stateObject.message) {
                stateObject.alertMessage(stateObject.message, 'error')
              } else {
                stateObject.alertMessage('Unable to remove team member. Please try again.', 'error')
              }
            }
          }
          stateObject.closeConfirmAction('remove_team_member')
        }
      ).catch(
        response => {
          stateObject.setModalStatus('fail')
          console.log('Exception in removeTeamMembers', response)
          stateObject.alertMessage('Uh-oh! An unknown error occurred, support has been notified.', 'error')
        }
      )
    },

    fetchBufferAccounts() {
      http.post(getBufferAccountsURL).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.setBufferData(response.data.accounts)
            }
          }
        },
        response => {
        }
      )
    },

    changePoweredBySetting() {
      http.post(changePoweredBySettingURL, {status: this.getProfile.poweredBy}).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
            } else {
              const profile = this.getProfile
              profile.poweredBy = !profile.poweredBy
              this.setProfile(profile)
              this.alertMessage(response.data.message, 'error')
            }
          }
        },
        response => {
          const profile = this.getProfile
          profile.poweredBy = !profile.poweredBy
          this.setProfile(profile)
        }
      )
    },
    isBruceCampaign() {
      if (!this.getSubscription || !this.getSubscription.type_of) return true
      return this.getSubscription.type_of === PLAN_TYPE.LIFETIME
    },
    /**
     * check if subscription date is passed
     * @returns {boolean}
     */
    isSubscriptionEffectivePass(cancellation_effective_at = '') {

      const currentDate = new Date()
      const targetDate = new Date(cancellation_effective_at)

      return currentDate >= targetDate
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getTeam', 'getBuffer'])
  }
}

export {userMixin}
