import axios from 'axios'
import Vue from 'vue'
import Toasted from 'vue-toasted'
import Velocity from 'velocity-animate'
import { INTERNAL_SERVER_ERROR } from './messages'
/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
Vue.use(Toasted)

function toast (payload) {
  // set the payload to success if no property defined.

  if (!payload.hasOwnProperty('type')) {
    payload.type = 'success'
  }

  let type = payload.type
  let message = payload.message
  console.debug('notification detail message ==== ', message, type)
  let i = ''
  let className = ''

  if (type === 'success') {
    className = 'success'
    i = '<img style="width: 24px;" class="success_icon" src="' + require('../assets/img/notification_icons/success_icon.svg') + '">'
  } else if (type === 'warn') {
    className = 'warn'
    i = '<img style="width: 24px;" class="warn_icon" src="' + require('../assets/img/notification_icons/warning_icon.svg') + '">'
  } else if (type === 'info') {
    className = 'info'
    i = '<img style="width: 24px;" class="info_icon" src="' + require('../assets/img/notification_icons/info_icon.svg') + '">'
  } else if (type === 'error') {
    className = 'error'
    i = '<img style="width: 24px;" class="error_icon" src="' + require('../assets/img/notification_icons/error_icon.svg') + '">'
  } else {
    className = 'success'
    i = '<img style="width: 24px;" class="success_icon" src="' + require('../assets/img/notification_icons/success_icon.svg') + '">'
  }
  message = `
                   <div class="d-flex align-items-center toastr_inner ${className}">

                       <div class="icon_sec">
                          ${i}
                       </div>
                       <div class="content_sec">
                           <p>${message}</p>
                       </div>
                        <div class="close_icon">
                         <img  class="success_icon" src="${require('../assets/img/notification_icons/cross_icon.svg')}">
                        </div>
                   </div>
                          `

  let toast = Vue.toasted.show(message, {
    theme: 'primary',
    position: 'top-right',
    duration: 5000
  })
  let toastItem = toast.el
  let closeIcon = toast.el.firstElementChild.lastElementChild
  closeIcon.addEventListener('click', (e) => {
    Velocity(toastItem, { 'opacity': 0, marginTop: '-40px' }, {
      duration: 375,
      easing: 'easeOutExpo',
      queue: false,
      complete: function () {
        toast.el.remove()
      }
    })
  })
}

const http = axios.create({})

http.CancelToken = axios.CancelToken
http.isCancel = axios.isCancel
http.interceptors.request.use(
  (config) => {
    let token = document.querySelector('meta[name=csrf-token]').getAttribute('content')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  }
)

http.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 500:
          toast({ message: INTERNAL_SERVER_ERROR, type: 'error' })
          break

        case 422:
          if (error.response.data.errors) {
            let errorsList = Object.entries(error.response.data.errors)
            try {
              toast({ message: errorsList[0][1][0], type: 'error' })
            } catch (e) {
              console.log('toast error', e)
            }
          } else {
            toast({ message: error.response.data.message, type: 'error' })
          }
          break
      }
    }
    return Promise.reject(error)
  }
)

export default http
