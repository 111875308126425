import Velocity from 'velocity-animate'
import Vue from 'vue'
import Toasted from 'vue-toasted'


Vue.use(Toasted)

export default {
  actions: {
    toastNotification ({ commit, getters }, payload) {
      // set the payload to success if no property defined.

      if (!payload.hasOwnProperty('type')) {
        payload.type = 'success'
      }

      let type = payload.type
      let message = payload.message
      console.debug('notification detail message ==== ', message, type)
      let i = ''
      let className = ''

      if (type === 'success') {
        className = 'success'
        i = '<img  style="width: 24px;" class="success_icon" src="' + require('../../assets/img/notification_icons_png/success_icon.png') + '">'
      } else if (type === 'warn') {
        className = 'warn'
        i = '<img style="width: 24px;" class="warn_icon" src="' + require('../../assets/img/notification_icons_png/warning_icon.png') + '">'
      } else if (type === 'info') {
        className = 'info'
        i = '<img style="width: 24px;" class="info_icon" src="' + require('../../assets/img/notification_icons_png/info_icon.png') + '">'
      } else if (type === 'error') {
        className = 'error'
        i = '<img style="width: 24px;" class="error_icon" src="' + require('../../assets/img/notification_icons_png/error_icon.png') + '">'
      } else {
        className = 'success'
        i = '<img style="width: 24px;" class="success_icon" src="' + require('../../assets/img/notification_icons_png/success_icon.png') + '">'
      }
      message = `
                   <div class="d-flex align-items-center toastr_inner ${className}">

                       <div class="icon_sec">
                          ${i}
                       </div>
                       <div class="content_sec">
                           <p>
                                ${message}
                           </p>
                       </div>
                       <div class="close_icon">
                            <img src="/assets/img/cancel.png" alt="">
                       </div>
                   </div>
                          `

      let toast = Vue.toasted.show(message, {
        theme: 'primary',
        position: 'top-right',
        duration: 5000
      })
      let toastItem = toast.el
      let closeIcon = toast.el.firstElementChild.lastElementChild
      closeIcon.addEventListener('click', (e) => {
        Velocity(toastItem, { 'opacity': 0, marginTop: '-40px' }, {
          duration: 375,
          easing: 'easeOutExpo',
          queue: false,
          complete: function () {
            toast.el.remove()
          }
        })
      })
    },

    toastNotificationFixed ({ commit, getters }, payload) {
      // set the payload to success if no property defined.

      if (!payload.hasOwnProperty('type')) {
        payload.type = 'success'
      }

      let type = payload.type
      let message = payload.message
      console.debug('notification detail message ==== ', message, type)
      let i = ''
      let className = ''

      if (type === 'success') {
        className = 'success'
        i = '<img style="width: 24px;"  class="success_icon" src="' + require('@/assets/img/notification_icons_png/success_icon.png') + '">'
      } else if (type === 'warn') {
        className = 'warn'
        i = '<img style="width: 24px;" class="warn_icon" src="' + require('@/assets/img/notification_icons_png/warning_icon.png') + '">'
      } else if (type === 'info') {
        className = 'info'
        i = '<img style="width: 24px;" class="info_icon" src="' + require('@/assets/img/notification_icons_png/info_icon.png') + '">'
      } else if (type === 'error') {
        className = 'error'
        i = '<img style="width: 24px;" class="error_icon" src="' + require('@/assets/img/notification_icons_png/error_icon.png') + '">'
      } else {
        className = 'success'
        i = '<img style="width: 24px;" class="success_icon" src="' + require('@/assets/img/notification_icons_png/success_icon.png') + '">'
      }
      message = `
                   <div class="d-flex align-items-center toastr_inner ${className}">

                       <div class="icon_sec">
                          ${i}
                       </div>
                       <div class="content_sec">
                           <p>${message}</p>
                       </div>
                        <div class="close_icon">
                         <img  class="success_icon" src="${require('@/assets/img/notification_icons_png/cross_icon.png')}">
                        </div>
                   </div>
                          `

      let toast = Vue.toasted.show(message, {
        theme: 'primary',
        position: 'bottom-right',
        duration: 5000
      })
      let toastItem = toast.el
      let closeIcon = toast.el.firstElementChild.lastElementChild
      closeIcon.addEventListener('click', (e) => {
        Velocity(toastItem, { 'opacity': 0, marginTop: '-40px' }, {
          duration: 375,
          easing: 'easeOutExpo',
          queue: false,
          complete: function () {
            toast.el.remove()
          }
        })
      })
    }
  }
}
