<template>
  <div class="d-flex align-items-center rplg_input" :style="cssProps">

    <div class="avatar">
      <i :class="icon" :style="{'color': iconColor}"></i>
    </div>
    <div class="content">
      <div class="label" :style="{'color': labelColor}">{{ labelText }}</div>
      <div class="name">
        <div class="validation">
          <!--          :class="{'input-field-error': getQuickLinkValidations.validations.url || getQuickLinkValidations.validations.url_valid}"-->
          <input v-model="modelValue"
                 @keyup.enter="submit()"
                 :placeholder="placeholder"
                 :disabled="disabled"
                 :type="type"
                 :style="{'color': textColor}">
          <span class="input_error" v-for="validation in validations"
                v-if="validation.show"
                :style="{'color': errorTextColor, 'background-color': errorBgColor}">
                      {{ validation.message }}
                    </span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rp-input',
  props: {
    labelText: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: 'rgba(49, 58, 81, 0.6)'
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#1f216e'
    },
    color: {
      type: String,
      default: '#3988fe'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: '#3988fe'
    },
    validations: {
      type: Array,
      default: []
    },
    errorTextColor: {
      type: String,
      default: '#ffffff'
    },
    errorBgColor: {
      type: String,
      default: '#f2405d'
    }
  },
  computed: {
    cssProps () {
      return {
        '--error-bg-color': this.errorBgColor
      }
    }
  },
  created () {
    this.validations = [
      {
        message: 'URL is invalid',
        show: true
      }
    ]
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.rplg_input {
  -webkit-box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  -moz-box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  box-shadow: 0 2px 20px 0 rgba(162, 174, 209, 0.11);
  background-color: #ffffff;
  padding: 10px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  .avatar {
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .content {
    text-align: left;
    width: 100%;
    display: inline-block;
    vertical-align: middle;

    .label {
      font-size: 12px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .name {
      font-weight: 400;

      .validation {
        position: relative;

        input {
          padding: 0;
          width: 100%;
          font-size: 14px;
          height: auto;
          border-radius: 0;
          border: 0 !important;
          outline: none;
        }

        .input_error {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
          margin: 0.5rem 0;
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 13px;
          text-align: left;
          line-height: 1.4;
          display: inline-block;

          &:after {
            content: "";
            position: absolute;
            top: -6px;
            left: 1rem;
            border-bottom: 6px solid var(--error-bg-color);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }
        }
      }
    }
  }
}
</style>
