import http from '@/mixins/http-lib.js'
import { deepLinksTypes } from '@/state/modules/mutation-types'
import { showDeepLinksBrands, showDeepLinksSettingURL } from '@/config/api'

export default {
  state: {
    deepLinksSettings: {
      list: [],
      selectedDeepLinkRoute: {},
      deepLinksItems: [],
      createDeepLink: {},
      loaders: {
        deeplinksListLoader: false
      }
    }
  },
  getters: {
    getDeepLinksSettingsList: state => state.deepLinksSettings.list,
    getSelectedDeepLink: state => state.deepLinksSettings.selectedDeepLinkRoute,
    getCreateDeepLink: state => state.deepLinksSettings.createDeepLink,
    getDeepLinksLoader: state => state.deepLinksSettings.loaders,
    getDeepLinksItems: state => state.deepLinksSettings.deepLinksItems
  },
  actions: {
    showDeepLinks ({ commit, getters, dispatch }, payload) {
      commit(deepLinksTypes.SHOW_DEEP_LINKS_SETTINGS_LOADER, true)
      return http.post(showDeepLinksSettingURL, payload).then(res => {
        if (res.data) {
          commit(deepLinksTypes.SHOW_DEEP_LINKS_SETTINGS, res.data.data)
          commit(deepLinksTypes.SET_FILTERED_DEEP_LINKS_LIST, [])
          commit(deepLinksTypes.SET_DEEP_LINKS_ITEMS, res.data.data)
          commit(deepLinksTypes.SHOW_DEEP_LINKS_SETTINGS_LOADER, false)
          return res.data.data
        }
      }).catch(res => {
        console.warn(res)
      })
    },
    async showDeepLinksBrandsList ({ commit, getters, dispatch }, payload) {
      return http.post(showDeepLinksBrands, payload).then(res => {
        return res.data.data
      }).catch(res => {
        console.warn(res)
      })
    },
    async selectCategory ({ commit, getters, dispatch }, value) {
      commit(deepLinksTypes.SET_SELECTED_DEEP_LINK_ITEM, value)
    },
    async setCreateDeepLink ({ commit }, value) {
      commit(deepLinksTypes.SET_CREATE_DEEP_LINK, value)
    },
    async setFilteredDeepLinksLists ({ commit, getters, dispatch }, value) {
      commit(deepLinksTypes.SET_FILTERED_DEEP_LINKS_LIST, value)
    },
    async resetDeepLinks ({ commit }) {
      commit(deepLinksTypes.RESET_DEEP_LINKS)
    }
  },
  mutations: {
    [deepLinksTypes.SHOW_DEEP_LINKS_SETTINGS] (state, value) {
      state.deepLinksSettings.list = value
    },
    [deepLinksTypes.SHOW_DEEP_LINKS_SETTINGS_LOADER] (state, value) {
      state.deepLinksSettings.loaders.deeplinksListLoader = value
    },
    [deepLinksTypes.SET_SELECTED_DEEP_LINK_ITEM] (state, value) {
      state.deepLinksSettings.selectedDeepLinkRoute = JSON.parse(JSON.stringify(state.deepLinksSettings.list.find(item => item.key === value)))
    },
    [deepLinksTypes.SET_CREATE_DEEP_LINK] (state, value) {
      value.brands.forEach((item, index) => {
        item.selected = (index === 0)
      })
      state.deepLinksSettings.createDeepLink = JSON.parse(JSON.stringify(value))
    },
    [deepLinksTypes.SET_FILTERED_DEEP_LINKS_LIST] (state, value) {
      state.deepLinksSettings.selectedDeepLinkRoute = JSON.parse(JSON.stringify(value))
    },
    [deepLinksTypes.SET_DEEP_LINKS_ITEMS] (state, value) {
      value.forEach(item => {
        state.deepLinksSettings.deepLinksItems.push(...item.items)
      })
    },
    [deepLinksTypes.RESET_DEEP_LINKS] (state) {
      state.deepLinksSettings = {
        list: [],
        selectedDeepLinkRoute: {},
        deepLinksItems: [],
        createDeepLink: {},
        loaders: {
          deeplinksListLoader: false
        }
      }
    }
  }
}
