<template>
    <b-modal modal-class="modal_basic upgradeModal updated-pricing-2019" id="upgradeExistingLifetimePlan" hide-footer
             hide-header>
        <i @click="$router.push({name: 'dashboard'})" class="close_modal fal fa-times-circle"></i>

        <div class="basic_form modal_content">
            <template v-if="loader">
                <div class="pricing_page">
                  <clip-loader :color="'#0095f3'" :size="size"></clip-loader>
<!--                    <beat-loader></beat-loader>-->
                </div>
            </template>
            <template v-else>
                <div class="pricing_page">
                    <div class="heading_x">
                        <h2>Special Lifetime Upgrade Offer</h2>
                        <p class="addon_note">
                        <span class="lifetime_note">
                            NOTE: Your new purchase will be stacked with your existing limits. If you already have higher limits on anything, upgrading will not reduce them.
                        </span>

                        <div class="already_paid_invoices">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Current Plan</th>
                                    <th style="width: 260px;">Amount Paid</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="order in orders_list">
                                    <tr>
                                        <td style="width: 240px;">{{order.name}}</td>
                                        <td class="text-center" style="width: 150px;">
                                            <strong>${{order.amount_paid}}</strong>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>

                        </div>
                        <p class="paid_amount">
                            You have already paid ${{amount_paid}}. You can upgrade to the higher plan by paying the
                            remaining amount according to the plan you choose.
                        </p>
                        <div class="coupon_code text-center" v-if="coupons_remaining >0">
                            <p class="coupon_code_text">
                                <span>
                                   Get $30 More OFF by using the <span class="coupon_code_value">EARLYBIRD30OFF</span> Coupon Code during checkout. Only {{coupons_remaining}} coupons left.
                                </span>

                            </p>
                        </div>
                        </p>

                    </div>
                    <div class="pricing__box">

                        <template>
                            <h3 class="package_heading_center" style="margin-top:50px;">Choose Plan</h3>
                            <div class="inner  clear row pricing-table-container">
                                <div class="price_box_3 col-md-3" v-if="getCurrentPlanLimits">
                                    <div class="inner" style="background: transparent;">
                                        <div class="fix-height d-flex align-items-end justify-content-center">
                                            <h3 class="package mb-3">Your Existing Limits</h3>
                                        </div>
                                        <!--<h2 class="price _purple" style="text-decoration: line-through;"><sup>$</sup>-->
                                        <!--249-->
                                        <!--<sub style="bottom: 0.35em;">Lifetime</sub></h2>-->
                                        <!--<h2 class="price original_price _purple"><sup>$</sup>-->
                                        <!--42-->
                                        <!--<sub style="bottom: 0.35em;">Lifetime</sub></h2>-->
                                        <p class="duration"></p>

                                        <ul class="list_detail">
                                            <li><i class="fal fa-check pricing-table-icon"></i><span class="bold">{{getCurrentPlanLimits.clicks | number-to-commas}}</span>
                                                clicks per month
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold"><template
                                                    v-if="getCurrentPlanLimits.brands> 500">Unlimited</template><template
                                                    v-else>{{getCurrentPlanLimits.brands}}</template></span>
                                                Brands
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    v-if="getCurrentPlanLimits.campaigns > 100"
                                                    class="bold">unlimited</span>
                                                <template v-else>{{getCurrentPlanLimits.campaigns}}
                                                </template>
                                                Campaigns
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold" v-if="getCurrentPlanLimits.retargeting_pixels > 1000">unlimited</span>
                                                <span v-else>{{getCurrentPlanLimits.retargeting_pixels}}</span>
                                                Retargeting Pixel
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links
                                            </li>
                                            <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i><template
                                                    v-if="getCurrentPlanLimits.custom_domains > 1000">unlimited
                                            </template>
                                            <template v-else>{{getCurrentPlanLimits.custom_domains}}
                                            </template>
                                            </span> Custom Domains
                                            </li>
                                            <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i><template
                                                    v-if="getCurrentPlanLimits.team_members > 1000">unlimited
                                            </template>
                                            <template v-else>{{getCurrentPlanLimits.team_members}}
                                            </template>
                                        </span> Team Members
                                            </li>
                                            <li class="">
                                                <i class="fal pricing-table-icon"
                                                   :class="{'fa-check': getCurrentPlanLimits.branding, 'fa-times': !getCurrentPlanLimits.branding}"></i>Remove
                                              {{ getSiteDetails.agency_name }} Branding
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getCurrentPlanLimits.dedicated_support, 'fa-times': !getCurrentPlanLimits.dedicated_support}"></i>Dedicated
                                                Support
                                            </li>
                                            <li v-if="getCurrentPlanLimits.ab_testing" class=""><i
                                                    class="fal  pricing-table-icon"
                                                    :class="{'fa-check': getCurrentPlanLimits.ab_testing, 'fa-times': !getCurrentPlanLimits.ab_testing}"></i>A/B
                                                Testing
                                            </li>
                                            <li v-if="getCurrentPlanLimits.whitelabel" class=""><i
                                                    class="fal  pricing-table-icon"
                                                    :class="{'fa-check': getCurrentPlanLimits.whitelabel, 'fa-times': !getCurrentPlanLimits.whitelabel}"></i>White-label
                                                (coming
                                                soon)
                                            </li>
                                            <li v-if="getCurrentPlanLimits.bio_links" class=""><i
                                                    class="fal  pricing-table-icon"
                                                    :class="{'fa-check': getCurrentPlanLimits.bio_links > 0, 'fa-times': !getCurrentPlanLimits.bio_links === 0}"></i><strong><template v-if="getCurrentPlanLimits.bio_links > 1000">Unlimited</template><template v-else>{{getCurrentPlanLimits.bio_links}}</template></strong>
                                                Bio-links
                                            <li v-if="getCurrentPlanLimits.deep_linking" class=""><i
                                                    class="fal  pricing-table-icon"
                                                    :class="{'fa-check': getCurrentPlanLimits.deep_linking, 'fa-times': !getCurrentPlanLimits.deep_linking}"></i>Deep-linking
                                                (coming
                                                soon)
                                            </li>
                                            <li v-if="getCurrentPlanLimits.conversion_tracking" class=""><i
                                                    class="fal  pricing-table-icon"
                                                    :class="{'fa-check': getCurrentPlanLimits.conversion_tracking, 'fa-times': !getCurrentPlanLimits.conversion_tracking}"></i>Conversion
                                                Tracking
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getCurrentPlanLimits.api_access, 'fa-times': !getCurrentPlanLimits.api_access}"></i>API
                                                Access
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getCurrentPlanLimits.onboarding_and_call_support, 'fa-times': !getCurrentPlanLimits.onboarding_and_call_support}"></i>Onboarding
                                                &amp;
                                                Call Support
                                            </li>
                                        </ul>
                                        <!--<div>-->
                                        <!--<button class="mt-3 btn-&#45;&#45;cta btn-blue btn-round pricing_btn  purple"><span>Upgrade</span></button>-->
                                        <!--</div>-->
                                    </div>
                                </div>
                                <div class="price_box_3 col-md-6" v-for="item in pricing_list.plans"
                                     :class="{'most_popular': item.level === 3 || item.level === 4, 'current_plan': item.level < level}"
                                     v-if="item.level === level">
                                    <div class="inner">

                                        <!--<h3 class="package">{{item.display}}</h3>-->

                                        <div class="fix-height" style="margin: 0 auto; display: block; width:280px;">

                                            <label for=""><strong>Select Plan Below</strong></label>
                                            <!--<h3  class="package_heading">Choose Plan</h3>-->
                                            <b-dropdown ref="" right
                                                        class="ml-2  dropdown-menu-right default_style_dropdown"
                                                        :no-caret="true" style="min-width: 170px; max-width: 280px;
    ">
                                                <!--d-flex align-items-center-->
                                                <div class="text-center dropdown_header  rounded_shape   d-flex align-items-center"
                                                     slot="button-content"
                                                     style="min-width: 170px; max-width: 280px; margin: 0 auto; background: rgb(164, 255, 222); border: 1px solid #1f216e;">
                                                    <div class="text">
                                                        <p class="text-capitalize text-center">
                                                            <template>
                                                                <strong> {{item.display}}</strong>
                                                            </template>
                                                        </p>
                                                    </div>
                                                    <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                            </span>
                                                </div>
                                                <ul class="clear inner" style="width:320px;">
                                                    <li @click="changeLevel(plan.level)" class="list_item_li"
                                                        v-for="plan in pricing_list.plans">
                                                        <i class="far fa-check-circle green-color"
                                                           style="color:rgb(0, 171, 110);"
                                                           v-if="level === plan.level"></i>
                                                        {{plan.display}}
                                                    </li>

                                                </ul>
                                            </b-dropdown>
                                            <h2 class="price" :class="item.color" style="text-decoration:line-through;">
                                                <sup>$</sup>
                                                {{item.original_price}}
                                                <!--<sub style="bottom: 0.35em">Lifetime</sub>-->
                                            </h2>
                                            <h2 class="price original_price"
                                                :class="item.color">
                                                <sup>$</sup>
                                                {{item.price + 20}}
                                                <sub style="bottom: 0.35em">Lifetime</sub>
                                            </h2>
                                        </div>

                                        <p class="duration">{{item.duration}}</p>

                                        <ul class="list_detail" style="padding-top:20px;">
                                            <!-- <li class="brand-li">
                                             <span class="btn-&#45;&#45;cta btn-round"><span
                                                     class="bold">{{item.brands}}</span> Brands</span>
                                             </li>-->

                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold">{{item.clicks | number-to-commas}}</span>
                                                clicks per month
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold"><template
                                                    v-if="item.brands> 500">Unlimited</template><template
                                                    v-else>{{item.brands}}</template></span>
                                                Brands
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold"><template
                                                    v-if="item.campaigns > 100">Unlimited</template><template
                                                    v-else>{{item.campaigns}}</template></span>
                                                Campaigns
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold"><template
                                                    v-if="item.retargeting_pixels > 100">Unlimited</template><template
                                                    v-else>{{item.retargeting_pixels}}</template></span>
                                                Retargeting Pixel
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links
                                            </li>
                                            <template v-if="item.custom_domains === 0">
                                                <li class="line_strike"><i class="fal fa-times pricing-table-icon"></i>Custom
                                                    Domains
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i>{{item.custom_domains}}</span>
                                                    Custom Domains
                                                </li>
                                            </template>
                                            <template v-if="item.team_members === 0">
                                                <li class="line_strike"><i class="fal fa-times pricing-table-icon"></i>Team
                                                    Members
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i>{{item.team_members}}</span>
                                                    Team Members
                                                </li>
                                            </template>
                                            <li :class="{'line_strike': !item.branding}"><i
                                                    :class="{'fa-check': item.branding, 'fa-times': !item.branding}"
                                                    class="fal pricing-table-icon"></i>Remove {{ getSiteDetails.agency_name }} Branding
                                            </li>
                                            <li :class="{'line_strike': !item.dedicated_support}"><i
                                                    :class="{'fa-check': item.dedicated_support, 'fa-times': !item.dedicated_support}"
                                                    class="fal  pricing-table-icon"></i>Dedicated Support
                                            </li>
                                            <li :class="{'line_strike': !item.ab_testing}"><i
                                                    :class="{'fa-check': item.ab_testing, 'fa-times': !item.ab_testing}"
                                                    class="fal  pricing-table-icon"></i>A/B Testing (coming soon)
                                            </li>
                                            <li :class="{'line_strike': !item.whitelabel}"><i
                                                    :class="{'fa-check': item.whitelabel, 'fa-times': !item.whitelabel}"
                                                    class="fal  pricing-table-icon"></i>White-label (coming soon)
                                            </li>
                                            <li :class="{'line_strike': item.bio_links === 0}"><i
                                                    :class="{'fa-check': item.bio_links > 0, 'fa-times': !item.bio_links === 0}"
                                                    class="fal  pricing-table-icon"></i><template v-if="item.bio_links > 1000">Unlimited</template><template v-else>{{item.bio_links}}</template> Bio-links
                                            </li>
                                            <li :class="{'line_strike': !item.deep_linking}"><i
                                                    :class="{'fa-check': item.deep_linking, 'fa-times': !item.deep_linking}"
                                                    class="fal  pricing-table-icon"></i>Deep-linking (coming soon)
                                            </li>
                                            <li :class="{'line_strike': !item.conversion_tracking}"><i
                                                    :class="{'fa-check': item.conversion_tracking, 'fa-times': !item.conversion_tracking}"
                                                    class="fal  pricing-table-icon"></i>Conversion Tracking (coming
                                                soon)
                                            </li>
                                            <li :class="{'line_strike': !item.api_access}"><i
                                                    :class="{'fa-check': item.api_access, 'fa-times': !item.api_access}"
                                                    class="fal  pricing-table-icon"></i>API Access
                                            </li>
                                            <li :class="{'line_strike': !item.onboarding_and_call_support}"><i
                                                    :class="{'fa-check': item.onboarding_and_call_support, 'fa-times': !item.onboarding_and_call_support}"
                                                    class="fal  pricing-table-icon"></i>Onboarding & Call Support
                                            </li>
                                        </ul>
                                        <div v-if="getSubscription.internal_id && getSubscription.internal_id === item.internal_id">
                                            <button class="mt-3 btn---cta btn-round  btn-border current_plan_btn pricing_btn">
                                                <span>Current Plan</span>
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button class="mt-3 btn---cta btn-blue btn-round pricing_btn "
                                                    :class="item.color.substring(1)" v-if="!item.is_subscribed_plan"
                                                    @click="upgradeLifetimeAgencyPlan(item.display, item.internal_name, item.price + 20)">
                                                <span>Upgrade</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="price_box_3 col-md-3" v-if="getNewPlanLimits">
                                    <div class="inner" style="background: transparent;">
                                        <div class="fix-height d-flex align-items-end justify-content-center">
                                            <h3 class="package mb-3">New Limits</h3>
                                        </div>

                                        <!--<h2 class="price _purple" style="text-decoration: line-through;"><sup>$</sup>-->
                                        <!--249-->
                                        <!--<sub style="bottom: 0.35em;">Lifetime</sub></h2>-->
                                        <!--<h2 class="price original_price _purple"><sup>$</sup>-->
                                        <!--42-->
                                        <!--<sub style="bottom: 0.35em;">Lifetime</sub></h2>-->
                                        <p class="duration"></p>

                                        <ul class="list_detail list_detail_green_check">
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold highlight_item">{{getNewPlanLimits.clicks | number-to-commas}}</span>
                                                clicks per month
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold"><template
                                                    v-if="getNewPlanLimits.brands> 500">Unlimited</template><template
                                                    v-else>{{getNewPlanLimits.brands}}</template></span>
                                                Brands
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    v-if="getNewPlanLimits.campaigns > 100"
                                                    class="bold">unlimited</span>
                                                <template v-else>{{getNewPlanLimits.campaigns}}
                                                </template>
                                                Campaigns
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold highlight_item"
                                                    v-if="getNewPlanLimits.retargeting_pixels > 1000">unlimited</span>
                                                <span v-else>{{getNewPlanLimits.retargeting_pixels}}</span> Retargeting
                                                Pixel
                                            </li>
                                            <li><i class="fal fa-check pricing-table-icon"></i><span
                                                    class="bold highlight_item">Unlimited</span> {{ getSiteDetails.agency_name }} Links
                                            </li>
                                            <li><span class="bold highlight_item"><i
                                                    class="fal fa-check pricing-table-icon"></i><template
                                                    v-if="getNewPlanLimits.custom_domains > 1000">unlimited
                                            </template>
                                            <template v-else>{{getNewPlanLimits.custom_domains}}
                                            </template>
                                            </span>
                                                Custom Domains
                                            </li>
                                            <li><span class="bold highlight_item"><i
                                                    class="fal fa-check pricing-table-icon"></i><template
                                                    v-if="getNewPlanLimits.team_members > 1000">unlimited</template>
                                            <template v-else>{{getNewPlanLimits.team_members}}
                                            </template>
                                        </span>
                                                Team Members
                                            </li>
                                            <li class="">
                                                <i class="fal pricing-table-icon"
                                                   :class="{'fa-check': getNewPlanLimits.branding, 'fa-times': !getNewPlanLimits.branding}"></i>Remove
                                              {{ getSiteDetails.agency_name }} Branding
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.dedicated_support, 'fa-times': !getNewPlanLimits.dedicated_support}"></i>Dedicated
                                                Support
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.ab_testing, 'fa-times': !getNewPlanLimits.ab_testing}"></i>A/B
                                                Testing
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.whitelabel, 'fa-times': !getNewPlanLimits.whitelabel}"></i>White-label
                                                (coming
                                                soon)
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.bio_links > 0, 'fa-times': getNewPlanLimits.bio_links === 0}"></i><strong><template v-if="getNewPlanLimits.bio_links > 1000">Unlimited</template><template v-else>{{getNewPlanLimits.bio_links}}</template></strong>
                                                Bio-links
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.deep_linking, 'fa-times': !getNewPlanLimits.deep_linking}"></i>Deep-linking
                                                (coming
                                                soon)
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.conversion_tracking, 'fa-times': !getNewPlanLimits.conversion_tracking}"></i>Conversion
                                                Tracking
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.api_access, 'fa-times': !getNewPlanLimits.api_access}"></i>API
                                                Access
                                            </li>
                                            <li class=""><i class="fal  pricing-table-icon"
                                                            :class="{'fa-check': getNewPlanLimits.onboarding_and_call_support, 'fa-times': !getNewPlanLimits.onboarding_and_call_support}"></i>Onboarding
                                                &amp;
                                                Call Support
                                            </li>
                                        </ul>
                                        <!--<div>-->
                                        <!--<button class="mt-3 btn-&#45;&#45;cta btn-blue btn-round pricing_btn  purple"><span>Upgrade</span></button>-->
                                        <!--</div>-->
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>

                </div>
            </template>

        </div>
    </b-modal>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import http from '@/common/http'
import {
  getCouponsUsedURL,
  getLifetimeBillingPlansURL,
  getNewLimitsURL,
  getOverridePriceURL
} from '@/config/api'

export default {
  data () {
    return {
      plan_type: 'lifetime',
      pricing_list: { plans: [] },
      level: 0,
      orders_list: [],
      amount_paid: 0,
      state: 'agency',
      loader: true,
      new_limits: {},
      coupons_remaining: 30
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription']),
    getCurrentPlanLimits () {
      return this.getSubscription.limits
    },
    getNewPlanLimits () {
      if (this.pricing_list.plans) {
        let item = this.pricing_list.plans.find(item => item.level === this.level)
        let subscription = this.new_limits // this.getSubscription.limits

        if (item) {
          item = JSON.parse(JSON.stringify(item))

          Object.entries(subscription).forEach(itemValue => {
            if (item.hasOwnProperty(itemValue[0])) {
              if (typeof itemValue[1] === 'number') {
                this.$set(item, itemValue[0], parseInt(itemValue[1]))
              }
              if (typeof itemValue[1] === 'boolean') {
                this.$set(item, itemValue[0], item[itemValue[0]])
              }
            } else {
              this.$set(item, itemValue[0], item[itemValue[0]])
            }
          })
        }
        return item
      }
    }
  },
  mounted () {
    this.$bvModal.show('upgradeExistingLifetimePlan')

    this.pricingPlans()
    this.checkCouponUsed()
  },
  methods: {
    ...mapActions(['upgradeSubscription']),

    togglePrice (name) {
      this.plan_type = name
    },

    upgradeLifetimeAgencyPlan (title, planName, price) {
      planName = planName.replace('bruce-', '')
      var productId = 573913
      http.post(getOverridePriceURL, {
        paddle_id: productId,
        price: price
      }).then(res => {
        if (res.data.status) {
          var paddleOptions = {
            product: productId,
            email: this.getProfile.email,
            title: title,
            price: price,
            auth: res.data.token,

            successCallback: (data) => {
              this.alertMessage('Thanks for your purchase. Your plan has been successfully upgraded, and you will be redirected to the billing section in a few seconds.')

              setTimeout(() => {
                this.getUserSubscription()
                this.$router.push({ 'name': 'billing' })
              }, 2000)
            }
          }
          paddleOptions.passthrough = { type: planName }
          // paddleOptions.coupon = 'TESTPURCHASE'
          Paddle.Checkout.open(paddleOptions)
        }
      })
    },
    async pricingPlans () {
      this.loader = true
      let resp = await http.post(getLifetimeBillingPlansURL).then(res => {
        if (res.data.status) {
          this.pricing_list.plans = res.data.data.plans
          this.orders_list = res.data.data.orders
          this.amount_paid = res.data.data.amount_paid
          this.state = res.data.data.state
          this.level = res.data.data.level
        } else {
          this.$router.push({ name: 'dashboard' })
        }
        return res
      }).catch(err => {

      })
      this.loader = false
      await this.changeLevel(this.level)
    },
    async changeLevel (level) {
      this.level = level
      let item = this.pricing_list.plans.find(item => item.level === level)
      let res = await http.post(getNewLimitsURL, { name: item.internal_name }).then(res => {
        if (res.data.status) {
          this.new_limits = res.data.data
        }
        return res
      }).catch()
      return res
    },
    async checkCouponUsed () {
      let res = await http.post(getCouponsUsedURL).then(res => {
        if (res.data.status) {
          this.coupons_remaining = res.data.data
        }
        return res
      }).catch()
      return res
    }
  }
}
</script>
<style lang="less">
    .coupon_code {
        padding: 20px 0;
        p {
            font-size: 20px !important;
        }
    }

    .coupon_code_text {
        span {
            font-size: 20px !important;
            /*background: #ff3366;*/
            padding: 5px 10px;
            color: #fff;
            font-weight: bold;
        }

    }

    .coupon_code_value {
        background: #ff3366;
    }
</style>
