import { baseURL, APIURL } from '@/config/config'

export const changeEmailURL = baseURL + 'settings/changeEmail'
export const verifyChangeEmailTokenURL = baseURL + 'settings/verifyChangeEmailToken'
export const resendChangeEmailURL = baseURL + 'settings/resendChangeEmail'
export const cancelChangeEmailURL = baseURL + 'settings/cancelChangeEmail'

export const joinTokenStatusURL = APIURL + 'joinTokenStatus'
export const joinTeamURL = APIURL + 'joinTeam'

export const hideChromeExtensionNotificationURL = 'markChromeExtension'
export const getLinkAccountValuesURL = APIURL + 'getLinkAccountValues'
export const checkPasswordURL = baseURL + 'checkPassword'
