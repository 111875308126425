<template>
  <div>
    <!-- Add new entry modal -->
    <b-modal modal-class="modal_basic sm-modal" id="addEntry" no-close-on-backdrop hide-footer hide-header>
      <img @click="$bvModal.hide('addEntry')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>Add {{ selectFiltersObj.type }}</h3>
        </div>

        <div class="row">
          <div class="col-md-12 pl-0 pr-1">
            <div class="input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url capitalize__text">Your {{ selectFiltersObj.type }}</div>
                <div class="name">
                  <div class="validation_input">
                    <b-form-datepicker v-if="selectFiltersObj.key!=='ip'" id="example-datepicker" :min="min"
                                       v-model="selectFiltersObj.newEntryValue" class="data-picker"></b-form-datepicker>
                    <input v-else type="text" v-model="selectFiltersObj.newEntryValue"
                           :placeholder="'Add your '+ selectFiltersObj.type">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" btn_block text-left">
          <button @click="addNewEntryInFilter()"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Add</span></button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('addEntry')"><span>Close</span></button>
        </div>

      </div>
    </b-modal>

    <!-- Rule List modal  -->
    <b-modal modal-class="modal_basic" ref="add_traffic_routing_rules_modal_component" scrollable
             id="addTrafficRoutingRulesModalComponent" no-close-on-backdrop
             hide-footer hide-header>
      <img @click="closeTrafficRoutingModal()" class="close_modal"
           src="/assets/img/cross.svg"
           alt="">
      <div class="basic_form modal_content">
        <div class="heading">
          <h3>
            <template>Create a routing rule</template>
          </h3>
        </div>
        <div v-if="filtersLoader">
          <clip-loader :size="size" :color="'#3988FE'"></clip-loader>
          <p class="text-center">Loading filters...</p>
        </div>
        <div v-else class="basic_form clear">

          <!-- List of rules section -->
          <div :key="ruleIndex" v-for="(rule,ruleIndex) of temp_routing_rule" class="mb-2 block-list">
            <div class="block-list-body">
              <a @click="removeRule(ruleIndex)" v-if="temp_routing_rule.length > 1" class="remove-rule"><i
                class="fal fa-times"></i></a>

              <!-- List of block section -->
              <div v-if="rule.blocks && rule.blocks.length" :key="blockIndex" v-for="(block,blockIndex) of rule.blocks"
                   class="mb-2 add-block-container">
                <a @click="removeBlock(rule,blockIndex)" v-if="rule.blocks.length > 1" class="remove-block"><i
                  class="fal fa-times"></i></a>
                <div class="block-body">

                  <!-- Selected filter list -->
                  <b-dropdown v-for="(filter,filterIndex) of block" boundary="boundariesElement"
                              :ref="'selected_filter_list'+ruleIndex+blockIndex+filterIndex"
                              class="dropdown-menu-left selected_filter_list default_style_dropdown mb-3"
                              :no-caret="true">

                    <div v-if="filter.selectedRule && filter.selectedRule.icon" style="width: 660px;"
                         class="d-flex align-items-center"
                         slot="button-content">
                      <span v-if="filterIndex==0">When </span>
                      <span v-else>And </span>
                      <a @click="sortSelectedListInFilter(filter)" :class="{'ml-4':filterIndex > 0}"
                         class="select-list ml-3">
                        <i class="mr-1 text-primary" :class="filter.selectedRule.icon"></i> {{
                          filter.selectedRule.type
                        }} is
                        <span> {{ selectedValueListDisplay(filter) }} </span>
                        <a @click="addEntry(filter,ruleIndex,blockIndex,filterIndex)"
                           v-if="['ip','date'].includes(filter.selectedRule.key)"><span><i
                          class="fas fa-plus-circle ml-2"></i></span></a>
                      </a>
                      <a @click="removeFilter(block,filterIndex)" class="remove_filter ml-auto"><i
                        class="fal fa-times"></i></a>
                    </div>

                    <ul v-if="filter.selectedRule && filter.selectedRule.type" class="clear inner">
                      <div v-if="filter.isShowSearch"
                           class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
                        <input :placeholder="'Search for ' + filter.selectedRule.type"
                               @input="searchInFilter($event,filter)"
                               type="text" class="search_input_block_for_dropdown" v-model="filter.search_query"> <i
                        class="fal fa-search"></i>
                        <hr>
                      </div>
                      <li v-if="filter.selectedRule.key !== 'day_of_week'" class="list_item_li w-100 top-li-border">
                        <p class="checkbox_input_image ml-auto">
                          <input :id="'select_all_filters'+ruleIndex+blockIndex+filterIndex"
                                 v-model="filter.select_all_filters"
                                 @change="filterSelectAll(filter)" type="checkbox">
                          <label style="color: #1f216e;" :for="'select_all_filters'+ruleIndex+blockIndex+filterIndex"
                                 class="checkbox_left w-100">All
                            {{ filter.selectedRule.type }}</label>
                        </p>
                      </li>
                      <li ref="traffic_routing_filters_dropdown" :key="index" v-for="(item, index) in filter.list"
                          class="list_item_li">
                        <div class="checkbox_input_image w-100">
                          <input type="checkbox" :id="item.key+ruleIndex+blockIndex+filterIndex"
                                 :value="item.isSelected"
                                 @change="filterSelectSingle($event,item,index,filter)"
                                 v-model="item.isSelected">
                          <label :for="item.key+ruleIndex+blockIndex+filterIndex" class="checkbox_left w-100">
                            <div class="d-flex align-items-center profile__box">
                              <div class="content">
                                <div class="d-flex align-items-center name">
                                  <img style="width: 18px" :src="item.icon" class="mr-2 img-fluid"/>
                                  <span>{{ item.name }}</span>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </b-dropdown>
                  <!---->

                  <!--List of traffic routing filters-->
                  <b-dropdown boundary="boundariesElement"
                              :ref="'traffic_routing_filters_dropdown'+(ruleIndex+blockIndex)"
                              class="dropdown-menu-left default_style_dropdown">
                    <div class="d-flex align-items-center" slot="button-content"><span class="text"><a
                    ><i class="fas fa-plus-circle"></i> Add a filter</a></span>
                    </div>
                    <ul class="clear inner">
                      <li :key="index" v-for="(item, index) in trafficRoutingRulesFilterList"
                          @click.prevent="selectTrafficRoutingFilter(item,block,ruleIndex,blockIndex)"
                          class="list_item_li" data-cy="camps">
                        <div class="d-flex align-items-center profile__box">
                          <div class="content">
                            <div class="name">
                              <i class="mr-1 text-primary" :class="item.icon"></i> {{ item.type }}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </b-dropdown>
                </div>
                <p v-if="isShowOR(rule,blockIndex)" class="ml-1 mt-1">or</p>
              </div>

              <!-- -->

              <a @click="addAnotherBlock(rule)"><i class="fas mt-2 ml-1 fa-plus-circle"></i> Add another block</a>
              <div class="d-flex mt-3">
                <p class="ml-1">Then</p>
                <i class="fas fa-link"></i>
                <div class="w-100 mr-2">
                  <input v-model="rule.then" @input="thenUrlConcatenation(rule, true)"
                         @blur="() => rule.then = focusoutConcatenation(rule.then)" class="then_field ml-1"
                         placeholder="URL" type="text"/>
                  <span class="input-error" v-if="rule.validations.url">{{ trafficRoutingMessages.url }}</span>
                  <span class="input-error" v-else-if="rule.validations.urlLength">{{
                      trafficRoutingMessages.urlLength
                    }}</span>
                  <span class="input-error" v-else-if="rule.validations.urlValid">{{
                      trafficRoutingMessages.urlValid
                    }}</span>
                  <span class="input-error" v-else-if="rule.validations.iframe">{{
                      trafficRoutingMessages.iframe
                    }}</span>
                </div>
                <clip-loader class="d-inline-block text-justify" v-if="rule.loader" :size="'12px'"
                             :color="'#3988fe'"></clip-loader>
              </div>
            </div>


            <div class="d-flex mt-1">
              <p v-if="isShowOtherwise(ruleIndex)" class="ml-1">Otherwise</p>
            </div>
          </div>
          <!---->
          <p class="ml-1"><strong>Otherwise point to default destination URL.</strong></p>
          <a @click="addAnotherRule()"><i class="fas mt-2 ml-1 fa-plus-circle"></i> Add another rule</a>
        </div>
        <div class=" btn_block text-left">
          <button :disabled="filtersLoader" @click="saveTrafficRouting()"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Save</span>
          </button>
          <button @click="closeTrafficRoutingModal()" type="button"
                  class="ml-2 btn---cta light-blue btn-round  btn-bold"><span>Close</span></button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {dayOfWeekList, trafficRoutingRulesFilterList, dayOfWeekObject} from '@/common/constants'
import {mapActions} from 'vuex'
import http from '@/mixins/http-lib'
import {fetchSelectedTrafficFilterURL} from '@/config/api'
import {trafficRoutingMessages, trafficRoutingValidations, validLength} from '@/common/attributes'

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export default {
  name: 'AddTrafficRoutingRulesModalComponent',
  data () {
    return {
      trafficRoutingRulesFilterList: trafficRoutingRulesFilterList,
      dayOfWeekList: dayOfWeekList,
      dayOfWeekObject: dayOfWeekObject,
      selectFiltersObj: {
        key: '',
        type: '',
        newEntryValue: '',
        ruleIndex: 0,
        blockIndex: 0,
        filterIndex: 0
      },
      min: new Date(),
      temp_routing_rule: [],
      compare_temp_routing_rule: [],
      trafficRoutingRulesObject: {},
      exceptionalFieldsArray: ['country', 'device', 'browser', 'operating_system'],
      filtersLoader: true,
      trafficRoutingMessages: trafficRoutingMessages,
      trafficRoutingValidations: trafficRoutingValidations,
      debounce: null,
      iframeURLSObj: {},
      isDifferent: false
    }
  },
  props: [
    'routing_rules',
    'campaign_id',
    'self'
  ],
  async created () {
    this.temp_routing_rule = JSON.parse(JSON.stringify(this.routing_rules))
    await this.setTrafficRoutingFilterObject()
    await this.resetTrafficRoutingFilter()
    this.init()
  },
  computed: {},
  methods: {
    ...mapActions['fetchSelectedTrafficFilter'],
    init () {
      if (!this.temp_routing_rule.length) {
        this.addAnotherRule()
      }
      this.compare_temp_routing_rule = JSON.parse(JSON.stringify(this.temp_routing_rule))
      // this.$forceUpdate()
    },
    async setTrafficRoutingFilterObject () {
      this.filtersLoader = true
      const promises = this.trafficRoutingRulesFilterList.map((currentVal) => {
        if (!currentVal.fetch) return []
        return this.fetchSelectedTrafficFilter({type: currentVal.key.toLowerCase()})
      })
      const apiData = await Promise.all(promises)

      this.trafficRoutingRulesObject = this.trafficRoutingRulesFilterList.reduce((initialValue, currentValue, i) => {
        initialValue[currentValue.key] = currentValue.key === 'day_of_week' ? dayOfWeekList : apiData[i]
        return initialValue
      }, {})
      this.filtersLoader = false
    },

    addAnotherRule () {
      if (this.temp_routing_rule.length >= 10) {
        this.self.$store.dispatch('toastNotification', {
          message: 'Maximum 10 rules are allowed.',
          type: 'error'
        })
        return
      }
      this.temp_routing_rule.push({
        blocks: [
          []
        ],
        then: '',
        validations: {...trafficRoutingValidations},
      })
    },

    addAnotherBlock (rule, index) {
      rule.blocks.push([])
    },

    removeBlock (rule, index) {
      if (rule.blocks.length <= 1) return
      rule.blocks.splice(index, 1)
    },

    removeRule (index) {
      if (this.temp_routing_rule.length <= 1) return
      this.temp_routing_rule.splice(index, 1)
    },

    isShowOtherwise (index) {
      return this.temp_routing_rule && (this.temp_routing_rule.length > 1 && index + 1 < this.temp_routing_rule.length)
    },

    isShowOR (rule, index) {
      return rule.blocks && (rule.blocks.length > 1 && index + 1 < rule.blocks.length)
    },

    /**
     * Fetching the list against the selected filter
     * @param selectedFilter
     * @param blockObj
     * @param ruleIndex
     * @param blockIndex
     */
    selectTrafficRoutingFilter (selectedFilter, blockObj, ruleIndex, blockIndex) {
      this.$refs['traffic_routing_filters_dropdown' + (ruleIndex + blockIndex)][0].visible = false
      if (this.isRuleAlreadySelected(selectedFilter, blockObj)) {
        this.self.$store.dispatch('toastNotification', {
          message: selectedFilter.type + ' filter already selected.',
          type: 'error'
        })
        return
      }

      this.setFilterObject(blockObj, selectedFilter, this.trafficRoutingRulesObject[selectedFilter.key.toLowerCase()])
    },

    filterSelectAll (filters) {
      let freshList = this.exceptionalFieldsArray.indexOf(filters.key) === -1 ? filters.list : this.trafficRoutingRulesObject[filters.key]

      filters.list = JSON.parse(JSON.stringify(freshList)).map(item => {
        item.isSelected = filters.select_all_filters
        return item
      })

      if (!filters.select_all_filters) {
        filters.value = []
        return
      }

      filters.value = JSON.parse(JSON.stringify(freshList))
    },

    filterSelectSingle (event, item, index, filters) {
      if (item.isSelected) {
        filters.value = filters.value || []
        filters.value.push(item)
      } else {
        filters.value = filters.value.filter(elem => elem.key !== item.key)
      }
      filters.select_all_filters = false
    },

    isRuleAlreadySelected (selectedFilter, filterList) {
      return filterList && filterList.find((item) => item.key === selectedFilter.key)
    },

    setFilterObject (blockObj, selectedFilter, list) {
      let modifiedList = list.map((el) => {
        let o = Object.assign({}, el)
        o.isSelected = false
        return o
      })
      blockObj.push({
        selectedRule: selectedFilter,
        key: selectedFilter.key,
        value: [],
        select_all_filters: false,
        isShowSearch: this.exceptionalFieldsArray.includes(selectedFilter.key),
        search_query: '',
        newEntryValue: '',
        list: modifiedList && modifiedList.length ? modifiedList : []
      })
    },

    removeFilter (filters, filterIndex) {
      filters.splice(filterIndex, 1)
    },

    searchInFilter (event, filters) {
      let freshList = JSON.parse(JSON.stringify(this.trafficRoutingRulesObject[filters.key]))
      let value = event.target.value
      if (value) {
        value = value.trim().toLowerCase()
      }
      if (value && value.length > 0) {
        freshList = freshList.filter(item => item.name.toLowerCase().includes(value))
      }

      freshList = freshList.map(item => {
        const spottedValue = filters.value.find(elem => elem.key === item.key)
        if (spottedValue) {
          spottedValue.isSelected = true
          return spottedValue
        }
        return item
      })
      filters.list = freshList
      this.sortSelectedListInFilter(filters, true)
    },

    addEntry (filters, ruleIndex, blockIndex, filterIndex) {
      this.$refs['selected_filter_list' + ruleIndex + blockIndex + filterIndex][0].visible = true
      this.selectFiltersObj = {
        key: filters.key,
        type: filters.selectedRule.type,
        newEntryValue: '',
        ruleIndex: ruleIndex,
        blockIndex: blockIndex,
        filterIndex: filterIndex
      }
      this.$bvModal.show('addEntry')
    },

    addNewEntryInFilter () {

      if (!this.selectFiltersObj.newEntryValue) {
        this.self.$store.dispatch('toastNotification', {
          message: this.selectFiltersObj.type + ' can not be empty.',
          type: 'error'
        })
        return
      }

      if (this.selectFiltersObj.key === 'ip' && !this.validateIpAddress(this.selectFiltersObj.newEntryValue)) {
        this.self.$store.dispatch('toastNotification', {
          message: this.selectFiltersObj.type + ' is not valid Ip.',
          type: 'error'
        })
        return
      }

      let filter = this.temp_routing_rule[this.selectFiltersObj.ruleIndex].blocks[this.selectFiltersObj.blockIndex][this.selectFiltersObj.filterIndex]
      let isFilterAlreadyExist = filter.list && filter.list.length && filter.list.find((item) => item.key == this.selectFiltersObj.newEntryValue.toLowerCase())
      if (isFilterAlreadyExist) {
        this.self.$store.dispatch('toastNotification', {
          message: this.selectFiltersObj.newEntryValue + ' already exists in ' + this.selectFiltersObj.type,
          type: 'error'
        })
        return
      }

      this.$bvModal.hide('addEntry')

      let length = filter.list && filter.list.length ? filter.list.length : 0
      filter.list[length] = this.getNewEntryObject(this.selectFiltersObj, 'list')
      filter.value = filter.list.filter(item => item.isSelected)
      filter.select_all_filters = filter.value.length == filter.list.length
      this.$forceUpdate()
    },

    getNewEntryObject (selectFiltersObj, type = '') {
      return {
        icon: '',
        key: selectFiltersObj.newEntryValue,
        type: selectFiltersObj.key,
        name: selectFiltersObj.newEntryValue,
        isSelected: type === 'list'
      }
    },

    selectedValueListDisplay (filters) {
      if (filters.value && filters.value.length > 1) {
        return filters.value[0].name + ' ...and ' + (filters.value.length - 1).toString() + ' more'
      } else if (filters.value && filters.value.length) {
        return filters.value[0].name
      } else {
        return 'Select ' + filters.selectedRule.type
      }
    },

    // Saving the traffic routing rules
    async saveTrafficRouting () {
      let error = false
      let errorMsg = ''

      if (this.temp_routing_rule.filter(item => item.loader).length) {
        error = true
        errorMsg = 'Traffic Routing in processing.'
      }

      this.temp_routing_rule.filter(item => item.validations.url = this.requiredCheck(item.then))

      this.temp_routing_rule.forEach(item => {
        if (!item.blocks.length) {
          error = true
          errorMsg = 'At least one block required'
          return
        }

        item.blocks.forEach(block => {
          if (!block.length || block.filter(filter => !filter.value.length).length) {
            // if filter is selected but no value is added in it
            error = true
            errorMsg = 'Filter is required'
            return
          }
        })

        // check for iframe on all then URLs in case CTA campaign is selected
        // error = await this.checkTrafficRoutingIframe()

        if (!Object.keys(item.validations).every(k => item.validations[k] === false)) {
          errorMsg = 'Routing Rules validation failed.'
          error = true
          return
        }

      })

      if (error) {
        if (!!errorMsg) {
          this.self.$store.dispatch('toastNotification', {
            message: errorMsg,
            type: 'error'
          })
        }
        this.$forceUpdate()
        return
      }

      this.$forceUpdate()
      if (error) {
        return
      }

      this.self.$store.dispatch('setTrafficRouting', {routing_rules: this.temp_routing_rule, isEdit: true})
      this.$bvModal.hide('addTrafficRoutingRulesModalComponent')
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////// Resetting the traffic routing rules ///////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Resetting the traffic routing filters on editing the links
     * @returns {Promise<boolean>}
     */
    async resetTrafficRoutingFilter () {
      this.temp_routing_rule = JSON.parse(JSON.stringify(this.routing_rules))
      // fetch all unique keys within the blocks
      if (!this.routing_rules && !this.routing_rules.length) return

      // iterate over the rules
      const populateRoutingRules = this.temp_routing_rule.map(rule => {
        const blocks = rule.blocks.map(block => {
          return Object.keys(block).map(filterKey => {
            return this.fetchFilter(filterKey, block)
          })

        })
        rule.validations = rule.validations || {}
        const validations = {...rule.validations, ...trafficRoutingValidations}
        return {
          blocks,
          then: rule.then,
          validations,
        }
      })

      this.temp_routing_rule = populateRoutingRules
      this.$forceUpdate()
      return true
    },

    fetchFilter (filterKey, block) {
      const fetchedSelectedFilterObj = this.fetchSelectedFilter(filterKey)
      let filterList = this.trafficRoutingRulesObject[filterKey.toLowerCase()]

      let modifiedList = []
      if (filterList && filterList.length) {
        modifiedList = filterList.map((el) => {
          let o = Object.assign({}, el)
          o.isSelected = false
          return o
        })
      }

      let value = this.fetchFilterValues(filterKey, block)

      if (filterKey === 'ip' || filterKey === 'date') {
        modifiedList = value.map((el) => {
          let o = Object.assign({}, el)
          o.name = el
          o.key = el
          o.icon = ''
          o.type = filterKey
          o.name = el
          o.isSelected = false
          return o
        })
      }

      let list = JSON.parse(JSON.stringify(modifiedList))
      value.forEach(val => {
        list.forEach(item => {
          if (item.key.toLowerCase() === val.toLowerCase()) {
            item.isSelected = true
          }
        })
      })

      return {
        selectedRule: fetchedSelectedFilterObj,
        key: fetchedSelectedFilterObj.key,
        value: list.filter(item => item.isSelected),
        select_all_filters: list.filter(item => item.isSelected).length == modifiedList.length,
        isShowSearch: this.exceptionalFieldsArray.includes(fetchedSelectedFilterObj.key),
        search_query: '',
        newEntryValue: '',
        list: list && list.length ? list : []
      }
    },

    fetchSelectedFilter (filterKey) {
      let obj = this.trafficRoutingRulesFilterList.find(item => item.key === filterKey)
      return obj
    },

    fetchFilterValues (filterKey, block) {
      let value = block[filterKey]
      return value || []
    },

    /**
     * Fetching the filter list against selected traffic routing filter
     */
    async fetchSelectedTrafficFilter (payload = null) {
      const resp = await http.get(fetchSelectedTrafficFilterURL + '?type=' + payload.type).then(res => {
        return res.data.data
      }).catch((err) => {
        console.warn(err)
        return null
      })
      return resp
    },
    closeTrafficRoutingModal () {
      const rules = JSON.parse(JSON.stringify(this.routing_rules))
      const current_rules = JSON.parse(JSON.stringify(this.temp_routing_rule))

      // if we are creating new rules and rules are empty - go back without prompt.
      const totalFilters = current_rules.reduce((total, rule) => {
        total += rule.blocks.reduce((sum, filters) => sum += filters.length, 0)
        return total
      }, 0)

      if (!totalFilters && !rules.length) {
        // previously, there were no rules and still no rules have been added
        this.temp_routing_rule = []
        this.resetTrafficRoutingFilter()
        this.$bvModal.hide('addTrafficRoutingRulesModalComponent')
        return
      }

      if(!this.isDifferent) {
        this.$bvModal.hide('addTrafficRoutingRulesModalComponent')
        return;
      }

      swal({
          title: 'Are you sure, you want to exit?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false,
          allowOutsideClick: true,
          text: 'Save changes before closing the modal. All unsaved changes will be discarded.',
        },
        (isConfirm) => {
          if (isConfirm) {
            // this.self.$store.dispatch('setTrafficRouting', { routing_rules: [], isEdit: false })
            this.temp_routing_rule = []
            this.resetTrafficRoutingFilter()
            this.$bvModal.hide('addTrafficRoutingRulesModalComponent')
          }
        })
    },

    thenUrlConcatenation (rule, removeIframeCheck = false) {
      if (rule.then && rule.then.length > 8) {
        rule.then = rule.then.trim()
        rule.then = this.urlConcatenation(rule.then)
      }

      rule.validations.url = this.requiredCheck(rule.then)
      if (rule.validations.url) {
        return false
      }

      rule.validations.urlLength = !this.maxLength(rule.then, validLength.url)
      if (rule.validations.urlLength) {
        return false
      }

      rule.validations.urlValid = !this.isValidURL(rule.then)
      if (rule.validations.urlValid) {
        return false
      }

      if (removeIframeCheck && this.isTypeCTA(this.campaign_id, this.self)) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          await this.checkingIframe(rule, this.campaign_id)
          this.$forceUpdate()
        }, 600)
      }
    },

    async recheckingIframe (self) {
      const isCta = await this.isTypeCTA(this.campaign_id, self)
      this.temp_routing_rule.forEach((item) => {
        if (isCta) {
          this.checkingIframe(item, this.campaign_id)
        } else {
          item.validations.iframe = false
        }
      })
      this.isDifferent = false
      this.$forceUpdate()
    },

    async checkingIframe (item, campaignId) {
      this.isDifferent = false
      if (this.requiredCheck(item.then)) return

      if (Object.keys(this.iframeURLSObj).indexOf(item.then) !== -1) {
        item.validations.iframe = this.iframeURLSObj[item.then]
        this.isDifferent = false
        return
      }
      item.loader = true
      this.$forceUpdate()
      const response = await this.checkLinkPreview(item.then, campaignId)
      item.validations.iframe = !response.data.status
      this.iframeURLSObj[item.then] = item.validations.iframe
      item.loader = false
      this.isDifferent = false
      this.$forceUpdate()
    },

    sortSelectedListInFilter (filter, isCalledFromSearch = false) {
      this.isDifferent = false
      //resetting the filter search field
      if (!isCalledFromSearch && !['ip', 'date', 'day_of_week'].includes(filter.key)) {
        filter.search_query = ''
        let freshList = JSON.parse(JSON.stringify(this.trafficRoutingRulesObject[filter.key]))
        freshList = freshList.map(item => {
          const spottedValue = filter.value.find(elem => elem.key === item.key)
          if (spottedValue) {
            spottedValue.isSelected = true
            return spottedValue
          }
          return item
        })
        filter.list = freshList
      }

      // sorting & moving the selected countries to the top
      if (filter.key === 'country') {
        filter.list.sort(function (a, b) {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })
        filter.list.sort(function (x, y) {return (x.isSelected === y.isSelected) ? 0 : x.isSelected ? -1 : 1})
      }
    }
  },
  watch: {
    temp_routing_rule: {
      handler () {
        this.isDifferent = !isEqual(this.temp_routing_rule, this.compare_temp_routing_rule);
      },
      deep: true,
    }
  }
}
</script>

<style lang="less">
#addTrafficRoutingRulesModalComponent {
  .dropdown-menu {
    width: 37% !important;
  }

  .modal-dialog {
    .modal-content {
      .modal-body {
        .basic_form {
          .block-list {
            .block-list-body {
              padding: 20px 10px;
              border-radius: 10px;
              border: 1px solid #c4d0e2;
              background: #F7FBFC;

              .remove-rule {
                color: #1f216e;
                position: absolute;
                margin-left: 86.7%;
                margin-top: -22px;
              }

              &:hover {
                border-color: #3988FE;
              }
            }

            .add-block-container {
              .block-body {
                padding: 20px 10px;
                border-radius: 10px;
                border: 1px solid #c4d0e2;

                .dropdown-menu-left {
                  .d-flex {
                    font-size: 14px;

                    .select-list {
                      color: #333;

                      span {
                        color: #3988FE;
                        cursor: pointer;
                      }
                    }
                  }

                  .remove_filter {
                    color: #1f216e;
                  }
                }
              }
            }

            .remove-block {
              color: #1f216e;
              position: absolute;
              margin-left: 85.2%;
              margin-top: -1px;
            }

            .d-flex {
              .fa-link {
                margin-left: 20px;
                margin-top: 2px;
                color: #3988FE;
              }

              .then_field {
                padding: 0px;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0px;
                background: #F7FBFC;
              }
            }
          }

          a {
            color: #3988FE;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
