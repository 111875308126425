import { pixelTypes } from '@/state/modules/mutation-types'
import http from '@/mixins/http-lib'
import { deletePixelURL, showPixelsAllURL, showPixelsURL, storePixelURL } from '../../../config/api'
import { googlePixelsTypeKey } from '@/common/attributes.js'

const defaultAddPixelState = () => {
  return {
    _id: null,
    name: null,
    pixel: null,
    type: null,
    key: 'google_adwords'
  }
}

export default {
  state: {
    pixel: {
      add: defaultAddPixelState(),
      list: [],
      fetchStatus: false,
      filteredList: [],
      loaders: {
        add: false,
        get: true
      }
    }
  },
  actions: {

    async storePixel ({ commit, getters, dispatch, state }) {
      getters.getPixelLoaders.add = true
      const payload = getters.getAddPixel
      const res = await http.post(storePixelURL, {
        _id: payload._id,
        type: payload.type,
        pixel: payload.pixel,
        name: payload.name.trim(),
        key: payload.key
      }).then(res => {
        if (res.data.status) {
          if (payload.hasOwnProperty('_id') && payload._id) {
            const index = getters.getFilteredPixelList.findIndex(item => {
              return item._id === payload._id
            })
            getters.getFilteredPixelList.splice(index, 1, res.data.data)
          } else {
            commit(pixelTypes.ADD_PIXEL_TO_LIST, res.data.data)
            dispatch('fetchPixels')
          }
          // $('#addPixelModal').modal('hide')
          commit(pixelTypes.SET_PIXEL)
          return res.data.data
        } else {
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
        }
        getters.getPixelLoaders.add = false
        return res
      }).catch(err => {
        return null
      })
      state.pixel.loaders.add = false

      return res
    },
    async fetchPixels ({ commit, getters, dispatch }, payload = null) {
      commit(pixelTypes.SET_PIXEL_FETCH_STATUS, false)
      return http.post(showPixelsAllURL, payload).then(res => {
        if (res.data.status) {
          commit(pixelTypes.SET_PIXEL_LIST_ALL, res.data.data)
          commit(pixelTypes.SET_PIXEL_FETCH_STATUS, true)
        }
        return res.data.data
      }).catch(err => {
        dispatch('toastNotification', { message: 'Unable to fetch retargeting pixels', type: 'error' })
      })
    },
    async fetchFilteredPixels ({ commit, getters, dispatch }, payload = null) {
      commit(pixelTypes.SET_PIXEL_LOADER_GET, true)
      return http.post(showPixelsURL, payload).then(res => {
        if (res.data.status) {
          commit(pixelTypes.SET_PIXELS_LIST, res.data.data.data)
        }
        commit(pixelTypes.SET_PIXEL_LOADER_GET, false)
        return res.data.data
      }).catch(err => {
        commit(pixelTypes.SET_PIXEL_LOADER_GET, false)
        dispatch('toastNotification', { message: 'Unable to fetch retargeting pixels', type: 'error' })
      })
    },
    resetPixels ({ commit }) {
      commit(pixelTypes.SET_PIXEL)
    },
    deletePixel ({ commit, getters, dispatch }, { pixelId, indexId }) {
      swal({
        title: 'Are you sure ?',
        type: 'warning',
        text: '',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel!',
        closeOnConfirm: true,
        customClass: 'sweetAlert_box',
        closeOnCancel: true,
        animation: false,
        allowOutsideClick: true
      },
      function (isConfirm) {
        if (isConfirm) {
          http.post(deletePixelURL, { _id: pixelId }).then(resp => {
            if (resp.data.status) {
              getters.getFilteredPixelList.splice(indexId, 1)
            }
          }).catch(err => {

          })
        } else {
          swal()
        }
      })
    }
  },
  getters: {
    getAddPixel: state => state.pixel.add,
    getPixelList: state => state.pixel.list,
    getPixelLoaders: state => state.pixel.loaders,
    getFilteredPixelList: state => state.pixel.filteredList,
    getPixel: state => state.pixel
  },
  mutations: {
    [pixelTypes.SET_PIXELS_LIST] (state, value) {
      state.pixel.filteredList = value
    },
    [pixelTypes.ADD_PIXEL_TO_LIST] (state, value) {
      state.pixel.filteredList.unshift(value)
    },
    [pixelTypes.SET_PIXEL] (state, value = null) {
      if (!value) {
        state.pixel.add = defaultAddPixelState()
        return
      }

      const pixelKey = (value.type === 'google' && value.key) ? Object.keys(googlePixelsTypeKey).find(key => googlePixelsTypeKey[key] === value.key) : 'google_adwords'
      value.key = pixelKey
      state.pixel.add = value
    },
    [pixelTypes.SET_PIXEL_LOADER_ADD] (state) {
      state.pixel.loaders.add = state
    },
    [pixelTypes.SET_PIXEL_LOADER_GET] (state, value) {
      state.pixel.loaders.get = value
    },
    [pixelTypes.SET_PIXEL_LIST_ALL] (state, value) {
      state.pixel.list = value
    },
    [pixelTypes.SET_PIXEL_FETCH_STATUS] (state, value) {
      state.pixel.fetchStatus = value
    }
  }
}
