import http from '@/mixins/http-lib'
import {
  checkDNSRecordsURL,
  checkDomainConnectivityURL,
  deleteDomainURL, fetchDefaultBioDomain, fetchDNSConfig, fetchDomainLinked,
  setDomainDefaultURL,
  setDomainUseHttpsURL,
  showDomainURL,
  storeDomainURL
} from '../../config/api'
import { campaignTypes, domainTypes } from './mutation-types'

const defaultDomainsState = () => {
  return {
    items: [],
    ids: [],
    fetchStatus: false,
    addButtonStatus: true
  }
}
const defaultAddDomainState = () => {
  return {
    id: null,
    url: null,
    redirect_url: null,
    root_redirect: null
  }
}
export default {
  state: {
    domain: {
      add: defaultAddDomainState(),
      defaultDomain: {},
      items: [],
      ids: [],
      fetchStatus: false,
      addButtonStatus: true,
      loaders: {
        add: false,
        get_records: true,
        custom_domain_loader: true
      },
      dns_records: []
    }
  },
  getters: {
    getDomains: state => state.domain,
    getAddDomain: state => state.domain.add,
    getDNSRecords: state => state.domain.dns_records,
    getDefaultDomainEnv: state => state.domain.defaultDomain,
    getCustomDomainsLoader: state => state.domain.loaders
  },
  actions: {
    storeDomain ({ actions, getters, commit, dispatch }) {
      getters.getCustomDomainsLoader.add = true
      const payload = getters.getAddDomain
      http.post(storeDomainURL, payload).then(res => {
        if (res.data.status) {
          // add new domain
          const domain = res.data.data
          domain.showLoader = false
          if (payload._id && payload._id.length > 3) {
            commit(domainTypes.UPDATE_DOMAIN_TO_LIST, domain)
          } else {
            commit(domainTypes.ADD_DOMAIN_TO_LIST, domain)
            commit(domainTypes.ADD_DOMAIN_ID_TO_LIST, domain._id)
          }

          dispatch('toastNotification', { message: res.data.message })
          commit(domainTypes.RESET_ADD_DOMAIN)
          // $('#customDomainModal').modal('hide')
        } else {
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
        }
        getters.getCustomDomainsLoader.add = false
      }).catch(() => {
        dispatch('toastNotification', { message: 'Unexpected error occurred. Kindly contact support.', type: 'error' })
      })
    },
    /**
     * retrieve a list for the custom domains
     * @param commit
     * @param getters
     * @param dispatch
     */
    fetchDomains ({ commit, getters, dispatch }) {
      commit(domainTypes.CUSTOM_DOMAIN_LOADER, true)
      commit(domainTypes.SET_DOMAIN_FETCH_STATUS, false)
      http.post(showDomainURL).then(res => {
        if (res.data.status) {
          let domains = res.data.data.domains
          domains = domains.map(d => ({ ...d, showLoader: false }))
          commit(domainTypes.SET_DOMAINS, domains)
          commit(domainTypes.SET_DOMAINS_IDS, res.data.data.domains_ids)
          dispatch('setDefaultDomain')
          commit(domainTypes.CUSTOM_DOMAIN_LOADER, false)
          commit(domainTypes.SET_DOMAIN_FETCH_STATUS, true)
          // this.setCtaDefaultDomain()
        } else {
          // need to convert to the alert/toast notification mixin.
          // this.alertMessage(response.data.message, 'error')
        }
      })
    },

    /**
     * Set the default domain for the call-to-actions
     * @param commit
     * @param getters
     * @param dispatch
     */
    setDefaultDomain ({ commit, getters, dispatch }) {
      // if the custom domain id has not set or saving saving details is not about editing the custom domain.

      if (!getters.getCampaignAdd.id && !getters.getCampaignAdd.domainId) {
        let domainId = ''

        // find the domain id for the custom domain.
        getters.getDomains.items.forEach(element => {
          if (element.default === true && element.connectivity === true) {
            domainId = element._id
          }
        })
        commit(campaignTypes.SET_CAMPAIGN_ADD_CUSTOM_DOMAIN, domainId)
      }
    },

    async checkDomainConnectivity ({ commit, getters, dispatch }, domain) {
      return await http.post(checkDomainConnectivityURL, { id: domain._id }).then(
        resp => {
          if (resp.data.status) {
            dispatch('toastNotification', { message: resp.data.message })
            domain.connectivity = true
          } else {
            domain.connectivity = false
            dispatch('toastNotification', { message: resp.data.message, type: 'error' })
          }
          return domain.connectivity
        },
        () => {
          dispatch('toastNotification', {
            message: 'Unexpected error occurred. Kindly contact support team',
            type: 'error'
          })
          return false
        })
    },

    deleteDomain ({ commit, getters, dispatch }, domain) {
      swal({
        title: 'Are you sure ?',
        type: 'warning',
        text: 'Your associated link campaigns for this account will be paused.',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel!',
        closeOnConfirm: true,
        customClass: 'sweetAlert_box',
        closeOnCancel: true,
        animation: false,
        allowOutsideClick: true
      },
      function (isConfirm) {
        if (isConfirm) {
          http.post(deleteDomainURL, { id: domain._id }).then(resp => {
            if (resp.data.status) {
              getters.getDomains.items.splice(getters.getDomains.items.indexOf(domain), 1)
            } else {
              dispatch('toastNotification', {
                message: 'Custom domain is already being used in whitelabel.',
                type: 'error'
              })
            }
          }).catch(err => {

          })
        } else {
          swal()
        }
      })
    },

    async checkDNSRecordsForDomain ({ commit, getters, dispatch }, url) {
      commit(domainTypes.SET_DNS_RECORDS, [])
      commit(domainTypes.GET_DNS_RECORDS_LOADER, true)
      const res = await http.post(checkDNSRecordsURL, { url: url }).then(resp => {
        if (resp.data.status) {
          commit(domainTypes.SET_DNS_RECORDS, resp.data.records)
        }
        commit(domainTypes.GET_DNS_RECORDS_LOADER, false)
        return resp
      }).catch(err => {
        return null
      })
      return res
    },

    /**
     * Fetch DNS configuration from backend based on environment
     * @returns {Promise<*>}
     */
    async fetchDNSConfig () {
      return http.post(fetchDNSConfig).then(resp => {
        return resp.data
      }).catch(() => {
        return null
      })
    },
    async fetchCustomDomainLink ({ commit, getters, dispatch }, domain) {
      const res = await http.post(fetchDomainLinked, { id: domain._id }).then(resp => {
        if (resp.data.status) {
          swal({
            title: 'Are you sure ?',
            type: 'warning',
            text: 'Your associated link campaigns for this account will be paused.',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, Cancel!',
            closeOnConfirm: true,
            customClass: 'sweetAlert_box',
            closeOnCancel: true,
            animation: false,
            allowOutsideClick: true
          },
          function (isConfirm) {
            if (isConfirm) {
              http.post(deleteDomainURL, { id: domain._id }).then(resp => {
                if (resp.data.status) {
                  getters.getDomains.items.splice(getters.getDomains.items.indexOf(domain), 1)
                } else {
                  dispatch('toastNotification', {
                    message: 'Custom domain is already being used in whitelabel.',
                    type: 'error'
                  })
                }
              }).catch(err => {
                console.warn(err)
              })
            } else {
              swal()
            }
          })
        } else {
          swal({
            title: 'Are you sure ?',
            type: 'warning',
            text: 'Your links will be reverted back to the default short domain and \nsome' +
                ' of them may not work properly if the same slug \n (unique identifier) is already ' +
                'used by other users.\n So, we recommend using a custom domain in these campaigns.',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, Cancel!',
            closeOnConfirm: true,
            customClass: 'sweetAlert_box',
            closeOnCancel: true,
            animation: false,
            allowOutsideClick: true
          },
          function (isConfirm) {
            if (isConfirm) {
              http.post(deleteDomainURL, { id: domain._id, confirm: true }).then(resp => {
                if (resp.data.status) {
                  getters.getDomains.items.splice(getters.getDomains.items.indexOf(domain), 1)
                } else {
                  dispatch('toastNotification', {
                    message: 'Custom domain is already being used in whitelabel.',
                    type: 'error'
                  })
                }
              }).catch(err => {
                console.warn(err)
              })
            } else {
              swal()
            }
          })
        }
        return resp
      }).catch(err => {
        return null
      })
      return res
    },
    setDomainHTTPSMethod ({ commit, getters, dispatch }, domain) {
      if (!domain.connectivity) {
        dispatch('toastNotification', {
          message: 'Your domain is not verified and connected with Replug.io',
          type: 'error'
        })
        return
      } else if (!domain.ssl_connected) {
        dispatch('toastNotification', { message: 'Your domain is not ssl connected.', type: 'error' })
        return
      }

      let isHTTPS = domain.useHttps

      if (!isHTTPS) {
        isHTTPS = false
      }

      const payload = {
        id: domain._id,
        status: !isHTTPS
      }

      http.post(setDomainUseHttpsURL, payload).then(resp => {
        if (resp.data.status) {
          domain.useHttps = resp.data.value
          domain.url = resp.data.domain
        } else {
          dispatch('toastNotification', { message: resp.data.message, type: 'error' })
        }
      }).catch()
    },

    setDefaultDomainStatus ({ commit, getters, dispatch }, domain) {
      if (!domain.connectivity) {
        dispatch('toastNotification', {
          message: 'Your domain is not verified and connected with Replug.io',
          type: 'error'
        })
        return
      }

      http.post(setDomainDefaultURL, { id: domain._id }).then(resp => {
        if (resp.data.status) {
          getters.getDomains.items.forEach((element) => {
            element.default = false
          })
          domain.default = resp.data.value
        } else {
          dispatch('toastNotification', {
            message: resp.data.message,
            type: 'error'
          })
        }
      }).catch()
    },
    async setDefaultDomainEnv ({ commit }) {
      return http.post(fetchDefaultBioDomain).then((res) => {
        commit(domainTypes.SET_DEFAULT_DOMAIN, res.data.data)
      })
    }
  },
  mutations: {
    [domainTypes.SET_DOMAINS] (state, domains) {
      state.domain.items = domains
    },
    [domainTypes.SET_DOMAINS_IDS] (state, domainIds) {
      state.domain.ids = domainIds
    },
    [domainTypes.ADD_DOMAIN_TO_LIST] (state, domain) {
      state.domain.items.unshift(domain)
    },
    [domainTypes.UPDATE_DOMAIN_TO_LIST] (state, domain) {
      const index = state.domain.items.findIndex(item => {
        return item._id === domain._id
      })
      state.domain.items.splice(index, 1, domain)
    },
    [domainTypes.ADD_DOMAIN_ID_TO_LIST] (state, domainId) {
      state.domain.ids.push(domainId)
    },
    [domainTypes.SET_ADD_DOMAIN] (state, value) {
      state.domain.add = value
    },
    [domainTypes.RESET_ADD_DOMAIN] (state) {
      state.domain.add = defaultAddDomainState()
    },
    [domainTypes.SET_DNS_RECORDS] (state, value) {
      state.domain.dns_records = value
    },
    [domainTypes.GET_DNS_RECORDS_LOADER] (state, value) {
      state.domain.loaders.get_records = value
    },
    [domainTypes.CUSTOM_DOMAIN_LOADER] (state, value) {
      state.domain.loaders.custom_domain_loader = value
    },
    [domainTypes.SET_DEFAULT_DOMAIN] (state, value) {
      state.domain.defaultDomain = value
    },
    [domainTypes.SET_DOMAIN_FETCH_STATUS] (state, value) {
      state.domain.fetchStatus = value
    }
  }

}
