<template>
    <span class="input-error">
      <slot></slot>
    </span>
</template>
<style lang="less" scoped>
  .input-error{
    /*margin-bottom:20px;*/
  }
</style>
<script>
  export default({
    name: 'input-field-error'
  })
</script>
