import http from '@/mixins/http-lib.js'
import {removeUTMURL, showUTMURL, storeUTMURL, showAllUTMURL} from '@/config/api'
import {applyUTMParameters, getUTMParameters, removeExistingUtmParameters} from '@/mixins/url-parsing'
import {campaignTypes, linkTypes, utmTypes} from '@/state/modules/mutation-types'

export const defaultCreateUTM = () => {
  return {
    _id: null,
    name: null,
    source: null,
    medium: null,
    campaign: null,
    term: null,
    content: null,
    create: false
  }
}
export const defaultUTMState = () => {
  return {
    utm: {
      list: [],
      create: defaultCreateUTM(),
      selected: defaultCreateUTM(),
      previousSelectedUTMId: ''
    },
    retrieveLoader: false
  }
}

export default {
  state: defaultUTMState(),
  getters: {
    getUTMList: state => state.utm.list,
    getCreateUTM: state => state.utm.create,
    getSelectedUTM: state => state.utm.selected,
    getDefaultSelectedUTM: state => defaultCreateUTM(),
    getUTMRetrieveLoader: state => state.retrieveLoader,
    getPreviousSelectedUTMId: state => state.utm.previousSelectedUTMId
  },
  actions: {
    storeUTM({commit, getters, dispatch}, payload = null) {
      if (payload === null) payload = getters.getCreateUTM
      return http.post(storeUTMURL, payload).then(res => {
        if (res.data.data) {
          // if the mongodb id is present already
          const mongoId = getters.getCreateUTM._id
          if (mongoId && mongoId.length > 3) {
            commit(utmTypes.UPDATE_EXISTING_UTM, res.data.data)
          } else {
            // new utm created
            commit(utmTypes.ADD_TO_UTM_LIST, res.data.data)
          }
          return res.data.data
          // $('#addUtmModal').modal('hide')
        } else {
          dispatch('toastNotification', {message: res.data.message, type: 'error'})
        }
      }).catch(res => {

      })
    },
    fetchUTMList({commit, getters, dispatch}, payload) {
      commit(utmTypes.SET_UTM_RETRIEVE_LOADER, true)
      return http.post(showUTMURL, payload).then(res => {
        if (res.data) {
          commit(utmTypes.SET_UTM_LIST, res.data.data.data)
          commit(utmTypes.SET_UTM_RETRIEVE_LOADER, false)
          return res.data.data
        }
      }).catch(res => {
        dispatch('toastNotification', {message: 'Unable to fetch UTMs', type: 'error'})
      })
    },
    /**
     * Fetch all UTMs
     * @returns {Promise<*>}
     */
    async fetchAllUTMs({dispatch}) {
      return await http.post(showAllUTMURL)
        .then(res => {
          if (!res.data) {
            dispatch('toastNotification', {message: 'Unable to fetch UTMs', type: 'error'})
            return []
          }

          return res.data.data
        })
        .catch(() => {
          dispatch('toastNotification', {message: 'Unable to fetch UTMs', type: 'error'})
          return []
        })
    },
    removeUTM({commit, getters, dispatch}, {utmId, index}) {
      return http.post(removeUTMURL, {_id: utmId}).then(res => {
        if (res.data.data > 0) {
          commit(utmTypes.REMOVE_UTM_FROM_LIST, index)
        }
      }).catch(res => {

      })
    },
    validateUTMURL({commit, getters, dispatch}, type) {
      let url
      switch (type) {
        case 'link':
          url = getters.getSaveLink.url
          break
        case 'message':
          url = getters.getCampaignAdd.button_url
          break
        case 'dashboard':
          url = getters.getQuickLink.url
          break
      }
      // if the url length is lesser, toast the notification

      if (!url || url.length <= 3) {
        dispatch('toastNotification', {message: 'Please enter a URL', type: 'error'})
        return false
      }
      return url
    },
    async applyUTM({state, commit, getters, dispatch}, type) {

      let url = await dispatch('validateUTMURL', type)
      // proceed with replacement and applying utm tags.
      if (url) {
        url = applyUTMParameters(getters.getSelectedUTM, url, getUTMParameters(url), getters.getPreviousSelectedUTMId, getters.getSelectedUTM._id)
        switch (type) {
          case 'link':
            commit(linkTypes.SET_SAVE_LINK_URL, url)
            break
          case 'message':
            commit(campaignTypes.SET_CAMPAIGN_ADD_BUTTON_URL, url)
            break
          case 'dashboard':
            commit(linkTypes.SET_QUICK_LINK_URL, url)
            break
        }

        commit(utmTypes.RESET_SELECTED_UTM)
      }
    },
    setUTMPreviousId({commit}, value) {
      commit(utmTypes.SET_UTM_PREVIOUS_ID, value)
    }
  },
  mutations: {
    [utmTypes.SET_UTM_LIST](state, value) {
      state.utm.list = value
    },
    [utmTypes.ADD_TO_UTM_LIST](state, value) {
      state.utm.list.unshift(value)
    },
    [utmTypes.UPDATE_EXISTING_UTM](state, item) {
      const foundIndex = state.utm.list.findIndex(x => x._id === item._id)
      state.utm.list.splice(foundIndex, 1, item)
    },
    [utmTypes.REMOVE_UTM_FROM_LIST](state, index) {
      state.utm.list.splice(index, 1)
    },
    [utmTypes.RESET_CREATE_UTM](state) {
      state.utm.create = defaultCreateUTM()
    },
    [utmTypes.EDIT_CREATE_UTM](state, payload) {
      state.utm.create = JSON.parse(JSON.stringify(payload))
    },
    [utmTypes.CHANGE_SELECTED_UTM](state, payload) {
      state.utm.selected = payload
    },
    [utmTypes.RESET_SELECTED_UTM](state) {
      state.utm.selected = defaultCreateUTM()
    },
    [utmTypes.SET_UTM_RETRIEVE_LOADER](state, value) {
      state.retrieveLoader = value
    },
    [utmTypes.SET_UTM_PREVIOUS_ID](state, value) {
      state.utm.previousSelectedUTMId = value
    }
  }
}
