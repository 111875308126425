<template>

    <div class="section footer">

        <!--<div class="shapes_block">-->
        <!--<img class="shape1" src="img/login_shapes/footer/Path8.svg" alt="">-->

        <!--</div>-->

        <div class="container">
            <ul>

                <li><a href="https://replug.io/index.php">Home</a></li>
                <li><a href="https://replug.io/contact.php">Contact</a></li>
                <li><a href="https://replug.io/privacy.php">Privacy Policy</a></li>
                <li><a href="https://replug.io/terms.php">Terms and Conditions</a></li>
                <li>&#169; Copyright 2018 <a href="https://replug.io">Replug.io</a></li>
            </ul>
        </div>
    </div>

</template>

<script>
export default {
  components: {

  },
  methods: {

  }
}
</script>
