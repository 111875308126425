<template>

    <div class="upgrade_page">

        <!--v-if="getProfile.trial_finish"-->
        <div class="top_bar">
            <a href="/logout" class="btn_logout">
                <img src="/img/logout_btn.svg" alt="">
            </a>
        </div>
        <!--<div class="top_bar" v-else>-->
        <!--X-->
        <!--</div>-->
        <div class="container">
            <div class="heading_x">
                <!--v-if="getProfile.trial_finish"-->
                <h2> Your trial subscription has been ended. Please upgrade your subscription plan.</h2>
                <!--<h2>To use this feature, you need to upgrade your subscription plan to the higher package.</h2>-->
            </div>
            <upgrade-modal v-if="getSubscription.internal_id"></upgrade-modal>
        </div>

    </div>

</template>
<script>
import {
  mapGetters, mapActions
} from 'vuex'
import UpgradeModal from './billing/UpgradeModal.vue'

export default ({
  components: {
    UpgradeModal
  },
  methods: {

  },
  created () {
    if (!this.getProfile.email) {
      this.getUserProfile()
    }
  },
  computed: {
    ...mapGetters(['getSubscription', 'getProfile'])
  }
})
</script>
