import http from '@/mixins/http-lib'
import {

} from '../../config/api'

import { swalDefaultValues } from '@/mixins/attributes'

export default {
  state: {
  },
  getters: {
  },
  actions: {
  }
}
