export default {
  state: {

    callToActions: {

      items: [],
      fetchStatus: false,
      brand: [],
      search: '',
      date: {
        label: 'All Time',
        value: ''
      },
      sort: 'created_desc',
      page: 1,
      limit: 10,
      totalPages: 0,
      totalItems: 0,

      allItems: [],
      fetchAllStatus: false,
      selectAll: true,

      archiveStatus: false

    },

    callToActionSelection: {

      savingDetails: {

        id: null,
        name: '',
        button_url: '',
        button_text: 'Call to action',
        message: 'Your message goes here',
        message_headline: 'Your headline goes here',
        image: null,
        resized_image: null,
        type: 'no_cta',
        // color schema
        cta_type: 'social',
        integration: 'poptin',
        integration_script: null,
        email_service: null,
        email_list: {
          id: null,
          name: null
        },
        email_service_id: '',
        name_field: true,
        background_color: '#FFFFFF',
        text_color: '#333',

        trigger_type: 'time',
        trigger_seconds: 5,
        trigger_scroll_value: 0,
        trigger_scroll_type: 'px',
        button_background_color: '#168eea',
        button_text_color: '#FFFFFF',
        position: 'left',
        branding_logo: 'true',
        domainId: '',
        redirect_original_website: true,
        redirect_after_submit: false,
        circle: true,
        sound: true,
        brand_id: '',

        retargeting_ids: [],
        script_ids: [],

        // pixel_list:[],
        link_color: '#168eea',
        campaign_type: {
          cta: false,
          retargeting: false,
          third_party: false
        },
        email_service_tags: [],
        agreement: []

      },

      retargeting_script_name: '',
      pixel_type: 'facebook',
      pixel_id: '',
      pixel_edit_id: '',
      pixel_add_check: false,

      requiredReferral: 0,
      social_cta_stats: false,
      link_preview: false,
      link_preview_url: null,

      emailTags: [],
      suggestedTags: [],
      emailTagText: '',
      focus: false

    },

    retargeting: {
      items: [],
      fetchPixel: false
    }

  },
  getters: {

    getCallToActions: state => {
      return state.callToActions
    }

  },
  actions: {

    setCallToActionsFetchStatus ({ commit }, status) {
      commit('setCallToActionsFetchStatus', status)
    },

    setAllCallToActionsFetchStatus ({ commit }, status) {
      commit('setAllCallToActionsFetchStatus', status)
    },

    setCallToActionSavingDetails ({ commit }, details) {
      commit('setCallToActionSavingDetails', details)
    }

  },
  mutations: {

    setCallToActionsFetchStatus (state, status) {
      state.callToActions.fetchStatus = status
    },

    setAllCallToActionsFetchStatus (state, status) {
      state.callToActions.fetchAllStatus = status
    },

    setCallToActionSavingDetails (state, details) {
      if (details) {
        if (typeof details.name === 'undefined') {
          details.name = ''
        }
        state.callToActionSelection.savingDetails = details
      } else {
        state.callToActionSelection.savingDetails = {

          id: null,
          button_url: '',
          name: '',
          button_text: 'Call to action',
          message: 'Your message goes here',
          message_headline: 'Your headline goes here',
          image: null,
          resized_image: null,
          type: 'no_cta',
          // color schema
          cta_type: 'social',
          integration: 'poptin',
          integration_script: null,
          email_service: null,
          email_list: {
            id: null,
            name: null
          },
          email_service_id: '',
          name_field: true,
          background_color: '#FFFFFF',
          text_color: '#333',

          trigger_type: 'time',
          trigger_seconds: 5,
          trigger_scroll_value: 0,
          trigger_scroll_type: 'px',
          button_background_color: '#168eea',
          button_text_color: '#FFFFFF',
          position: 'left',
          branding_logo: 'true',
          domainId: '',
          redirect_original_website: true,
          circle: true,
          sound: true,
          brand_id: '',

          retargeting_ids: [],
          script_ids: [],

          link_color: '#168eea',
          campaign_type: {
            cta: false,
            retargeting: false,
            third_party: false
          },
          email_service_tags: [],
          agreement: []

        }
        state.callToActionSelection.retargeting_script_name = ''
        state.callToActionSelection.pixel_type = 'facebook'
        state.callToActionSelection.pixel_id = ''
        state.callToActionSelection.pixel_edit_id = ''
        state.callToActionSelection.pixel_add_check = false

        state.callToActionSelection.emailTags = []
        state.callToActionSelection.suggestedTags = []
        state.callToActionSelection.emailTagText = ''
        state.callToActionSelection.focus = false
      }
    }

  }
}
