import { mapActions, mapGetters, mapMutations } from 'vuex'
import { linkTypes } from '@/state/modules/mutation-types'

let tagMixin = {
  computed: {
    ...mapGetters([
      'getTagList',
      'getSaveLink',
      'getLinks',
      'getQuickLink'
    ])
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'storeTag',
      'addTagToLink',
      'addTagToQuickLink',
      'removeTagFromLink'

    ]),

    async addLinkTag (event, tags, add = false) {
      let tag = (tags) ? tags.trim() : tags


      // set the store focus to true
      this.$store.commit(linkTypes.SET_LINK_TAG_FOCUS, true)

      if (tag && tag.length > 0) {
        if (typeof tag === 'string' || tag instanceof String) tag = tag.trim()

        // if tag value is greater than 0 and user adds a new tag

        if (add === true || event.key === 'Enter') {

          // find the tag if it is already present or not

          let find = this.getTagList.find(item => {
            return item.tag.toLowerCase() === tag.toLowerCase()
          })

          // if not tag found, create a new one

          if (!find) {
              await this.storeTag({ id: null, name: tag, type: 'create' })
          } else {
            // if the tag is not presented already

            if (this.getSaveLink.tags.indexOf(find) < 0) {
              this.$store.commit(linkTypes.SET_LINK_TAGS, this.getSaveLink.tags.concat(find))
            }
          }
          this.$store.commit(linkTypes.SET_LINK_TAG_BOX, '')
          this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
        }
      }

      let finalList = this.getTagList.filter((item, index) => {
        return (item.tag.toLowerCase().includes(tag.toLowerCase()) && this.getSaveLink.tags.indexOf(item._id) < 0)
      })
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, finalList)
    },

    async addLinkQuickTag (event, linkId, value, add = false) {
      this.$store.commit(linkTypes.SET_QUICK_LINK_TAG_FOCUS, true)
      let tag = (value && value.length > 0) ? value.trim() : value
      if (tag.length > 0) {
        if (add === true || event.key === 'Enter') {
          if (value && value.length > 0) {
            this.$store.commit(linkTypes.SET_QUICK_LINK_TAG_LOADER, true)
            await this.addTagToQuickLink({
              tag: value,
              linkId: linkId
            })
          } else {
            this.alertMessage('Please enter tag name.', 'error')
          }
        }
      }
      let finalList = this.getTagList.filter(item => {
        return (item.tag.toLowerCase().includes(tag.toLowerCase()) && this.getQuickLink.tags.indexOf(item._id) < 0)
      })
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, finalList)
    },

    async addLinkListingTag (event, linkId, value, index, add = false) {
      let link = this.getLinks.items[index]
      link['focus'] = true
      let tag = (value && value.length > 0) ? value.trim() : value
      if (tag.length > 0) {
        if (add === true || event.key === 'Enter') {
          if (value && value.length > 0) {
            link['tagLoader'] = true
            await this.addTagToLink({
              tag: value,
              linkId: linkId,
              index: index
            })
          } else {
            this.alertMessage('Please enter tag name.', 'error')
          }
        }
      }

      let finalList = this.getTagList.filter((item, index) => {
        return (!link['tags'] || (item.tag.toLowerCase().includes(tag.toLowerCase()) && link['tags'].indexOf(item._id) < 0))
      })
      this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, finalList)
    },
    removeLinkTag (linkId, index, tag, quick = false, tag_index = 0) {
      if (quick === true) {
        let tagList = this.getQuickLink.tags
        tagList.splice(index, 1)
        this.$store.dispatch(linkTypes.SET_QUICK_LINK_TAGS, tagList)
      } else {
        let tagList = this.getLinks.items[index]['tags']
        tagList.splice(tag_index, 1)
        this.getLinks.items[index]['tags'] = tagList
      }
      this.removeTagFromLink({ tag: tag, linkId: linkId, index: index })
    },

    blurFocusQuickTag () {
      setTimeout(() => {
        this.$store.commit(linkTypes.SET_QUICK_LINK_TAG_FOCUS, false)
      }, 200)
    },
    getTagName (tag) {
      let tagName = this.getTagList.find(item => {
        return item._id === tag
      })
      return (tagName && tagName.tag) ? tagName.tag : tagName
    },

    checkTagNotExist (tagName, data) {
      let tag = this.getTagList.find(item => {
        return item.tag.toLowerCase() === tagName.toLowerCase()
      }
      )
      if (tag && tag.hasOwnProperty('_id')) {
        if (data.indexOf(tag._id) >= 0 && this.getLinks.suggestedTags.length === 0) {
          return false
        }
      }
      return true
    }

  }

}

export { tagMixin }
