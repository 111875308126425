<template>
  <div class="mb-3 customize-message-component">
    <div class="input_icon_text position-relative">
      <div class="setting_item text-justify">
        <div class="text_sec d-inline-block">
          <p>Customize Message</p>
        </div>
        <div class="status_sec" style="float: right">
          <label class="switch">
            <input type="checkbox" class="d-none" @click="toggleCustomizeMessage()"
                   :disabled="!isTypeCTA(call_to_action_id)"
                   :title="!isTypeCTA(call_to_action_id) ? 'Customize Message only allowed to type cta campaigns' : ''"
                   :value="customizeMessageObj.has_custom_message" :checked="customizeMessageObj.has_custom_message">
            <div class="slider round"></div>
          </label>
        </div>
      </div>
      <div v-if="customizeMessageObj.has_custom_message">
        <div v-if="getCampaignType(call_to_action_id) !== 'social_modern'"
             class="d-flex align-items-center url_input_main">
          <div class="content mt-3">
            <div class="url">Message Headline</div>
            <div class="name">
              <div class="validation_input">
                <input style="padding-right: 30px;"
                       placeholder="Your headline goes here"
                       name="link_password"
                       v-model="customizeMessageObj.message_headline" type="text"
                       autocomplete="off"
                       class="form-control txt-left-left">
              </div>
            </div>
            <span class="input-error" v-if="customizeMessageValidations.message_headline">{{
                customizeMessage.message_headline
              }}</span>
            <span class="input-error" v-else-if="customizeMessageValidations.message_headline_length">{{
                customizeMessage.message_headline_length
              }}</span>
          </div>
        </div>
        <div v-if="getCampaignType(call_to_action_id) !== 'banner'" class="d-flex align-items-center url_input_main ">
          <div class="d-flex align-items-center url_input_main">
            <div class="content mt-3">
              <div class="url">Message</div>
              <div class="name">
                <div class="validation_input">
                    <textarea placeholder="Your message goes here"
                              class="form-control text-muted message_textarea txt-left-left"
                              style="padding-right: 30px;"
                              v-model="customizeMessageObj.message" name="" id="" cols="50"
                              rows="5"></textarea>
                </div>
              </div>
              <span class="input-error" v-if="customizeMessageValidations.message">{{
                  customizeMessage.message
                }}</span>
              <span class="input-error" v-else-if="customizeMessageValidations.messageLength">{{
                  customizeMessage.messageLength
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { passwordProtectedMessages } from '@/common/attributes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PasswordProtectedComponent',
  data () {
    return {
      passwordProtectedMessages: passwordProtectedMessages,
      customizeMessageObj: {
        has_custom_message: this.has_custom_message,
        message_headline: this.message_headline,
        message: this.message
      }
    }
  },
  created () {
    this.customizeMessageObj.has_custom_message = this.has_custom_message
  },
  props: [
    'customizeMessageValidations',
    'customizeMessage',
    'has_custom_message',
    'message_headline',
    'message',
    'call_to_action_id'
  ],
  methods: {
    toggleCustomizeMessage () {
      this.customizeMessageObj.has_custom_message = !this.customizeMessageObj.has_custom_message
      this.$emit('customizeMessageObj', this.customizeMessageObj)
    }
  },
  watch: {
    'customizeMessageObj.message_headline' (value) {
      this.$emit('customizeMessageObj', this.customizeMessageObj)
      this.customizeMessageValidations.message_headline = this.requiredCheck(value)
      this.customizeMessageValidations.message_headline_length = !this.maxLength(value, 250)
    },
    'customizeMessageObj.message' (value) {
      this.$emit('customizeMessageObj', this.customizeMessageObj)
      this.customizeMessageValidations.message = this.requiredCheck(value)
      this.customizeMessageValidations.messageLength = !this.maxLength(value, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.customize-message-component {
  .input_icon_text {
    box-shadow: none;

    .setting_item {
      .d-inline-block {
        p {
          a {
            color: #3988fe;
          }
        }
      }
    }

    .view_bottom {
      .view_bottom_inner {
        p {
          color: #1f216e;
          font-weight: bold;
          font-size: small;
        }
      }
    }
  }

  .message_textarea:focus {
    box-shadow: none;
  }
}
</style>
