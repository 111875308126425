import {bioLinkTypes, userTypes} from './mutation-types'
import http from '@/mixins/http-lib'
import {
  bioShortenURLUpdate, createBioQRCodeURL,
  deleteBioContentSectionURL,
  deleteBioURL, renderQRCodeURL,
  showBioURL,
  storeBioContentSectionURL,
  storeBioURL, updateBioContentURL,
  fetchBioLinksAnalytics, archiveBioURL
} from '@/config/api'
import {uploadImageURL} from '@/config/urls/helper/file'
import {refreshRssFeedUrl} from '@/config/urls/link/link'
import {searchBioURL} from '../../config/api'
import {trackBioLinkCreated, clean_payload} from '../../common/methods'
import {bio_content_type} from '../../common/attributes'
import {defaultScheduleObj} from '@/common/defaults'

const getDefaultBioAdd = () => {
  return {
    page_title: null,
    page_sub_title: null,
    name: null,
    _id: null,
    brand_id: null,
    image: null,
    resized_image: null,
    theme: 'pink_and_gradient',
    title_color: '#ffffff',
    sub_title_color: '#ffffff',
    block_title_color: '#ffffff',
    content_sections: [],
    shorten_domain: null,
    domain_id: '',
    shorten_url: null,
    domain: null,
    is_creating_bio: false,
    first_step_verify: false,
    is_update_slug: false,
    qr_code_url: null,
    meta: {
      title: null,
      description: null,
      image: null,
      resized_image: null
    },
    favicon: null,
    retargeting_pixels: [],
    custom_theme: {
      background_color: '#000000',
      background_style: 'flat',
      button_color: '#ffffff',
      button_text_color: '#333',
      button_style: 'filled'
    },
    link_history: {}
  }
}

const getDefaultContentChannels = () => {
  return {
    instagram: null,
    twitter: null,
    facebook: null,
    youtube: null,
    medium: null,
    twitch: null,
    spotify: null,
    linkedin: null,
    soundcloud: null,
    apple: null,
    pinterest: null
  }
}

export const getDefaultSocialChannelsValidations = () => {
  return {
    instagram_required: false,
    twitter_required: false,
    facebook_required: false,
    youtube_required: false,
    medium_required: false,
    twitch_required: false,
    spotify_required: false,
    linkedin_required: false,
    soundcloud_required: false,
    apple_required: false,
    pinterest_required: false
  }
}
const getDefaultContentSectionAdd = () => {
  return {
    _id: null,
    title: null,
    type: null,
    links: [],
    posts: [],
    channels_option: [],
    rss: null,
    channels: getDefaultContentChannels(),
    show_title: true,
    social_color: '#ffffff'
  }
}

const defaultSchedule = (schedule_enabled = false) => (
  {
    schedule_enabled: schedule_enabled,
    schedule: {...defaultScheduleObj}
  }
)

const defaultHeight = (height_enabled = false, height = 199) => ({height, height_enabled})

// Audio block default object
const getDefaultAudioSection = () => {
  return {
    _id: null,
    title: null,
    url: null,
    ...defaultHeight(),
    ...defaultSchedule(),
    type: null,
    order: null
  }
}

// video block default object
const getDefaultVideoSection = () => {
  return {
    _id: null,
    title: null,
    url: null,
    embed_url: null,
    ...defaultHeight(),
    ...defaultSchedule(),
    type: null,
    order: null
  }
}

// card block default object
const getDefaultCardSection = () => {
  return {
    _id: null,
    title: null,
    description: null,
    url: null,
    image: null,
    style: 'card-1',
    animation_enabled: false,
    animation: '',
    ...defaultSchedule(),
    type: null,
    order: null
  }
}

// rss feed default object
const getDefaultRssSection = () => {
  return {
    _id: null,
    show_title: true,
    title: null,
    rss: null,
    posts: [],
    style: 'card-1',
    animation_enabled: false,
    animation: '',
    type: null,
    order: null,
    card_number: 5
  }
}

// text block default object
const getDefaultTextSection = () => {
  return {
    _id: null,
    text: '',
    animation_enabled: false,
    animation: '',
    type: null,
    order: null
  }
}

export default ({
  state: {
    add: getDefaultBioAdd(),
    add_content_section: getDefaultContentSectionAdd(),
    add_audio_section: getDefaultAudioSection(),
    add_video_section: getDefaultVideoSection(),
    add_card_section: getDefaultCardSection(),
    add_text_section: getDefaultTextSection(),
    add_rss_section: getDefaultRssSection(),
    items: {},
    loaders: {
      retrieve: false,
      store: false
    },
    ignore_changes: false
  },
  getters: {
    getBioLinks: state => state,
    getBioLinkItems: state => state.items,
    getBioLinkAdd: state => state.add,
    getBioLinkContentSectionAdd: state => state.add_content_section,
    getBioLinkAudioSectionAdd: state => state.add_audio_section,
    getBioLinkVideoSectionAdd: state => state.add_video_section,
    getBioLinkCardSectionAdd: state => state.add_card_section,
    getBioLinkTextSectionAdd: state => state.add_text_section,
    getBioLinkRssSectionAdd: state => state.add_rss_section,
    getBioLinkLoaders: state => state.loaders,
    getIgnoreChanges: state => state.ignore_changes
  },
  mutations: {

    [bioLinkTypes.SET_BIO_LINK_ITEMS] (state, items) {
      state.items = JSON.parse(JSON.stringify(items.data))
      state.items = state.items.map(val => ({...val, fetchStats: true}))
    },
    [bioLinkTypes.RESET_BIO_LINK_ITEM] (state) {
      state.add = getDefaultBioAdd()
    },
    [bioLinkTypes.ADD_BIO_LINK_ITEM] (state, item) {
      state.items.unshift(item)
    },
    [bioLinkTypes.REMOVE_BIO_LINK_ITEM] (state, item) {
      const findIndex = state.items.findIndex(x => x._id === item._id)
      state.items.splice(findIndex, 1)
    },
    [bioLinkTypes.REMOVE_BIO_LINK_ITEM_WITH_INDEX] (state, findIndex) {
      state.items.splice(findIndex, 1)
    },
    [bioLinkTypes.UPDATE_BIO_LINK_ITEM] (state, item) {
      const findIndex = state.items.findIndex(x => x._id === item._id)
      state.items.splice(findIndex, 1, item)
    },
    [bioLinkTypes.SET_BIO_ADD_VALUE] (state, value) {
      state.add = JSON.parse(JSON.stringify(value))
    },
    [bioLinkTypes.SET_BIO_ADD_BRAND] (state, value) {
      state.add.brand_id = value
    },
    [bioLinkTypes.SET_BIO_ADD_ID] (state, value) {
      state.add._id = value
    },
    [bioLinkTypes.SET_BIO_ADD_IS_CREATING_BIO] (state, value) {
      state.add.is_creating_bio = value
    },
    [bioLinkTypes.SET_BIO_ADD_THEME] (state, value) {
      state.add.theme = value
    },
    [bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN] (state, value) {
      state.add.shorten_domain = value
    },
    [bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN_ID] (state, value) {
      state.add.domain_id = value
    },
    [bioLinkTypes.SET_BIO_ADD_IMAGE] (state, value) {
      state.add.image = value
    },
    [bioLinkTypes.SET_GRAPH_ADD_IMAGE] (state, value) {
      state.add.meta.image = value
    },
    [bioLinkTypes.SET_FAVICON_ADD_IMAGE] (state, value) {
      state.add.favicon = value
    },
    [bioLinkTypes.SET_BIO_ADD_RESIZED_IMAGE] (state, value) {
      state.add.resized_image = value
    },
    [bioLinkTypes.SET_GRAPH_ADD_RESIZED_IMAGE] (state, value) {
      state.add.meta.resized_image = value
    },
    [bioLinkTypes.SET_BIO_ADD_SHORTEN_URL] (state, value) {
      state.add.shorten_url = value
    },
    [bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD] (state) {
      state.add_content_section = getDefaultContentSectionAdd()
      state.add_audio_section = getDefaultAudioSection()
      state.add_video_section = getDefaultVideoSection()
      state.add_card_section = getDefaultCardSection()
      state.add_text_section = getDefaultTextSection()
      state.add_rss_section = getDefaultRssSection()
    },
    [bioLinkTypes.SET_AUDIO_CONTENT_SECTION_ADD] (state, item = null) {
      state.add_audio_section = item || getDefaultAudioSection()
    },
    [bioLinkTypes.SET_VIDEO_CONTENT_SECTION_ADD] (state, item = null) {
      state.add_video_section = item || getDefaultVideoSection()
    },
    [bioLinkTypes.SET_CARD_CONTENT_SECTION_ADD] (state, item = null) {
      state.add_card_section = item || getDefaultCardSection()
    },
    [bioLinkTypes.SET_RSS_CONTENT_SECTION_ADD] (state, item = null) {
      state.add_rss_section = item || getDefaultRssSection()
    },
    [bioLinkTypes.SET_RSS_CONTENT_SECTION_POSTS] (state, item = null) {
      state.add_rss_section.posts = JSON.parse(JSON.stringify(item.posts.slice(0, item.card_number)))
    },
    [bioLinkTypes.SET_TEXT_CONTENT_SECTION_ADD] (state, item = null) {
      state.add_text_section = item || getDefaultTextSection()
    },
    [bioLinkTypes.SET_BIO_CONTENT_SECTION_ADD] (state, item) {
      state.add_content_section = JSON.parse(JSON.stringify(item))
    },
    [bioLinkTypes.ADD_BIO_CONTENT_SECTION_ITEM] (state, item) {
      state.add.content_sections.push(item)
    },
    [bioLinkTypes.UPDATE_BIO_CONTENT_SECTION_OBJECT] (state, item) {
      state.add.content_sections = item
    },
    [bioLinkTypes.SET_BIO_CONTENT_SECTION_TYPE] (state, value) {
      state.add_content_section.type = value
    },
    [bioLinkTypes.SET_BIO_AUDIO_CONTENT_SECTION_TYPE] (state, value) {
      state.add_audio_section.type = value
    },
    [bioLinkTypes.SET_BIO_VIDEO_CONTENT_SECTION_TYPE] (state, value) {
      state.add_video_section.type = value
    },
    [bioLinkTypes.SET_BIO_CARD_CONTENT_SECTION_TYPE] (state, value) {
      state.add_card_section.type = value
    },
    [bioLinkTypes.SET_BIO_RSS_CONTENT_SECTION_TYPE] (state, value) {
      state.add_rss_section.type = value
    },
    [bioLinkTypes.SET_BIO_TEXT_CONTENT_SECTION_TYPE] (state, value) {
      state.add_text_section.type = value
    },
    [bioLinkTypes.UPDATE_BIO_CONTENT_SECTION_ITEM] (state, item) {
      const findIndex = state.add.content_sections.findIndex(x => x._id === item._id)
      state.add.content_sections.splice(findIndex, 1, item)
    },
    [bioLinkTypes.REMOVE_BIO_CONTENT_SECTION_ITEM] (state, item) {
      const findIndex = state.add.content_sections.findIndex(x => x._id === item._id)
      state.add.content_sections.splice(findIndex, 1)
    },
    [bioLinkTypes.SET_BIO_CONTENT_SECTION_ADD_LINKS] (state, value) {
      state.add_content_section.links.push(value)
    },
    [bioLinkTypes.SET_QR_CODE_URL] (state, value) {
      state.add.qr_code_url = value
    },
    [bioLinkTypes.SET_BIO_CONTENT_SECTION_ADD_LINKS] (state, value) {
      state.add_content_section.links.push(value)
    },
    [bioLinkTypes.SET_IGNORE_CHANGES] (state, value) {
      state.ignore_changes = value
    },
    [bioLinkTypes.SET_BIO_LINK_ANALYTICS] (state, data) {
      const bioLink = state.items.find(item => item._id === data._id)
      if (bioLink) {
        bioLink.fetchStats = false
        bioLink.total_visitors = data.data.hits.total
      }
    },
    [bioLinkTypes.SET_BIO_LINK_RETRIEVE_LOADER] (state, value) {
      state.loaders.retrieve = value
    },
    [bioLinkTypes.SET_BIO_LINK_DOMAIN] (state, value) {
      state.add.domain = value
    }
  },
  actions: {
    async storeBio ({commit, getters, dispatch}, isState = null) {
      const payload = getters.getBioLinkAdd
      if (isState) payload.state = isState
      const res = await http.post(storeBioURL, payload).then(res => {
        if (res && res.data.status) {
          if (!payload._id) {
            trackBioLinkCreated(getters.getProfile)
          }
          commit(bioLinkTypes.SET_BIO_ADD_ID, res.data.data._id)
          commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_URL, res.data.data.shorten_url)
          commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN, res.data.data.shorten_domain)
          if (getters.getProfile.onboarding === false) {
            if (getters.getProfile.onboarding_data.brand && getters.getProfile.onboarding_data.cta && getters.getProfile.onboarding_data.link) {
              getters.getProfile.onboarding = true
            }
            getters.getProfile.onboarding_data.bio_link = true
          }
        }
        return res
      }).catch(err => {
        return null
      })
      return res
    },
    async setBioLinkDomain ({commit}, value) {
      commit(bioLinkTypes.SET_BIO_LINK_DOMAIN, value)
    },
    async storeBioContentSection ({commit, getters, dispatch}, getterType = 'getBioLinkContentSectionAdd') {
      const payload = clean_payload({...getters[getterType]})
      console.log('storeBioContentSection =>', getters.getBioLinkAdd)
      payload.domain_id = getters.getBioLinkAdd.domain_id
      payload.shorten_domain = getters.getBioLinkAdd.shorten_domain
      return await http.post(storeBioContentSectionURL, {
        ...payload,
        bio_id: getters.getBioLinkAdd._id
      }).then(res => {
        if (res && res.data.status) {
          if (getters[getterType]._id) {
            commit(bioLinkTypes.UPDATE_BIO_CONTENT_SECTION_ITEM, res.data.data.content_section)
            commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
            return res
          }

          if (!payload._id) {
            trackBioLinkCreated(getters.getProfile)
          }

          if (res.data.data.bio) {
            commit(bioLinkTypes.SET_BIO_ADD_ID, res.data.data.bio._id)
            commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_URL, res.data.data.bio.shorten_url)
            commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN, res.data.data.bio.shorten_domain)
            if (getters.getProfile.onboarding === false) {
              if (getters.getProfile.onboarding_data.brand && getters.getProfile.onboarding_data.cta && getters.getProfile.onboarding_data.link) {
                getters.getProfile.onboarding = true
              }
              getters.getProfile.onboarding_data.bio_link = true
            }
          }

          commit(bioLinkTypes.ADD_BIO_CONTENT_SECTION_ITEM, res.data.data.content_section)
          commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
        }
        return res
      }).catch(err => {
        const resp = {error: 'Unexpected error has occurred. We have been notified.', data: {status: false}}
        // in case of 422 Unprocessable Content
        if (err.response.status === 422) {
          const errors = err.response.data.errors
          resp.error = Object.values(errors)[0][0]
        }
        dispatch('toastNotification', {message: resp.error, type: 'error'})
        console.warn(err)
        return resp
      })
    },
    resetIgnoreChanges ({commit}) {
      commit(bioLinkTypes.SET_IGNORE_CHANGES, false)
    },
    async updateBioContentSection ({commit, getters, dispatch}, contentSection) {
      await http.post(updateBioContentURL, {content_section_ids: contentSection.map(e => e._id)}).then(res => {
        if (res && res.data.status) {
          commit(bioLinkTypes.SET_IGNORE_CHANGES, true)
          commit(bioLinkTypes.UPDATE_BIO_CONTENT_SECTION_OBJECT, res.data.data)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async deleteBioContentSection ({commit, getters, dispatch}, payload) {
      const res = await http.post(deleteBioContentSectionURL, payload).then(res => {
        if (res && res.data.status) {
          commit(bioLinkTypes.REMOVE_BIO_CONTENT_SECTION_ITEM, res.data.data)
        }
        return res
      }).catch(err => {
        return null
      })
      return res
    },
    async showBio ({commit, getters, dispatch}, payload) {
      commit(bioLinkTypes.SET_BIO_LINK_RETRIEVE_LOADER, true)
      return await http.post(showBioURL, {
        archive: payload.archive,
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        sort: payload.sort
      }).then(res => {
        if (res && res.data.status) {
          commit(bioLinkTypes.SET_BIO_LINK_ITEMS, res.data.data)
          commit(bioLinkTypes.SET_BIO_LINK_RETRIEVE_LOADER, false)
          return res.data.data
        }
        return res
      }).catch(err => {
        commit(bioLinkTypes.SET_BIO_LINK_RETRIEVE_LOADER, false)
        return null
      })
    },
    fetchBioLinkAnalytics ({commit, getters, dispatch}) {
      getters.getBioLinkItems.forEach((item) => {
        http.post(fetchBioLinksAnalytics, {
          bio_link_id: item._id
        }).then(res => {
          if (res.data.status) {
            const obj = {
              _id: item._id,
              data: res.data.data
            }
            commit(bioLinkTypes.SET_BIO_LINK_ANALYTICS, obj)
          }
        })
      })
    },
    async deleteBio ({commit, getters, dispatch}, {bioId, index}) {
      return await http.post(deleteBioURL, {
        _id: bioId
      }).then(res => {
        if (res && res.data.status) {
          commit(bioLinkTypes.REMOVE_BIO_LINK_ITEM_WITH_INDEX, index)
        }
        return res
      }).catch(err => {
        return null
      })
    },
    async bioShortenURLUpdate ({commit, getters, dispatch}, payload) {
      return await http.post(bioShortenURLUpdate, payload).then(res => res).catch(err => err)
    },
    async uploadBioImage ({commit, getters, dispatch}, event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      const res = await http.post(uploadImageURL, formData).then(res => res
      ).catch(err => err)
      event.target.value = ''
      return res
    },
    async refreshRssFeed ({commit, getters, dispatch, state}, contentId) {
      const res = await http.post(refreshRssFeedUrl, {content_id: contentId}).then(res => res).catch(err => err)
      state.add.content_sections.forEach(section => {
        if (section._id === contentId) {
          section.posts = res.data.data
        }
      })
      return res
    },
    async uploadGraphImage ({commit, getters, dispatch}, event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      const res = await http.post(uploadImageURL, formData).then(res => res
      ).catch(err => err)
      event.target.value = ''
      return res
    },
    createBioQRCode ({commit, getters, dispatch}, {url = null, slug = null}) {
      if (!url) {
        url = getters.getSaveLink.url
      }
      if (!slug) {
        slug = getters.getSaveLink.slug
      }
      url += '?r=qr'
      http.post(createBioQRCodeURL, {
        url: url,
        slug: slug
      }).then(res => {
        if (res.data.data) {
          commit(bioLinkTypes.SET_QR_CODE_URL, res.data.data)
          dispatch('renderBioQRCode', res.data.data)
        }
      }).catch(
        () => {
        }
      )
    },

    renderBioQRCode ({commit, getters, dispatch}, url) {
      http.post(renderQRCodeURL, {
        url: url
      }, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'image/png'
        }
      }).then(res => {
        // download the report for the user.
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        const splitURL = url.split('/')
        // improve the file download name
        const fileName = splitURL[splitURL.length - 1] + '.png'
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }).catch(
        () => {
        }
      )
    },

    async archiveBioLink ({commit, getters, dispatch}) {
      const payload = getters.getModalData
      const profileArchiveStatus = getters.getArchiveStatus.status
      if (profileArchiveStatus) payload.hideArchiveMessage = true
      await http.post(archiveBioURL, payload).then(
        res => {
          if (res.data.status) {
            dispatch('toastNotification', {message: res.data.message})
            getters.getBioLinkItems.splice(payload.index, 1)
            commit(userTypes.SET_MODAL_STATUS, 'success')
            if (profileArchiveStatus) commit(userTypes.SET_PROFILE_ARCHIVE_MESSAGE_STATUS)
          } else {
            commit(userTypes.SET_MODAL_STATUS, 'fail')
            dispatch('toastNotification', {message: res.data.message, type: 'error'})
          }
          return res
        }
      ).catch(
        () => {
          commit(userTypes.SET_MODAL_STATUS, 'fail')
          return false
        }
      )
      setTimeout(() => {
        commit(userTypes.SET_CLOSE_CONFIRMATION_MODAL, 'archiveBrand')
      }, 1000)
    },

    async searchBioLinks ({commit, getters}, payload) {
      const res = await http.post(searchBioURL, payload).then(res => res).catch(() => null)
      return res
    },
    resetAudioContentSectionAdd ({commit}) {
      commit(bioLinkTypes.SET_AUDIO_CONTENT_SECTION_ADD)
    },
    resetVideoContentSectionAdd ({commit}) {
      commit(bioLinkTypes.SET_VIDEO_CONTENT_SECTION_ADD)
    },
    resetCardContentSectionAdd ({commit}) {
      commit(bioLinkTypes.SET_CARD_CONTENT_SECTION_ADD)
    },
    resetTextContentSectionAdd ({commit}) {
      commit(bioLinkTypes.SET_TEXT_CONTENT_SECTION_ADD)
    },
    resetRssContentSectionAdd ({commit}) {
      commit(bioLinkTypes.SET_RSS_CONTENT_SECTION_ADD)
    }
  }
})
