export default {
  state: {
    online: true,
    unauthorizedException: false,
    interval: 0
  },
  getters: {
    getOnlineStatus: state => {
      return state.online
    },
    getUnauthorizedException: state => {
      return state.unauthorizedException
    },
    getCSRFInterval: state => {
      return state.interval
    }
  },
  actions: {
    setOnlineStatus ({ commit }, status) {
      commit('setOnlineStatus', status)
    },
    setUnauthorizedException ({ commit }, status) {
      commit('setUnauthorizedException', status)
    },
    setCSRFInterval ({ commit }, interval) {
      commit('setCSRFInterval', interval)
    }
  },
  mutations: {
    setOnlineStatus (state, status) {
      state.online = status
    },
    setUnauthorizedException (state, status) {
      state.unauthorizedException = status
    },
    setCSRFInterval (state, interval) {
      state.interval = interval
    }
  }

}
