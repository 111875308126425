import http from '@/mixins/http-lib'
import { fetchAddonSubscriptionURL, fetchSubscriptions } from '../../../config/api'
import {billingType} from "@/state/modules/mutation-types";

export default {
  state: {
    addonConsumedLimitDetail: {},
    addonBaseSubscriptionList: [],
    activeAddonDetail: {},
    limitConsumedError: false,
    editModifierLimits: {},
    loaders: {
      payment_details: false,
      addons: false,
      invoices: false,
      pause_subscription: false,
      resume_subscription: false,
      cancel_subscription: false,
    }
  },
  getters: {
    getAddonConsumedLimitDetail: state => state.addonConsumedLimitDetail,
    getAddonBaseSubscriptionList: state => state.addonBaseSubscriptionList,
    getBillingLoaders: state => state.loaders,
    getActiveAddonDetail: state => state.activeAddonDetail,
    getLimitConsumedError: state => state.limitConsumedError,
    getEditModifierLimits: state => state.editModifierLimits
  },
  actions: {
    /**
     * fetching the addons subscription list
     * @param commit
     * @param getters
     * @param dispatch
     * @param date
     * @returns {Promise<void>}
     */
    async fetchAddonSubscriptions({commit, getters, dispatch}, date) {
      return await http.post(fetchAddonSubscriptionURL, {date}).then(res => {
        if (res.data.status) {
          commit(billingType.SET_ADDON_BASED_SUBSCRIPTION_LIST, res.data.data)
        }

        // computing the active addons details
        let subscriptionList = res.data.data.find(item => ['active', 'past_due'].includes(item['paddle_status']))
        if(subscriptionList) {
          commit(billingType.SET_ACTIVE_ADDON_DETAIL, {
            paddle_subscription_id: subscriptionList && subscriptionList.paddle_subscription_id ? subscriptionList.paddle_subscription_id : '',
            bill_type: subscriptionList.modifiers[0]['bill_type']
          })
        }

        return res.data
      }).catch(err => {
        return err
      })
    },
    /**
     * fetching the specified subscription list
     * @param commit
     * @param getters
     * @param dispatch
     * @param date
     * @returns {Promise<void>}
     */
    async fetchSpecifiedSubscriptionsList({commit, getters, dispatch}, plans) {
      return await http.post(fetchSubscriptions, {plans}).then(res => {
        if (res.data.status) {
          return res.data.data
        }
        return res.data
      }).catch(err => {
        return err
      })
    },
    setAddonConsumedLimitDetail({commit, getters, dispatch}, data) {
      commit(billingType.SET_ADDON_CONSUMED_LIMIT_DETAIL, data)
    },
    setLimitConsumedError({commit, getters, dispatch}, data) {
      commit(billingType.SET_LIMIT_CONSUMED_ERROR, data)
    },
    setEditModifierLimits({commit, getters, dispatch}, data) {
      commit(billingType.SET_EDIT_MODIFER_Limits, data)
    },
  },
  mutations: {
    [billingType.SET_ADDON_CONSUMED_LIMIT_DETAIL] (state, value) {
      state.addonConsumedLimitDetail = value
    },
    [billingType.SET_ADDON_BASED_SUBSCRIPTION_LIST] (state, value) {
      state.addonBaseSubscriptionList = value
    },
    [billingType.SET_ACTIVE_ADDON_DETAIL] (state, value) {
      state.activeAddonDetail = value
    },
    [billingType.SET_LIMIT_CONSUMED_ERROR] (state, value) {
      state.limitConsumedError = value
    },
    [billingType.SET_EDIT_MODIFER_Limits] (state, value) {
      state.editModifierLimits = value
    }
  }
}
