<template>
  <h2>Fetching your access code...</h2>
</template>


<script>
import { mapActions } from 'vuex'
import { APIURL } from '@/config/config'
import { API_URL_v2, API_URL_v3, cookieDomain } from '@/constants'
import vueCookie from 'vue-cookie'
import http from '@/mixins/http-lib'

export default ({
  'name': 'oauth',
  components: {
    params: null
  },
  data: function () {
    return {}
  },
  created () {
    this.params = this.$route.query
    http.post(`${API_URL_v3}me`).then(
      response => {
        if (response) {
          if (!this.params || !this.params.state) {
            http.get(`${API_URL_v2}connectReplug?response_type=${this.params.response_type}&redirect_uri=${this.params.redirect_uri}&client_id=${this.params.client_id}&scope=${this.params.scope}`).then(
              resp => {
                if (resp.data.status) {
                  window.location.href = `${APIURL}authorizeReplug?response_type=${this.params.response_type}&redirect_uri=${this.params.redirect_uri}&client_id=${this.params.client_id}&scope=${this.params.scope}&auth_id=${response.data._id}`
                }
              }
            )
          } else {
            http.get(`${API_URL_v2}connectReplug?response_type=${this.params.response_type}&redirect_uri=${this.params.redirect_uri}&client_id=${this.params.client_id}&scope=${this.params.scope}&state=${this.params.state}`).then(
              resp => {
                if (resp.data.status) {
                  window.location.href = `${APIURL}authorizeReplug?response_type=${this.params.response_type}&redirect_uri=${this.params.redirect_uri}&client_id=${this.params.client_id}&scope=${this.params.scope}&auth_id=${response.data._id}&state=${this.params.state}`
                }
              }
            )
          }
        }
      },
      error => {
        this.$router.push({
          name: 'login', query: {
            response_type: this.params.response_type,
            redirect_uri: this.params.redirect_uri,
            client_id: this.params.client_id,
            scope: this.params.scope
          }
        })
      }
    )
  },
  methods: {}

})
</script>
