let widgetValidations = {
  name: false,
  javascript: false,
  html: false
}
let widgetMessages = {
  name: 'Name cannot be empty',
  html: 'HTML cannot be empty',
  javascript: 'Javascript cannot be empty'
}
let widgetCMOptions = {
  scroll: true,
  lineNumbers: true,
  matchBrackets: true
}

let bioLinkValidations = () => {
  return {
    name: false,
    url: false
  }
}

let bioLinkMessages = () => {
  return {
    name: 'Name cannot be empty',
    url: 'URL cannot be empty'
  }
}

let brandValidations = {
  name: false,
  url: false,
  url_valid: false
}

let brandMessages = {
  name: 'Name cannot be empty',
  url: 'URL cannot be empty',
  url_valid: 'Please enter a valid URL.'
}
let pixelValidations = {
  name: false,
  pixel: false
}
let pixelMessages = {
  name: 'Pixel name cannot be empty.',
  pixel: 'Pixel ID cannot be empty.'

}

const swalDefaultValues = (isHtml = false) => {
  return {
    title: 'Are you sure ?',
    type: 'warning',
    text: '',
    html: isHtml,
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, Delete it!',
    cancelButtonText: 'No, Cancel!',
    closeOnConfirm: true,
    customClass: 'sweetAlert_box',
    closeOnCancel: true,
    animation: false,
    allowOutsideClick: true
  }
}

const swalDispatchReportsValues = () => {
  return {
    title: 'Are you sure to dispatch reports manually?',
    type: 'warning',
    text: '',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, Generate it!',
    cancelButtonText: 'No, Cancel!',
    closeOnConfirm: true,
    customClass: 'sweetAlert_box',
    closeOnCancel: true,
    animation: false
  }
}
const defaultEmailProviders = () => {
  return [
    {
      name: 'Drip',
      internal_name: 'drip',
      connected: false
    },
    {
      name: 'Gist',
      internal_name: 'convertfox',
      connected: false
    },
    {
      name: 'Mailchimp',
      internal_name: 'mailchimp',
      connected: false
    },
    {
      name: 'ActiveCampaign',
      internal_name: 'activecampaign',
      connected: false
    },
    {
      name: 'GetResponse',
      internal_name: 'getresponse',
      connected: false
    },
    {
      name: 'Mautic',
      internal_name: 'mautic',
      connected: false
    },
    {
      name: 'ConstantContact',
      internal_name: 'constantcontact',
      connected: false
    },
    {
      name: 'Aweber',
      internal_name: 'aweber',
      connected: false
    },
    {
      name: 'Mailerlite',
      internal_name: 'mailerlite',
      connected: false
    },
    {
      name: 'Sendlane',
      internal_name: 'sendlane',
      connected: false
    },
    {
      name: 'SendPulse',
      internal_name: 'sendPulse',
      connected: false
    },
    {
      name: 'sendy',
      internal_name: 'sendy',
      connected: false
    },
    {
      name: 'ConvertKit',
      internal_name: 'convertkit',
      connected: false
    },
    {
      name: 'Moosend',
      internal_name: 'moosend',
      connected: false
    },
    {
      name: 'Sendinblue',
      internal_name: 'sendinblue',
      connected: false
    },
    {
      name: 'Benchmark Email',
      internal_name: 'benchmark',
      connected: false
    },
    {
      name: 'Campaign Monitor',
      internal_name: 'campaignmonitor',
      connected: false
    },
    {
      name: 'FreshMail',
      internal_name: 'freshmail',
      connected: false
    }

  ]
}

const URLValidations = {
  url: false,
  url_valid: false
}
const URLMessages = {
  url: 'URL cannot be empty',
  url_valid: 'URL is invalid, please enter a valid URL'
}

export {
  widgetCMOptions,
  widgetMessages,
  widgetValidations,
  brandMessages,
  brandValidations,
  URLValidations,
  URLMessages,
  swalDefaultValues,
  swalDispatchReportsValues,
  defaultEmailProviders,
  pixelMessages,
  pixelValidations

}
