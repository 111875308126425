import {upgradePlanURL, STATUS, ROLES} from '../../common/constants'
import http from '@/mixins/http-lib'
import {userTypes} from './mutation-types'
import {
  fetchProfileURL,
  getMemberURL,
  inviteMemberURL,
  resendInvitationURL,
  showStatisticsURL,
  storeTriggerURL, updateNewFeatureAnnouncementsURL
} from '../../config/api'
import {joinTeamURL} from '../../config/urls/user/user'
import router from '../../router'
import {getUserSubscriptionURL} from '../../config/config'
import {accountStateMessages} from '@/common/attributes'

const getDefaultAddTeamMember = () => {
  return {
    id: '',
    email: '',
    role: 'admin',
    first_name: '',
    last_name: '',
    permission: {
      createBrand: false,
      viewAllBioLinks: false,
      brands: [],
      pixels: [],
      domains: [],
      // keepData:false,
      integrations: {
        aweber: [],
        getresponse: [],
        mailchimp: [],
        constantcontact: [],
        activecampaign: [],
        convertfox: [],
        drip: [],
        mailerlite: []
      }
    }
  }
}
export default {
  state: {
    profile: {
      paddle_account_id: null,
      username: null,
      first_name: null,
      last_name: null,
      email: null,
      subscription_id: null,
      subscription_state: null,
      notifications: [],
      profile_image: null,
      onboarding: false,
      onboarding_data: {
        brand: false,
        cta: false,
        link: false,
        bio_link: false
      },
      append_hash: false,
      chrome_extension: true,
      hide_archive_message: false,
      is_trial_expired: true,
      policy: null,
      totalBrands: null,
      totalCampaigns: null,
      is_fetched: false,
      new_announcements: false
    },
    subscription: {
      display_name: null,
      fastspring_name: null,
      internal_id: null,
      account_id: null,
      type_of: ''
    },
    loader: {
      changeEmailLoader: false,
      inviteTeamMemberLoader: false,
      joinTokenStatus: false
    },

    team: getDefaultAddTeamMember(),
    teamMembers: {
      item: [],
      fetchStatus: false,
      loader: false,
      memberId: '',
      keepData: false
    },

    confirmAction: {
      data: '',
      stage: ''
    },

    buffer: {
      fetchStatus: false,
      data: []
    },

    removeStatus: '',
    modalMemberStatus: 1,
    hideArchiveMessage: {
      status: false
    },
    modalData: {
      status: false
    },
    whitelabel: null,
    whitelabel_loader: true,
    subscription_loader: true
  },
  getters: {
    getProfile: state => {
      return state.profile
    },
    getSubscription: state => state.subscription,
    getSubscriptionLoader: state => state.subscription_loader,
    getUserLoader: state => {
      return state.loader
    },
    getTeam: state => {
      return state.team
    },
    getTeamMember: state => {
      return state.teamMembers
    },

    getModalMemberStatus: state => {
      return state.modalMemberStatus
    },

    getModalStatus: state => {
      return state.removeStatus
    },
    getArchiveStatus: state => {
      return state.hideArchiveMessage
    },
    getModalData: state => {
      return state.modalData
    },

    getBuffer: state => {
      return state.buffer
    },
    getPaymentGatewayAccountId: state => {
      return state.profile.paddle_account_id
    },
    getWhitelabel: state => {
      return state.whitelabel
    },
    getWhiteLabelLoader: state => state.whitelabel_loader
  },
  mutations: {
    [userTypes.SET_MODAL_DATA](state, value) {
      state.modalData = value
    },
    [userTypes.SET_WHITELABEL](state, value) {
      state.whitelabel = value
    },
    [userTypes.SET_MODAL_STATUS](state, value) {
      state.removeStatus = value
    },
    [userTypes.SET_PROFILE_ARCHIVE_MESSAGE_STATUS](state) {
      if (state.hideArchiveMessage) state.profile.hide_archive_message = true
    },
    [userTypes.SET_CLOSE_CONFIRMATION_MODAL](state, modalId) {
      state.teamMembers.memberId = ''
      state.teamMembers.keepData = false
      state.removeStatus = ''
      state.modalMemberStatus = 1
      $(`#${modalId}`).modal('hide')
    },

    [userTypes.REMOVE_TEAM_MEMBER_ID](state, value) {
      state.teamMembers.memberId = value
    },
    [userTypes.KEEP_TEAM_MEMBER_DATA](state, value) {
      state.teamMembers.keepData = value
    },
    [userTypes.RESET_TEAM_ADD](state) {
      state.team = getDefaultAddTeamMember()
    },
    [userTypes.SET_TEAM_ADD_VALUE](state, value) {
      if (value.role === ROLES.admin) {
        value.permission = getDefaultAddTeamMember().permission
      }
      state.team = value
    },
    [userTypes.SET_TEAM_MEMBERS](state, value) {
      state.teamMembers.item = value
    },
    [userTypes.SET_TEAM_MEMBERS_RETRIEVE_LOADER](state, value) {
      state.teamMembers.loader = value
    },
    [userTypes.SET_TEAM_MEMBERS_RETRIEVE_STATUS](state, value) {
      state.teamMembers.fetchStatus = value
    },
    [userTypes.SET_TEAM_MEMBER_STORE_LOADER](state, value) {
      state.loader.inviteTeamMemberLoader = value
    },
    [userTypes.SET_WHITELABEL_LOADER](state, value) {
      state.whitelabel_loader = value
    },
    [userTypes.SET_SUBSCRIPTIONS_LOADER](state, value) {
      state.subscription_loader = value
    },
    setProfile(state, profile) {
      if (!profile.profile_image) {
        profile.profile_image = null
      }
      if (!profile.constantcontact_status) {
        profile.constantcontact_status = false
      }
      if (!profile.mailchimp_status) {
        profile.mailchimp_status = false
      }
      if (!profile.activecampaign_status) {
        profile.activecampaign_status = false
      }
      if (!profile.aweber_status) {
        profile.aweber_status = false
      }
      if (!profile.getresponse_status) {
        profile.getresponse_status = false
      }
      if (!profile.fastspring_account_id) {
        profile.fastspring_account_id = null
      }
      if (!profile.chrome_extension) {
        profile.chrome_extension = false
      }
      state.profile = profile
    },

    setProfileImage(state, url) {
      state.profile.profile_image = url
    },
    setSubscription(state, subscription) {
      state.subscription = subscription
    },
    setOnboarding(state, status) {
      state.profile.onboarding = status
    },
    setChangeEmailLoader(state, status) {
      state.loader.changeEmailLoader = status
    },
    setTeamBrands(state, brands) {
      state.team.brands = brands
    },
    setTeamPixels(state, pixels) {
      state.team.pixels = pixels
    },
    setTeamDomains(state, domain) {
      state.team.domains = domain
    },
    setTeamIntegrations(state, integration) {
      state.team.integrations = integration
    },
    setTeamDefault(state, value) {
      if (!value) {
        state.team = getDefaultAddTeamMember()
      } else {
        state.team = value
      }
    },

    setTeamMemberLoader(state, loader) {
      state.loader.inviteTeamMemberLoader = loader
    },

    setJoinTeamLoader(state, status) {
      state.loader.joinTokenStatus = status
    },

    setTeamMember(state, members) {
      state.teamMembers.item = members
    },
    setFetchTeamMemberStatus(state, status) {
      state.teamMembers.fetchStatus = status
    },

    setFetchTeamMemberLoader(state, loader) {
      state.teamMembers.loader = loader
    },

    setRemoveTeamMemberId(state, status) {
      state.teamMembers.memberId = status
    },
    setRemoveTeamMemberKeepDataStatus(state, status) {
      state.teamMembers.keepData = status
    },
    setModalStatus(state, status) {
      state.removeStatus = status
    },
    setModalMemberStatus(state, status) {
      state.modalMemberStatus = status
    },
    setArchiveStatus(state, status) {
      state.hideArchiveMessage.status = status
    },
    setModalData(state, status) {
      state.modalData = status
    },

    setConfirmActionData(state, data) {
      state.confirmAction.data = data
    },

    setConfirmActionStage(state, stage) {
      state.confirmAction.stage = stage
    },
    setChromeExtensionStatus(state, status) {
      state.profile.chrome_extension = status
    },

    // buffer

    setBufferFetchStatus(state, status) {
      state.buffer.fetchStatus = status
    },
    setBufferData(state, data) {
      state.buffer.data = data
    },
    /*setProfileIsFetched (state, data) {
      state.profile.is_fetched = data
    }*/
  },
  actions: {
    async fetchProfile({dispatch, commit, getters}) {
      return http.post(fetchProfileURL, {}).then(res => {
        commit('setProfile', res.data.profile)
        // commit('setProfileIsFetched', res.data.status)
        const status = res.data.profile.state
        if ([STATUS.deleted, STATUS.disabled, STATUS.cancelled].indexOf(status) !== -1) {
          this.$store.dispatch('toastNotification', {
            message: accountStateMessages(status).account_state,
            type: 'error'
          })
          router.push({name: 'logout'})
        }
        return true
      }).catch(() => null)
    },
    setWhitelabelLoader({commit}, value) {
      commit(userTypes.SET_WHITELABEL_LOADER, value)
    },
    storeTrigger({dispatch, commit, getters}, trigger) {
      http.post(storeTriggerURL, {name: trigger}).then(res => {
        if (res.data.status) {
          getters.getProfile.triggers[trigger] = true
        }
      }).catch(() => null)
    },
    async showStatistics({commit, getters, dispatch}) {
      const res = await http.post(showStatisticsURL).then(res => res).catch(() => null)
      return res
    },
    async upgradeSubscription({commit, state, getters, dispatch}, payload) {
      if (!getters.getPaymentGatewayAccountId || getters.getProfile.cancellation_effective_at) {
        dispatch('checkoutPlan', payload.subscription)
        return
      }

      const result = await http.post(upgradePlanURL, {
        subscription: payload.subscription,
        type: 'paddle'
      }).then(resp => {
        if (resp.data.status) {
          dispatch('toastNotification', {message: resp.data.message, type: 'success'})
          location.reload()
        }

        return resp

      }).catch()
      return result
    },
    checkoutPlan({commit, getters, dispatch, state}, plan) {
      const couponCode = plan.coupon && plan.coupon.length ? plan.coupon : '20OFFANNUAL'
      Paddle.Checkout.open(
        {
          product: plan.paddle_id,
          email: getters.getProfile.email,
          title: plan.display,
          disableLogout: true,
          coupon: couponCode,
          successCallback: function (data) {
            dispatch('toastNotification', {message: 'Thanks for your purchase.', type: 'success'})

            window.fpr('referral', {email: getters.getProfile.email, uid: getters.getProfile._id})

            plan.loader = false
            setTimeout(() => {
              window.location.reload()
            }, 500)
          },
          closeCallback: function (data) {
            plan.loader = false
          }
        })
    },
    async fetchTeamMembersList({commit, getters, dispatch}) {
      commit(userTypes.SET_TEAM_MEMBERS_RETRIEVE_LOADER, false)
      const res = await http.post(getMemberURL).then(
        res => {
          if (res.data.status) {
            commit(userTypes.SET_TEAM_MEMBERS, res.data.members)
            commit(userTypes.SET_TEAM_MEMBERS_RETRIEVE_STATUS, true)
          } else {
            dispatch('toastNotification', {message: res.data.message, type: 'error'})
          }
          return res
        },
        err => {
          return null
        }
      )
      commit(userTypes.SET_TEAM_MEMBERS_RETRIEVE_LOADER, false)
    },

    async joinTeam({commit, getters, dispatch}, payload) {
      const res = await http.post(joinTeamURL, payload).then(res => res).catch(err => err)
      return res
    },

    resendInvitation({commit, getters, dispatch}, memberId) {
      http.post(resendInvitationURL, {
        member_id: memberId
      }).then(res => {
        dispatch('toastNotification', {message: res.data.message, status: (res.data.status) ? 'success' : 'error'})
      })
    },
    async inviteMember({commit, getters, dispatch}) {
      commit(userTypes.SET_TEAM_MEMBER_STORE_LOADER, true)
      const team = JSON.parse(JSON.stringify(getters.getTeam))
      if (team.permission.utms) {
        team.permission.utms = team.permission.utms.map(u => u._id)
      }
      const res = await http.post(inviteMemberURL, team).then(res => res).catch(err => err)
      commit(userTypes.SET_TEAM_MEMBER_STORE_LOADER, false)

      return res
    },

    async fetchSubscription({commit}) {
      return http.post(getUserSubscriptionURL).then(
        async response => {
          if (response.data.status) {
            this.state.subscription = await response.data.subscription
            commit(userTypes.SET_SUBSCRIPTIONS_LOADER, false)
            // commit('setSubscription', response.data.subscription)
            // this.setSubscription(response.data.subscription)
          }
        })
    },

    async updateNewFeatureAnnouncements({getters}) {
      return http.post(updateNewFeatureAnnouncementsURL, {}).then(res => {
        if (res.data.status) {
          getters.getProfile.new_announcements = res.data.status
          return true
        }
        return false
      }).catch((e) => console.warn(e))
    },

    setProfile({commit}, profile) {
      commit('setProfile', profile)
    },
    setProfileImage({commit}, url) {
      commit('setProfileImage', url)
    },
    setSubscription({commit}, subscription) {
      commit('setSubscription', subscription)
    },
    setOnboarding({commit}, status) {
      commit('setOnboarding', status)
    },
    setChangeEmailLoader({commit}, status) {
      commit('setChangeEmailLoader', status)
    },
    setTeamBrands({commit}, brands) {
      commit('setTeamBrands', brands)
    },
    setTeamPixels({commit}, pixels) {
      commit('setTeamPixels', pixels)
    },
    setTeamDomains({commit}, domains) {
      commit('setTeamDomains', domains)
    },
    setTeamIntegrations({commit}, integration) {
      commit('setTeamIntegrations', integration)
    },
    setTeamDefault({commit}, value) {
      commit('setTeamDefault', value)
    },
    setTeamMemberLoader({commit}, loader) {
      commit('setTeamMemberLoader', loader)
    },
    setJoinTeamLoader({commit}, loader) {
      commit('setJoinTeamLoader', loader)
    },

    setFetchTeamMemberLoader({commit}, loader) {
      commit('setFetchTeamMemberLoader', loader)
    },
    setFetchTeamMemberStatus({commit}, status) {
      commit('setFetchTeamMemberStatus', status)
    },
    setTeamMember({commit}, members) {
      commit('setTeamMember', members)
    },
    setChromeExtensionStatus({commit}, status) {
      commit('setChromeExtensionStatus', status)
    },
    setRemoveTeamMemberId({commit}, status) {
      commit('setRemoveTeamMemberId', status)
    },
    setRemoveTeamMemberKeepDataStatus({commit}, status) {
      commit('setRemoveTeamMemberKeepDataStatus', status)
    },
    setModalStatus({commit}, status) {
      commit('setModalStatus', status)
    },
    setModalMemberStatus({commit}, status) {
      commit('setModalMemberStatus', status)
    },
    setArchiveStatus({commit}, status) {
      commit('setArchiveStatus', status)
    },

    setModalData({commit}, status) {
      commit('setModalData', status)
    },

    setConfirmActionData({commit}, data) {
      commit('setConfirmActionData', data)
    },

    setConfirmActionStage({commit}, stage) {
      commit('setConfirmActionStage', stage)
    },

    // buffer

    setBufferFetchStatus({commit}, status) {
      commit('setBufferFetchStatus', status)
    },
    setBufferData({commit}, data) {
      commit('setBufferData', data)
    }
  }

}
