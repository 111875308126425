import {
  bioDetailViewStatisticsURL,
  bioRealtimeStatisticsURL,
  detailViewStatisticsURL,
  fetchAnalyticsByBrand,
  fetchAnalyticsByCampaigns,
  fetchAnalyticsByLinks,
  fetchAnalyticsByUserId,
  fetchBioLinksAnalytics,
  fetchTotalStatistics,
  listViewLinksStatisticsURL,
  listViewStatisticsURL,
  realtimeStatisticsURL
} from '../../config/api'
import http from '@/mixins/http-lib'
import { analyticsTypes } from './mutation-types'

const defaultDtValue = moment().subtract(29, 'days').format('YYYY-MM-DDTHH:mm:ss') + ' _ ' +
  moment().format('YYYY-MM-DDTHH:mm:ss')
export default {
  state: {
    filters: {
      brand: {
        _id: null
      },
      campaign: {
        _id: null
      },
      link: {
        _id: null
      },
      bio_link: {
        _id: null
      },
      apply: false,
      date: {
        label: ' Last 30 Days',
        value: defaultDtValue
      },
      view_filter: 'overview'
    },
    analytics: {

      callToActions: [],
      brands: [],
      fetchAnalytics: false,

      search: '',
      date: {
        label: 'All Time',
        value: ''
      },
      page: 1,
      limit: 10,
      totalPages: 0,
      totalItems: 0,
      brandItems: [],
      brandAnalytics: [],

      linkAnalytics: []

    },

    analyticsDetail: {
      id: '',
      type: 'link',
      data: [],
      showingData: [],
      date: {
        label: 'All Time',
        value: ''
      },
      search: '',
      page: 1,
      limit: 10,
      totalPages: 0,
      totalItems: 0,
      filter: 'Overview',

      brandFilter: '',
      ctaFilter: '',
      linkFilter: '',

      callToAction: [],
      links: [],
      searchedLinks: [],
      searchLoader: false,
      totalLinks: 0,
      searchRequest: [],

      brandSearch: '',
      ctaSearch: '',
      linkSearch: '',

      brandFilterText: '',
      ctaFilterText: '',
      linkFilterText: '',

      cancelRequest: [],

      overviewBrowserSort: 'visit_desc',
      overviewOsSort: 'visit_desc',
      overviewCountrySort: 'visit_desc',
      overviewReferralSort: 'visit_desc',
      commonSort: 'visit_desc',
      allTypeSort: 'created_desc'
    },

    analyticsList: [],
    analyticsListBrands: [],

    referralOption: {
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<div class="engagement_tooltip">',
        pointFormat: '<h4>{series.name}</h4><p class="engagement_point"><span>{point.name}: <strong>{point.y}</strong></span></p>',
        footerFormat: '</div>',
        backgroundColor: null,
        shadow: false,
        useHTML: true,
        borderWidth: 0
      },
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: ''
      },

      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45,
          colors: [
            '#879dbf',
            '#b8d2b0',
            '#f6dbaa',
            '#b5b0d2',
            '#ffa59e',
            '#bfcfe1'
          ]
        }
      },
      series: []
    },
    countriesOption: {
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<div class="engagement_tooltip">',
        pointFormat: '<h4>{series.name}</h4><p class="engagement_point"><span>{point.name}: <strong>{point.y}</strong></span></p>',
        footerFormat: '</div>',
        backgroundColor: null,
        shadow: false,
        useHTML: true,
        borderWidth: 0
      },
      chart: {

        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },

      title: {
        text: ''
      },

      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45,
          colors: [

            '#879dbf',
            '#b8d2b0',
            '#ffa59e',
            '#b5b0d2',
            '#f6dbaa',
            '#bfcfe1'

          ]
        }
      },
      series: []
    },

    noReferralData: false,
    noCountriesData: false,

    referralData: [],
    countriesData: []
  },
  getters: {
    getAnalyticsFilters: state => state.filters,
    getAnalytics: state => {
      return state.analytics
    },

    getAnalyticsDetail: state => {
      return state.analyticsDetail
    },

    getAnalyticsListBrands: state => {
      return state.analyticsListBrands
    }

  },
  actions: {
    async detailViewStatistics ({ getters }) {
      return await http.post(detailViewStatisticsURL, {
        brand: getters.getAnalyticsFilters.brand._id,
        campaign: getters.getAnalyticsFilters.campaign._id,
        link: getters.getAnalyticsFilters.link._id,
        date: getters.getAnalyticsFilters.date
      })
        .then(res => res)
        .catch(() => null)
    },
    async listViewStatistics ({ commit, getters }, list) {
      const res = await http.post(listViewStatisticsURL, {}).then(res => res).catch(() => {
        return null
      })
      res.data.overview.forEach(brand => {
        brand.campaigns = brand.campaigns.map(val => ({ ...val, fetchStats: false }))
      })
      return res.data.overview
    },
    async fetchBrandListAnalytics ({ commit, getters, dispatch }, brandId) {
      const res = await http.post(fetchAnalyticsByBrand, {
        brand_id: brandId
      }).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async fetchCampaignListAnalytics ({ commit, getters, dispatch }, campaignId) {
      const res = await http.post(fetchAnalyticsByCampaigns, {
        call_to_action_id: campaignId
      }).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async fetchLinkListAnalytics ({ commit, getters, dispatch }, linkId) {
      const res = await http.post(fetchAnalyticsByLinks, {
        link_id: linkId
      }).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async fetchBioLinkListAnalytics ({ commit, getters, dispatch }, bioLinkId) {
      const res = await http.post(fetchBioLinksAnalytics, {
        bio_link_id: bioLinkId
      }).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async fetchTotalClicks ({ commit, getters, dispatch }, date) {
      const res = await http.post(fetchTotalStatistics, {
        date: date
      }).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async fetchAnalyticsByUserId ({ commit, getters, dispatch }, data) {
      const res = await http.post(fetchAnalyticsByUserId, data).then(res => res).catch(() => {
        return null
      })
      return res.data.data
    },
    async listViewLinksStatistics ({ commit, getters }, { brandId, campaignId, page = 1 }) {
      const res = await http.post(listViewLinksStatisticsURL, {
        brand_id: brandId,
        call_to_action_id: campaignId,
        page: page
      }).then(res => res).catch(() => {
        return null
      })
      return res
    },
    async bioDetailViewStatistics ({ getters }) {
      return await http.post(bioDetailViewStatisticsURL, {
        brand: getters.getAnalyticsFilters.brand._id,
        bio_link: getters.getAnalyticsFilters.bio_link._id,
        date: getters.getAnalyticsFilters.date
      }).then(res => res).catch(() => {
        return null
      })
    },
    async realtimeStatistics ({ commit, getters }, { page = 1, sort }) {
      const res = await http.post(realtimeStatisticsURL, {
        brand: getters.getAnalyticsFilters.brand._id,
        campaign: getters.getAnalyticsFilters.campaign._id,
        link: getters.getAnalyticsFilters.link._id,
        date: getters.getAnalyticsFilters.date,
        page: page,
        sort: sort
      }
      ).then(res => res).catch(() => {
        return null
      })
      return res
    },
    async bioRealtimeStatistics ({ commit, getters }, { page = 1, sort }) {
      const res = await http.post(bioRealtimeStatisticsURL, {
        brand: getters.getAnalyticsFilters.brand._id,
        bio_link: getters.getAnalyticsFilters.bio_link._id,
        date: getters.getAnalyticsFilters.date,
        page: page,
        sort: sort
      }
      ).then(res => res).catch(() => {
        return null
      })
      return res
    },
    setAnalyticsListBrands ({ commit }, list) {
      commit(analyticsTypes.SET_ANALYTICS_LIST_BRANDS, list)
    }

  },
  mutations: {
    [analyticsTypes.RESET_ANALYTICS_LINK_FILTER] (state) {
      state.filters.link = { _id: null, name: null }
    },
    [analyticsTypes.SET_ANALYTICS_LIST_BRANDS] (state, value) {
      state.filters.analyticsListBrands = value
    },
    [analyticsTypes.RESET_ANALYTICS_CAMPAIGN_FILTER] (state) {
      state.filters.campaign = { _id: null, name: null }
    },
    [analyticsTypes.RESET_ANALYTICS_BRAND_FILTER] (state) {
      state.filters.brand = { _id: null, name: null }
    },
    [analyticsTypes.SET_ANALYTICS_DATE] (state, value) {
      state.filters.date = value
    },
    [analyticsTypes.SET_ANALYTICS_VIEW_FILTER] (state, value) {
      state.filters.view_filter = value
    },
    [analyticsTypes.SET_ANALYTICS_BRAND_FILTER] (state, value) {
      state.filters.brand = value
    },
    [analyticsTypes.SET_ANALYTICS_BIO_LINK_FILTER] (state, value) {
      state.filters.bio_link = value
    },
    [analyticsTypes.SET_ANALYTICS_CAMPAIGN_FILTER] (state, value) {
      state.filters.campaign = value
    },
    [analyticsTypes.SET_ANALYTICS_LINK_FILTER] (state, value) {
      state.filters.link = value
    }
  }
}
