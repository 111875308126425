<template>
  <div class=" input_field shorten_form">

    <div class="mt-3 mb-3 share_link_block_step  ">
      <div class="create_link_block d-flex align-items-center"
           :class="{'active': edit_slug, 'input-field-error': validations.shorten_url_max_length}">

        <span class="link_url pr-0">{{ getSaveLink.domain.url }}</span>
        <span class="ellipsis_slug" v-tooltip="getSaveLink.shorten_url" v-if="!edit_slug">{{
            getSaveLink.shorten_url
          }}</span>
        <input v-else @focus="active_input = true"
               @blur="active_input = false" class="editable_url" type="text"
               ref="editSlugField"
               v-model="getSaveLink.shorten_url"
               @keyup.enter="updateSlugForURL()"
        >
      </div>
      <span class="input-error" v-if="validations.shorten_url_max_length">
                {{ slugMessages.slug_length }}
            </span>
      <div class="btn_block  d-flex align-items-center ">
        <input type="button"
               class="btn---cta btn-border color--gray btn-round  btn-bold mr-2 copy-btn"
               value="Copy"
               :disabled="edit_slug"
               v-clipboard:copy="copyUrl(getSaveLink.shorten_url,getSaveLink.domain.url)"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError">

        <button v-if="!edit_slug"
                @click.prevent="editSlug()"
                :disabled="isTrialUserObj.isTrial"
                :title="isTrialUserObj.message"
                class="btn---cta btn-border btn-round  btn-bold">
          <span>Edit
          </span>
        </button>

        <template v-else-if="edit_slug">
          <button :class="{'btn_disabled' : !edit_slug}"
                  :disabled="!edit_slug"
                  @click.prevent="updateSlugForURL()"
                  class="btn---cta btn-border btn-round  btn-bold btn--loader loader--right">
            <span>Save</span>
            <clip-loader v-if="getLinksLoaders.slug" class="loader"
                         :size="'14px'"
                         :color="'#ffffff'"></clip-loader>
          </button>
        </template>

      </div>
    </div>

    <!--<span class="d-none share_link" v-if="false">-->
    <!--<span class="link_url">{{getDomainURLById(getSaveLink.cta.domainId)}}</span>-->
    <!--<input class="editable_url" type="text" v-model="getSaveLink.shorten_url">-->
    <!--<input type="button" class="btn&#45;&#45;cta" value="Copy to Clipboard"-->
    <!--v-clipboard:copy="copyUrl(getSaveLink.shorten_url,getDomainURLById(getSaveLink.cta.domainId))"-->
    <!--v-clipboard:success="onCopy"-->
    <!--v-clipboard:error="onError">-->
    <!--</span>-->

    <div class="social-networks text-center">
      <p>
        Now go ahead and share this optimized {{ getSiteDetails.agency_name }} link with your audience on social, email,
        web or any other channel you want.

      </p>

      <social-sharing class="circle_social"
                      :url="socialUrl(getSaveLink.shorten_url,getSaveLink.domain.url)"
                      inline-template>
        <div>
          <network network="facebook">
            <i class="fab fa-fw fa-facebook fb_bg"></i>
          </network>
          <network network="twitter">
            <i class="fab fa-fw fa-twitter tw_bg"></i>
          </network>
          <!-- <network network="googleplus">
               <i class="fa fa-fw fa-google-plus gplus_bg"></i>
           </network>-->
          <network network="linkedin">
            <i class="fab fa-fw fa-linkedin lin_bg"></i>
          </network>
          <network network="pinterest">
            <i class="fab fa-fw fa-pinterest pin_bg"></i>
          </network>
          <network network="reddit">
            <i class="fab fa-fw fa-reddit reddit_bg"></i>
          </network>

        </div>
      </social-sharing>
      <div class="or_separator">
        <span class="sep_text">OR</span>
      </div>
      <button
        :disabled="getLinksLoaders.qr_code_download"
        @click="createQRCode({url: socialUrl(getSaveLink.shorten_url,getSaveLink.domain.url), slug: getSaveLink.shorten_url})"
        class="btn---cta btn-blue btn-round ml-auto btn--loader loader--right">
        <span>Download QR code</span>
        <clip-loader v-if="getLinksLoaders.qr_code_download" class="loader"
                     :size="'14px'"
                     :color="'#ffffff'"></clip-loader>
      </button>
      <button class="btn---cta btn-border color--gray btn-round  btn-bold copy-btn mt-2"
              @click.prevent="createNewLink()"><span>Create
        Another {{ getSiteDetails.agency_name }} Link</span></button>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { slugMessages } from '@/common/attributes'

export default {
  data () {
    return {
      size: '14px',
      color: '#fff',
      colorBlue: '#2d99ec',
      active_input: false,
      edit_slug: false,
      validations: {
        shorten_url_max_length: false
      },
      prevSlugName: '',
      isTrialUserObj: {},
      slugMessages: slugMessages
    }
  },
  created () {
    this.isTrialUserObj = this.isTrialUser(this.getSubscription)
    this.prevSlugName = this.getSaveLink.shorten_url
  },
  computed: {
    ...mapGetters([
      'getLinks',
      'getLinksLoaders',
      'getSubscription'
    ]),
  },
  methods: {
    ...mapActions([
      'createQRCode',
      'renderQRCode',
      'updateSlug'
    ]),
    async updateSlugForURL () {
      this.edit_slug = false
      if (this.validations.shorten_url_max_length) {
        this.alertMessage(slugMessages.slug_length, 'error')
        this.getSaveLink.shorten_url = this.prevSlugName
        return
      }

      if (this.prevSlugName == this.getSaveLink.shorten_url) {
        this.getSaveLink.shorten_url = this.prevSlugName
        return
      }

      let res = await this.$store.dispatch('updateSlug')

      if(!res.data.status) this.getSaveLink.shorten_url = this.prevSlugName

      this.alertMessage(res.data.message, res.data.status ? 'success' : 'error')
    },
    editSlug () {
      this.edit_slug = true
      this.prevSlugName = this.getSaveLink.shorten_url
      setTimeout(() => { this.$refs.editSlugField.focus() })
    }
  }
  ,
  watch: {
    'getSaveLink.shorten_url' (value) {
      if (value) {
        this.validations.shorten_url_max_length = this.checkSlugLength(value)
      }
    }
  }
}
</script>
<style scoped lang="less">
.copy_btn {
  margin-left: 76px
}

.ellipsis_slug {
  width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
