import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from "@sentry/vue";

if(process.env.VUE_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://c230e6f35c8f4d6fa11e467f44722621@sentry-onpremise.contentstudio.io/6",
    environment: process.env.VUE_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["stage.replug.io", "app.replug.io"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

import Toasted from './components/toasted_src/index.js'
import InputFieldError from '@/slots/InputFieldError'
import ClipLoader from './components/spinners/ClipLoader'
import LoadingScreen from './components/LoadingScreen'
import PasswordConfirmation from './components/dialogs/PasswordConfirmation'
import Alert from './slots/Alert'
import BootstrapVue, { BPopover, DropdownPlugin, ModalPlugin, BProgress, ProgressPlugin } from 'bootstrap-vue'

import Pagination from './misc/Pagination.vue'
import PaginationNextPrev from './misc/PaginationNextPrev.vue'
import Skeleton from '@/components/Skeleton'
import CountryFlag from 'vue-country-flag'
import slick from 'vue-slick'
import VueClipboard from 'vue-clipboard2'

import HighchartsVue from 'highcharts-vue'
import axios from 'axios'
import NoEventsAvailable from '@/views/pages/analytics/NoEventsAvailable'

// mixins
import validationMixin from '@/mixins/validation-mixin'
import { brandsMixin } from '@/mixins/BrandsMixin'
import projectMixin from '@/mixins/project-mixin'
import stringMixin from '@/mixins/string-mixin'
import { appMixin } from '@/mixins/helper/AppMixin'
import { authenticationMixin } from '@/mixins/helper/AuthenticationMixin'
import { helperMixin } from '@/mixins/helper/HelperMixin'
import { countryMixin } from '@/mixins/helper/CountryMixin'
import { utilsMixin } from '@/mixins/helper/UtilsMixin'
import { actionConfirmationMixin } from '@/mixins/helper/actionConfirmationMixin'
import { guidedTourMixin } from '@/mixins/helper/GuidedTourMixin'
import { userMixin } from '@/mixins/user/UserMixin'
import campaignsMixin from '@/mixins/campaigns-mixin'
import validations from '@/mixins/validations'
import linksExtraMixin from '@/mixins/links-mixin'
import { LinksMixin } from '@/mixins/link/LinksMixin'
import { AnalyticsMixin } from '@/mixins/analytics/AnalyticsMixin'
import bioLinkMixin from '@/mixins/bio-link-mixin'
import { PixelMixin } from '@/mixins/PixelMixin'
import { widgetsMixin } from './mixins/widgets-mixin'
import { tagMixin } from './mixins/tag-mixin'
import { mixin as clickaway } from 'vue-clickaway'

import UiButton from '@/ui/ui-kit/UiButton'
import UiInput from '@/ui/ui-kit/UiInput'
import UiDropdown from '@/ui/ui-kit/UiDropdown'
import UiTopBanner from '@/ui/ui-kit/UiTopBanner'
import vSelect from 'vue-select'

import moment from 'moment'

import VTooltip from 'v-tooltip'
import userActionsMixin from './mixins/user-actions-mixin'
import io from 'socket.io-client'

// Initialize web socket connection
window.socket = io.connect(process.env.VUE_APP_WEBSOCKET_URL, {transports: ['websocket'], secure: true});

// import fontawesome from "@fortawesome/fontawesome-free";
// import brands from "@fortawesome/fontawesome-free-brands";
// import 1 icon if you just need this one. Otherwise you can import the whole module
// import faSpinner from "@fortawesome/fontawesome-free-solid/faSpinner";

import vueCodeditor from 'vue-codeditor'

Vue.component('code-editor', vueCodeditor)

Vue.use(BootstrapVue)
Vue.component('pagination', Pagination)
Vue.component('pagination-next-prev', PaginationNextPrev)
Vue.component('country-flag', CountryFlag)
Vue.component('Slick', slick)
Vue.mixin(clickaway)

Vue.use(HighchartsVue)
Vue.use(ProgressPlugin)

Vue.config.productionTip = false
Vue.mixin(validationMixin)
Vue.mixin(projectMixin)
Vue.mixin(stringMixin)
Vue.component('input-field-error', InputFieldError)
Vue.component('password-confirmation', PasswordConfirmation)
Vue.component('loading-screen', LoadingScreen)
Vue.component('alert', Alert)
Vue.component('clip-loader', ClipLoader)
Vue.component('b-popover', BPopover)
Vue.component('ui-button', UiButton)
Vue.component('ui-top-banner', UiTopBanner)
Vue.component('ui-input', UiInput)
Vue.component('ui-dropdown', UiDropdown)
Vue.component('skeleton', Skeleton)
// VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.component('b-progress', BProgress)

Vue.component('v-select', vSelect)
Vue.use(DropdownPlugin)
Vue.use(ModalPlugin)
Vue.use(Toasted)

Vue.use(moment)

Vue.filter('from-now', (value) => {
  return moment.utc(value).fromNow()
})
Vue.filter('short-date-with-time', (value) => {
  const time = new Date(value)
  const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }

  return (new Intl.DateTimeFormat('en-US', options).format(time))
})
Vue.filter('short-date-with-mm-ss', (value) => {
  const time = new Date(value)
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  return (new Intl.DateTimeFormat('en-US', options).format(time))
})
Vue.filter('full-date-with-mm-ss', (value) => {
  const time = new Date(value)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  return (new Intl.DateTimeFormat('en-US', options).format(time))
})
Vue.filter('time-only', (value) => {
  const time = new Date(value)
  const options = { hour: 'numeric', minute: 'numeric' }

  return (new Intl.DateTimeFormat('en-US', options).format(time))
})

Vue.filter('date', (value) => {
    if (!value) {
      return ''
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return Intl.DateTimeFormat('en-US', options).format(value)
  }
)
Vue.filter('limit-text-length', (value) => {
    if (!value) {
      return ''
    }
    if (value && value.length > 80) return value.substring(0, 80) + '...'
    return value
  }
)

Vue.filter('relative', function (dateValue) {
  const date = moment.utc(dateValue)
  return moment(date).local().fromNow()
})
Vue.filter('float-to-int', function (value) {
  if (!value) return 0
  return Number(value).toFixed(0)
})

Vue.filter('number-to-commas', function (value) {
  if (!value) return 0
  if (value === 0) return 0
  return value.toLocaleString()
})

Vue.filter('relativeCaptilize', function (dateValue) {
  // let date = moment.utc(new Date(dateValue))
  let date = moment.utc(dateValue)
  date = moment(date).local().fromNow()
  if (date && date.charAt(0) == 'a') {
    return date.charAt(0).toLowerCase() + date.slice(1)
  }
  return date
})

Vue.use(VTooltip)

const SocialSharing = require('vue-social-sharing')
Vue.use(SocialSharing)

// mixins
Vue.mixin(userActionsMixin)
Vue.mixin(brandsMixin)
Vue.mixin(appMixin)
Vue.mixin(authenticationMixin)
Vue.mixin(helperMixin)
Vue.mixin(countryMixin)
Vue.mixin(utilsMixin)
Vue.mixin(guidedTourMixin)
Vue.mixin(actionConfirmationMixin)
Vue.mixin(userMixin)
Vue.mixin(campaignsMixin)
Vue.mixin(validations)
Vue.mixin(linksExtraMixin)
Vue.mixin(LinksMixin)
Vue.mixin(AnalyticsMixin)
Vue.mixin(bioLinkMixin)
Vue.mixin(PixelMixin)
Vue.mixin(widgetsMixin)
Vue.mixin(tagMixin)

Vue.component('NoEventsAvailable', NoEventsAvailable)

// axios.defaults.withCredentials = true
Vue.prototype.$http = axios
// fontawesome.library.add(brands, faSpinner);

// Vue.components('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
