export default {
  methods: {
    requiredCheck (value) {
      return (!value || (value && value.trim().length === 0))
    },
    requiredCheckWithInteger (value) {
      if (typeof value === 'number') if (value > 0) return false
      return (!value || (value && value.trim().length === 0))
    },
    minLength (value, length) {
      return (value && value.length >= length)
    },
    maxLength (value, length) {
      return (value && value.length <= length)
    },
    checkEmail (value) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (!value || !re.test(String(value.toLowerCase())))
    },
    checkTel (value) {
      // org regex for tel /^[+][-0-9]+\d+$/
      const re = /[-0-9]+\d+$/
      return (!value || !re.test(String(value.toLowerCase())))
    }
  }
}
