<template>
  <div class="post">
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'skeleton',
  created () {
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.post {
  display: flex;
}
.line {
  float: left;
  width: 50px;
  height: 16px;
  //margin-top: 12px;
  margin: 0 auto;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 20px, #ddd 40px);
  background-size: 600px;
  animation: shine-lines 1.2s infinite linear;
}

.line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
</style>
