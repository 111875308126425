export default ({
  methods: {
    ucWithFirstLetterOnly(value) {
      if (typeof value !== 'string') return ''
      if (!value) return ''
      return value.charAt(0).toUpperCase()
    },
    ucFirst(){
      if (typeof value !== 'string') return ''
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
})
