import { usermavenClient } from '@usermaven/sdk-js'

export const APIURL = process.env.VUE_APP_API_URL
export const baseURL = `${APIURL}api/v3/`
export const baseURLv3 = `${APIURL}api/v2/`
export const loginURL = baseURL + 'authenticate'
export const registerURL = baseURL + 'register'
export const registerFormURL = baseURL + 'auth/register'
export const forgotPasswordURL = baseURL + 'auth/forgotPassword'
export const recoverTokenStatusURL = baseURL + 'auth/recoverTokenStatus'
export const resetPasswordURL = baseURL + 'auth/resetPassword'
export const verifyEmailTokenURL = baseURL + 'auth/verifyEmailToken'
export const resendVerificationEmailURL = baseURL + 'auth/resendVerificationEmail'
export const updateEmail = baseURL + 'auth/user/change_verification_email'
export const USERMAVEN_PROJECT_ID = process.env.VUE_APP_USER_MAVEN_KEY
export const USERMAVEN_TRACKING_HOST = process.env.VUE_APP_USERMAVEN_TRACKING_HOST

export const userMaven = usermavenClient({
  key: USERMAVEN_PROJECT_ID,
  tracking_host: USERMAVEN_TRACKING_HOST,
  autocapture: true
})

// export const analyticsConfig = Analytics({
//   app: 'replug-segment',
//   plugins: [
//     segmentPlugin({
//       writeKey: 'jAwOamHjn1t46ndtE9iHU21uQGTddpSo'
//     })
//   ]
// })

export const refreshCSRF = baseURL + 'refreshCSRF'

// links

export const removeLinkURL = baseURL + 'links/remove'

// profile

export const getProfileURL = baseURL + 'auth/user/profile'
export const getUserSubscriptionURL = baseURL + 'auth/user/subscription'
export const saveProfileURL = baseURL + 'auth/user/saveProfile'
export const uploadProfilePictureURL = baseURL + 'auth/user/uploadProfilePicture'
export const removeProfilePictureURL = baseURL + 'auth/user/removeProfilePicture'
export const updatePasswordURL = baseURL + 'auth/user/updatePassword'
export const setPasswordURL = baseURL + 'auth/user/setPassword'
export const setEmailPasswordURL = baseURL + 'setEmailPassword'
export const getAuthorizationURL = baseURL + 'integration/getAuthorization'
export const getIntegrationMessageURL = baseURL + 'integration/getIntegrationMessage'
export const removeIntegrationURL = baseURL + 'integration/removeIntegration'
export const reconnectIntegrationURL = baseURL + 'integration/reconnectIntegration'
export const fetchIntegrationListURL = baseURL + 'integration/integrationList'
export const changeHashStatusURL = baseURL + 'auth/user/changeHashStatus'
export const changePoweredBySettingURL = baseURL + 'auth/user/changePoweredBySetting'
export const changeMiscSettingURL = baseURL + 'auth/user/changeMiscSetting'
export const uploadAppPictureURL = baseURL + 'auth/user/uploadAppPicture'
export const getWhitelabelDetailsFromDomain = baseURL + 'whitelabel/fetch_domain_details'
export const authCheck = baseURL + 'auth/check'

// buffer

export const getBufferAccountsURL = baseURL + 'getBufferAccounts'
export const removeBufferURL = baseURL + 'removeBuffer'

// notifications
export const getNotificationsURL = baseURL + 'getNotifications'
export const readNotificationsURL = baseURL + 'notification/read'

// custom integrations
export const baseIntegrationURL = baseURL + 'integration/apps/'
export const getResponseConnectURL = baseIntegrationURL + 'getResponse/connect'
export const getResponseSaveAccountURL = baseIntegrationURL + 'getResponse/saveAccount'
export const saveAweberLists = baseIntegrationURL + 'aweber/saveLists'
export const saveMailChimpLists = baseIntegrationURL + 'mailchimp/saveLists'
export const saveConstantContact = baseIntegrationURL + 'constantcontact/saveLists'
export const activeCampaignConnectURL = baseIntegrationURL + 'activeCampaign/connect'
export const activeCampaignSaveAccountURL = baseIntegrationURL + 'activeCampaign/saveAccount'
export const convertKitConnectURL = baseIntegrationURL + 'convertKit/connect'
export const convertKitSaveAccountURL = baseIntegrationURL + 'convertKit/saveAccount'
export const getConvertFoxConnectURL = baseIntegrationURL + 'convertfox/connect'
export const getMailerliteConnectURL = baseIntegrationURL + 'mailerlite/connect'
export const mailerliteSaveAccountURL = baseIntegrationURL + 'mailerlite/saveAccount'
export const SendLaneConnectURL = baseIntegrationURL + 'sendlane/connect'
export const sendLaneSaveAccountURL = baseIntegrationURL + 'sendlane/saveAccount'
export const SendPulseConnectURL = baseIntegrationURL + 'sendpulse/connect'
export const sendPulseSaveAccountURL = baseIntegrationURL + 'sendpulse/saveAccount'
export const MoosendConnectURL = baseIntegrationURL + 'moosend/connect'
export const MoosendSaveAccountURL = baseIntegrationURL + 'moosend/saveAccount'
export const SendyConnectURL = baseIntegrationURL + 'sendy/connect'
export const sendinBlueConnectURL = baseIntegrationURL + 'sendinblue/connect'
export const sendinBlueSaveAccountURL = baseIntegrationURL + 'sendinblue/saveAccount'
export const benchMarkConnectURL = baseIntegrationURL + 'benchmark/connect'
export const benchMarkSaveAccountURL = baseIntegrationURL + 'benchmark/saveAccount'
export const saveCampaignMonitor = baseIntegrationURL + 'campaignMonitor/saveList'
export const freshMailConnectURL = baseIntegrationURL + 'freshMail/connect'
export const freshMailSaveAccountURL = baseIntegrationURL + 'freshMail/SaveAccount'
export const saveMauticURL = baseIntegrationURL + 'mautic/connect'
// statistics

export const statisticsURL = baseURL + '/statistics'

// fastspring

export const upgradeURL = baseURL + 'fastspring/upgrade'
export const checkoutURL = baseURL + 'fastspring/generateKey'

// admin
export const fetchUserLogsUrl = baseURL + 'fetchLogs'

// whitelabel
export const getWhiteLabel = baseURL + 'whitelabel/fetch'
export const saveWhiteLabel = baseURL + 'whitelabel/store'

// agency clients
export const saveWhitelabelClient = baseURL + 'whitelabel/store_client'
export const deleteWhitelabelClient = baseURL + 'whitelabel/delete_client'
export const verifyEmailRecords = baseURL + 'whitelabel/verify_records/email'
export const verifyDomainRecords = baseURL + 'whitelabel/verify_records/domain'
export const deleteWhitelabelDomainSSL = baseURL + 'whitelabel/verify_records/deleteWhiteLabelSSL'
export const verifyWhitelabelEmail = baseURL + 'whitelabel/verify_records/verifyWhitelabelEmail'
