<template>

  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner w-100 m-auto">
        <div class="header_content mt-5 mb-5 clear">
          <div class="logo_block text-center m-auto">
            <a class="active">
              <img width="10%" :src="getSiteDetails.login_logo" :alt="getSiteDetails.agency_name"/>
            </a>
          </div>

        </div>
        <div class="white_box m-auto w-50">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center justify-content-center">
              <div class="left">
                <h2>Create an account</h2>

              </div>

            </div>
            <div class="box_content" :class="{'join_overlay':join_team_loader}">

              <clip-loader v-if="join_team_loader" class="full_page_loader" :size="size"
                           :color="loaderColorBlue"></clip-loader>

              <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
                   :class="{'input-field-error': validations.first_name}">

                <div class="avatar">
                  <i class="fal fa-user"></i>
                </div>
                <div class="content">
                  <div class="url">First Name</div>
                  <div class="name">
                    <div class="validation_input">
                      <input id="first_name" placeholder="Your first name" v-model="first_name"
                             type="text">
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="validations.first_name" class="input-error">
                                {{ messages.first_name }}
                            </span>

              <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
                   :class="{'input-field-error': validations.last_name}">

                <div class="avatar">
                  <i class="fal fa-lock"></i>
                </div>
                <div class="content">
                  <div class="url">Last Name</div>
                  <div class="name">
                    <div class="validation_input">
                      <input id="last_name" placeholder="Enter your password" v-model="last_name"
                             type="text">

                    </div>
                  </div>
                </div>
              </div>
              <span v-if="validations.last_name" class="input-error">
                                {{ messages.last_name }}
                            </span>
              <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
                   :class="{'input-field-error': validations.password}">

                <div class="avatar">
                  <i class="fal fa-lock"></i>
                </div>
                <div class="content">
                  <div class="url">Password</div>
                  <div class="name">
                    <div class="validation_input">
                      <input id="member_password" placeholder="Enter your password"
                             v-model="password" type="password">

                    </div>
                  </div>
                </div>
              </div>
              <span v-if="validations.password" class="input-error">
                                {{ messages.password }}
                            </span>

              <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
                   :class="{'input-field-error': validations.confirm_password}">

                <div class="avatar">
                  <i class="fal fa-lock"></i>
                </div>
                <div class="content">
                  <div class="url">Confirm Password</div>
                  <div class="name">
                    <div class="validation_input">
                      <input id="member_confirm_password"
                             placeholder="Enter your confirm password" v-model="confirmPassword"
                             type="password"
                             @keyup.enter="validateAndJoinTeam()"
                      >

                    </div>
                  </div>
                </div>
              </div>

              <span v-if="validations.confirm_password" class="input-error">
                                {{ messages.confirm_password }}
                            </span>
              <div class="mt-4 btn_block txt-center">

                <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                        @click.prevent="validateAndJoinTeam()">
                  <span>Join Now</span>
                  <clip-loader v-if="join_button_loader" :color="color"
                               :size="size"></clip-loader>
                </button>

              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import {joinTokenStatusURL} from '../../../config/urls/user/user'
import http from '@/mixins/http-lib'
import LoginLeft from './LoginLeft.vue'

export default {
  components: {
    ClipLoader,
    LoginLeft
  },
  computed: {
    ...mapGetters(['getUserLoader'])
  },
  async created () {
    await this.fetchWhitelabelAppSettings()
    this.getJoinTokenStatus()
  },
  data () {
    return {
      size: '14px',
      loaderColor: '#ffffff',
      loaderColorBlue: '#168eea',
      password: '',
      join_team_loader: true,
      join_button_loader: false,
      confirmPassword: '',
      first_name: null,
      last_name: null,
      validations: {
        first_name: false,
        last_name: false,
        password: false,
        confirm_password: false
      },
      messages: {
        first_name: 'First name cannot be empty',
        last_name: 'Last name cannot be empty',
        password: 'Password cannot be empty',
        confirm_password: 'Confirm password cannot be empty'

      }
    }
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    getJoinTokenStatus () {
      console.debug('Method:getJoinTokenStatus')
      http.post(joinTokenStatusURL, {
        token: this.$route.params.token
      }).then(
        res => {
          if (res.data.status) {
            this.first_name = res.data.first_name
            this.last_name = res.data.last_name
          } else {
            this.alertMessage(res.data.message, 'error')
            this.$router.push({name: 'login'})
          }
          this.join_team_loader = false
        },
        err => {
          this.$router.push({name: 'login'})
          this.alertMessage('Uh-oh! An unknown error occurred, support has been notified.', 'error')
        }
      )
    },
    async validateAndJoinTeam () {
      this.validations.first_name = this.requiredCheck(this.first_name)
      this.validations.last_name = this.requiredCheck(this.last_name)
      this.validations.password = this.requiredCheck(this.password)
      this.validations.confirm_password = this.requiredCheck(this.confirmPassword)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        if (this.password !== this.confirmPassword) {
          this.alertMessage('Password and Confirm Password does not match. ')
          return
        }
        let res = await this.$store.dispatch('joinTeam', {
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          token: this.$route.params.token
        })
        if (res && res.data.status) {
          this.$router.push({name: 'dashboard'})
        } else {
          this.alertMessage(res.data.message, 'error')
        }
      }
    }
  }
}
</script>
