<template>
  <div class="mb-3 traffic-routing-rules-component">
    <upgrade-subscription-modal :self="self"></upgrade-subscription-modal>
    <add-traffic-routing-rules ref="add_traffic_routing_rules_modal_component" :self="this.self"
                               :routing_rules="routing_rules"
                               :campaign_id="campaign_id"></add-traffic-routing-rules>
    <div :class="[is_called_by_dashboard ? 'mt-0' : 'mt-3']" class="input_icon_text position-relative">
      <div class="setting_item">
        <div class="text_sec d-inline-block">
          <span class="badge"
                :class="[is_called_by_dashboard ? 'new-feature-badge-dashboard' : 'link-new-badge']">New!</span>
          <p>Traffic Routing Rules <a v-if="!getSiteDetails.isWhiteLabel" href="https://docs.replug.io/article/886-traffic-routing-rules" target="_blank">(Help)</a>
          </p>
        </div>
        <div class="status_sec" style="float: right">
          <label class="switch">
            <input type="checkbox" class="d-none"
                   :disabled="is_ab_test || !isFeatureAllowedInPlan('traffic_routing',self)"
                   @click="toggleTrafficRoutingRule()"
                   :value="value" :checked="value">
            <div class="slider round"></div>
          </label>
        </div>
      </div>
      <div v-if="value" class="bg-transparent">
        <div class="view_bottom_inner pt-3">
          <p v-if="routing_rules && routing_rules.length" class="mb-3 d-flex">{{ routing_rules.length }} Routing Rules
            <a
              @click="editRules()">
              <i v-if="!traffic_routing_iframe_loader" class="fas fa-pen ml-2"></i>
              <clip-loader v-else class="ml-2" :color="'#3988fe'" :size="'12px'"></clip-loader>
            </a></p>
          <a v-else @click="addNewRule()"><i class="fas fa-plus-circle"></i> Add new rule</a>
        </div>
      </div>
      <div v-if="!isFeatureAllowedInPlan('traffic_routing',self)"
           class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>Traffic Routing Rules</strong>.
          <br> <a @click="$bvModal.show('upgrade-subscription-modal')">Go to billing section.</a></p>
      </div>
      <div v-else-if="is_ab_test" class="alert d-flex align-items-center mt-3 mb-0 alert-warning" role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>You can not use <strong>Traffic Routing Rules</strong> if A/B Testing is activated.</p>
      </div>
      <div v-if="traffic_routing_iframe_loader" class="alert d-flex align-items-center mt-3 mb-0 alert-success"
           role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>Traffic Routing in processing. Please wait...</p>
      </div>
      <div v-else-if="traffic_routing_cta_failed" class="alert d-flex align-items-center mt-3 mb-0 alert-danger"
           role="alert">
        <i class="fal fa-exclamation-triangle"></i>
        <p>{{ check_iframe_error }} Destination URL<span v-if="check_iframe_error !== 1">(S)</span> <span
          v-if="check_iframe_error !== 1"> are </span><span v-else> is </span> invalid.</p>
      </div>
    </div>
  </div>
</template>

<script>
import AddTrafficRoutingRulesModalComponent from '@/views/pages/links/AddTrafficRoutingRulesModalComponent'
import { dayOfWeekObject, trafficRoutingRulesFilterList } from '@/common/constants'

export default {
  name: 'TrafficRoutingRulesComponent',
  data () {
    return {
      trafficRoutingRulesFilterList: trafficRoutingRulesFilterList,
      dayOfWeekObject: dayOfWeekObject
    }
  },
  props: [
    'value',
    'routing_rules',
    'self',
    'is_ab_test',
    'campaign_id',
    'traffic_routing_cta_failed',
    'traffic_routing_iframe_loader',
    'check_iframe_error',
    'is_called_by_dashboard'
  ],
  async created () {
  },
  computed: {},
  components: {
    'add-traffic-routing-rules': AddTrafficRoutingRulesModalComponent,
    'upgrade-subscription-modal': () => import('@/components/UpgradeSubscriptionModal')
  },
  methods: {

    toggleTrafficRoutingRule () {
      this.$emit('input', !this.value)
    },

    addNewRule () {
      this.$refs.add_traffic_routing_rules_modal_component.init()
      this.$bvModal.show('addTrafficRoutingRulesModalComponent')
      this.$refs.add_traffic_routing_rules_modal_component.iframeURLSObj = {}
    },

    editRules () {
      this.$refs.add_traffic_routing_rules_modal_component.resetTrafficRoutingFilter()
      this.$refs.add_traffic_routing_rules_modal_component.recheckingIframe(this.self)
      this.$refs.add_traffic_routing_rules_modal_component.iframeURLSObj = {}
      this.$bvModal.show('addTrafficRoutingRulesModalComponent')
    }
  }
}
</script>

<style lang="less" scoped>
.traffic-routing-rules-component {
  .input_icon_text {
    box-shadow: none;

    .setting_item {
      .d-inline-block {
        p {
          a {
            color: #3988fe;
          }
        }
      }
    }

    .bg-transparent {
      .view_bottom_inner {
        a {
          color: #3988FE;
          cursor: pointer;
        }
      }
    }
  }

  .alert-warning {
    font-size: small;
    padding: 5px;

    .fa-exclamation-triangle {
      padding: 7px 10px;
      font-size: large;
    }

    p {
      font-size: small;
      color: #856404;
      text-align: left;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .alert-success {
    font-size: small;
    padding: 5px;

    .fa-exclamation-triangle {
      padding: 7px 10px;
      font-size: large;
    }

    p {
      font-size: small;
      color: #155724;
      text-align: left;
    }
  }

  .alert-danger {
    font-size: small;
    padding: 5px;

    .fa-exclamation-triangle {
      padding: 7px 10px;
      font-size: large;
    }

    p {
      font-size: small;
      color: #721c24;
      text-align: left;

      span {
        color: #721c24;
      }
    }
  }
}
</style>
