export default {
  state: {
    guidedTour: {
      step1: false, // true
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      step8: false,
      step9: false,
      step10: false,
      step11: false,
      step12: false,
      step13: false,
      step14: false
    },

    guidedTourOverlay: false
  },
  getters: {
    getGuidedTour: state => {
      return state.guidedTour
    },

    getGuidedTourOverlay: state => {
      return state.guidedTourOverlay
    }
  },
  mutations: {

    setGuidedTour (state, data) {
      state.guidedTour = data
    },

    setGuidedTourOverlay (state, data) {
      state.guidedTourOverlay = data
    }
  },
  actions: {
    setGuidedTour ({ commit }, data) {
      commit('setGuidedTour', data)
    },

    setGuidedTourOverlay ({ commit }, data) {
      commit('setGuidedTourOverlay', data)
    }
  }
}
