// import http from '@/mixins/http-lib'
import requests from '@/mixins/http-lib'

export default {
  state: {

  },
  getters: {
  },
  actions: {

    async postRequest ({}, URL, payload) {
      let res = await requests.post(URL, payload).then(res => {
        return res
      }).catch(() => {
        return null
      }
      )
      return res
    },
    async getRequest ({}, URL) {
      let res = await requests.get(URL).then(res => {
        return res
      }).catch(() => {
        return null
      }
      )
      return res
    }
  },
  mutations: {

  }
}
