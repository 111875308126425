<template>
    <div class="pricing__box">

        <div class="toggle_btn_single">
            <div class="discount_wrapper">
                <div class="toggle_btn_inner">
                    <div class="active_move"
                         :class="{'active_left':plan_type === 'monthly','active_right':plan_type === 'annually'}"></div>
                    <button :class="{'active':plan_type === 'monthly'}"
                            @click="togglePrice('monthly')"
                            class="btn btn_round border_btn large_btn">
                        <span>Monthly</span>
                    </button>
                    <button :class="{'active':plan_type === 'annually'}"
                            @click="togglePrice('annually')"
                            class="btn btn_round border_btn large_btn">
                        <span>Yearly</span>

                    </button>
                </div>
                <p class="discount-text">*Save 20% on yearly Subscription</p>
                <!--<span class="discount">save<br>20%</span>-->
            </div>
        </div>

        <div class="inner  clear row pricing-table-container">

            <div class="price_box_3 col-md-4" v-for="item in pricing_list.plans" v-if="plan_type === item.type"
                 :class="{'most_popular': item.level === 3 || item.level === 4, 'current_plan': item.level < level}">
                <div class="inner">
                    <h3 class="package">{{item.display}}</h3>
                    <h2 class="price" :class="item.color">
                        <sup>$</sup>
                        {{item.price.replace("$", "")}}
                        <sub v-if="plan_type === 'monthly'">/month</sub>
                        <sub v-if="plan_type === 'annually'">/year</sub>
                    </h2>

                    <p class="duration">{{item.duration}}</p>

                    <ul class="list_detail">
                        <li class="brand-li">
                            <span class="btn---cta btn-round"><span class="bold">{{item.brands}}</span> Brand</span>
                        </li>

                        <li><i class="fal fa-check pricing-table-icon"></i><span class="bold">{{item.clicks}}</span> clicks per month</li>
                        <li><i class="fal fa-check pricing-table-icon"></i><span class="bold">{{item.campaigns}}</span> Campaigns</li>
                        <li><i class="fal fa-check pricing-table-icon"></i><span class="bold">{{item.pixels}}</span> Retargeting Pixel</li>
                        <li><i class="fal fa-check pricing-table-icon"></i><span class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links</li>
                        <template v-if="item.custom_domains === 0">
                            <li class="line_strike"><i class="fal fa-times pricing-table-icon"></i>Custom Domains</li>
                        </template>
                        <template v-else>
                            <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i>{{item.custom_domains}}</span> Custom Domains</li>
                        </template>
                        <template v-if="item.team_members === 0">
                            <li class="line_strike"><i class="fal fa-times pricing-table-icon"></i>Team Members</li>
                        </template>
                        <template v-else>
                            <li><span class="bold"><i class="fal fa-check pricing-table-icon"></i>{{item.team_members}}</span> Team Members</li>
                        </template>
                        <li :class="{'line_strike': !item.branding}"><i :class="{'fa-check': item.branding, 'fa-times': !item.branding}" class="fal pricing-table-icon"></i>Remove {{ getSiteDetails.agency_name }} Branding</li>
                        <li :class="{'line_strike': !item.dedicated_support}"><i :class="{'fa-check': item.dedicated_support, 'fa-times': !item.dedicated_support}"  class="fal  pricing-table-icon"></i>Dedicated Support</li>
                        <li :class="{'line_strike': !item.api_access}"><i :class="{'fa-check': item.api_access, 'fa-times': !item.api_access}"  class="fal  pricing-table-icon"></i>API Access</li>
                        <li :class="{'line_strike': !item.onboarding}"><i :class="{'fa-check': item.onboarding, 'fa-times': !item.onboarding}"  class="fal  pricing-table-icon"></i>Onboarding & Call Support</li>
                    </ul>
                    <div v-if="getSubscription.internal_id && getSubscription.internal_id === item.internal_id">
                        <button  class="mt-3 btn---cta btn-round  btn-border current_plan_btn pricing_btn">
                            <span>Current Plan</span>
                        </button>
                    </div>
                    <div v-else>
                        <button class="mt-3 btn---cta btn-blue btn-round pricing_btn " :class="item.color.substring(1)" v-if="item.level > level"
                           @click="upgradePlan(item)">
                            <span>Upgrade</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { pricingList } from '@/mixins/pricing_constants'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      plan_type: 'monthly',
      pricing_list: pricingList,
      level: 0
    }
  },
  computed: {
    ...mapGetters(['getSubscription'])
  },
  mounted () {
    this.setLevel()
  },
  methods: {
    ...mapActions(['upgradeSubscription']),
    setLevel () {
      let internalId = this.getSubscription.internal_id
      this.pricing_list.plans.forEach(item => {
        if (internalId === item.internal_id) {
          this.level = item.level
        }
      })
    },
    togglePrice (name) {
      this.plan_type = name
    },
    async upgradePlan (item) {
      await this.upgradeSubscription({ 'subscription': item })
      this.getUserSubscription()
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>
