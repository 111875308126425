import http from '@/mixins/http-lib'
import {
  archiveCampaignURL,
  deleteCampaignURL, editCampaignNameURL, fetchAnalyticsByCampaigns,
  fetchCampaignsLinksByIdURL,
  fetchConvertfoxTagsURL,
  fetchDripTagsURL,
  fetchMauticTagsURL,
  showCampaignsURL,
  storeCampaignURL
} from '../../config/api'
import {brandTypes, campaignTypes, linkTypes, userTypes} from './mutation-types'

import {swalDefaultValues} from '@/mixins/attributes'
import {
  callTypeList,
  nameValidations,
  nameMessages,
  campaignValidations,
  campaignMessages,
  campaignSectionsValidations, URLValidations, URLMessages
} from '@/common/attributes'
import {uploadImageURL} from '../../config/urls/helper/file'
import router from '../../router'
import {trackCampaignCreated} from '../../common/methods'

const defaultCampaignsValidations = () => {
  return {
    validations: {
      provider: false,
      provider_id: false,
      redirect_after_submission: false,
      valid_redirect_after_submission: false
    },
    nameValidations: nameValidations,
    nameMessages: nameMessages,
    image: URLValidations,
    imageMessage: URLMessages,
    customMessageValidation: campaignValidations,
    customErrorMessage: campaignMessages,
    messages: {
      provider: 'Please select an email provider',
      provider_id: 'Please select your list or tag.',
      redirect_after_submission: 'Please enter URL',
      valid_redirect_after_submission: 'Please enter valid URL'
    }
  }
}

const defaultCampaignStatistics = () => {
  return {
    unique_visitors: 0,
    clicks: 0,
    conversions: 0,
    ctr: 0
  }
}

const defaultProviderValue = () => {
  return {
    created_tags: [],
    suggested_tags: [],
    tag_text: null,
    focus: false
  }
}
const defaultAddCampaign = () => {
  return {
    _id: null,
    name: '',
    button_url: '',
    button_text: 'Call to action',
    message: 'Your message goes here',
    message_headline: 'Your headline goes here',
    image: null,
    resized_image: null,
    type: 'no_cta',
    // color schema
    cta_type: 'social',
    integration: 'poptin',
    integration_script: null,
    email_service: null,
    email_list: {
      id: null,
      name: null
    },
    email_service_id: '',
    name_field: true,
    background_color: '#FFFFFF',
    text_color: '#333',
    background_image_exit_intent: null,
    exit_intent_opacity: 0.5,
    trigger_type: 'time',
    trigger_seconds: 5,
    trigger_scroll_value: 0,
    trigger_scroll_type: 'px',
    button_background_color: '#168eea',
    button_text_color: '#F5F5DC',
    position: 'left',
    branding_logo: 'true',
    domainId: '',
    redirect_original_website: true,
    redirect_after_submit: false,
    circle: true,
    sound: true,
    brand_id: '',

    retargeting_ids: [],
    script_ids: [],

    // pixel_list:[],
    link_color: '#168eea',
    campaign_type: {
      cta: false,
      retargeting: false,
      third_party: false,
      bio_link: false
    },
    redirect_after_submission: null,
    email_service_tags: [],
    agreement: []

  }
}

export default {
  state: {
    validations: JSON.parse(JSON.stringify(defaultCampaignsValidations())),
    campaignSectionsValidations: campaignSectionsValidations,
    list: [],
    componentCampaignList: [],
    page: 1,
    limit: 10,
    sort: 'created_desc',
    total_pages: 0,
    total_items: 0,
    date: {
      label: 'All Time',
      value: ''
    },
    archive: false,
    add: defaultAddCampaign(),
    loaders: {
      retrieve: false,
      image_upload: false,
      store: false,
      tags: false,
      campaign_image: false,
      fetch_campaigns_dashboard: true,
      image_upload_exit_intent: false
    },
    selected_brands: [],
    social_cta_stats: true,
    pixel_add_check: false,
    provider: defaultProviderValue(),
    select_all: false,
    search: '',
    // keys to improve campaigns page speed
    isListFetched: false,
    isAllListFetched: false,
    periodic: true,
    previousPage: false
  },
  getters: {
    getCampaigns: state => state,
    getCampaignsList: state => state.list,
    getComponentCampaignList: state => state.componentCampaignList,
    getCampaignAdd: state => state.add,
    getCampaignLoaders: state => state.loaders,
    getCampaignValidations: state => state.validations,
    getCampaignSectionsValidations: state => state.campaignSectionsValidations
  },
  actions: {
    async fetchCampaignsList ({commit, getters, dispatch}, {page = 1, isFetchAll = false}) {
      // fetching all campaigns no need to show loader

      if (!isFetchAll) {
        commit(campaignTypes.SET_CAMPAIGN_RETRIEVE_LOADER, true)
        commit(campaignTypes.SET_CAMPAIGN_DASHBOARD_LOADER, true)
      }

      const payload = getters.getCampaigns
      return await http.post(showCampaignsURL,
        {
          search: payload.search,
          page: page,
          sort: payload.sort,
          date: payload.date,
          archive: payload.archive,
          brand: payload.selected_brands,
          isFetchAll: isFetchAll
        }).then(res => {
        if (res.data.status && isFetchAll) {
          return res.data.data.all
        }
        if (res.data.status) {
          commit(campaignTypes.SET_CAMPAIGNS_LIST, res.data.data.paginate)
          commit(campaignTypes.SET_CAMPAIGN_RETRIEVE_LOADER, false)
          commit(campaignTypes.SET_CAMPAIGN_DASHBOARD_LOADER, false)
        }
        return res
      }).catch(() => {
        commit(campaignTypes.SET_CAMPAIGN_RETRIEVE_LOADER, false)
        commit(campaignTypes.SET_CAMPAIGN_DASHBOARD_LOADER, false)
      })
    },
    fetchCampaignAnalytics ({commit, getters}) {
      getters.getComponentCampaignList.forEach((item) => {
        commit(campaignTypes.SET_DEFAULT_CAMPAIGN_ANALYTICS, item._id)
        http.post(fetchAnalyticsByCampaigns, {
          call_to_action_id: item._id
        }).then(res => {
          if (res.data.status) {
            const obj = {
              _id: item._id,
              data: res.data.data
            }
            commit(campaignTypes.SET_CAMPAIGNS_ANALYTICS, obj)
          }
        })
      })
    },
    async deleteCampaign ({commit, state, getters, dispatch}, {campaignId, index}) {
      console.debug('Method::removeCallToActions')

      const confirmation = swalDefaultValues(true)
      confirmation.text = `
        <p style="color:gray !important; font-size: small !important;font-weight: bold !important;" class="mb-2 mt-2">By removing this Campaign, their:</p>
        <div style="background: #FFF8D1; border:1px solid #F8EACD" class="p-3 rounded">
             <ul class="ml-2" style="list-style-type: disc !important; display:list-item !important;;color:#967e4b !important;">
            <li style="color:#AA976C !important;" class="mb-1">Links will be removed.</li>
            </ul>
             <ul class="ml-2" style="list-style-type: disc !important; display:list-item !important;;color:#967e4b !important;">
            <li style="color:#AA976C !important;" class="mb-1">RSS automation will be removed.</li>
            </ul>
             <ul class="ml-2" style="list-style-type: disc !important; display:list-item !important;color:#967e4b !important;">
            <li style="color:#AA976C !important;" class="mb-1">RSS feed links will be removed.</li>
            </ul>
        </div>
        <p style="color:gray !important; font-size: small !important;font-weight: bold !important;" class="mb-2 text-center mt-2">This action cannot be undone.</p>
      `

      swal(confirmation,
        function (isConfirm) {
          if (isConfirm) {
            return http.post(deleteCampaignURL, {_id: campaignId}).then(res => {
              if (res.data.status) {
                dispatch('toastNotification', {message: res.data.message})
                getters.getCampaignsList.splice(index, 1)
                getters.getComponentCampaignList.splice(index, 1)
              } else {
                dispatch('toastNotification', {
                  message: 'An error occurred while removing your campaign, please try again.',
                  type: 'error'
                })
              }
              return res
            }).catch()
          } else {
            swal()
          }
        })
    },
    async archiveCampaign ({commit, state, getters, dispatch}) {
      const payload = getters.getModalData
      const profileArchiveStatus = getters.getArchiveStatus.status
      if (profileArchiveStatus) payload.hideArchiveMessage = true
      const res = await http.post(archiveCampaignURL, payload).then(
        res => {
          if (res.data.status) {
            dispatch('toastNotification', {message: res.data.message})
            getters.getCampaignsList.splice(payload.index, 1)
            getters.getComponentCampaignList.splice(payload.index, 1)
            commit(userTypes.SET_MODAL_STATUS, 'success')
            commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, [])
            commit(linkTypes.SET_CTA_FOR_LINK, [])
            if (profileArchiveStatus) commit(userTypes.SET_PROFILE_ARCHIVE_MESSAGE_STATUS)
          } else {
            commit(userTypes.SET_MODAL_STATUS, 'fail')
            dispatch('toastNotification', {message: res.data.message, type: 'error'})
          }
          // hide the loader

          return res
        }
      ).catch(
        () => {
          commit(userTypes.SET_MODAL_STATUS, 'fail')
          return false
        }
      )
      setTimeout(() => {
        commit(userTypes.SET_CLOSE_CONFIRMATION_MODAL, 'archiveCampaign')
      }, 1000)
    },
    async storeCampaign ({commit, getters, dispatch}, confirmUpdate = false) {
      getters.getCampaignLoaders.store = true
      const payload = getters.getCampaignAdd
      console.log('storeCampaign =>', payload)
      payload.confirm_update = confirmUpdate
      return await http.post(storeCampaignURL, payload).then(async res => {
        if (res.data.status) {
          // set the onboarding value to true
          if (getters.getProfile.onboarding === false) {
            getters.getProfile.onboarding_data.cta = true
          }
          if (payload.hasOwnProperty('_id') && payload._id && payload._id.length > 3) {
            dispatch('toastNotification', {message: res.data.message})
            const campaigns = getters.getCampaigns

            const campaignListIndex = campaigns.list.findIndex(item => item._id === res.data.data._id)
            campaigns.list.splice(campaignListIndex, 1, res.data.data)

            const componentCampaignListIndex = campaigns.componentCampaignList.findIndex(item => item._id === res.data.data._id)
            campaigns.componentCampaignList.splice(componentCampaignListIndex, 1, res.data.data)

            router.push({name: 'campaigns'})
            getters.getCampaignLoaders.store = false
            return res.data
            // update
          } else {
            dispatch('toastNotification', {message: res.data.message})
            await trackCampaignCreated(getters.getProfile)
            commit(campaignTypes.ADD_CAMPAIGNS_TO_LIST, {...res.data.data, ...defaultCampaignStatistics()})
            // commit(brandTypes.ADD_CAMPAIGN_TO_BRANDS_LIST, res.data.data)
            router.push({name: 'campaigns'})
            getters.getCampaignLoaders.store = false
            return res.data
          }
        } else {
          if (res.data.domain_change) {
            dispatch('verifyChangeDomain', res.data.message)
          } else {
            dispatch('toastNotification', {message: res.data.message, type: 'error'})
          }
          getters.getCampaignLoaders.store = false
        }
        return res.data
      }).catch((e) => {
        console.log(e)
      })
    },
    verifyChangeDomain ({dispatch}, text) {
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Update it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false,
          allowOutsideClick: true,
          text
        },
        (isConfirm) => {
          if (isConfirm) {
            dispatch('storeCampaign', true)
          } else {
            swal()
          }
        })
    },
    async fetchProviderTags ({commit, getters, dispatch}, {account, provider}) {
      let providerTagsURL
      switch (provider) {
        case 'drip':
          providerTagsURL = fetchDripTagsURL
          break
        case 'mautic':
          providerTagsURL = fetchMauticTagsURL
          break
        case 'convertfox':
          providerTagsURL = fetchConvertfoxTagsURL
          break
      }
      commit(campaignTypes.SET_CAMPAIGN_PROVIDER_LOADER, true)
      const res = await http.post(providerTagsURL, {account: account}).then(res => {
        if (!res.data.status && res.data.message) {
          dispatch('toastNotification', {message: res.data.message, type: 'error'})
        }
        if (res.data.tags) {
          commit(campaignTypes.SET_CAMPAIGN_PROVIDER_EMAIL_TAGS, res.data.tags)
        }
        return res
      }).catch(() => {

      })
      commit(campaignTypes.SET_CAMPAIGN_PROVIDER_LOADER, false)
    },
    storeCampaignName ({commit, getters, dispatch, state}, {campaign, index}) {
      return http.post(editCampaignNameURL, {name: campaign.edit_value.trim(), _id: campaign._id}).then(res => {
        return res.data
      }).catch(() => {
          return null
        }
      )
    },

    async uploadCampaignFormImage ({commit, getters, dispatch, state}, event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      commit(campaignTypes.SET_CAMPAIGN_IMAGE_LOADER, true)
      await http.post(uploadImageURL, formData).then(res => {
        if (res.data.status) {
          commit(campaignTypes.SET_CAMPAIGN_ADD_IMAGE, res.data.url)
          commit(campaignTypes.SET_CAMPAIGN_ADD_RESIZE_IMAGE, res.data.resize_url)
        } else {
          const message = (res.data.message) ? res.data.message : 'Unable to change the brand image'
          dispatch('toastNotification', {message: message, type: 'error'})
        }
        return res
      }).catch(() => {
        return null
      })
      event.target.value = ''
      commit(campaignTypes.SET_CAMPAIGN_IMAGE_LOADER, false)
    },

    async uploadExitIntentBackgroundImage ({commit, getters, dispatch, state}, event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      commit(campaignTypes.SET_EXIT_INTENT_IMAGE_LOADER, true)
      state.add.background_color = '#FFFFFF'
      await http.post(uploadImageURL, formData).then(res => {
        if (res.data.status) {
          commit(campaignTypes.SET_BACKGROUND_IMAGE_EXIT_INTENT, res.data.url)
        } else {
          dispatch('toastNotification', {message: 'Failed to upload image', type: 'error'})
        }
        return res
      }).catch((error) => {
        console.warn(error)
        return null
      })
      event.target.value = ''
      commit(campaignTypes.SET_EXIT_INTENT_IMAGE_LOADER, false)
    },
    /**
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param campaignId {Object} - Campaign id to retrieve the links from
     * @returns {Promise<void>}
     */
    async fetchCampaignLinksById ({commit, getters, dispatch}, {campaignId, page = 1}) {
      const res = await http.post(fetchCampaignsLinksByIdURL, {id: campaignId, page: page}).then(res => res
      ).catch(() => null)
      return res
    },
    async resetExitIntentBackGroundImage ({commit, getters, dispatch}) {
      commit(campaignTypes.SET_BACKGROUND_IMAGE_EXIT_INTENT, null)
    },
    async setComponentCampaignList ({commit}, value) {
      await commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, value)
    }
  },
  mutations: {
    [campaignTypes.RESET_CAMPAIGN_ADD] (state) {
      state.add = defaultAddCampaign()
    },
    [campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE] (state, value) {
      state.add = Object.assign(defaultAddCampaign(), JSON.parse(JSON.stringify(value)))
    },
    [campaignTypes.ADD_CAMPAIGNS_TO_LIST] (state, value) {
      state.list.unshift(value)
      state.componentCampaignList.unshift(value)
    },
    [campaignTypes.SET_CAMPAIGNS_LIST] (state, value) {
      // if record is not in period then fetch from DB

      state.page = value.current_page
      state.limit = value.per_page
      state.total_items = value.total
      state.total_pages = value.last_page

      // to maintain all records in both dropdown and pagination
      state.list = [...state.list, ...value.data].map(val => ({...val, fetchStats: true}))
    },
    [campaignTypes.SET_DEFAULT_CAMPAIGN_ANALYTICS] (state, ctaId) {
      const campaign = state.componentCampaignList.find(campaign => campaign._id === ctaId)
      if (!campaign) {
        return
      }
      campaign.fetchStats = true
      campaign.total_visitors = 0
      campaign.unique_visitors = 0
      campaign.total_clicks_and_conversions = 0
      campaign.unique_visitors_conversion_rate = 0
    },
    [campaignTypes.SET_CAMPAIGNS_ANALYTICS] (state, value) {
      state.componentCampaignList.filter(campaign => {
        if (campaign._id == value._id) {
          campaign.fetchStats = false
          campaign.total_visitors = value.data.aggregations.total_visitors.value
          campaign.unique_visitors = value.data.aggregations.unique_clicks.value
          campaign.total_clicks_and_conversions = value.data.aggregations.clicked_conversions.doc_count
          if (campaign.total_visitors <= 0) {
            campaign.unique_visitors_conversion_rate = 0
          } else {
            const digits = 2
            let val = (campaign.total_clicks_and_conversions / campaign.total_visitors) * 100
            val = val * Math.pow(10, digits)
            val = Math.round(val)
            campaign.unique_visitors_conversion_rate = val / Math.pow(10, digits)
          }
        }
      })
    },
    [campaignTypes.SET_CAMPAIGNS_ARCHIVE_VALUE] (state, value) {
      state.archive = value
    },
    [campaignTypes.EDIT_CAMPAIGN_NAME] (state, campaignId) {
      const campaign = state.componentCampaignList.find(item => campaignId === item._id)
      campaign.edit_value = (campaign.name) ? campaign.name : 'Untitled'
      campaign.edit = true
    },
    [campaignTypes.SET_CAMPAIGNS_SORT_VALUE] (state, value) {
      state.sort = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_BRAND] (state, value) {
      state.add.brand_id = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_BUTTON_URL] (state, value) {
      state.add.button_url = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_IMAGE] (state, value) {
      state.add.image = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_RESIZE_IMAGE] (state, value) {
      state.add.resized_image = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_TYPE] (state, value) {
      state.add.type = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_TYPE_THIRD_PARTY_VALUE] (state, value) {
      state.add.campaign_type.third_party = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_TYPE_BIO_LINK_VALUE] (state, value) {
      state.add.campaign_type.bio_link = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_CTA_VALUE] (state, value) {
      state.add.campaign_type.cta = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_CUSTOM_DOMAIN] (state, value) {
      state.add.domainId = value
    },
    [campaignTypes.ADD_TO_CAMPAIGN_CODE_WIDGET_LIST] (state, value) {
      state.add.script_ids.push(value)
    },
    [campaignTypes.REMOVE_FROM_CAMPAIGN_CODE_WIDGET_LIST] (state, value) {
      const index = state.add.script_ids.indexOf(value)
      if (index !== -1) state.add.script_ids.splice(index, 1)
    },
    [campaignTypes.SET_CAMPAIGN_PIXEL_ADD_CHECK] (state, value) {
      state.pixel_add_check = value
    },
    [campaignTypes.SET_CAMPAIGN_RETARGETING_PIXEL_LIST] (state, value) {
      state.add.retargeting_ids = value
    },
    // provider tags

    [campaignTypes.SET_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS] (state, value) {
      state.add.email_service_tags = value
    },
    [campaignTypes.ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS] (state, value) {
      if (state.add.email_service_tags.indexOf(value) < 0) state.add.email_service_tags.push(value)
    },
    [campaignTypes.SET_CAMPAIGN_ADD_EMAIL_SERVICE_ID] (state, value) {
      state.add.email_service_id = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_EMAIL_SERVICE_NAME] (state, value) {
      state.add.email_service = value
    },
    [campaignTypes.SET_CAMPAIGN_PROVIDER_EMAIL_TAGS] (state, value) {
      state.provider.created_tags = value
    },
    [campaignTypes.ADD_TAG_TO_CAMPAIGN_PROVIDER_EMAIL_TAGS] (state, value) {
      if (state.provider.created_tags.indexOf(value) < 0) state.provider.created_tags.push(value)
    },
    [campaignTypes.REMOVE_TAG_FROM_CAMPAIGN_PROVIDER_EMAIL_TAGS] (state, value) {
      const index = state.provider.created_tags.indexOf(value)
      state.provider.created_tags.splice(index, 1)
    },
    [campaignTypes.REMOVE_CAMPAIGN_ADD_EMAIL_SERVICE_TAG] (state, value) {
      const index = state.add.email_service_tags.indexOf(value)
      state.add.email_service_tags.splice(index, 1)
    },
    [campaignTypes.SET_CAMPAIGN_PROVIDER_SUGGESTED_TAGS] (state, value) {
      state.provider.suggested_tags = value
    },
    [campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_TEXT] (state, value) {
      state.provider.tag_text = value
    },
    [campaignTypes.SET_CAMPAIGN_PROVIDER_TAG_FOCUS] (state, value) {
      state.provider.focus = value
    },
    [campaignTypes.SET_CAMPAIGN_ADD_EMAIL_LIST] (state, value) {
      state.add.email_list = value
    },
    [campaignTypes.RESET_CAMPAIGN_PROVIDER] (state) {
      state.provider = defaultProviderValue()
      state.add.email_service_id = ''
      state.add.email_service_tags = []
    },
    [campaignTypes.SET_CAMPAIGN_PROVIDER_LOADER] (state, value) {
      state.loaders.tags = value
    },
    [campaignTypes.SET_CAMPAIGN_IMAGE_LOADER] (state, value) {
      state.loaders.campaign_image = value
    },
    [campaignTypes.SET_CAMPAIGN_BRAND_FILTERS] (state, value) {
      state.selected_brands = value
    },
    [campaignTypes.SET_CAMPAIGN_SOCIAL_CTA_STATS] (state, value) {
      state.social_cta_stats = value
    },
    [campaignTypes.SET_CAMPAIGNS_DATE_VALUE] (state, value) {
      value = (!value.hasOwnProperty('label')) ? {label: 'All Time', value: ''} : value
      state.date = value
    },
    [campaignTypes.SET_CAMPAIGN_RETRIEVE_LOADER] (state, value) {
      state.loaders.retrieve = value
    },
    [campaignTypes.SET_CAMPAIGN_DASHBOARD_LOADER] (state, value) {
      state.loaders.fetch_campaigns_dashboard = value
    },
    [campaignTypes.RESET_CAMPAIGN_VALIDATIONS] (state) {
      state.validations = JSON.parse(JSON.stringify(defaultCampaignsValidations()))
    },
    [campaignTypes.RESET_CAMPAIGN_SECTION_VALIDATIONS] (state) {
      state.campaignSectionsValidations = campaignSectionsValidations
    },
    [campaignTypes.SET_CAMPAIGN_SECTION_VALIDATIONS] (state, payload) {
      state.campaignSectionsValidations = payload
    },
    [campaignTypes.SET_EXIT_INTENT_IMAGE_LOADER] (state, value) {
      state.loaders.image_upload_exit_intent = value
    },
    [campaignTypes.SET_BACKGROUND_IMAGE_EXIT_INTENT] (state, value) {
      state.add.background_image_exit_intent = value
    },
    [campaignTypes.SET_IS_ALL_LIST_FETCHED] (state, value) {
      state.isAllListFetched = value
    },
    [campaignTypes.SET_IS_LIST_FETCHED] (state, value) {
      state.isListFetched = value
    },
    [campaignTypes.SET_CAMPAIGN_LIST] (state, value) {
      state.list = value
    },
    [campaignTypes.SET_COMPONENT_CAMPAIGN_LIST] (state, value) {
      state.componentCampaignList = [...value]
    }
  }
}
