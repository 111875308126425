<template>
  <h4>Connecting your integration...</h4>
</template>

<script>
import { APIURL } from '@/config/config'
import http from '@/mixins/http-lib'
import { API_URL_v3 } from '@/constants'
import { AWEBER_KEY, cookieAppDomain, JWT_CHROME_LOCAL_STORAGE } from '../../../../constants'
import vueCookie from 'vue-cookie'

export default ({
  name: 'auth-aweber',
  created () {
  },
  mounted () {
    http.post(`${API_URL_v3}me`).then(
      response => {
        if (response) {
          const secret = vueCookie.get(AWEBER_KEY, { domain: cookieAppDomain })
          http.get(`${APIURL}oauth/connect/aweber?code=${this.$route.query.code}&state=${this.$route.query.state}`).then(
            resp => {
              if(resp.data.status) {
                resp.data.message = ''
                this.$router.push({name: 'integrations', query: {integration_id: resp.data.integration_id, message: resp.data.message}})
              }
            }
          )
          // window.location.href = `${APIURL}oauth/connect/mailchimp?auth=${response.data._id}`
        }
      }
    )

  }
})
</script>
