import {baseURL} from '@/config/config'

export const upgradePlanURL = baseURL + 'billing/paddle/upgrade'
export const BLUE_LOADER_COLOR = '#168eea'
export const DARK_PURPLE_LOADER_COLOR = '#168eea'
export const DEFAULT_IMAGE = 'https://storage.googleapis.com/replug/profile-default.png'

export const STATUS = {
  deleted: 'deleted',
  disabled: 'disabled',
  cancelled: 'cancelled',
  trial_expired: 'trial_expired'
}
export const ROLES = {
  super_admin: 'super_admin',
  collaborator: 'collaborator',
  guest: 'guest',
  admin: 'admin'
}

export const bulkCSVLinkStatus = {
  valid: 'valid',
  duplicate: 'duplicate',
  duplicate_in_list: 'duplicate_in_list',
  iframe_error: 'iframe_error',
  invalid: 'invalid',
  slug_length: 'slug_length',
  meta_invalid: 'meta_invalid'
}
export const overviewOfClicksPerformance = () => {
  return {
    credits: {
      enabled: false
    },

    tooltip: {
      headerFormat: '<div class="engagement_tooltip_for_chart">',
      pointFormat: '<p class="engagement_point"><span>{point.x:%e %b}</span></p><h4>{point.y} {series.name}</h4>',
      footerFormat: '</div>',
      backgroundColor: null,
      shadow:
        false,
      useHTML:
        true,
      borderWidth:
        0
    },

    chart: {
      type: 'areaspline',
      spacingBottom:
        10,
      spacingTop:
        10,
      spacingLeft:
        10,
      spacingRight:
        10,
      width:
        null,
      height:
        null,
      style:
        {
          fontFamily: '\'Source Sans Pro\', sans-serif'
        }
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    // subtitle: {
    //     text: 'Engagement metrics stats'
    // },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats:
        { // don't display the dummy year
          month: '%e. %b',
          year:
            '%b'
        },
      //                        maxZoom: 20 * 1000,
      //                        categories: [],
      title: {
        text: ''
      },
      labels: {
        style: {
          color: '#2e3c54'
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: '#62747d'
        }
      },
      gridLineColor: '#f8f8f8',
      title:
        {
          text: ''
        },
      min: 0
    },
    plotOptions: {
      series: {
        lineWidth: 5,
        lineColor: '#74c5fa',
        fillOpacity: 0.1,
        marker: {
          radius: 4,
          enabled: true,
          fillColor: '#49b5fc',
          states: {
            hover: {
              enabled: true
            }
          }

        }
      }

    },
    series: [
      {
        name: 'Clicks',
        data: [],
        color: '#d2e3fd',
        shadow: {
          width: 4,
          color: '#ddeefa'
        },
        threshold: null,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, '#cae2f5'],
            [1, '#eaf6fb']
          ]
        }
      }
    ]
  }
}

export const overviewOfConversionsPerformance = () => {
  return {
    credits: {
      enabled: false
    },

    tooltip: {
      headerFormat: '<div class="engagement_tooltip_for_chart">',
      pointFormat: '<p class="engagement_point"><span>{point.x:%e %b}</span></p><h4>{point.y} {series.name}</h4>',
      footerFormat:
        '</div>',
      backgroundColor:
        null,
      shadow:
        false,
      useHTML:
        true,
      borderWidth:
        0
    },

    chart: {
      type: 'areaspline',
      spacingBottom:
        10,
      spacingTop:
        10,
      spacingLeft:
        10,
      spacingRight:
        10,
      width:
        null,
      height:
        null,
      style:
        {
          fontFamily: '\'Source Sans Pro\', sans-serif'
        }
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats:
        {
          month: '%e. %b',
          year:
            '%b'
        },
      title: {
        text: ''
      },
      labels: {
        style: {
          color: '#2e3c54'
        }
      }
    },
    yAxis: {
      labels: {
        style: {
          color: '#62747d'
        }
      },
      gridLineColor: '#f8f8f8',
      title:
        {
          text: ''
        },
      min: 0
    },
    plotOptions: {
      series: {
        lineWidth: 5,
        lineColor: '#3297fe',
        fillOpacity: 0.1,
        marker: {
          radius: 4,
          enabled: true,
          fillColor: '#1788fc',
          states: {
            hover: {
              enabled: true
            }
          }

        }
      }

    },
    /* plotOptions: {
      spline: {
        marker: {
          enabled: true,
          states:
            {
              hover: {
                enabled: true
              }
            },
          radius: 5,
          symbol:
            'square'
        }
      }
    }, */
    series: [
      {
        name: 'Conversions',
        data: [],
        color: '#3297fe',
        shadow: {
          width: 4,
          color: '#ddedf7'
        },
        threshold: null,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, '#cae2f5'],
            [1, '#eaf6fb']
          ]
        }
      }
    ]
  }
}

export const pieVisitedFromChart = () => {
  return {
    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<div class="engagement_tooltip_for_chart">',
      pointFormat: '<h4>{series.name}</h4><p class="engagement_point"><span>{point.name}: <strong>{point.y}</strong></span></p>',
      footerFormat: '</div>',
      backgroundColor: null,
      shadow: false,
      useHTML: true,
      borderWidth: 0
    },
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45
      }
    },
    title: {
      text: ''
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
        colors: [
          '#60a0fd',
          '#45d4ff',
          '#45ffcd',
          '#daecf7'
          // '#ffa59e',
          // '#bfcfe1'
        ]
      }
    },
    series: []
  }
}

export const pieVisitedCountriesChart = () => {
  return {
    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<div class="engagement_tooltip_for_chart">',
      pointFormat: '<h4>{series.name}</h4><p class="engagement_point"><span>{point.name}: <strong>{point.y}</strong></span></p>',
      footerFormat: '</div>',
      backgroundColor: null,
      shadow: false,
      useHTML: true,
      borderWidth: 0
    },

    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45
      }
    },
    title: {
      text: ''
    },

    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
        colors: [

          '#60a0fd',
          '#45d4ff',
          '#45ffcd',
          '#daecf7',
          '#f6dbaa',
          '#bfcfe1'

        ]
      }
    },
    series: []
  }
}

export const ANALYTIC_TYPE = {
  LINK: 'links',
  BIO_LINK: 'bio_links'
}

// traffic routing rules filters list
export const trafficRoutingRulesFilterList = [
  {
    key: 'country',
    type: 'Country',
    icon: 'far fa-flag',
    fetch: true
  },
  {
    key: 'device',
    type: 'Device',
    icon: 'fas fa-tablet-alt',
    fetch: true
  },
  {
    key: 'browser',
    type: 'Browser',
    icon: 'fab fa-firefox-browser',
    fetch: true
  },
  {
    key: 'operating_system',
    type: 'OS',
    icon: 'fab fa-windows',
    fetch: true
  },
  {
    key: 'ip',
    type: 'IP',
    icon: 'fas fa-map-marker',
    fetch: false
  },
  {
    key: 'date',
    type: 'Date',
    icon: 'fas fa-calendar-day',
    fetch: false
  },
  {
    key: 'day_of_week',
    type: 'Day of Week',
    icon: 'fas fa-calendar-week',
    fetch: false
  }
]

export const dayOfWeekObject = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
}

export const dayOfWeekList = Object.keys(dayOfWeekObject)
  .map(key => {
    return {
      name: dayOfWeekObject[key],
      isSelected: false,
      icon: '',
      type: 'day_of_week',
      key
    }
  })

export const PLAN_TYPE = {
  LIFETIME: 'lifetime',
  RECURRING: 'recurring',
  TRIAL: 'trial'
}

export const YEARLY_PLANS = ['Agency Annually', 'Replug Growth', 'Basic Annually', 'Professional Annually']

export const NEW_LTD_PRIME_PLANS = ['basic-prime-lifetime-2024', 'professional-prime-lifetime-2024', 'agency-prime-lifetime-2024', 'agency-unlimited-10-brands-Prime-Lifetime-2024', 'agency-unlimited-20-brands-Prime-Lifetime-2024', 'agency-unlimited-50-brands-Prime-Lifetime-2024']

export const OLD_LTD_SUBSCRIPTIONS = [
  'contentstudio-replug',
  'replug-pro-lifetime',
  'replug-agency-lifetime',
  'bruce-basic-lifetime',
  'bruce-agency-lifetime',
  'bruce-agency-lifetime-with-20-brands',
  'bruce-agency-lifetime-with-50-brands',
  'bruce-agency-lifetime-with-100-brands',
  'bruce-agency-lifetime-with-unlimited-brands'
];

export const csvExportSteps = {
  SELECT_FILE: 'SELECT_FILE',
  MAP_ATTRIBUTES: 'MAP_ATTRIBUTES',
  REVIEW: 'REVIEW'
}

export const colorsList = [
  '#f8f9fa', '#f1f3f5', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd', '#868e96', '#495057', '#343a40', '#212529', '#fff5f5', '#ffe3e3', '#ffc9c9', '#ffa8a8', '#ff8787', '#ff6b6b', '#fa5252', '#f03e3e', '#e03131', '#c92a2a', '#fff0f6', '#ffdeeb', '#fcc2d7', '#faa2c1', '#f783ac', '#f06595', '#e64980', '#d6336c', '#c2255c', '#a61e4d', '#f8f0fc', '#f3d9fa', '#eebefa', '#e599f7', '#da77f2', '#cc5de8', '#be4bdb', '#ae3ec9', '#9c36b5', '#862e9c', '#f3f0ff', '#e5dbff', '#d0bfff', '#b197fc', '#9775fa', '#845ef7', '#7950f2', '#7048e8', '#6741d9', '#5f3dc4', '#edf2ff', '#dbe4ff', '#bac8ff', '#91a7ff', '#748ffc', '#5c7cfa', '#4c6ef5', '#4263eb', '#3b5bdb', '#364fc7', '#e7f5ff', '#d0ebff', '#a5d8ff', '#74c0fc', '#4dabf7', '#339af0', '#228be6', '#1c7ed6', '#1971c2', '#1864ab', '#e3fafc', '#c5f6fa', '#99e9f2', '#66d9e8', '#3bc9db', '#22b8cf', '#15aabf', '#1098ad', '#0c8599', '#0b7285', '#e6fcf5', '#c3fae8', '#96f2d7', '#63e6be', '#38d9a9', '#20c997', '#12b886', '#0ca678', '#099268', '#087f5b', '#ebfbee', '#d3f9d8', '#b2f2bb', '#8ce99a', '#69db7c', '#51cf66', '#40c057', '#37b24d', '#2f9e44', '#2b8a3e',
  '#f4fce3', '#e9fac8', '#d8f5a2', '#c0eb75', '#a9e34b', '#94d82d', '#82c91e', '#74b816', '#66a80f', '#5c940d', '#fff9db', '#fff3bf', '#ffec99', '#ffe066', '#ffd43b', '#fcc419', '#fab005', '#f59f00', '#f08c00', '#e67700', '#fff4e6', '#ffe8cc', '#ffd8a8', '#ffc078', '#ffa94d', '#ff922b', '#fd7e14', '#f76707', '#e8590c', '#d9480f'
]
