<template>

  <div class="setpassword">

    <div class="setpassowrd_inner container">

      <div class="top_content">

        <p v-if="loader">Verifying your email address.

          <clip-loader :size="size" :color="loaderColor"
                       style="height:14px;margin-right: 10px;"></clip-loader>
        </p>

        <p v-if="error" class="text-danger">{{error}}</p>

        <div v-if="resendEmail" class="input_field col-md-12 txt-center">
          <button class="btn---cta btn-blue btn-round "
                  @click.prevent="resendVerificationEmail()">
            <span>Resend Verification Email</span>
          </button>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { verifyEmailTokenURL } from '@/config/config'
import http from '@/mixins/http-lib'

export default (
  {
    data () {
      return {
        loader: true,
        error: false,
        resendEmail: false,
        size: '14px',
        loaderColor: '#168eea'
      }
    },
    created () {
      this.verifyEmailToken()
    },
    methods: {
      verifyEmailToken () {
        http.post(verifyEmailTokenURL, {
          token: this.$route.params.token
        }).then(
          response => {
            if (response.data.status) {
              this.$router.push({ 'name': 'dashboard' })
            } else {
              this.error = "Your token has been expired, please use the valid confirmation link."
              this.loader = false
              this.resendEmail = true
            }
          },
          () => {
            // this case is not practically possible because the auth guards will navigate to login screen initially
            this.error = "You need to be logged in to verify your email address."
            this.loader = false
          }
        )
      }

    }

  }
)
</script>
