<template>
  <div class="authentication-component">
    <login-left></login-left>
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner">
        <div class="header_content clear">
          <div class="logo_block">
            <a class="active">
              <img src="/assets/img/replug_logo_black_text.png" alt="replug">
            </a>
          </div>
        </div>

        <div class="alert-box">
          <div style="border-radius: 10px;" class="inner_box border-0 p-3">
            <div class=" text-left mb-3" style="font-size: 16px;">
              This <strong style="text-transform: capitalize;">{{ provider }}</strong> Profile is currently managed by
              the following {{ getSiteDetails.agency_name }} Account:
            </div>


            <div class="profile_block">
              <div class="p_img" :style="{ 'background': 'url(' + backgroundImage(user) + ')' }"></div>
              <!--<div v-else class="p_img" style="background: url('/img/profile-default.png');"></div>-->
              <div class="name" v-if="user.last_name">{{ user.first_name }} {{ user.last_name }}</div>
              <div class="name" v-else>{{ user.first_name }}</div>
            </div>
          </div>
        </div>
        <div class="white_box  ">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center justify-content-center">
              <div class="left">
                <h2>Login to account</h2>
              </div>
            </div>
            <div class="box_content">

              <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                <div class="avatar">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <div class="url">Email address</div>
                  <div class="name">
                    <div class="validation_input">
                      <input class=" " id="username" type="text" autocomplete="false" disabled="disabled"
                             v-model="user.email" placeholder="Enter your email address...">

                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 text-right">
                <router-link style="color: #3988fe;" :to="{path: 'forgot_password'}">
                  Forgot your password?
                </router-link>
              </div>
              <div class="mt-2 input_icon_text d-flex align-items-center profile__box">

                <div class="avatar">
                  <i class="fal fa-lock"></i>
                </div>
                <div class="content">
                  <div class="url">Password</div>
                  <div class="name">
                    <div class="validation_input">
                      <input class="form-control" id="password" type="password" v-model="password"
                             placeholder="Enter your password...">

                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4 btn_block txt-center">

                <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                        @click.prevent="mergeAccount()">
                  <span>Sign in</span>
                  <clip-loader v-if="mergeLoader" :color="color" :size="size"></clip-loader>
                </button>

              </div>


              <div class="bottom_link mt-3 text-center">

                Have another account?

                <router-link :to="{ path: 'login' }"
                             class="login_lin ">
                  Sign in here
                </router-link>


              </div>


            </div>


          </div>
        </div>
      </div>

    </div>

  </div>
  <!--<div class="authentication_latest">-->
  <!--<login-left></login-left>-->

  <!--<div class="sub_header_section">-->
  <!--<div class="container">-->
  <!--<div class="header_content clear">-->
  <!--<div class="logo_block" style="margin-bottom: 0;">-->
  <!--<a class="active">-->
  <!--<img src="/img/replug_logo.png" alt="replug">-->
  <!--</a>-->
  <!--</div>-->
  <!--&lt;!&ndash;<h1>Forgot your password?</h1>&ndash;&gt;-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="section contact_section  inner_shapes_section">-->
  <!--<div class="shapes_block">-->
  <!--<img class="shape1" src="img/login_shapes/Path6.svg" alt="">-->
  <!--<img class="shape2" src="img/login_shapes/Path7.svg" alt="">-->
  <!--<img class="shape3" src="img/login_shapes/Path8.svg" alt="">-->

  <!--</div>-->
  <!--<div class="container">-->
  <!--<div class="contact_form">-->

  <!--<div class="login_block">-->

  <!--<div class="auth_top_new col-md-12" >-->

  <!--<div style="margin-bottom: 20;" class="alert alert-warning info_msg full_width">-->
  <!--<strong><i aria-hidden="true" class="far fa-info"></i></strong>-->
  <!--This <strong style="text-transform: capitalize;">{{provider}}</strong> Profile is currently managed by-->
  <!--the following Replug Account:</div>-->

  <!--<div class="profile_block">-->
  <!--<div class="p_img" :style="{ 'background': 'url(' + backgroundImage(user) + ')' }"></div>-->
  <!--&lt;!&ndash;<div v-else class="p_img" style="background: url('/img/profile-default.png');"></div>&ndash;&gt;-->
  <!--<div class="name" v-if="user.last_name">{{user.first_name}} {{user.last_name}}</div>-->
  <!--<div class="name" v-else>{{user.first_name}}</div>-->
  <!--</div>-->

  <!--</div>-->

  <!--<form  action="javascript:;" class="basic_form clear" >-->

  <!--<div class="inner_form clear">-->
  <!--<div class="input_field col-md-12">-->

  <!--<label for="">Email address</label>-->

  <!--<input class=" " id="username" type="text"  autocomplete="false" disabled="disabled" v-model="user.email" placeholder="Enter your email address...">-->

  <!--</div>-->

  <!--<div class="input_field col-md-12">-->
  <!--<label for="" class="full-width-label">-->
  <!--Password-->
  <!--<router-link :to="{path: 'forgot_password'}"-->
  <!--class="input_links pull-right">-->
  <!--Forgot your password?-->
  <!--</router-link>-->

  <!--</label>-->

  <!--<input class="form-control" id="password" type="password"  v-model="password" placeholder="Enter your password...">-->

  <!--</div>-->
  <!--</div>-->

  <!--<div class="input_field col-md-12 txt-center" >-->

  <!--<a href="javascript:;" v-if="mergeLoader" class="btn&#45;&#45;cta txt-center">-->

  <!--<clip-loader    style="line-height: 0;" class="inline_loader right inline_middle"-->
  <!--:color="color" :size="size"></clip-loader>-->
  <!--<span class="inline_middle">Sign in</span>-->

  <!--</a>-->
  <!--<a v-else href="javascript:;" v-else  @click.prevent="mergeAccount()"-->
  <!--class="btn&#45;&#45;cta txt-center ">-->
  <!--<span class="inline_middle">Sign in</span>-->

  <!--</a>-->
  <!--</div>-->

  <!--<div class="col-md-12 text-center">-->
  <!--<p class="link_text">Have another account?-->
  <!--<router-link :to="{ path: 'login' }"-->
  <!--class="txt-center  btn_link">-->
  <!--Sign in here-->
  <!--</router-link>-->
  <!--</p>-->
  <!--</div>-->

  <!--</form>-->
  <!--</div>-->
  <!--</div>-->

  <!--</div>-->
  <!--</div>-->

  <!--<login-footer></login-footer>-->
  <!--</div>-->

</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import LoginLeft from './LoginLeft.vue'
import LoginFooter from './LoginFooter.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import {
  getLinkAccountValuesURL,
  checkPasswordURL
}
  from '../../../config/urls/user/user'

export default (
  {
    data() {
      return {

        password: '',
        user: [],
        size: '14px',
        loaderColor: '#ffffff',
        provider: null,
        loader: false,
        id: null,
        mergeLoader: false

      }
    },
    created() {
      this.getUserData()
    },
    components: {
      LoginLeft,
      ClipLoader,
      LoginFooter
    },

    computed: {
      ...mapGetters([])
    },

    methods: {

      ...mapActions([]),

      getUserData() {
        this.loader = true
        // We are passing account_id and provider from route query params
        this.$http.post(getLinkAccountValuesURL, this.$route.query).then(
          response => {
            if (this.processAjaxResponse(response)) {
              if (response.data.status == false) {
                this.$router.push({name: 'login'})
              } else {
                this.provider = response.data.provider
                this.user = response.data.user
                this.id = response.data.user._id
              }
            }
            this.loader = false
          },
          response => {
            console.debug('Exception in getUserData', response)
            this.loader = false
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      },
      mergeAccount() {
        console.debug('Method:mergeAccount')

        if (this.password == '') {
          this.alertMessage('Please enter your password.', 'error')
        } else {
          this.mergeLoader = true
          this.$http.post(checkPasswordURL, {
            password: this.password,
            id: this.id
          }).then(
            response => {
              if (this.processAjaxResponse(response)) {
                if (response.data.status == false) {
                  this.alertMessage(response.data.message, 'error')
                } else {
                  this.alertMessage('Your account has been successfully linked to your profile. Now you can use both methods to login.', 'success')
                  this.$router.push({
                    name: 'dashboard'
                  })
                }

                this.mergeLoader = false
              }
            },
            response => {
              console.debug('Exception in mergeAccount', response)
              this.mergeLoader = false
              this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
            })
        }
      }

    }
  }
)
</script>
