import {notesTypes} from "../state/modules/mutation-types";

export default ({
  methods: {
    getStateVariation(state) {
      switch (state) {
        case 'active':
          return 'variation-state variation-success';
        case 'completed':
          return 'variation-state variation-success';
        case 'trial_finish':
          return 'variation-state variation-warning';
        case 'cancelled':
          return 'variation-state variation-warning';
        case 'canceled':
          return 'variation-state variation-warning';
        case 'past_due':
          return 'variation-state variation-warning';
        case 'disabled':
          return 'variation-state variation-danger';
        case 'deleted':
          return 'variation-state variation-danger';
        case 'refunded':
          return 'variation-state variation-danger';
      }
    },
    getBillingStateVariation(state){
      switch (state) {
        case 'completed':
          return 'variation-state variation-success';
        case 'past_due':
          return 'variation-state variation-warning';
        case 'refunded':
          return 'variation-state variation-danger';
        case 'deleted':
          return 'variation-state variation-danger';
      }
    },
    /**
     * Storing note from a shared method. This will be used in different places where we are giving an option to use
     * the note option.
     * @param note
     */
    storeNoteFromPromise(note){
      if(note && note.length > 1){
        this.$store.commit(notesTypes.RESET_STORE_NOTE)
        this.$store.commit(notesTypes.SET_STORE_NOTE_TEXT, note)
        this.$store.dispatch('storeNoteAction', {
          user_id: this.$route.params.user_id,
          ...this.$store.getters.getStoreNote
        })
      }
    },
    getLogClassState(event){
      switch (event) {
        case 'DEBUG':
          return 'tr-state-debug'
        case 'INFO':
          return 'tr-state-info'
        case 'ERROR':
          return 'tr-state-error'
        case 'CRITICAL':
          return 'tr-state-critical'
      }

    }
  }
})
