import Velocity from 'velocity-animate'
import { mapActions } from 'vuex'

let utilsMixin = {
  created () {
  },
  methods: {

    ...mapActions(['setUnauthorizedException']),

    alertMessage (message, type) {
      let i = ''
      let className = ''

      if (type === 'success') {
        className = 'success'
        i = '<i class="far fa-check"></i>'
      } else if (type === 'warn') {
        className = 'warn'
        i = '<i class="far fa-exclamation-circle"></i>'
      } else if (type === 'info') {
        className = 'info'
        i = '<i class="far fa-info-circle"></i>'
      } else if (type === 'error') {
        className = 'error'
        i = '<i class="fal fa-times"></i>'
      } else {
        className = 'success'
        i = '<i class="far fa-check"></i>'
      }
      message = `
                   <div class="toastr_inner ${className}">
<!--                     <div class="close_icon">-->
<!--                        <img src="/assets/img/cancel.png" alt="">-->
<!--                      </div>-->
                       <div class="icon_sec">
                          ${i}
                       </div>
                       <div class="content_sec">
                           <p>
                                ${message}
                           </p>
                       </div>
                       <div class="close_icon">
                            <img src="/assets/img/cancel.png" alt="">
                       </div>
                   </div>

                          `

      let toast = this.$toasted.show(message, {
        theme: 'primary',
        position: 'top-right',
        duration: 5000
      })
      let toast_item = toast.el
      let close_icon = toast.el.firstElementChild.firstElementChild

      close_icon.addEventListener('click', (e) => {
        Velocity(toast_item, { 'opacity': 0, marginTop: '-40px' }, {
          duration: 375,
          easing: 'easeOutExpo',
          queue: false,
          complete: function () {
            toast.el.remove()
          }
        })
      })
      // this.notify({ message: message, title: title, type: type });
    },

    processAjaxResponse (response) {
      console.debug('Method:processAjaxResponse')
      if (response.data.tokenError && response.data.tokenError == true) {
        this.setUnauthorizedException(true)
        return false
      }
      return true
    }

  }
}

export { utilsMixin }
