import {mapActions, mapGetters} from 'vuex'
import vueCookie from 'vue-cookie'
import store from '@/store'
import {authenticationTypes, userTypes} from '@/state/modules/mutation-types'
import {JWT_LOCAL_STORAGE, JWT_CHROME_LOCAL_STORAGE, refreshCSRF, cookieAppDomain, cookieDomain} from '@/constants'

const appMixin = {
  mounted () {
    // $(document).on('hide.bs.modal', '.modal', function (e) {
    //   setTimeout(function () {
    //     $('.modalAddTeam.modal-footer-sticky').removeClass('show')
    //   }, 100)
    // })
    //
    // this.showTeamModelFotter()
  },
  computed: {
    ...mapGetters(['getSubscription', 'getWhitelabel', 'getWhiteLabelLoader']),
  },
  methods: {
    ...mapActions(['setWhitelabelLoader']),
    isAgency (self = this) {
      return self.getSubscription && self.getSubscription.display_name && (self.getSubscription.display_name.toLowerCase()).includes('agency')
    },
    refreshCsrf () {
      console.debug('Method:refreshCsrf')

      this.$http.post(refreshCSRF).then(
        response => {
          try {
            $('[name="csrf-token"]').attr('content', response.data.token)
            window.Laravel = response.data.json_data
          } catch (m) {
            console.debug(m)
          }
        },
        response => {

        })
    },
    async loadRequiredData () {
      if (this.getSubscription.paused) {
        this.$router.push({name: 'billing'})
        return true
      }
      return false
    },
    /**
     * Responsible for setting site cookies
     */
    setSiteCookies (token) {
      window.localStorage.setItem(JWT_LOCAL_STORAGE, token)
      window.localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, token)
      vueCookie.set(JWT_LOCAL_STORAGE, token, {expires: '1Y', domain: cookieDomain})
      vueCookie.set(JWT_LOCAL_STORAGE, token, {expires: '1Y', domain: cookieAppDomain})
      vueCookie.set(JWT_CHROME_LOCAL_STORAGE, token, {expires: '1Y', domain: cookieDomain})
      vueCookie.set(JWT_CHROME_LOCAL_STORAGE, token, {expires: '1Y', domain: cookieAppDomain})
      store.commit(authenticationTypes.SET_JWT_TOKEN, token)
      store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
    },

    // showTeamModelFotter (close) {
    //   console.debug('showTeamModelFotter')
    //   $('.modalAddTeam').off('scroll')
    //   if (close) {
    //     $('.modalAddTeam.modal-footer-sticky').removeClass('show')
    //     console.debug('---------------- Hide 2-------------')
    //   } else {
    //     $('.modalAddTeam').on('scroll', function () {
    //       let getWidth = $(this).find('.modal-dialog').outerWidth()
    //
    //       if (!($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - 100)) {
    //         console.debug('---------------- show-------------')
    //         $('.modalAddTeam.modal-footer-sticky').addClass('show').css({
    //           'width': getWidth
    //         })
    //       } else {
    //         $('.modalAddTeam.modal-footer-sticky').removeClass('show')
    //         console.debug('---------------- Hide-------------')
    //       }
    //     })
    //   }
    // }

  }
}
export {appMixin}
