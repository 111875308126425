import { authenticationTypes, userTypes } from './mutation-types'
import { JWT_CHROME_LOCAL_STORAGE } from '../../constants'
import requests from '@/mixins/http-lib'
import { getProfileURL, updateEmail } from '../../config/config'

export default ({
  state: {
    profile: {},
    is_authenticated: false,
    has_loaded_once: false,
    is_authenticating: false
  },
  getters: {
    isAuthenticated: state => state.is_authenticated,
    getLoadedOnce: state => state.has_loaded_once,
    isAuthenticating: state => state.is_authenticating
  },
  actions: {
    authenticate ({ commit }) {
      if (this.state.is_authenticating) {
        return
      }
      commit(authenticationTypes.SET_AUTHENTICATING, true)
      return requests.post(getProfileURL).then(res => {
        commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
        commit(userTypes.SET_USER_PROFILE, res.data.user)
      },
      err => {
        console.log(err)
      }
      ).finally(() => {
        // this needs to be done in any case
        commit(authenticationTypes.SET_AUTHENTICATING, false)
        commit(authenticationTypes.SET_LOADED_ONCE, true)
      })
    },
    async updateEmail ({ commit, getters, dispatch }, data) {
      return await requests.post(updateEmail, data)
    }
  },
  mutations: {
    [authenticationTypes.SET_JWT_TOKEN] (state, value) {
      localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, value)
    },
    [authenticationTypes.SET_LOADED_ONCE] (state, value) {
      state.has_loaded_once = value
    },
    [authenticationTypes.SET_IS_AUTHENTICATED] (state, value) {
      state.is_authenticated = value
    },
    [authenticationTypes.SET_AUTHENTICATING] (state, value) {
      state.is_authenticating = value
    }
  }
})
