import { baseURL, baseURLv3 } from '@/config/config'

// export const API_URL = 'http://localhost:8000/api/'
// export const API_URL_v3 = 'http://localhost:8000/api/v3/'
export const API_URL_v2 = baseURLv3
export const API_URL_v3 = baseURL
export const API_URL = baseURL
export const APP_URL = process.env.VUE_APP_URL
export const JWT_LOCAL_STORAGE = 'rplg_logged_jwt_token'
export const JWT_CHROME_LOCAL_STORAGE = 'rplg_chrome_logged_jwt_token'
export const AWEBER_KEY = 'aweber_key'
export const cookieDomain = '.replug.io'
export const cookieAppDomain = 'app.replug.io'
export const refreshCSRF = APP_URL + 'refreshCSRF'
export const INTEGRATION_COOKIE = 'integration'

export const getDefaultConfirmationAttributes = () => {
  return {
    title: 'Please Confirm',
    size: 'md',
    buttonSize: 'md',
    okVariant: 'danger',
    okTitle: 'YES',
    cancelTitle: 'NO',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true
  }
}

export const getDefaultStoreUser = () => {
  return {
    _id: null,
    firstname: null,
    lastname: null,
    email: null,
    username: null,
    subscription_id: null
  }
}

export const getDefaultBlacklistUrl = () => {
  return {
    url: null
  }
}

export const getDefaultReportAbuse = () => {
  return {
    _id: null,
    url: null,
    shorten_url: null
  }
}
