<template>

    <div class="setpassword">

        <div class="setpassowrd_inner container">

            <div class="center_notification_box">
                <template v-if="loader">
                    <!--Verifying..........-->
                    <h3>Verifying Change Email...</h3>
                    <clip-loader :size="size" :color="loaderColor" style="height:14px;margin-right: 10px;"></clip-loader>

                    <!--after verified ......-->

                </template>
                <template v-if="status">
                    <h3><i class="fa far fa-check-circle green"></i> Email Change Complete!</h3>
                    <p >Your email has been changed, and you have been logged into your account.
                        You will be redirect back to site in 5 seconds.
                    </p>
                </template>
                <template v-if="error">
                    <p >{{error_text}}</p>
                </template>

            </div>

        </div>

    </div>

</template>

<script>

import { verifyChangeEmailTokenURL } from '../../../config/urls/user/user.js'

export default (
  {
    data () {
      return {
        loader: true,
        error: false,
        error_text: '',
        size: '14px',
        loaderColor: '#168eea',
        status: false
      }
    },
    created () {
      let stateObject = this
      setTimeout(function () {
        stateObject.verifyEmailToken()
      }, 5000)
    },
    methods: {
      verifyEmailToken () {
        this.$http.post(verifyChangeEmailTokenURL, {
          token: this.$route.params.token
        }).then(
          response => {
            if (response.data.status) {
              this.loader = false
              this.status = true
              let stateObject = this
              setTimeout(function () {
                stateObject.$router.push({ 'name': 'dashboard' })
              }, 5000)
            } else {
              this.error_text = response.data.message
              this.error = true
              this.loader = false
            }
          },
          response => {

          }
        )
      }

    }

  }
)
</script>
