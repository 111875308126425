import { mapActions, mapGetters, mapMutations } from 'vuex'

import { fetchLinkPreviewURL } from '@/config/urls/link/link'

import axios from 'axios'
import { campaignTypes, linkTypes } from '@/state/modules/mutation-types'
import http from '@/mixins/http-lib'
import {csvExportSteps} from '@/common/constants'

window._ = require('lodash')

const LinksMixin = {
  computed: {

    ...mapGetters([
      'getTagList',
      'getLinks', 'getSaveLink', 'getQuickLink', 'getModalData', 'getArchiveStatus', 'getAnalyticsDetail'

    ])

  },

  methods: {

    ...mapActions([
      'storeTag',
      'setBrandsFetchStatus', 'setCallToActionsFetchStatus', 'setOnboarding', 'setLinksFetchStatus',
      'setLinksSort',
      'setQuickLinkEditSlug',
      'setModalStatus', 'setArchiveStatus', 'setModalData'

    ]),
    ...mapMutations([
      linkTypes.SET_QR_CODE_URL,
      linkTypes.SET_LINK_NOTE
    ]),
    async paginateLinks (page) {
      console.debug('Method::paginateLinks')
      await this.$store.dispatch('fetchLinks', page)
      this.$store.dispatch('fetchLinkAnalytics')
    },

    showBulkImportModal () {
      this.$parent.$refs.links_bulk_import.validations = {
        campaign: false,
        links: false
      }
      this.$store.commit(linkTypes.SET_BULK_LINK_DEFAULT_VALUES)
      this.$store.commit(linkTypes.SET_LINKS_DEFAULT_VALUE)
      this.$bvModal.show('bulk-import')
    },

    showCSVImportModal () {
      this.$parent.$refs.links_bulk_import.validations = {
        campaign: false,
        links: false
      }
      this.$store.commit(linkTypes.SET_BULK_LINK_DEFAULT_VALUES)
      this.$store.commit(linkTypes.SET_LINKS_DEFAULT_VALUE)
      this.$bvModal.show('csv-import')
    },

    allowBulkLimits () {
      this.bulkLinkUpload(true)
    },

    bulkLinkProcessingComplete (data) {
      console.debug('Method:bulkLinkProcessingComplete', data)
      if (data.status) {
        if (this.$route.name === 'links') {
          // this.fetchOldLinks()
          this.setLinksFetchStatus(true)
        } else {
          this.setLinksFetchStatus(false)
        }
        this.alertMessage(data.message, 'success')
      } else {
        this.alertMessage(data.message, 'error')
      }
      // let stateObject = this;
      // $(this.getRss.rssData).each(function(index, el) {
      //     if (el._id === data.rss_id) {
      //         stateObject.getRss.rssData[index].fetch_status = true;
      //         return false;
      //     }
      //
      // });
    },

    createNewLink (url = null) {
      this.$store.commit(linkTypes.SET_LINKS_NEW_CREATED, false)
      this.$store.commit(linkTypes.SET_LINKS_DEFAULT_VALUE)
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, '')
      this.$store.commit(campaignTypes.RESET_CAMPAIGN_ADD)
      this.getSaveLink.url = url || ''
      this.getSaveLink.is_deep_link_enabled = this.isFeatureAllowedInPlan('deep_linking') && !!url
      this.getSaveLink.is_deep_link_allowed = !!url
      this.getSaveLink.showSeo = false
      this.getSaveLink.seo = {
        title: null,
        description: null,
        image: null
      }
      this.getSaveLink.showFavicon = false
      this.getSaveLink.showLinkExpiry = false
      this.getSaveLink.link_expire_attributes = {
        expiry: null,
        expiry_time: '00:00:00',
        expiry_datetime: null,
        link_expiry_logo: null
      }
      this.$router.push({ name: 'link' })
    },
    customMetaImageUrl () {
      if (!this.isValidURL(this.getSaveLink.seo.image)) {
        // this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
        // return;
      }
    },
    linkPreview () {
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_STATUS, false)
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, true)
      const filters = this.getLinks
      const CancelToken = axios.CancelToken
      for (let i = 0; i < filters.cancelRequest.length; i++) {
        const remove = filters.cancelRequest[i]
        remove()
      }

      if (!this.getSaveLink.url) {
        this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
        return
      }
      if (!this.isValidURL(this.getSaveLink.url)) {
        this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
        return
      }

      let cancel
      http.post(fetchLinkPreviewURL, {
        url: this.getSaveLink.url,
        cta: this.getSaveLink.call_to_action_id
      }, {
        cancelToken: new CancelToken(function executor (c) {
          cancel = c
        })
      }).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_STATUS, true)
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, response.data.url)
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, '')
            } else {
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, 'notAllowed')
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_STATUS, false)
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
              this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
            }
          }
          this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
        })
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_REQUESTS, filters.cancelRequest.concat(cancel))
    },
    async checkLinkPreview (url, campaign) {
      return http.post(fetchLinkPreviewURL, {
        url: url,
        cta: campaign
      }).then(
        response => {
          return response
        })
    },
    blurFocusTag (index = null) {
      const self = this
      setTimeout(function () {
        if (index) {
          self.getLinks.items[index].focus = false
        } else {
          self.focus = false
        }
      }, 200)
    },
    addTagSuggestion (id, index) {
      const linkTags = this.getSaveLink.tags
      if (linkTags.indexOf(id) < 0) {
        this.$store.commit(linkTypes.SET_LINK_TAGS, linkTags.concat(id))
        this.$store.commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
      }
    },

    removeTag (tag) {
      const tagsList = this.getSaveLink.tags
      const index = tagsList.indexOf(tag)
      if (index >= 0) {
        tagsList.splice(index, 1)
      }
    },

    closePage () {
      this.$router.push(
        {
          name: 'links'
        }
      )
    },

    changeLinkSort (type, sort) {
      console.debug('Method::changeLinkSort')
      if (type == 'click') {
        if (sort == 'click_desc') {
          this.setLinksSort('click_asc')
        } else {
          this.setLinksSort('click_desc')
        }
      } else if (type == 'conv') {
        if (sort == 'conv_desc') {
          this.setLinksSort('conv_asc')
        } else {
          this.setLinksSort('conv_desc')
        }
      } else if (type == 'conv_rate') {
        if (sort == 'conv_rate_desc') {
          this.setLinksSort('conv_rate_asc')
        } else {
          this.setLinksSort('conv_rate_desc')
        }
      } else if (type == 'avg_rate') {
        if (sort == 'avg_desc') {
          this.setLinksSort('avg_asc')
        } else {
          this.setLinksSort('avg_desc')
        }
      } else if (type == 'unique') {
        if (sort == 'unique_desc') {
          this.setLinksSort('unique_asc')
        } else {
          this.setLinksSort('unique_desc')
        }
      }
      this.paginateLinks(1)
    },
    /**
     * this method will validate deep links
     * @param url
     */
    checkDeepLink (url, deepLinksBrandsList, self = this) {
      const isDeepLink = !!deepLinksBrandsList.find(item => url.match(item.regex))
      self.getSaveLink.is_deep_link_allowed = isDeepLink
      self.getQuickLink.is_deep_link_allowed = isDeepLink
    },
    isPasswordProtectionValid (passwordProtectedValidations, password_protected, password, password_protected_logo) {
      if (!password_protected) return
      passwordProtectedValidations.password = this.requiredCheck(password)
      passwordProtectedValidations.passwordValid = password && (password.length < 6 || password.length > 12)
      if (this.isAgency()) {
        passwordProtectedValidations.url = this.requiredCheck(password_protected_logo)
        passwordProtectedValidations.urlLength = !this.maxLength(password_protected_logo, 500)
        passwordProtectedValidations.urlValid = !this.isValidURL(password_protected_logo)
      }
      return Object.keys(passwordProtectedValidations).every(k => this.passwordProtectedValidations[k] === false)
    },
    isCustomizeMessageValid (customizeMessageValidations, message_headline, message) {
      customizeMessageValidations.message_headline = this.requiredCheck(message_headline)
      customizeMessageValidations.message_headline_length = !this.maxLength(message_headline, 250)

      customizeMessageValidations.message = this.requiredCheck(message)
      customizeMessageValidations.messageLength = !this.maxLength(message, 500)

      return Object.keys(customizeMessageValidations).every(k => this.customizeMessageValidations[k] === false)
    }
  }

}

export { LinksMixin }
