import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted)
export default ({
  actions: {
    toast({commit, getters}, payload) {
      // set the payload to success if no property defined.
      let type = (!payload.hasOwnProperty('type')) ? 'success' : payload.type
      let message = payload.message
      console.debug('notification detail message ==== ', message, type)
      let icon = ''
      switch (type) {
        case 'success':
          icon = '<img class="success_icon" src="' + require('@/assets/img/notification_icons/success_icon.svg') + '">'
          break;
        case 'warning':
          icon = '<img class="warn_icon" src="' + require('@/assets/img/notification_icons/warning_icon.svg') + '">'
          break;
        case 'info':
          icon = '<img class="info_icon" src="' + require('@/assets/img/notification_icons/info_icon.svg') + '">'
          break;
        case 'error':
          icon = '<img class="error_icon" src="' + require('@/assets/img/notification_icons/error_icon.svg') + '">'
          break;
        default:
          icon = '<img class="success_icon" src="' + require('@/assets/img/notification_icons/success_icon.svg') + '">'
          break;
      }
      message = `
                   <div class="d-flex align-items-center toaster-inner ${type}">

                       <div class="icon_sec">
                          ${icon}
                       </div>
                       <div class="content_sec">
                           <p>${message}</p>
                       </div>
                        <div class="close_icon">
                         <img  class="success_icon" src="${require('@/assets/img/notification_icons/cross_icon.svg')}">
                        </div>
                   </div>
                          `
      let toast = Vue.toasted.show(message, {
        theme: 'primary',
        position: (payload.position) ? payload.position : 'top-right'
      })
      toast.goAway(5000)
      let closeIcon = toast.el.firstElementChild.lastElementChild
      closeIcon.addEventListener('click', (e) => {
        toast.goAway(0)
      })
    }
  }
})
