import Login from '@/views/authentication/Login.vue'
import ForgotPassword from '@/views/users/auth/ForgotPassword.vue'
import Signup from '@/views/authentication/Signup.vue'
import RecoverPassword from '@/views/users/auth/RecoverPassword.vue'
import SetPassword from '@/views/users/auth/SetPassword.vue'
import LoginLinkAccount from '@/views/users/auth/LoginLinkAccount.vue'
import UpgradePackage from '@/views/pages/setting/UpgradePackage.vue'
import OauthAuthorize from '@/views/authentication/OauthAuthorize'
import TestOauthAuthorize from '../../views/authentication/TestOauthAuthorize.vue'
import UpdateEmail from '../../views/users/auth/UpdateEmail'
import store from "../../store";

/**
 * Route Guard: If user is not authenticated, we follow along with the route otherwise
 * we redirect to /
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const ifNotAuthenticated = async (to, from, next) => {
  const queryParams = new URLSearchParams(to.query).toString()
  // for the first time, send the request to verify that API call has been made to the server.
  if (!store.getters.isAuthenticating && !store.getters.getLoadedOnce) {
    await store.dispatch('authenticate')
  }
  // if user is being authenticated, wait with loader
  if (store.getters.isAuthenticating) {
    return
  }
  if (!store.getters.isAuthenticated) next()
  else next(`/?${queryParams}`)
}
const noIndexing = {
  name: 'robots',
  content: 'noindex'
}
export const authenticationRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `Login`
    },
    beforeEnter: ifNotAuthenticated
  },
  /**
   * signup name is referenced in http-lib.js
   * cross check before changing route name
   */
  {
    path: '/register',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'Register Your Account',
      metaTags: [
        {
          conditions: [
            {key: 'subscription_name', value: 'trial-agency'},
            {key: 'subscription_name', value: 'trial-personal'},
            {key: 'subscription_name', value: 'trial-professional'},
            {key: 'email', value: 'undefined'}
          ]
        }
      ]
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Your Password'
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    component: SetPassword,
    meta: {
      title: 'Set Your new Password'
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/loginLinkAccount',
    name: 'loginLinkAccount',
    component: LoginLinkAccount,
    meta: {
      title: 'Account already Exist'
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/recoverPassword/:token',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: {
      title: 'Recover Your Password'
    }
  },
  {
    path: '/oauth/authorize',
    name: 'oauth',
    component: OauthAuthorize,
    meta: {
      title: 'Replug Authorization'
    }
  },
  {
    path: '/test_oauth/authorize',
    name: 'test-oauth',
    component: TestOauthAuthorize,
    meta: {
      title: 'Replug Authorization'
    }
  },
  {
    // TODO: Which guard does it deserve
    path: '/update_email',
    name: 'update_email',
    component: UpdateEmail,
    meta: {
      title: 'Update Your Email'
    }
  },
  {
    path: '/upgradePackage',
    name: 'upgradePackage',
    component: UpgradePackage,
    meta: {
      title: 'Upgrade Package'
    }
  }
]
