import { utmTypes } from '@/state/modules/mutation-types'
import store from '../store'

/**
 * This will return the url without query parameters
 * @param url
 * @returns {*}
 */
function removeExistingUtmParameters (url) {
  let queryString = ''
  const splitUrl = url.split('?')

  if (splitUrl[1]) {
    url = splitUrl[0]

    const parameters = splitUrl[1].split('&')
    if (parameters) {
      parameters.forEach(function (parameter) {
        if (
          parameter.indexOf('utm_source') < 0 &&
          parameter.indexOf('utm_medium') < 0 &&
          parameter.indexOf('utm_campaign') < 0 &&
          parameter.indexOf('utm_term') < 0 &&
          parameter.indexOf('utm_content') < 0
        ) {
          if (queryString) {
            queryString += '&'
          }
          queryString += parameter
        }
      })
    }

    if (queryString) {
      url += '?' + queryString
    }
  }

  return url
}

/**
 *
 * @param payload: contains the utm details such as utm_content etc.
 * @param url string: on which we will be applying utl
 */
export function applyUTMParameters (payload, url, queryParametersList, prevId = null, newId = null) {
  const queryParamObj = parserQueryParamList(queryParametersList)
  let queryString = ''
  const queryParamNameList = ['medium', 'source', 'campaign', 'term', 'content']

  // Adding the utm parameters if a link have no parameters
  if (!queryParametersList) {
    queryString = queryParamNameList
      .map(el => 'utm_' + el + '=' + payload[el])
      .join('&')
      .replaceAll(' ', '%20')

    url += (url.match(/\?/) ? '&' : '?') + queryString
    store.commit(utmTypes.SET_UTM_PREVIOUS_ID, store.getters.getSelectedUTM._id)
    return url
  }

  // Already Selected UTM - get UTM Id as well - overwrite with new UTM
  if (queryParametersList && prevId) {
    url = removeExistingUtmParameters(url)
    if (newId) {
      queryString = queryParamNameList
        .map(el => 'utm_' + el + '=' + payload[el])
    } else {
      queryString = queryParamNameList
        .map(el => ('utm_' + el + '=' + (queryParamObj[el] ? queryParamObj[el] : payload[el])))
    }
    queryString = queryString.join('&')
      .replaceAll(' ', '%20')

    url += (url.match(/\?/) ? '&' : '?') + queryString
    store.commit(utmTypes.SET_UTM_PREVIOUS_ID, store.getters.getSelectedUTM._id)
    return url
  }

  // If a user added his own custom parameters
  if (queryParametersList && !prevId) {
    url = removeExistingUtmParameters(url)
    queryString = queryParamNameList
      .map(el => 'utm_' + el + '=' + (queryParamObj[el] && queryParamObj[el].length ? (queryParamObj[el] !== payload[el] ? queryParamObj[el] : payload[el]) : payload[el]))
      .join('&')
      .replaceAll(' ', '%20')

    url += (url.match(/\?/) ? '&' : '?') + queryString
    store.commit(utmTypes.SET_UTM_PREVIOUS_ID, '')
    return url
  }
}

export function getUTMParameters (url) {
  const splitUrl = url.split('?')
  if (splitUrl[1]) {
    return splitUrl[1].split('&')
  }
  return null
}

function parserQueryParamList (queryParamList) {
  const queryList = ['medium', 'source', 'campaign', 'term', 'content']
  let obj = {}
  queryList.forEach(item => {
    let data = queryParamList && queryParamList.find(query => query.includes(item))
    if (data) {
      data = data.split('=')
    }
    obj[item] = data && data[1] ? data[1] : ''
  })
  return obj
}
