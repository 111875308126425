import { mapGetters } from 'vuex'

export default ({
  methods: {
    getSocialChannelPreviewLink (channel, channels) {
      const channelUsername = channels[channel]
      if (channelUsername && channelUsername.startsWith('http')) {
        return channelUsername
      }
      if (!channelUsername) {
        return null
      }
      switch (channel) {
        case 'medium':
          return `https://medium.com/@${channelUsername}`
        case 'spotify':
          return `https://open.spotify.com/user/${channelUsername}`
        case 'linkedin':
          return `https://www.linkedin.com/in/${channelUsername}`
        case 'tiktok':
          return `https://vm.tiktok.com/${channelUsername}`
        default:
          return `https://${channel}.com/${channelUsername}`
      }
    },
    getBioBackgroundStyleImage (image) {
      return `background: url('${image}');`
    },
    /**
     *  getting the selected brand name
     * @returns {string|*|string}
     */
    getSelectedBioBrand () {
      // if creating new rss so cta_id is null
      if (!this.getBioLinkAdd.brand_id) {
        return 'Select your brand'
      }

      // while editing rss so ge campaign name from list if not then get from rss feed list
      const brandName = this.getBrandById(this.getBioLinkAdd.brand_id)
      if (!brandName) {
        const brandObj = this.getBioLinkItems.find(item => this.getBioLinkAdd.brand_id === item.brand_id)
        return brandObj.brand && brandObj.brand.name ? brandObj.brand.name : 'Select your Brand'
      }

      // is selected campaign exists then simple returs its name
      return brandName && brandName.name ? brandName.name : 'Select your Brand'

    }
  },
  computed: {
    ...mapGetters(['getBioLinkAdd', 'getBioLinkContentSectionAdd','getBioLinkItems'])
  }
})
