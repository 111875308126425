<template>
  <div id="app">
    <!-- Display loader meanwhile authentication is being checked -->
    <div class="loader-wrapper" v-if="!checkLoadedOnce">
      <img src="/assets/img/replug-loader.svg" alt="loading" title="Loading..." class="logo-loading">
      <p>Getting everything ready...</p>
    </div>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Merriweather:300,300i,400,400i,700,700i,900,900i');
@import "~bootstrap/scss/bootstrap.scss";
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import "~font-awesome/css/font-awesome.css";

</style>
<style lang="less">
@import "./assets/less/main.less";
</style>
<script>
import store from "./store";
import {USER_MAVEN_KEY} from "./config/config";

export default ({
  async created() {
    if (!store.getters.getLoadedOnce) {
      await store.dispatch('authenticate')
    }
    /*this.initScript("https://t.usermaven.com/lib.js", [
      { key: 'data-key', value: USER_MAVEN_KEY },
      { key: 'data-tracking-host', value: 'https://eventcollectors.usermaven.com' },
    ])*/
    // await analyticsConfig.page()
  },
  computed: {
    /**
     * It tells us the status of loader
     * We disable this loader once we get response of authentication.
     **/
    checkLoadedOnce() {
      return this.$store.getters.getLoadedOnce
    }
  },
  methods: {
    initScript(url, data = []) {
      let script = document.createElement('script');
      script.setAttribute('src', url);
      if (data.length) {
        data.forEach(elem => {
          script.setAttribute(elem.key, elem.value)
        })
      }
      document.head.appendChild(script);
    }
  }
})
</script>
