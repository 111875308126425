<template>
    <div class="pagination justify-content-center">
        <ul>
            <li v-for="pageNumber in totalPagesNumber" >
                <span  v-if="(Math.abs(pageNumber - currentPage) < 3 || pageNumber == totalPagesNumber - 1 || pageNumber == 0 || pageNumber == 1 || pageNumber == 2 || pageNumber == 3)">
                        <template v-if="(pageNumber == 3 && Math.abs(currentPage - pageNumber) > 3)">
                            <a href="javascript:;" class="dotted" >...</a>
                        </template>
                        <template v-else>
                            <a href="javascript:;" class="dotted" v-if="(pageNumber == totalPagesNumber - 1 &&
                                                            Math.abs(pageNumber - currentPage) > 3)">...</a>
                            <a href="javascript:;"  @click.prevent="getPage(pageNumber)"
                               :class="{active: currentPage === pageNumber,
                            last: (pageNumber == totalPagesNumber - 1 && Math.abs(pageNumber - currentPage) > 3),
                            first:(pageNumber == 0 && Math.abs(pageNumber - currentPage) > 3)}">{{pageNumber}}</a>
                        </template>
               </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default ({
  'name': 'Pagination',
  props: {
    'resultCount': {
      default: ''
    },
    'itemsPerPage': {
      default: ''
    },
    'currentPage': {
      default: ''
    },
    'totalPagesNumber': {
      default: 0
    },
    'clickedMethod': {
      type: Function
    }
  },
  data: function () {
    return {}
  },
  methods: {
    getPage: function (page) {
      this.clickedMethod(page)
      //                this.$parent.$options.methods.getPageValues(page)
    }

  }
})
</script>
