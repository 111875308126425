<template>
  <div class="container" style="width:800px;">
    <div v-if="online_status === false" class="top_head_slider offline_bar">
      <div class="notificationCarousel pink">
        <div class="container text-center">
          <span class="">&nbsp;</span>
          <span class="text">Your device lost its internet connection, please reload..</span>
          <button class="ml-4 btn btn_white large_btn" @click.prevent="refreshPage()">Reconnect Now</button>
        </div>
        <!--<button type="button" class="close close_slider" >&times;</button>-->
      </div>
    </div>

  </div>
</template>

<script>
import Offlinejs from 'offline-js'
import {APP_URL} from "../constants";

export default {
  name: 'IsOffline',
  data() {
    return {
      online_status: null

    }
  },
  mounted() {
    // Set our options for the Offline detection library
    Offline.options = {
      checkOnLoad: false,
      checks: {
        image: {
          url() {
            return APP_URL + 'assets/img/favicon/favicon.ico'
          }
        },
        active: 'image'
      }
    }
    // binding events for status
    Offline.on('up', this.internetUp)
    Offline.on('down', this.internetDown)

    // Checking if status online or offline
    if (Offline.state == 'up') {
      this.online_status = true
    } else if (Offline.state == 'down') {
      this.online_status = false
    } else {
      this.online_status = null
    }
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
    internetUp() {
      this.online_status = true
    },

    internetDown() {
      this.online_status = false
    }
  }
}
</script>
