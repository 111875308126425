export default ({
  methods: {
    projectMemberRole(role) {
      switch (role) {
        case 'super_administrator':
          return 'Super Administrator';
        case 'administrator':
          return 'Administrator';
        case 'member':
          return 'Member';
        case 'viewer':
          return 'Viewer';
        default:
          return 'Member'
      }
    },
    projectMemberNameInitials(firstName, lastName){

    }
  }
})
