import Aweber from '@/views/pages/integrations/auth-integrations/Aweber'

const Common = () => import('@/views/pages/integrations/auth-integrations/CommonIntegration');

export const authIntegrationRoutes = [
  {
    path: '/oauth/integration/aweber',
    name: 'auth-aweber',
    component: Aweber,
    meta: {
      title: 'Connecting your Aweber account'
    }
  },
  {
    path: '/oauth/integration/constantContact',
    name: 'auth-constant-contact',
    component: Common,
    props: {
      title: 'Connecting your ConstantContact account',
      url: 'oauth/connect/constantContact'
    }
  },
  {
    path: '/oauth/integration/campaignMonitor',
    name: 'auth-campaign-monitor',
    component: Common,
    props: {
      title: 'Connecting your Campaign Monitor account',
      url: 'oauth/connect/campaignMonitor'
    }
  },
  {
    path: '/oauth/integration/mailchimp',
    name: 'auth-mailchimp',
    component: Common,
    props: {
      title: 'Connecting your Mailchimp account',
      url: 'oauth/connect/mailchimp'
    }
  },
]
