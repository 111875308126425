<template>
  <div class="mb-3 password-protected-component">
    <div class="input_icon_text position-relative">
      <div class="setting_item text-justify">
        <div class="text_sec d-inline-block">
          <p>Password Protected</p>
        </div>
        <div class="status_sec" style="float: right">
          <label class="switch">
            <input type="checkbox" class="d-none" @click="togglePasswordProtected()"
                   :value="passwordProtectedObj.password_protected" :checked="passwordProtectedObj.password_protected">
            <div class="slider round"></div>
          </label>
        </div>
      </div>
      <div v-if="passwordProtectedObj.password_protected">
        <div class="d-flex align-items-center url_input_main">
          <div class="content mt-3">
            <div class="url">Password</div>
            <div class="name">
              <div class="validation_input">
                <input style="padding-right: 30px;"
                       placeholder="Enter your password"
                       name="link_password"
                       v-model="passwordProtectedObj.password" type="password"
                       autocomplete="off"
                       class="form-control txt-left-left">
              </div>
            </div>
            <span class="input-error" v-if="passwordProtectedValidations.password">{{
                passwordProtectedMessages.password
              }}</span>
            <span class="input-error" v-else-if="passwordProtectedValidations.passwordLength">{{
                passwordProtectedMessages.passwordLength
              }}</span>
            <span class="input-error" v-else-if="passwordProtectedValidations.passwordValid">{{
                passwordProtectedMessages.passwordValid
              }}</span>
          </div>
        </div>
        <div v-if="isAgency(self)">
          <div :class="{'mt-3':called}" class="d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="url">
                Upload Password Protected Image URL
              </div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="Enter the favicon image url (16 x 16 recommended)"
                    v-model="passwordProtectedObj.password_protected_logo" type="text"
                    class="form-control txt-left-left">
                </div>
              </div>
              <span class="input-error" v-if="passwordProtectedValidations.url">{{
                  passwordProtectedMessages.url
                }}</span>
              <span class="input-error"
                    v-else-if="passwordProtectedValidations.urlLength">{{ passwordProtectedMessages.urlLength }}</span>
              <span class="input-error"
                    v-else-if="passwordProtectedValidations.urlValid">{{ passwordProtectedMessages.urlValid }}</span>
            </div>
          </div>
          <div :class="{'mt-3':called}" class="d-flex align-items-center url_input_main ">
            <div class="content">
              <div class="name">
                <div class="validation_input">
                  <input
                    id="password_protected_logo"
                    placeholder="Enter the favicon image url (16 x 16 recommended)"
                    type="file"
                    @change="uploadImage($event)"
                    accept=".png, .jpg, .jpeg, .gif, .ico"
                    class="form-control txt-left-left d-none">
                  <label for="password_protected_logo" class="upload-meta-image">
                    <i class="fa fa-upload" v-if="!self.getLoaders.password_protected_logo" aria-hidden="true"></i>
                    <clip-loader v-if="self.getLoaders.password_protected_logo" class="loader" :size="'14px'"
                                 :color="'#2d99ec'"></clip-loader>
                    <span>Upload Image</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { passwordProtectedMessages } from '@/common/attributes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PasswordProtectedComponent',
  data () {
    return {
      invalidPassword: false,
      passwordProtectedMessages: passwordProtectedMessages,
      passwordProtectedObj: {
        password_protected: this.password_protected,
        password: this.password,
        password_protected_logo: this.password_protected_logo
      }
    }
  },
  created () {
  },
  props: [
    'passwordProtectedValidations',
    'self',
    'password_protected',
    'password',
    'password_protected_logo',
    'called'
  ],
  computed: {
    ...mapGetters(['getLoaders'])
  },
  methods: {
    ...mapActions(['uploadPasswordProtectedLogo']),
    togglePasswordProtected () {
      this.passwordProtectedObj.password_protected = !this.passwordProtectedObj.password_protected
      this.$emit('passwordProtectedObj', this.passwordProtectedObj)
    },
    async uploadImage (event) {
      this.passwordProtectedObj.password_protected_logo = await this.self.uploadPasswordProtectedLogo(event)
    }
  },
  'watch': {
    'passwordProtectedObj.password' (value) {
      this.$emit('passwordProtectedObj', this.passwordProtectedObj)
      this.passwordProtectedValidations.password = this.requiredCheck(value)
      this.passwordProtectedValidations.passwordValid = value && (value.length < 6 || value.length > 12)
    },
    'passwordProtectedObj.password_protected_logo' (value) {
      this.$emit('passwordProtectedObj', this.passwordProtectedObj)
      this.passwordProtectedValidations.url = this.requiredCheck(value)
      this.passwordProtectedValidations.urlLength = !this.maxLength(value, 500)
      this.passwordProtectedValidations.urlValid = !this.isValidURL(value)
    }
  }
}
</script>

<style lang="less" scoped>
.password-protected-component {
  .input_icon_text {
    box-shadow: none;

    .setting_item {
      .d-inline-block {
        p {
          a {
            color: #3988fe;
          }
        }
      }
    }

    .view_bottom {
      .view_bottom_inner {
        p {
          color: #1f216e;
          font-weight: bold;
          font-size: small;
        }
      }
    }
  }
}
</style>
