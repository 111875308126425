<template>

  <b-modal @hidden="utmCreate=false" modal-class="modal_basic applyUTMModal" id="applyUTMModal" hide-footer
           hide-header>
    <img @click="$bvModal.hide('applyUTMModal')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3>Add UTM Parameters</h3>
      </div>
      <div class="  clear" role="form">

        <div class="inline_or_item d-flex align-items-center justify-content-between ">

          <b-dropdown ref="utm_list_dropdown" right
                      class="utm_list_dropdown dropdown-menu-right default_style_dropdown"
                      :no-caret="true">
            <div class="dropdown_header rounded_shape d-flex align-items-center  " slot="button-content">
              <span class="text">Select from saved UTM parameter</span>
              <span class="arrow_icon ml-auto">
                                <i class=" fal fa-angle-down ml-3"></i>
                             </span>

            </div>
            <ul class="inner ">

              <template v-if="getUTMList.length > 0">
                <li v-for="(utm, index) in getUTMList"
                    @click="$store.commit('CHANGE_SELECTED_UTM', utm), $refs.utm_list_dropdown.hide(true),utmCreate=true"
                    class="list_item_li">
                  {{ utm.campaign }}
                </li>
              </template>

              <li class="no_text_li" v-else>
                You do not have any UTM.
              </li>

            </ul>
          </b-dropdown>

          <div class="or_separator">
            <span class="sep_text">OR</span>
          </div>

          <button @click="$store.commit('RESET_SELECTED_UTM'),utmCreate=true"
                  class="btn---cta btn-link">
            <span>Create a new UTM preset</span>
          </button>
        </div>

        <template v-if="utmCreate">

          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">UTM Campaign</div>
              <div class="name">
                <div class="validation_input">
                  <input :class="{'input-field-error' : validations.campaign}"
                         v-model="getSelectedUTM.campaign"
                         type="text"
                         placeholder="Product, promo code, or slogan (e.g. spring_sale)">
                  <span class="input-error" v-if="validations.campaign">
                                    {{ messages.campaign }}
                                </span>

                </div>
              </div>
            </div>
          </div>
          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">UTM Medium</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getSelectedUTM.medium"
                         :class="{'input-field-error' : validations.medium}"
                         type="text"
                         placeholder="Marketing Medium (e.g. social)">

                  <span class="input-error" v-if="validations.medium">
                                    {{ messages.medium }}
                                </span>

                </div>
              </div>
            </div>
          </div>
          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">UTM Source</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getSelectedUTM.source"
                         :class="{'input-field-error' : validations.source}"
                         type="text"
                         placeholder="The referrer (e.g. facebook, twitter)">
                  <span class="input-error" v-if="validations.source">
                                    {{ messages.source }}
                                </span>
                </div>
              </div>
            </div>
          </div>

          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">UTM Term</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getSelectedUTM.term"
                         :class="{'input-field-error' : validations.term}"
                         type="text"
                         placeholder="e.g paid keywords">
                  <span class="input-error" v-if="validations.term">
                                    {{ messages.term }}
                                </span>
                </div>
              </div>
            </div>
          </div>
          <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">UTM Content</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getSelectedUTM.content"
                         :class="{'input-field-error' : validations.content}"
                         type="text"
                         placeholder="e.g text AD name">
                  <span class="input-error" v-if="validations.content">
                                    {{ messages.content }}
                                </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class=" btn_block text-left">

        <template v-if="getSelectedUTM._id === null">
          <button :disabled="!utmCreate" @click.prevent="createAndApply()"
                  class="btn---cta btn-blue with-shadow btn-round  btn-bold">
            <span>Apply</span>
          </button>
        </template>
        <template v-else>
          <button :disabled="!utmCreate" @click.prevent="validateAndApplyUTM()"
                  class="btn---cta btn-blue with-shadow btn-round  btn-bold">
            <span>Apply</span>
          </button>
        </template>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="$bvModal.hide('applyUTMModal')">
          <span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Dropdown from '../../../../ui/Dropdown.vue'
import { utmTypes } from '../../../../state/modules/mutation-types'

export default {
  data () {
    return {
      utmCreate: false,
      size: '14px',
      color: '#fff',
      loaders: {
        save: false
      },
      validations: {
        name: false,
        medium: false,
        source: false,
        campaign: false
      },
      messages: {
        name: 'UTM Name is required.',
        medium: 'UTM Medium is required.',
        source: 'UTM Source is required.',
        campaign: 'UTM Campaign is required.'
      }

    }
  },
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters(['getDefaultSelectedUTM', 'getSelectedUTM', 'getUTMList']),
    selected_utm: {
      get () {
        return this.getSelectedUTM
      },
      set (value) {
        this.$store.commit('CHANGE_SELECTED_UTM', value)
      }
    }
  },
  async created () {
    await this.fetchUTMList()
  },
  methods: {
    ...mapActions([
      'applyUTM',
      'storeUTM',
      'validateUTMURL',
      'fetchUTMList'
    ]),
    async validateAndApplyUTM () {
      this.validations.medium = this.requiredCheck(this.getSelectedUTM.medium)
      this.validations.source = this.requiredCheck(this.getSelectedUTM.source)
      this.validations.campaign = this.requiredCheck(this.getSelectedUTM.campaign)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.applyUTM(this.$route.name)
        this.$bvModal.hide('applyUTMModal')
      }
    },
    async createAndApply () {
      let selectedUTM = this.getSelectedUTM
      this.validations.medium = this.requiredCheck(selectedUTM.medium)
      this.validations.source = this.requiredCheck(selectedUTM.source)
      this.validations.campaign = this.requiredCheck(selectedUTM.campaign)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        // validate the url first

        let url = await this.$store.dispatch('validateUTMURL', this.$route.name)

        // if url validated then store and apply utm by passing the url
        if (url) {
          let res = await this.storeUTM(selectedUTM)
          await this.$store.commit(utmTypes.CHANGE_SELECTED_UTM, res)
          setTimeout(() => {
            this.applyUTM(this.$route.name)
          }, 200)
        }
        this.$bvModal.hide('applyUTMModal')
      }
    }
  },
  watch: {
    'getSelectedUTM.medium' (val) {
      if (val && val.length > 0) this.validations.medium = false
    },
    'getSelectedUTM.source' (val) {
      if (val && val.length > 0) this.validations.source = false
    },
    'getSelectedUTM.campaign' (val) {
      if (val && val.length > 0) this.validations.campaign = false
    }
  }
}
</script>

<style>
@-moz-document url-prefix() {

  .applyUTMModal .modal-dialog .inline_or_item .utm_list_dropdown {
    max-width: 315px !important;
  }
}
</style>
