<template>
    <span :class="`ci-alert ${variant}`" >
      <slot></slot>
    </span>
</template>
<script>
  export default({
    props:{
      variant:{
        type: String,
        default: 'primary'
      }
    },
    name: 'alert'
  })
</script>
