<template>
    <div class="no_analytics_section">
        <img src="/assets/img/analytics/replug_no_data_found.png" alt="">
        <h4>OOOPS!</h4>
        <p>No data available yet, we are waiting for your events to crunch the
            numbers.</p>
    </div>
</template>
<script>
export default ({})
</script>
