const URLValidations = {
  url: false,
  urlValid: false,
  urlLength: false
}
const URLMessages = {
  url: 'URL can not be empty',
  urlValid: 'URL is invalid, please enter a valid URL.',
  urlLength: 'URL should not be greater than 1000 characters.'
}
// brands validation
const brandValidations = {
  name: false,
  nameLength: false,
  ...URLValidations
}

const brandMessages = {
  name: 'Name is required.',
  nameLength: 'The brand name should not be greater than 100 characters.',
  ...URLMessages
}

// dashboard quick link validations
const quickLinksValidations = {
  campaign: false,
  ...URLValidations
}

const quickLinksMessages = {
  campaign: 'Please select a campaign',
  ...URLMessages
}

// campaign validations

const nameValidations = {
  name: false,
  nameLength: false
}

const nameMessages = {
  name: 'Name is required.',
  nameLength: 'The name should not be greater than 100 characters.'
}

const campaignSectionsValidations = {
  campaignName: false,
  image: false,
  integration: false,
  agreement: false,
  customMessage: false
}

const campaignValidations = {
  message_headline: false,
  messageHeadlineLength: false,
  message: false,
  messageLength: false,
  button_text: false,
  buttonTextLength: false,
  ...URLValidations
}

const campaignMessages = {
  message_headline: 'Message headline is required.',
  messageHeadlineLength: 'The Headline should not be greater than 100 characters.',
  message: 'Message is required.',
  messageLength: 'The Headline should not be greater than 500 characters.',
  button_text: 'Button text is required.',
  buttonTextLength: 'Button text should not be greater than 100 characters.',
  ...URLMessages
}

// replug links validation
const replugLinksValidation = {
  shorten_url_max_length: false,
  ...URLValidations
}

// rss feed validation

const rssFeedValidations = {
  campaign: false,
  ...URLValidations
}

const rssFeedMessages = {
  campaign: 'Please select a campaign',
  ...URLMessages
}

// bio links rss feed
const bioLinksRssFeedValidations = {
  title_required: false,
  title_length: false,
  rss_data_required: false,
  ...URLValidations
}

const bioLinksRssFeedMessages = {
  title_required: 'Title is required.',
  title_length: 'The title should not be greater than 100 characters.',
  ...URLMessages
}

// bio links section validation
const bioLinksSectionMessages = {
  ...URLMessages,
  phoneValid: 'Please enter a valid phone number.',
  mailValid: 'Please enter a valid email.'
}
const bioLinksSectionValidations = {
  ...URLValidations,
  phoneValid: false,
  mailValid: false
}

const validLength = {
  url: 1000,
  custom_widget: 3000,
  slug_length: 40
}

const widgetValidations = {
  name: false,
  nameLength: false,
  javascript: false,
  javascriptLength: false,
  html: false,
  htmlLength: false
}

const widgetMessages = {
  name: 'Name is required.',
  nameLength: 'The name should not be greater than 35 characters.',
  html: 'HTML snippet is required.',
  htmlLength: 'The html should not be greater than 3000 characters.',
  javascript: 'Javascript snippet is required.',
  javascriptLength: 'The javascript should not be greater than 3000 characters.'
}

const widgetCMOptions = {
  scroll: true,
  lineNumbers: true,
  matchBrackets: true
}

const pixelValidations = {
  name: false,
  nameLength: false,
  pixel: false,
  pixelLength: false
}
const pixelMessages = {
  name: 'Pixel name is required.',
  nameLength: 'The name should not be greater than 255 characters',
  pixel: 'Pixel ID is required.',
  pixelLength: 'The pixel should not be greater than 2000 characters'
}

// replug links customize preview
const customizeLinkPreviewValidations = {
  title: false,
  titleLength: false,
  description: false,
  descriptionLength: false,
  ...URLValidations
}

const customizeLinkPreviewMessages = {
  title: 'Title is required',
  titleLength: 'The title should not be greater than 500 characters.',
  description: 'Description is required',
  descriptionLength: 'The Description should not be greater than 500 characters.',
  ...URLMessages
}

// replug a/b testing
const abTestingObjValidations = {
  weight: false,
  iframe: false,
  weightCriteria: false,
  ...URLValidations
}

const abTestingObjMessages = {
  weight: 'Weight is required.',
  uniqueId: 'Unique id is required',
  iframe: 'Can not show iframe on this link',
  weightCriteria: 'Accumulative weight should be 100%.',
  ...URLMessages
}

const trafficRoutingMessages = {
  iframe: 'Can not show iframe on this link',
  ...URLMessages
}

const trafficRoutingValidations = {
  iframe: false,
  ...URLValidations
}

// password protected
const passwordProtectedValidations = {
  password: false,
  passwordLength: false,
  passwordValid: false,
  ...URLValidations
}

const passwordProtectedMessages = {
  password: 'Password is required.',
  passwordLength: 'Password should be less than 12.',
  passwordValid: 'Password must contains 6-12 characters.',
  ...URLMessages
}

// link Expiry
const linkExpireAttributesValidations = {
  expiry: false,
  ...URLValidations
}

const linkExpireAttributesMessages = {
  expiry: 'Date is required.',
  ...URLMessages
}

// slug messages
const slugMessages = {
  slug_length: 'You can enter ' + validLength.slug_length + ' maximum characters for the slug.'
}

// default link expiry attribute
const linkExpireAttributesDefault = {
  expiry: '',
  expiry_time: '00:00:00',
  expiry_datetime: '',
  link_expiry_logo: ''
}

// customize message validations
const customizeMessageValidations = {
  message_headline: false,
  message_headline_length: false,
  message: false,
  messageLength: false
}

// customize message
const customizeMessage = {
  message_headline: 'Headline is required.',
  message_headline_length: 'The headline should not be greater than 250 characters.',
  message: 'Message is required.',
  messageLength: 'The message should not be greater than 500 characters.'
}

// feature lists
const featuresList = {
  deep_linking: 'deep_linking',
  traffic_routing: 'traffic_routing',
  ab_testing: 'ab_testing'
}

// rss feed status messages
const rssFeedStatusMessages = {
  success: 'Your RSS Feed is processed successfully.',
  already_in_queue: 'Rss Already In Queue.',
  processing: 'Your RSS Feed in processing...',
  unable_to_update: 'Unable to update your rss feed.',
  unable_to_fetch: 'Unable to fetch your rss feed.',
  iframe: 'Your RSS links could not be processed due to the websites do not support iframe or contains an invalid link.',
  invalid_rss_feed_url: 'Your rss feed is invalid.',
  rss_already_updated: 'Your rss feed is already updated.'
}

// rss feed status colors
const rssFeedStatusColors = {
  success: 'alert-success',
  already_in_queue: 'alert-warning',
  processing: 'alert-info',
  unable_to_update: 'alert-danger',
  unable_to_fetch: 'alert-danger',
  iframe: 'alert-danger',
  invalid_rss_feed_url: 'alert-danger',
  rss_already_updated: 'success'
}

// Audio validation
const audioValidation = {
  ...URLValidations,
  isValidAudio: false
}

// schedule validations
const scheduleValidations = {
  start_date: false,
  end_date: false,
  start_time: false,
  end_time: false,
  time_zone: false
}

// schedule messages
const scheduleMessages = {
  start_date: 'Start date is required.',
  end_date: 'End date is required.',
  start_time: 'Start time is required.',
  end_time: 'End time is required.',
  time_zone: 'Time Zone is required.'
}

// audio app regex
const audioRegexArray = [
  {width: '22px', name: 'Deezer', image: '/assets/img/deeplinks/Deezer.svg', regex: 'deezer\\.com\\/(.*)'},
  {width: '22px', name: 'Spotify', image: '/assets/img/deeplinks/Spotify.svg', regex: 'open.spotify\\.com\\/(.*)'},
  {width: '22px', name: 'Soundcloud', image: '/assets/img/deeplinks/soundcloud.png', regex: 'w.soundcloud.com\\/(.*)'},
  {width: '35px', name: 'Tidal', image: '/assets/img/deeplinks/Tidal Music Streaming.svg', regex: 'tidal\\.com\\/(.*)'},
  {width: '22px', name: 'Apple', image: '/assets/img/deeplinks/apple.png', regex: 'embed\\.music\\.apple\\.com\\/'},
  {
    width: '35px',
    name: 'Amazon Music',
    image: '/assets/img/deeplinks/Amazon Music.svg',
    regex: 'music\\.amazon\\.com\\/'
  },
  {width: '22px', name: 'Anchor', image: '/assets/img/deeplinks/anchor.png', regex: 'anchor\\.fm\\/'},
  {width: '22px', name: 'Fizy', image: '/assets/img/deeplinks/Fizy.svg', regex: 'fizy\\.com\\/'},
  {width: '22px', name: 'Shazam', image: '/assets/img/deeplinks/Shazam.svg', regex: 'Shazam\\.com\\/'},
  {width: '22px', name: 'Genius', image: '/assets/img/deeplinks/Genius.svg', regex: 'genius\\.com\\/'},
  {width: '30px', name: 'Mixcloud', image: '/assets/img/deeplinks/Mixcloud.svg', regex: 'Mixcloud\\.com\\/'},
  {
    width: '22px',
    name: 'YouTube Music',
    image: '/assets/img/deeplinks/YouTube Music.svg',
    regex: 'youtube\\music\\.com\\/'
  }
]

// animations
const animationList = [
  {key: 'tada', name: 'Tada', selected: false, image: '/assets/img/bioLink/BioLinkIcons/tada.png'},
  {key: 'shake', name: 'Shake', selected: false, image: '/assets/img/bioLink/BioLinkIcons/shake.png'},
  {key: 'swing', name: 'Swing', selected: false, image: '/assets/img/bioLink/BioLinkIcons/swing.png'},
  {key: 'wobble', name: 'Wobble', selected: false, image: '/assets/img/bioLink/BioLinkIcons/wobble.png'},
  {key: 'jello', name: 'Jello', selected: false, image: '/assets/img/bioLink/BioLinkIcons/jello.png'},
  {key: 'pulse', name: 'Pulse', selected: false, image: '/assets/img/bioLink/BioLinkIcons/pulse.png'},
  {key: 'zoom', name: 'Zoom', selected: false, image: '/assets/img/bioLink/BioLinkIcons/zoom.png'}
]

// video validation
const videoValidation = {
  ...URLValidations,
  isValidVideo: false
}

const videoRegexArray = [
  {width: '25px', key: 'youtube', name: 'Youtube', image: '/assets/img/deeplinks/Youtube.svg', regex: 'youtu'},
  {width: '35px', key: 'vimeo', name: 'Vimeo', image: '/assets/img/deeplinks/Vimeo.svg', regex: 'vimeo\\.com\\/(.*)'},
  {
    width: '20px',
    key: 'twitch',
    name: 'Twitch',
    image: '/assets/img/deeplinks/Twitch TV.svg',
    regex: 'twitch\\.tv\\/(.*)'
  },
  {
    width: '20px',
    key: 'tikTok',
    name: 'TikTok',
    image: '/assets/img/deeplinks/TikTok.svg',
    regex: 'tiktok\\.com\\/(.*)'
  },
  {width: '35px', key: 'zoom', name: 'Zoom', image: '/assets/img/deeplinks/ZoomLogo.png', regex: 'zoom\\.us\\/(.*)'},
  {
    width: '35px',
    key: 'dacast',
    name: 'Dacast',
    image: '/assets/img/deeplinks/dacastlogo.png',
    regex: 'dacast\\.com\\/(.*)'
  },
  {
    width: '50px',
    key: 'publitio',
    name: 'Publitio',
    image: '/assets/img/deeplinks/publitio.png',
    regex: 'publit\\.io\\/(.*)'
  },
  {width: '32px', key: 'adilo', name: 'Adilo', image: '/assets/img/deeplinks/adilo.png', regex: 'adilo\\.com\\/(.*)'},
  {
    width: '25px',
    key: 'cincopa',
    name: 'Cincopa',
    image: '/assets/img/deeplinks/cincopa.png',
    regex: 'cincopa\\.com\\/(.*)'
  },
  {
    width: '25px',
    key: 'dailymotion',
    name: 'Dailymotion',
    image: '/assets/img/deeplinks/dailymotion.png',
    regex: 'dailymotion\\.com\\/(.*)'
  },
  {
    width: '20px',
    key: 'facebook',
    name: 'Facebook Video',
    image: '/assets/img/deeplinks/Facebook.svg',
    regex: 'facebook\\.com\\/(.*)'
  },
  {
    width: '20px',
    key: 'fb',
    name: 'Facebook Video',
    image: '/assets/img/deeplinks/Facebook.svg',
    regex: 'fb\\.watch\\/(.*)'
  },
  {
    width: '20px',
    key: 'instagram',
    name: 'Instagram Video',
    image: '/assets/img/deeplinks/Instagram.svg',
    regex: 'instagram\\.com\\/(.*)'
  },
  {
    width: '30px',
    key: 'killer',
    name: 'killer Player',
    image: '/assets/img/deeplinks/killerplayer.png',
    regex: 'killerplayer\\.com\\/(.*)'
  },
  {
    width: '40px',
    key: 'viloud',
    name: 'Viloud',
    image: '/assets/img/deeplinks/viloud.png',
    regex: 'viloud\\.tv\\/(.*)'
  },
  {width: '40px', key: 'qiwio', name: 'Qiwio', image: '/assets/img/deeplinks/qiwio.png', regex: 'qiwio\\.io\\/(.*)'},
  {
    width: '25px',
    key: 'snapchat',
    name: 'Snapchat',
    image: '/assets/img/deeplinks/Snapchat.svg',
    regex: 'snapchat\\.com\\/(.*)'
  },
  {
    width: '25px',
    key: 'spotlightr',
    name: 'Spotlightr',
    image: '/assets/img/deeplinks/spotlightr.png',
    regex: 'spotlightr\\.com\\/(.*)'
  },
  {
    width: '25px',
    key: 'sprout',
    name: 'Sprout Video',
    image: '/assets/img/deeplinks/sproutvideo.png',
    regex: 'sproutvideo\\.com\\/(.*)'
  },
  {
    width: '30px',
    key: 'storyxpress',
    name: 'Storyxpress',
    image: '/assets/img/deeplinks/storyxpress.png',
    regex: 'storyxpress\\.co\\/(.*)'
  },
  {width: '45px', key: 'vadoo', name: 'Vadoo', image: '/assets/img/deeplinks/vadoo.svg', regex: 'vadoo\\.tv\\/(.*)'}
]

// card validation
const cardValidation = {
  ...URLValidations
}

// text block validations
const textBlockValidation = {
  text: false,
  textLength: false
}

const textBlockMessages = {
  text: 'Text can not be empty',
  textLength: 'Text should not be greater than 10,000 characters.'
}

// account state messages
const accountStateMessages = (state) => {
  return {
    account_state: 'Your account has been ' + state.split('_').join(' ') + ' by the administrator.'
  }
}

// search filters messages
const searchFiltersMessages = (emptyListKey = '') => {
  return {
    // dropdown
    search_field_dropdown: 'No results found.',
    empty_list_dropdown: `You do not have any ${emptyListKey}.`,
    empty_list_create_new_dropdown: `You do not have any ${emptyListKey}, please create one by clicking below button.`,
    // sections
    filters: 'No results, please change your filters.',
    empty_list: `No ${emptyListKey} created yet, please create your ${emptyListKey} first.`,
    archived_list: `You do not have any archived ${emptyListKey}!`
  }
}

// agreement box validations
const agreementValidations = {
  name: false,
  nameLength: false,
  text: false,
  textLength: false
}

// agreement box messages
const agreementMessages = {
  name: 'Name is required.',
  nameLength: 'Title should not be greater than 100 characters.',
  text: 'Agreement is required',
  textLength: 'Agreement text should not be greater than 500 characters.'
}

// routes list with policies
const routeList = {
  bio_links: 'can_view_bio_links',
  billing: 'can_view_billing',
  team: 'can_view_team_members',
  clients: 'can_view_clients',
  tags: 'can_view_tags',
  integrations: 'can_view_integrations',
  'social-media': 'can_view_deep_links',
  'e-commerce': 'can_view_deep_links',
  music: 'can_view_deep_links',
  video: 'can_view_deep_links',
  productivity: 'can_view_deep_links',
  custom_domains: 'can_view_domains',
  pixels: 'can_view_pixels',
  custom_code: 'can_view_widgets',
  utm: 'can_view_utm',
  blacklist_ip: 'can_view_blacklist',
  miscellaneous: 'can_view_miscellaneous',
  'create-white-label': 'can_view_white_label'
}

// white label sections validations
const whiteLabelValidations = {
  agency_name: {...nameValidations},
  header_logo: {...URLValidations},
  favicon: {...URLValidations},
  privacy_policy: {...URLValidations},
  terms_of_service: {...URLValidations},
  login_logo: {...URLValidations},
  app_loader: {...URLValidations},
  white_label_domain: {...URLValidations},
  custom_domain_id: false,
  whiteLabel_domain_connected: false,
  is_email_connected: false,
  email_validate: {
    from_email: false,
    email_sender_name: false,
    smtp_host: false,
    smtp_port: false,
    smtp_username: false,
    smtp_password: false,
    smtp_encryption: false
  }
}

// replug domains list on which we will not allow whitelabel
const replugDomainsList = ['app.replug.io', 'staging-frontend.replug.io', 'stage.replug.io', 'localhost']

const notAllowedIntegrationsInWhitelabel = ['aweber', 'campaignmonitor', 'constantcontact', 'mailchimp']

const hiddenIntegrationList = ['']

// white label validation messages
const whiteLabelMessage = {
  ...nameMessages,
  ...URLMessages,
  from_email: 'Email is required.',
  email_sender_name: 'Email sender name is required',
  smtp_host: 'SMTP host is required.',
  smtp_port: 'SMTP port is required.',
  smtp_username: 'SMTP username is required',
  smtp_password: 'SMTP password is required',
  smtp_encryption: 'SMTP encryption is required'
}

const allowedMembers = [
  'mohsin.ali@d4interactive.io',
  'azhar@d4interactive.io',
  'muhammad.azhar109@gmail.com',
  'waqar.azeem1986@gmail.com',
  'daniyal.ali@d4interactive.io'
]

const swalDefaultValues = () => {
  return {
    title: 'Are you sure ?',
    type: 'warning',
    text: '',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, Delete it!',
    cancelButtonText: 'No, Cancel!',
    closeOnConfirm: true,
    customClass: 'sweetAlert_box',
    closeOnCancel: true,
    animation: false
  }
}
const swalDispatchReportsValues = () => {
  return {
    title: 'Are you sure to dispatch reports manually?',
    type: 'warning',
    text: '',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, Generate it!',
    cancelButtonText: 'No, Cancel!',
    closeOnConfirm: true,
    customClass: 'sweetAlert_box',
    closeOnCancel: true,
    animation: false
  }
}
const defaultEmailProviders = () => {
  return [
    {
      name: 'Drip',
      internal_name: 'drip',
      connected: false
    },
    {
      name: 'Gist',
      internal_name: 'convertfox',
      connected: false
    },
    {
      name: 'Mailchimp',
      internal_name: 'mailchimp',
      connected: false
    },
    {
      name: 'ActiveCampaign',
      internal_name: 'activecampaign',
      connected: false
    },
    {
      name: 'GetResponse',
      internal_name: 'getresponse',
      connected: false
    },
    {
      name: 'Mautic',
      internal_name: 'mautic',
      connected: false
    },
    {
      name: 'ConstantContact',
      internal_name: 'constantcontact',
      connected: false
    },
    {
      name: 'Aweber',
      internal_name: 'aweber',
      connected: false
    },
    {
      name: 'Mailerlite',
      internal_name: 'mailerlite',
      connected: false
    },
    {
      name: 'Sendlane',
      internal_name: 'sendlane',
      connected: false
    },
    {
      name: 'SendPulse',
      internal_name: 'sendPulse',
      connected: false
    },
    {
      name: 'sendy',
      internal_name: 'sendy',
      connected: false
    },
    {
      name: 'ConvertKit',
      internal_name: 'convertkit',
      connected: false
    },
    {
      name: 'Moosend',
      internal_name: 'moosend',
      connected: false
    },
    {
      name: 'Sendinblue',
      internal_name: 'sendinblue',
      connected: false
    },
    {
      name: 'Benchmark Email',
      internal_name: 'benchmark',
      connected: false
    },
    {
      name: 'Campaign Monitor',
      internal_name: 'campaignmonitor',
      connected: false
    },
    {
      name: 'FreshMail',
      internal_name: 'freshmail',
      connected: false
    }

  ]
}

export const bio_content_type = {
  social: 'social',
  links: 'links',
  rss: 'rss',
  audio: 'audio',
  video: 'video',
  card: 'card',
  text: 'text'
}

const googlePixelsType = {
  google_adwords: 'google_adwords',
  ga4: 'ga4',
  universal_analytics: 'universal_analytics',
  google_tag_manager: 'google_tag_manager'
}

const googlePixelsTypePlaceHolder = {
  google_adwords: 'xxxxxxxxxxxxx-x',
  ga4: '2VKS238XT9',
  universal_analytics: '77673152-0',
  google_tag_manager: 'PX35DQE'
}

const googlePixelsTypeKey = {
  google_adwords: 'AW-',
  ga4: 'G-',
  universal_analytics: 'UA-',
  google_tag_manager: 'GTM-'
}

// bio links social channels list
const socialsChannelsList = [
  {id: 'instagram', value: 'instagram', dataCy: 'instagram', icon: 'ins fab fa-instagram'},
  {id: 'twitter', value: 'twitter', dataCy: 'twitter', icon: 'tw fab fa-twitter'},
  {id: 'facebook', value: 'facebook', dataCy: 'facebook', icon: 'fb fab fa-facebook-f'},
  {id: 'youtube', value: 'youtube', dataCy: 'youtube', icon: 'youtube-red fab fa-youtube'},
  {id: 'linkedin', value: 'linkedin', dataCy: 'linkedin', icon: 'linkedin fab fa-linkedin'},
  {id: 'medium', value: 'medium', dataCy: 'medium', icon: 'medium fab fa-medium'},
  {id: 'twitch', value: 'twitch', dataCy: 'twitch', icon: 'twitch fab fa-twitch'},
  {id: 'spotify', value: 'spotify', dataCy: 'spotify', icon: 'spotify fab fa-spotify'},
  {id: 'soundcloud', value: 'soundcloud', dataCy: 'soundcloud', icon: 'soundcloud fab fa-soundcloud'},
  {id: 'apple', value: 'apple', dataCy: 'apple', icon: 'apple fab fa-apple'},
  {id: 'pinterest', value: 'pinterest', dataCy: 'pinterest', icon: 'pinterest fab fa-pinterest'},
  {id: 'tiktok', value: 'tiktok', dataCy: 'tiktok', icon: 'tiktok fab fa-tiktok'},
  {id: 'snapchat', value: 'snapchat', dataCy: 'snapchat', icon: 'snapchat fab fa-snapchat'},
  {id: 'reddit', value: 'reddit', dataCy: 'reddit', icon: 'reddit fab fa-reddit'},
  {id: 'tumblr', value: 'tumblr', dataCy: 'tumblr', icon: 'tumblr fab fa-tumblr'},
  {id: 'quora', value: 'quora', dataCy: 'quora', icon: 'quora fab fa-quora'},
  {id: 'line', value: 'line', dataCy: 'line', icon: 'line fab fa-line'},
  {id: 'telegram', value: 'telegram', dataCy: 'telegram', icon: 'telegram fab fa-telegram'},
  {id: 'viber', value: 'viber', dataCy: 'viber', icon: 'viber fab fa-viber'},
  {id: 'discord', value: 'discord', dataCy: 'discord', icon: 'discord fab fa-discord'},
  {id: 'slack', value: 'slack', dataCy: 'slack', icon: 'slack fab fa-slack'},
  {id: 'meetup', value: 'meetup', dataCy: 'meetup', icon: 'meetup fab fa-meetup', isDropdown: true},
  {id: 'flickr', value: 'flickr', dataCy: 'flickr', icon: 'flickr fab fa-flickr', isDropdown: true},
  {id: 'wechat', value: 'wechat', dataCy: 'wechat', icon: 'wechat fab fa-wechat', isDropdown: true},
  {id: 'skype', value: 'skype', dataCy: 'skype', icon: 'skype fab fa-skype', isDropdown: true},
  {id: 'scribd', value: 'scribd', dataCy: 'scribd', icon: 'scribd fab fa-scribd', isDropdown: true},
  {id: 'vimeo', value: 'vimeo', dataCy: 'vimeo', icon: 'vimeo fab fa-vimeo', isDropdown: true},
  {id: 'digg', value: 'digg', dataCy: 'digg', icon: 'digg fab fa-digg', isDropdown: true}
]

export {
  widgetCMOptions,
  widgetMessages,
  widgetValidations,
  brandMessages,
  brandValidations,
  URLValidations,
  URLMessages,
  swalDefaultValues,
  swalDispatchReportsValues,
  defaultEmailProviders,
  pixelMessages,
  campaignValidations,
  campaignMessages,
  pixelValidations,
  validLength,
  quickLinksValidations,
  quickLinksMessages,
  replugLinksValidation,
  rssFeedMessages,
  rssFeedValidations,
  bioLinksRssFeedValidations,
  bioLinksRssFeedMessages,
  bioLinksSectionValidations,
  customizeLinkPreviewValidations,
  customizeLinkPreviewMessages,
  bioLinksSectionMessages,
  abTestingObjValidations,
  abTestingObjMessages,
  linkExpireAttributesValidations,
  linkExpireAttributesMessages,
  trafficRoutingMessages,
  trafficRoutingValidations,
  passwordProtectedValidations,
  passwordProtectedMessages,
  slugMessages,
  linkExpireAttributesDefault,
  featuresList,
  accountStateMessages,
  searchFiltersMessages,
  agreementValidations,
  agreementMessages,
  routeList,
  customizeMessageValidations,
  customizeMessage,
  rssFeedStatusMessages,
  rssFeedStatusColors,
  scheduleValidations,
  scheduleMessages,
  audioRegexArray,
  audioValidation,
  videoRegexArray,
  videoValidation,
  cardValidation,
  animationList,
  googlePixelsType,
  googlePixelsTypePlaceHolder,
  googlePixelsTypeKey,
  textBlockValidation,
  textBlockMessages,
  nameValidations,
  nameMessages,
  campaignSectionsValidations,
  whiteLabelValidations,
  whiteLabelMessage,
  allowedMembers,
  replugDomainsList,
  notAllowedIntegrationsInWhitelabel,
  hiddenIntegrationList,
  socialsChannelsList
}
