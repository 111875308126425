<template>
  <div class="home welcome-screen">
    <div class="loading-screen">
      <div class="logo-image">
        <img src="@/assets/img/logo.png">
      </div>
      <div class="logo-text">
        <p>
          We're loading your data...
        </p>
      </div>
      <div class="ci-spinner pt-2">
        <clip-loader :color="'#939598'"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default ({
    name: 'loading-screen'
  })
</script>
