<template>
    <div class="">
        <template v-if="!edit">
            <span class="note_bg" v-if="getSaveLink._id">
                {{getSaveLink.note}}
            </span>
        </template>
        <template v-else>
        <textarea v-model="getSaveLink.note" name="" id="" cols="30" rows="5"
                  placeholder="A brief description of this link."></textarea>
        </template>

    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    edit: {
      default: false
    }

  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getSaveLink'])
  }
}
</script>
<style lang="less">
    .note_bg {
        /*padding: 10px 10px;*/
        /*border-radius: 2px;*/
        /*background: #ececec;*/
    }
</style>
