<template>

  <div class="cta_main_view">
    <apply-utm-modal></apply-utm-modal>
    <div :class="{'activeTour' : getGuidedTour.step9}" class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">
          <template v-if="getLinks.new_link_created && getSaveLink._id">
            Share Your Link
          </template>
          <template v-else>
            <template v-if="!getSaveLink._id">
              Create your {{ getSiteDetails.agency_name }} link
            </template>
            <template v-else>
              Update your {{ getSiteDetails.agency_name }} link
            </template>
          </template>

        </h4>
        <router-link class="cross_icon ml-auto" :to="{name: 'links'}">
          <i class="fal fa-times"></i>
        </router-link>
      </div>

      <div class="view_center basic_form">

        <template v-if="getLinks.new_link_created && getSaveLink._id">
          <new-link-created></new-link-created>
        </template>
        <template v-else>

          <!-- Campaign dropdown -->
          <b-dropdown ref="campaigns_dropdown_quick_link" right class="mb-3 dropdown-menu-right default_style_dropdown"
                      :no-caret="true">
            <div @click="fetchCampaignOnDropdown" class="dropdown_header br-10 rounded_shape d-flex align-items-center"
                 slot="button-content"
                 data-cy="select-camp">
              <div v-tooltip="limitTextLength(getSelectedCampaign(),40)" class="name"><p>
                {{ limitTextLength(getSelectedCampaign(), 40) }}</p></div>
              <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
            </div>
            <ul id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList',1)"
                class="clear inner">
              <template
                v-if="(getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7) || getCampaigns.search">
                <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
                  <input placeholder="Search for campaign"
                         type="text"
                         class="search_input_block_for_dropdown" :value="getCampaigns.search"
                         @input="debounceCampaignDropdownSearch"
                         data-cy="search-camp"> <i
                  class="fal fa-search"></i>
                </div>
                <hr>
              </template>
              <template v-if="getCampaignsList && getCampaignsList.length">
                <li class="list_item_li" @click="$refs.campaigns_dropdown_quick_link.visible = false"
                    v-for="(cta, index) in getComponentCampaignList"
                    @click.prevent="selectCampaignForLink(cta),$refs.campaigns_dropdown_quick_link.hide(true)"
                    v-if="cta.name" :key="index" data-cy="camps">

                  <div class="d-flex align-items-center profile__box">
                    <div class="avatar">
                      <div class="brand_img"
                           :style="brandImageLink(cta.image ? cta.image : cta.brand.avatar)"></div>
                    </div>
                    <div v-tooltip="cta.name" class="content">
                      <div class="name">
                        {{ limitTextLength(cta.name, 25) }}
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
                  <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
                </li>
              </template>
              <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
                <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
              </li>
              <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length"
                  class=" no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}
              </li>
              <template v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length">
                <li class=" no_text_li">{{ searchFiltersMessages('campaigns').empty_list_dropdown }}</li>
                <li class="   no_text_li">
                  <button @click="$router.push({ 'name': 'create' })" class="btn-link btn---cta btn-blue btn-round">
                    <span>Create a New Branded CTA</span></button>
                </li>
              </template>
            </ul>
          </b-dropdown>
          <div class=" input_icon_text d-flex align-items-center url_input_main "
               :class="checkURLState()">

            <div class="content">
              <div class="url">
                <template
                  v-if="getCampaignType(getSaveLink.call_to_action_id) && getCampaignType(getSaveLink.call_to_action_id).type === 'no_cta'">
                  Enter Your URL
                </template>
                <template v-else>
                  URL (Link which you want to shorten and optimize)
                </template>
              </div>
              <!--  @blur="handleBlur()" -->
              <div class="name">
                <div class="validation_input">
                  <url
                    :value="getSaveLink.url"
                    @input="checkLinkPreviewWithCTA($event)"
                    @onEnter="store($event)"
                    :validations="validations"
                    @paste="checkLinkPreviewWithCTA($event)"
                    v-bind:blur="() => getSaveLink.url = focusoutConcatenation(getSaveLink.url)"
                    ref="urlRef"
                    data-cy="link-url"
                    placeholder="Enter full URL here (e.g https://contentstudio.io)"></url>
                </div>
              </div>
            </div>
            <div class="avatar mr-0 ml-3">

              <i v-if="checkURLState() === 'error_state'"
                 class=" msg_icon far fa-exclamation-triangle"></i>

              <i v-if="checkURLState() === 'warning_state' && !getLinksLoaders.link_preview && isTypeCTA(getSaveLink.call_to_action_id)"
                 class="msg_icon far fa-exclamation"></i>

              <i v-if="checkURLState() === 'success_state'" class=" msg_icon far fa-check"></i>


              <clip-loader v-else-if="getLinksLoaders.link_preview" class="loader mt-4" :size="size"
                           :color="colorBlue"></clip-loader>
            </div>
          </div>
          <span class="input-error" v-if="validations.urlLength">URL length should not be greater than 500.</span>
          <span class="input-warning" v-else-if="httpErrorWarning">Links with 'http' might not work. <br> Recommended: "https" links</span>
          <div class="text-right mb-2 link-text">
            <p class="add_utm_link" @click.prevent="$bvModal.show('applyUTMModal')">+ <span>Add UTM</span></p>
            <!--            <apply-utm-link></apply-utm-link>-->
          </div>

          <!--Status message here-->
          <div class="alert-box" :class="computeStateForFallback()"
               v-if="computeIframeErrorPrompt()">
            <div class="inner_box d-flex align-items-center pl-3">
              <p class="mt-0">{{ computeIframeMessage() }}</p>
              <i class="ml-auto fal fa-question-circle custom_tooltip tag_tooltip">
                <div class="tool_tip_box">
                  <div class="inner__box">
                    <span>{{ getSiteDetails.agency_name }} CTA campaign can be applied to only those links which allow iframe. For the destination URLs that do not allow iframe, we will be showing summary page.</span>
                  </div>
                </div>
              </i>
            </div>
          </div>

          <!--Status message here-->
          <div class="alert-box warning" v-if="!isFallbackCtaEnabled() && getLinks.previewErrorMessage === 'notAllowed' && !isBridgeCTACampaign()">
            <div class="inner_box d-flex align-items-center pl-3">
              <p class="mt-0">{{ computeIframeMessage() }}</p>
              <i class="ml-auto fal fa-question-circle custom_tooltip tag_tooltip">
                <div class="tool_tip_box">
                  <div class="inner__box">
                    <span>{{ getSiteDetails.agency_name }} CTA campaign can be applied to only those links which allow iframe. For the destination URLs that do not allow iframe, we will be showing summary page.</span>
                  </div>
                </div>
              </i>
            </div>
          </div>

          <div v-if="getSaveLink._id" class=" input_icon_text d-flex align-items-center "
               :class="{'input-field-error': validations.shorten_url_max_length}">
            <div class="content">
              <div class="url">Short Link</div>
              <div class="name ">

                <div class="create_link_block d-flex align-items-center"
                     :class="{'active': active_input}">

                  <p class="d-inline">{{ getSaveLink.domain.url }}</p>
                  <span class="ellipsis_slug" v-tooltip="getSaveLink.shorten_url" v-if="!isShowShortenUrl"
                        @click="editShortenUrl()">{{ getSaveLink.shorten_url }}</span>
                  <input v-else :disabled="isTrialUserObj.isTrial"
                         v-model="getSaveLink.shorten_url"
                         type="text"
                         ref="editSlugField"
                         @blur="editShortenUrl()"
                         :title='isTrialUserObj.message'
                         class="editable_url d-inline-block">

                </div>

              </div>
            </div>
          </div>
          <div v-if="getSaveLink._id && getSaveLink.qr_code_url" class="text-right link-text">
            <button class="add_utm_link d-inline-flex border-0 bg-transparent"
                    :disabled="getLinksLoaders.qr_code_download" @click="renderQRCode(getSaveLink.qr_code_url)"><span><i
              class="far fa-qrcode" aria-hidden="true"></i> QR Code</span>
              <clip-loader v-if="getLinksLoaders.qr_code_download" class="loader ml-1" :size="'11px'"
                           :color="'#3988fe'"></clip-loader>
            </button>
          </div>
          <span class="input-error" v-if="validations.shorten_url_max_length">{{ slugMessageObj.slug_length }}</span>

          <div class=" mt-3 tag_input mb-3">
            <div class="tag_input_inner" v-on-clickaway="closeTag">
              <div class=" input_icon_text d-flex align-items-center  ">
                <div class="content">
                  <div class="url">Add tags (optional)</div>
                  <div class="name">
                    <div class="validation_input">
                      <input type="text" placeholder="Examples: christmas, blackfriday, evergreen"
                             @focus="addLinkTag($event,getLinks.linkTag)"
                             @blur="blurFocusTag()"
                             autocomplete="off"
                             v-on:keyup="addLinkTag($event,getLinks.linkTag)"
                             class="" v-model="getLinks.linkTag" data-cy="tags">
                    </div>
                  </div>

                </div>
                <div class="avatar mr-0 ml-3">
                  <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                    <div class="tool_tip_box">
                      <div class="inner_box">
                        <p>Tags are for your own reference only and not visible to public.
                          They help you easily sort, identify and analyze your links and
                          campaigns
                          later on.</p>
                      </div>
                    </div>
                  </i>
                </div>
              </div>
              <ul class="tag_list_dd"
                  v-if="getLinks.linkTag.length > 0 && (getSaveLink && getSaveLink.tags && checkTagNotExist(getLinks.linkTag,getSaveLink.tags))">
                <template v-if="getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
                  <li class="old_item" v-for="(tag,index) in getLinks.suggestedTags">

                    <span class="text" @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
                  </li>
                </template>
                <li v-else class="new_item">
                  <span class="text"
                        @click.prevent="addLinkTag($event,getLinks.linkTag,true)">Add new tag "{{
                      getLinks.linkTag
                    }}"</span>
                </li>

              </ul>
              <ul class="tag_list_dd"
                  v-else-if="getLinks.linkTag.length === 0 && getLinks.focus=== true && getLinks.suggestedTags && getLinks.suggestedTags.length > 0">
                <li class="old_item" v-for="(tag,index) in getLinks.suggestedTags">
                  <span class="text" @click.prevent="addTagSuggestion(tag._id,index)">{{ getTagName(tag._id) }}</span>
                </li>
              </ul>
            </div>

            <div class="tag-item-list mt-2 mb-2 " v-if="getSaveLink.tags.length > 0">

              <ul>
                <li v-for="tag in getSaveLink.tags"
                    v-if="getTagName(tag) !== '' && getTagName(tag) && getTagName(tag).length > 0">
                  <span class="text">{{ getTagName(tag) }}</span><span
                  @click.prevent="removeTag(tag)" class="cross_icon"></span>
                </li>

              </ul>
            </div>
          </div>

          <div class=" mb-3 input_icon_text d-flex align-items-center " @click="addNoteStatus = true"
               v-on-clickaway="saveNote">
            <div class="content">
              <div class="url">Add Note</div>
              <div class="name">

                <div class="validation_input">
                  <link-notes v-if="addNoteStatus" :edit="true"></link-notes>
                  <link-notes :edit="false" v-if="!addNoteStatus"></link-notes>
                </div>
              </div>
            </div>

          </div>

          <!-- custom message option display here-->
          <customize-message-component
            v-if="getSaveLink.call_to_action_id && isTypeCTA(getSaveLink.call_to_action_id)"
            @customizeMessageObj="customizeMessageObj($event)"
            :has_custom_message="getSaveLink.has_custom_message"
            :message_headline="getSaveLink.message_headline"
            :message="getSaveLink.message"
            :customizeMessageValidations="customizeMessageValidations"
            :customizeMessage="customizeMessage"
            :call_to_action_id="getSaveLink.call_to_action_id"
          ></customize-message-component>


          <password-protected-component
            @passwordProtectedObj="passwordProtectedObj($event)"
            :password_protected="getSaveLink.password_protected"
            :password="getSaveLink.password"
            :password_protected_logo="getSaveLink.password_protected_logo"
            :self="this"
            :passwordProtectedValidations="passwordProtectedValidations"
            :called="true"
          ></password-protected-component>


          <div class="input_icon_text mt-3">
            <div class="setting_item" :class="{'mb-3': getSaveLink.showLinkExpiry}">
              <div class="text_sec d-inline-block">
                <p>Link Expiry</p>
              </div>
              <div class="status_sec" style="float: right">
                <label class="switch">
                  <input type="checkbox" class="d-none" @click="getLinkExpiry()" :checked="getSaveLink.showLinkExpiry">
                  <div class="slider round"></div>
                </label>
              </div>
            </div>

            <div class=" mb-3 d-flex align-items-center url_input_main "
                 v-if="getSaveLink.showLinkExpiry">

              <div class="content">
                <div class="url">
                  Date
                </div>
                <div class="name">
                  <div class="validation_input">
                    <b-form-datepicker id="example-datepicker" :min="min"
                                       v-model="getSaveLink.link_expire_attributes.expiry"
                                       class="data-picker"></b-form-datepicker>

                  </div>
                  <span class="input-error" v-if="getSaveLink.showLinkExpiry && invalidExpiryDate">
                            Enter the date.
                        </span>
                </div>
                <div class="content mt-3">
                  <div class="url">
                    Time
                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <b-form-timepicker id="datepicker-valid" class="time-picker"
                                         v-model="getSaveLink.link_expire_attributes.expiry_time"></b-form-timepicker>
                    </div>
                    <span class="input-error" v-if="getSaveLink.showLinkExpiry && invalidExpiryTime">
                            Enter the time.
                        </span>
                  </div>
                </div>

                <div v-if="isAgency">
                  <div class=" mb-3 mt-3 d-flex align-items-center url_input_main ">
                    <div class="content">
                      <div class="url">
                        Upload Link Expiry Image URL
                      </div>
                      <div class="name">
                        <div class="validation_input">
                          <input
                            placeholder="Enter the favicon image url (16 x 16 recommended)"
                            v-model="getSaveLink.link_expire_attributes.link_expiry_logo" type="text"
                            class="form-control txt-left-left">
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="getSaveLink.showLinkExpiry && invalidLinkExpiryURl">
                            Enter the valid url.
            </span>
                  <div class=" mb-3 d-flex align-items-center url_input_main ">
                    <div class="content">
                      <div class="name">
                        <div class="validation_input">
                          <input
                            id="link_expiry_logo"
                            placeholder="Enter the favicon image url (16 x 16 recommended)"
                            type="file"
                            @change="uploadLinkExpiryLogo($event)"
                            accept=".png, .jpg, .jpeg, .gif, .ico"
                            class="form-control txt-left-left d-none">
                          <label for="link_expiry_logo" class="upload-meta-image">
                            <i class="fa fa-upload" v-if="!getLoaders.link_expiry_logo"
                               aria-hidden="true"></i>
                            <clip-loader v-if="getLoaders.link_expiry_logo" class="loader" :size="size"
                                         :color="colorBlue"></clip-loader>
                            <span>Upload Image</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--                    custom meta -->
          <div class="input_icon_text mt-3">
            <div class="setting_item" :class="{'mb-3': getSaveLink.showSeo}">
              <div class="text_sec d-inline-block">
                <p>Customize Link Preview</p>
              </div>
              <div class="status_sec" v-tooltip="computeIframeErrorPrompt() ? 'Toggle disabled in case of summary page' : ''" style="float: right">
                <label class="switch">
                  <!--                  <input type="checkbox" @click="showSeo = !showSeo" :checked="getSeoState()">-->
                  <input type="checkbox" class="d-none" :disabled="computeIframeErrorPrompt()" @click="getSeoState()" :checked="getSaveLink.showSeo">
                  <div class="slider round" :class="{'opacity-60': computeIframeErrorPrompt()}"></div>
                </label>
              </div>
            </div>
            <template v-if="getLinksLoaders.customizeLinkPreviewLoader">
              <clip-loader :size="'22px'" :color="'#3988fe'"></clip-loader>
              <p class="text-center text-muted">Scrapping data...</p>
            </template>
            <template v-else>
              <div class="d-flex align-items-center url_input_main " v-if="getSaveLink.showSeo">
                <div class="content">
                  <div class="url">
                    Meta Title
                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <input
                        placeholder="Enter the meta title"
                        v-model="getSaveLink.seo.title" type="text"
                        class="form-control txt-left-left">
                    </div>
                  </div>
                </div>
              </div>
              <span class="input-error"
                    v-if="getSaveLink.showSeo && customizeLinkPreviewValidations.title">{{
                  customizeLinkPreviewMessages.title
                }}</span>
              <span class="input-error"
                    v-else-if="getSaveLink.showSeo && customizeLinkPreviewValidations.titleLength">{{
                  customizeLinkPreviewMessages.titleLength
                }}</span>

              <div class=" mt-3 d-flex align-items-center url_input_main " v-if="getSaveLink.showSeo">
                <div class="content">
                  <div class="url">
                    Meta Description
                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <input
                        placeholder="Enter the meta description"
                        v-model="getSaveLink.seo.description" type="text"
                        class="form-control txt-left-left">
                    </div>
                  </div>
                </div>
              </div>
              <span class="input-error"
                    v-if="getSaveLink.showSeo && customizeLinkPreviewValidations.description">{{
                  customizeLinkPreviewMessages.description
                }}</span>
              <span class="input-error"
                    v-else-if="getSaveLink.showSeo && customizeLinkPreviewValidations.descriptionLength">{{
                  customizeLinkPreviewMessages.descriptionLength
                }}</span>
              <div class=" mt-3 d-flex align-items-center url_input_main " v-if="getSaveLink.showSeo"
                   :class="checkMetaImageURL()">
                <div class="content">
                  <div class="url">
                    Meta Image URL
                  </div>
                  <div class="name">
                    <div class="validation_input">
                      <input
                        placeholder="Enter the meta image url (1200 x 630 px) or more"
                        v-model="getSaveLink.seo.image" type="text"
                        class="form-control txt-left-left">
                    </div>
                  </div>
                </div>
              </div>
              <span class="input-error"
                    v-if="getSaveLink.showSeo && customizeLinkPreviewValidations.url">{{
                  customizeLinkPreviewMessages.url
                }}</span>
              <span class="input-error"
                    v-else-if="getSaveLink.showSeo && customizeLinkPreviewValidations.urlLength">{{
                  customizeLinkPreviewMessages.urlLength
                }}</span>
              <div class="mt-3 mb-3 d-flex align-items-center url_input_main " v-if="getSaveLink.showSeo">
                <div class="content">
                  <div class="name">
                    <div class="validation_input">
                      <input
                        id="custom_meta_image"
                        placeholder="Enter the meta image url"
                        type="file"
                        @change="uploadMetaImage($event)"
                        accept=".png, .jpg, .jpeg, .gif"
                        class="form-control txt-left-left d-none">
                      <label for="custom_meta_image" class="upload-meta-image">
                        <i class="fa fa-upload" v-if="!getLoaders.custom_meta_image"
                           aria-hidden="true"></i>
                        <clip-loader v-if="getLoaders.custom_meta_image" class="loader" :size="size"
                                     :color="colorBlue"></clip-loader>
                        <span>Upload Image</span>
                      </label>
                      <div class="upload-meta-preview d-inline-block"
                           @click="showPreview('seo_preview')">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                        <span>Preview</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <!-- Customize Favicon component -->
          <div class="input_icon_text mb-3 mt-3">
            <div class="setting_item" :class="{'mb-3': getSaveLink.showFavicon}">
              <div class="text_sec d-inline-block">
                <p>Customize Favicon</p>
              </div>
              <div class="status_sec" v-tooltip="computeIframeErrorPrompt() ? 'Toggle disabled in case of summary page' : ''" style="float: right">
                <label class="switch">
                  <input type="checkbox" class="d-none" @click="getFaviconState()" :disabled="computeIframeErrorPrompt()" :checked="getSaveLink.showFavicon">
                  <div class="slider round" :class="{'opacity-60': computeIframeErrorPrompt()}"></div>
                </label>
              </div>
            </div>

            <div class=" mb-3 d-flex align-items-center url_input_main " v-if="getSaveLink.showFavicon"
                 :class="checkMetaImageURL()">
              <div class="content">
                <div class="url">
                  Favicon Image URL
                </div>
                <div class="name">
                  <div class="validation_input">
                    <input
                      placeholder="Enter the favicon image url (16 x 16 recommended)"
                      v-model="getSaveLink.custom_favicon_url" type="text"
                      class="form-control txt-left-left">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="getSaveLink.showFavicon && invalidFavicon">
                            Enter the valid url.
            </span>

            <div class=" mb-3 d-flex align-items-center url_input_main " v-if="getSaveLink.showFavicon">
              <div class="content">
                <div class="name">
                  <div class="validation_input">
                    <input
                      id="custom_favicon"
                      placeholder="Enter the favicon image url (16 x 16 recommended)"
                      type="file"
                      @change="uploadCustomFaviconImage($event)"
                      accept=".png, .jpg, .jpeg, .gif"
                      class="form-control txt-left-left d-none">
                    <label for="custom_favicon" class="upload-meta-image">
                      <i class="fa fa-upload" v-if="!getLoaders.custom_favicon_image_url"
                         aria-hidden="true"></i>
                      <clip-loader v-if="getLoaders.custom_favicon_image_url" class="loader" :size="size"
                                   :color="colorBlue"></clip-loader>
                      <span>Upload Image</span>
                    </label>
                    <div class="upload-meta-preview d-inline-block"
                         @click="showPreview('custom_favicon')">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                      <span>Preview</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Traffic routing rules -->
          <traffic-routing-rules-component
            v-model="getSaveLink.is_routing_rule"
            @input="showTrafficRoutingRules($event)"
            :routing_rules="getSaveLink.routing_rules"
            :campaign_id="getSaveLink.call_to_action_id"
            :self="this"
            :is_ab_test="getSaveLink.is_ab_test"
            :traffic_routing_cta_failed="trafficRoutingCTAFailed"
            :traffic_routing_iframe_loader="trafficRoutingIframeLoader"
            :check_iframe_error="checkTrafficRoutingIframeErrorCount"
          ></traffic-routing-rules-component>

          <!-- A/B Testing Component -->
          <ab-testing-component
            :split_urls="getSaveLink.split_urls"
            :campaignId="getSaveLink.call_to_action_id"
            :campaign="getSaveLink.cta"
            :url="getSaveLink.url"
            :self="this"
            @input="showABTesting($event)"
            :is_ab_test="getSaveLink.is_ab_test"
            :is_routing_rule="getSaveLink.is_routing_rule"
          />


          <!-- Deep links Component -->
          <deep-links-component
            v-model="getSaveLink.is_deep_link_enabled"
            :is_deep_link_allowed="getSaveLink.is_deep_link_allowed"
            :url="getSaveLink.url"
            :deepLinksBrandsList="deepLinksBrandsList"
            :campaignId="getSaveLink.call_to_action_id"
            :self="this"
          ></deep-links-component>

        </template>

      </div>

      <div class="view_bottom">

        <div class="view_bottom_inner text-center">
          <div v-if="getLinksLoaders.store">
            <button :disabled="getLinksLoaders.store" v-if="getSaveLink.id"
                    class="btn---cta  light-blue btn-round btn--loader loader--right">
              <span>Updating Link</span>
              <clip-loader :size="size" :color="color"></clip-loader>
            </button>
            <button :disabled="getLinksLoaders.store" v-else
                    class="btn---cta  light-blue btn-round btn--loader loader--right">
              <span>Creating Link</span>
              <clip-loader :size="size" :color="color"></clip-loader>
            </button>
          </div>
          <div v-else>

            <!--- onboarding link -->

            <template v-if="getGuidedTour.step9">
              <a class="another_link" v-if="getLinks.new_link_created" @click.prevent="createNewLink()">Create
                Another {{ getSiteDetails.agency_name }} Link</a>
              <!--                            <button v-if="getLinks.new_link_created" href="javascript:;"-->
              <!--                                    class="btn-&#45;&#45;cta  btn-blue btn-round btn&#45;&#45;loader loader&#45;&#45;right">-->
              <!--                                <span>Create Another Replug Link</span>-->
              <!--                            </button>-->
              <button v-else-if="!getSaveLink.id"
                      class="btn---cta  light-blue btn-round btn--loader loader--right">
                <span>Save Link</span>
              </button>

              <button v-else class="btn---cta  btn-blue btn-round btn--loader loader--right">
                <span>Update Link</span>
              </button>
            </template>
            <template v-else>

              <!--              <button class="btn-&#45;&#45;cta btn-blue btn-round" v-if="getLinks.new_link_created" @click.prevent="createNewLink()">Create-->
              <!--                Another Replug Link</button>-->

              <!--                            <button v-if="getLinks.new_link_created" @click.prevent="createNewLink()"-->
              <!--                                    class="btn-&#45;&#45;cta  btn-blue btn-round btn&#45;&#45;loader loader&#45;&#45;right">-->
              <!--                                <span>Create Another Replug Link</span>-->
              <!--                            </button>-->
              <div v-if="getLinks.new_link_created"></div>
              <button v-else-if="!getSaveLink._id" @click.prevent="store()"
                      :disabled="!isValidURL(this.getSaveLink.url) || trafficRoutingIframeLoader || getLinksLoaders.link_preview"
                      class="btn---cta  btn-blue btn-round btn--loader loader--right">
                <span>Save Link</span>
                <clip-loader v-if="getLinksLoaders.store" class="loader" :size="'14px'"
                             :color="'#ffffff'"></clip-loader>
              </button>
              <button v-else :disabled="!isValidURL(this.getSaveLink.url) || trafficRoutingIframeLoader"
                      @click.prevent="updateExistingLink()"
                      class="btn---cta  btn-blue btn-round btn--loader loader--right">
                <span>Update Link</span>
                <clip-loader v-if="getLinksLoaders.store" class="loader" :size="'14px'"
                             :color="'#ffffff'"></clip-loader>
              </button>
            </template>

          </div>

        </div>

      </div>
    </div>

    <b-modal modal-class="modal_basic modal_basic_seo_preview modal-seo-preview" id="seo-preview" hide-footer
             hide-header>

      <div class="heading">
        <h3 class="d-inline-block title">
          <template>
            Preview
          </template>
        </h3>
        <img @click="$bvModal.hide('seo-preview')" class="close_modal" src="/assets/img/cross.svg" alt="">
      </div>
      <div class="modal_content" v-if="previewCall == 'seo_preview' && getSaveLink.seo">
        <img :src="getSaveLink.seo.image ? getSaveLink.seo.image : '/assets/img/notfound.png'">
        <div class="heading">
          <div class="title" v-if="getSaveLink.seo.title == ''">Meta Title</div>
          <div class="title" v-else>{{ getSaveLink.seo.title }}</div>
          <div class="description" v-if="getSaveLink.seo.description == ''">Meta Description</div>
          <div class="description" v-else>{{ getSaveLink.seo.description }}</div>
        </div>
      </div>
      <div class="modal_content" v-else>
        <img :src="getSaveLink.custom_favicon_url ? getSaveLink.custom_favicon_url : '/assets/img/notfound.png'">
      </div>
    </b-modal>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import ApplyUTMLink from '@/views/pages/setting/utm/ApplyUTMLink.vue'
import NewLinkCreated from './NewLinkCreated'
import LinkNotes from './LinkNotes'
import {campaignTypes, linkTypes} from '@/state/modules/mutation-types'
import ApplyUTMModal from '@/views/pages/setting/utm/ApplyUTMModal'
import {
  replugLinksValidation,
  customizeLinkPreviewValidations,
  customizeLinkPreviewMessages,
  abTestingObjValidations,
  passwordProtectedValidations,
  slugMessages, customizeMessageValidations, customizeMessage
} from '@/common/attributes'
import URL from '@/ui/URL'
import DeepLinksComponent from '@/views/pages/links/DeepLinksComponent'
import ABTestingComponent from '@/views/pages/links/links-component/ABTestingComponent'
import TrafficRoutingRulesComponent from '@/views/pages/links/TrafficRoutingRulesComponent'
import PasswordProtectedComponent from '@/views/pages/links/PasswordProtectedComponent'
import CustomizeMessageComponent from '@/views/pages/links/CustomizeMessageComponent'

export default (
  {
    data () {
      return {
        size: '14px',
        color: '#fff',
        colorBlue: '#2d99ec',
        active_input: false,
        addNoteStatus: false,
        validations: replugLinksValidation,
        isShowShortenUrl: false,
        originalUrl: '',
        //                    focus:false,
        invalidExpiryDate: false,
        invalidExpiryTime: false,
        invalidFavicon: false,
        invalidLinkExpiryURl: false,
        isPermission: false,
        min: new Date(),
        httpErrorWarning: false,
        isTrialUserObj: {},
        oldDomainObj: {},
        customizeLinkPreviewValidations: customizeLinkPreviewValidations,
        customizeLinkPreviewMessages: customizeLinkPreviewMessages,
        abTestingObjValidations: abTestingObjValidations,
        debounce: null,
        deepLinksBrandsList: [],
        trafficRoutingCTAFailed: false,
        trafficRoutingIframeLoader: false,
        checkTrafficRoutingIframeErrorCount: 0,
        passwordProtectedValidations: passwordProtectedValidations,
        slugMessageObj: slugMessages,
        customizeMessageValidations: customizeMessageValidations,
        customizeMessage: customizeMessage,
        previewCall: '',
        metaFetchStatus: true
      }
    },
    async created () {
      await this.fetchWhitelabelAppSettings()
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, '')
      // get the link preview if the post is of type CTA. As if we are editing the link this does not happen.
      this.isTrialUserObj = this.isTrialUser(this.getSubscription)
      if (this.$route.query.shorten_url) {
        if (this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign(this.getSaveLink.cta.cta_type)) this.linkPreview()
      }
      this.originalUrl = this.getSaveLink.shorten_url
      await this.fetchCampaignOnDropdown()
      this.oldDomainObj = this.getSaveLink.domain
      this.deepLinksBrandsList = await this.showDeepLinksBrandsList()
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, this.getSaveLink.cta)
    },
    async mounted () {
      if (window.localStorage.getItem('access_password_protected') === 'true') {
        this.isPermission = true
      }
      await this.getLinkExpiry(true)
      await this.getSeoState(true)
      await this.getFaviconState(true)
      this.resetValidations()
    },
    components: {
      Dropdown,
      ClipLoader,
      'apply-utm-link': ApplyUTMLink,
      NewLinkCreated,
      LinkNotes,
      'apply-utm-modal': ApplyUTMModal,
      'url': URL,
      'ab-testing-component': ABTestingComponent,
      'deep-links-component': DeepLinksComponent,
      'traffic-routing-rules-component': TrafficRoutingRulesComponent,
      'password-protected-component': PasswordProtectedComponent,
      'customize-message-component': CustomizeMessageComponent
    },
    computed: {
      ...mapGetters([
        'getComponentCampaignList',
        'getCallToActions',
        'getSaveLink',
        'getLinks',
        'getLoaders',
        'getBrands',
        'getLinksLoaders',
        'getSubscription',
        'getDomains',
        'getDefaultDomainEnv',
        'getCampaignLoaders',
        'getCampaigns',
        'getCampaignsList'
      ])
    },
    methods: {
      ...mapActions([
        'fetchWhitelabelAppSettings',
        'setAllCallToActionsFetchStatus',
        'setFetchTagsStatuss',
        'setAllBrandsFetchStatus',
        'createQRCode',
        'renderQRCode',
        'storeLink',
        'uploadCustomMetaImage',
        'uploadCustomFaviconImage',
        'verifyImageResolution',
        'fetchCampaignsList',
        'uploadPasswordProtectedLogo',
        'uploadLinkExpiryLogo',
        'fetchCustomizeLinkPreviewObj',
        'setUTMPreviousId',
        'showDeepLinksBrandsList',
        'setTrafficRoutingRules'
      ]),
      /**
       * computing the iframe error prompt
       * @returns {false|boolean|*}
       */
      computeIframeErrorPrompt() {
        return this.isFallbackCtaEnabled() && this.getLinks.previewErrorMessage === 'notAllowed' && this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign()
      },
      /**
       * computing the error message for iframe
       * @returns {string}
       */
      computeIframeMessage() {
        if(!this.metaFetchStatus) {
          return `URL can't be validated. Please enter valid URL.`
        }
        return this.computeIframeErrorPrompt() ? 'Summary page applied to your shortened URL.' : 'Your link cannot be shortened because the destination URL does not allow iFrame for the CTA campaign. Please enable the summary page as a fallback from Miscellaneous Settings.'
      },
      /**
       * computing the state error state for fallback url
       * @returns {string}
       */
      computeStateForFallback () {
        return this.computeIframeErrorPrompt() ? (this.metaFetchStatus ? 'success' : 'warning' ) : 'warning'
      },
      showPreview (calledBy = '') {
        this.previewCall = calledBy
        this.$bvModal.show('seo-preview')
      },
      /**
       *  getting the selected campaign name
       * @returns {string|*|string}
       */
      getSelectedCampaign () {
        // if creating new rss so cta_id is null
        if (!this.getSaveLink.call_to_action_id) {
          return 'Select your Campaign'
        }

        // while editing rss so ge campaign name from list if not then get from rss feed list
        const campaignName = this.getCampaignName(this.getSaveLink.call_to_action_id)

        if (!campaignName) {
          const campaign = this.getLinks.items.find(item => this.getSaveLink.call_to_action_id === item.call_to_action_id)
          return campaign && campaign.cta && campaign.cta.name ? campaign.cta.name : 'Select your Campaign'
        }

        // is selected campaign exists then simple returs its name
        return campaignName && campaignName.name ? campaignName.name : 'Select your Campaign'

      },
      async checkLinkPreviewWithCTA (event) {
        // check the call to action type, based on that, we will show the widget etc.
        this.getSaveLink.url = event
        this.setUTMPreviousId('')
        const isCTAType = this.isTypeCTA(this.getSaveLink.call_to_action_id)
        if (!isCTAType) {
          this.checkDeepLink(this.getSaveLink.url, this.deepLinksBrandsList)
        }
        if (this.getSaveLink.url && this.getSaveLink.url.length > 8) {
          this.getSaveLink.url = this.isCTAConcatenation(this.getSaveLink.url)
          if (isCTAType && !this.isBridgeCTACampaign()) this.linkPreview()
        }
        this.httpErrorWarning = this.getSaveLink.url.includes('http://')
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {

          // if fallback enabled and link not allowed for iframe
          if (this.computeIframeErrorPrompt() || !this.metaFetchStatus) {
            this.getSaveLink.showSeo = true
            this.getSaveLink.showFavicon = true
            this.setCustomizeLinkPreview()
            return
          }

          if (this.getSaveLink.showSeo) {
            this.setCustomizeLinkPreview()
            return
          }
        }, 600)
      },
      handleBlur (e) {
        this.getSaveLink.url = this.isCTAConcatenation(this.getSaveLink.url)
        if (this.isTypeCTA(this.getSaveLink.call_to_action_id)) this.linkPreview()
      },
      showABTesting (event) {
        this.getSaveLink.is_ab_test = event
      },
      showTrafficRoutingRules (event) {
        this.getSaveLink.is_routing_rule = event
      },
      showPasswordProtected (event) {
        this.getSaveLink.password_protected = event
      },
      passwordProtectedObj (event) {
        this.getSaveLink.password = event.password
        this.getSaveLink.password_protected_logo = event.password_protected_logo
        this.getSaveLink.password_protected = event.password_protected
      },
      customizeMessageObj (event) {
        this.getSaveLink.has_custom_message = event.has_custom_message
        this.getSaveLink.message_headline = event.message_headline
        this.getSaveLink.message = event.message
      },
      async setCustomizeLinkPreview () {
        let linkPreviewObj = await this.fetchCustomizeLinkPreviewObj({'url': this.getSaveLink.url})
        this.metaFetchStatus = linkPreviewObj.status

        if(!this.metaFetchStatus) {
          this.resetMetadata()
          return
        }

        this.getSaveLink.custom_favicon_url = linkPreviewObj.data.favicon_url ? linkPreviewObj.data.favicon_url : ''
        this.getSaveLink.seo.title = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.title : null
        this.getSaveLink.seo.description = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.description : null
        if (linkPreviewObj.data.facebook.image && (linkPreviewObj.data.facebook.image.includes('http://') || linkPreviewObj.data.facebook.image.includes('https://'))) {
          this.getSaveLink.seo.image = linkPreviewObj.data.facebook.image
        } else {
          this.getSaveLink.seo.image = linkPreviewObj.data.images[0]
        }
      },
      /**
       * resetting the meta data if  link has no meta data
       */
      resetMetadata() {
        console.log('reset')
        this.getSaveLink.custom_favicon_url =  ''
        this.getSaveLink.seo.title =  null
        this.getSaveLink.seo.description = null
        this.getSaveLink.seo.image = ''
      },
      async store () {
        if (!this.isValidURL(this.getSaveLink.url)) return

        if(this.getLinksLoaders.store) {
          return
        }

        if (this.getSaveLink.password_protected && !this.isPasswordProtectionValid(this.passwordProtectedValidations, this.getSaveLink.password_protected, this.getSaveLink.password, this.getSaveLink.password_protected_logo)) {
          this.$store.dispatch('toastNotification', {
            message: 'Password Protection validation failed.',
            type: 'error'
          })
          return
        }

        // customize message
        this.getSaveLink.has_custom_message = this.getSaveLink.has_custom_message && this.isTypeCTA(this.getSaveLink.call_to_action_id)
        if (this.getSaveLink.has_custom_message && !this.isCustomizeMessageValid(this.customizeMessageValidations, this.getSaveLink.message_headline, this.getSaveLink.message)) {
          this.$store.dispatch('toastNotification', {
            message: 'Customize message validation failed.',
            type: 'error'
          })
          return
        }

        if (!this.getSaveLink.showSeo) {
          this.getSaveLink.seo = {
            title: null,
            description: null,
            image: null
          }
        }

        // if fallback enabled and no link meta fetched
        if(this.computeIframeErrorPrompt() && !this.metaFetchStatus) {
          this.$store.dispatch('toastNotification', {message: `URL can't be validated. Please enter valid URL.`, type: 'error'})
          return
        }

        if (this.getLinksLoaders.link_preview === false && this.checkURLState() === 'warning_state' && this.isTypeCTA(this.getSaveLink.call_to_action_id)) {
          this.$store.dispatch('toastNotification', {
            message: 'Your link cannot be shortened because the destination URL does not allow iFrame for the CTA campaign. Please enable the summary page as a fallback from Miscellaneous Settings.',
            type: 'error'
          })
          return
        }

        if (this.getSaveLink.showLinkExpiry && this.getSaveLink.link_expire_attributes.expiry == null) {
          this.invalidExpiryDate = true
          return
        } else {
          this.invalidExpiryDate = false
        }

        this.invalidExpiryDate = !!(this.getSaveLink.showLinkExpiry && this.getSaveLink.link_expire_attributes.expiry.length === 0)

        let customizeLinkPreviewValidate = false
        if (this.getSaveLink.showSeo) {
          this.customizeLinkPreviewValidations.title = this.requiredCheck(this.getSaveLink.seo.title)
          this.customizeLinkPreviewValidations.titleLength = !this.maxLength(this.getSaveLink.seo.title, 500)
          this.customizeLinkPreviewValidations.description = this.requiredCheck(this.getSaveLink.seo.description)
          this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(this.getSaveLink.seo.description, 500)
          this.customizeLinkPreviewValidations.url = this.requiredCheck(this.getSaveLink.seo.image)
          this.customizeLinkPreviewValidations.urlLength = !this.maxLength(this.getSaveLink.seo.image, 500)
          customizeLinkPreviewValidate = !Object.keys(this.customizeLinkPreviewValidations).every(k => this.customizeLinkPreviewValidations[k] == false)
        }

        if (customizeLinkPreviewValidate) return

        if (this.getSaveLink.showFavicon && this.getSaveLink.custom_favicon_url.length === 0) {
          this.invalidFavicon = true
          return
        }
        if (this.getSaveLink.showFavicon && this.getSaveLink.custom_favicon_url.length > 0 && !this.isValidURL(this.getSaveLink.custom_favicon_url)) {
          this.invalidFavicon = true
          return
        }

        if (this.invalidLinkExpiryURl && this.getSaveLink.link_expire_attributes.link_expiry_logo.length === 0) {
          this.invalidLinkExpiryURl = true
          return
        }

        if (this.invalidLinkExpiryURl && this.getSaveLink.link_expire_attributes.link_expiry_logo.length > 0 && !this.isValidURL(this.getSaveLink.link_expire_attributes.link_expiry_logo)) {
          this.invalidLinkExpiryURl = true
          return
        }

        if (this.getSaveLink.link_expire_attributes && !this.getSaveLink.link_expire_attributes.link_expiry_logo) {
          this.getSaveLink.link_expire_attributes.link_expiry_logo = null
        }

        if (!this.getSaveLink.showLinkExpiry) {
          this.getSaveLink.link_expire_attributes = {
            expiry: '',
            expiry_time: '00:00:00',
            expiry_datetime: '',
            link_expiry_logo: ''
          }
        }
        if (!this.getSaveLink.showFavicon) {
          this.getSaveLink.custom_favicon_url = null
        }

        if (this.getSaveLink.showLinkExpiry && this.getSaveLink.link_expire_attributes.expiry) {
          let d = new Date(this.getSaveLink.link_expire_attributes.expiry)
          d.setHours(this.getSaveLink.link_expire_attributes.expiry_time.substring(0, 2))
          d.setMinutes(this.getSaveLink.link_expire_attributes.expiry_time.substring(3, 5))
          this.getSaveLink.link_expire_attributes.expiry_datetime = d.toISOString()
        }

        this.validations.url = this.requiredCheck(this.getSaveLink.url)
        this.getSaveLink.url = this.urlConcatenation(this.getSaveLink.url)
        this.validations.urlValid = !this.isValidURL(this.getSaveLink.url)
        let result = Object.keys(this.validations).every(k => this.validations[k] === false)

        // here we are rechecking the validation of ab testing
        if (this.getSaveLink.is_ab_test) {
          //before storing checking the ab testing validations
          if (this.getSaveLink.split_urls.length <= 1) {
            this.$store.dispatch('toastNotification', {
              message: 'At least two links are required for A/B Testing.',
              type: 'error'
            })
            return

            this.getSaveLink.split_urls = []
          }

          const validABValidation = this.getSaveLink.split_urls
            .every((item) => Object.keys(item.validations).every(k => item.validations[k] === false))

          if (this.getSaveLink.split_urls.length && !validABValidation) {
            this.$store.dispatch('toastNotification', {message: 'A/B Testing Validations failed.', type: 'error'})
            return
          }

          const isValidWeight = this.isValidWeight(this.getSaveLink.split_urls)

          if (this.getSaveLink.split_urls.length && !isValidWeight) {
            this.$store.dispatch('toastNotification', {message: 'A/B Testing Validations failed.', type: 'error'})
            return
          }
        }

        if (this.getSaveLink.split_urls.length <= 1) {
          this.getSaveLink.split_urls = []
        }

        const payload = JSON.parse(JSON.stringify(this.getSaveLink))

        if (payload.is_deep_link_enabled && !payload.is_deep_link_allowed) {
          this.$store.dispatch('toastNotification', {message: 'Deep Link Validation failed.', type: 'error'})
          return
        }

        if (payload.split_urls && payload.split_urls.length > 0) {
          payload.split_urls.forEach((item) => {
            delete item['validations']
            delete item['isEdit']
            delete item['loader']
          })
        }

        /// traffic routing /////

        if (payload.is_routing_rule) payload.is_routing_rule = !!payload.routing_rules.length
        if (this.getSaveLink.is_routing_rule) this.getSaveLink.is_routing_rule = !!payload.routing_rules.length

        // check if validations failed for traffic routing
        if (this.getSaveLink.is_routing_rule) {
          this.trafficRoutingIframeLoader = true
          this.checkTrafficRoutingIframeErrorCount = 0
          if (this.isTypeCTA(this.getSaveLink.call_to_action_id)) {

            const uniqueThen = [...new Set(this.getSaveLink.routing_rules.map((item) => {return item.then}))]

            const promises = uniqueThen.map((item) => {
              return this.checkLinkPreview(item, this.getSaveLink.call_to_action_id)
            })

            const apiData = await Promise.all(promises)
            apiData.filter((item, index) => {
              if (item.data.status == false) {
                this.checkTrafficRoutingIframeErrorCount += this.getSaveLink.routing_rules.filter(item => item.then === uniqueThen[index]).length
              }
            })

            if (this.checkTrafficRoutingIframeErrorCount) {
              this.trafficRoutingIframeLoader = false
              this.trafficRoutingCTAFailed = true
              return
            }
          }
        }
        this.trafficRoutingCTAFailed = false
        this.trafficRoutingIframeLoader = false

        if (this.getSaveLink.is_routing_rule && this.getSaveLink.routing_rules_edit) {
          const rules = this.getSaveLink.routing_rules.map(rule => {
            const blocks = rule.blocks.map(block => {
              return block.reduce((initialVal, currentVal) => {
                initialVal[currentVal.key] = currentVal.value.map(item => item.key)
                return initialVal
              }, {})
            })

            return {
              blocks,
              then: rule.then
            }
          })
          payload.routing_rules = rules
        }

        // if campaign type bridge page
        if (this.isBridgeCTACampaign() && result && (!this.getSaveLink.showLinkExpiry || !this.invalidExpiryDate) && this.checkMetaImageURL('error_state_meta') !== 'error_state_meta') {
          return this.storeLink(payload)
        }

        // if fallback enabled
        if (this.computeIframeErrorPrompt()) {
          return this.storeLink(payload)
        }

        if (result && (!this.getSaveLink.showLinkExpiry || !this.invalidExpiryDate) && this.checkMetaImageURL('error_state_meta') !== 'error_state_meta') {
          return this.storeLink(payload)
        }
      },
      resetValidations () {
        this.customizeLinkPreviewValidations = {
          title: false,
          titleLength: false,
          description: false,
          descriptionLength: false,
          url: false,
          urlLength: false,
          urlValid: false
        }
      },
      addNote () {
        this.addNoteStatus = !this.addNoteStatus
      },
      saveNote () {
        this.addNoteStatus = false
      },
      selectCampaignForLink (campaign) {
        this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
        this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, campaign)
        this.isCampaignChange = true
        const httpPrefix = 'http://'
        const httpsPrefix = 'https://'
        if (this.getSaveLink.url.includes(httpPrefix)) {
          this.getSaveLink.url = httpsPrefix + this.getSaveLink.url.substring(httpPrefix.length)
        }
        this.$store.commit(linkTypes.SET_LINK_CAMPAIGN_ID, campaign._id)
        // this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, JSON.parse(JSON.stringify(campaign)))
        this.$set(this.getSaveLink, 'cta', campaign)
        let domainObj = this.getDomains.items.find((obj => {
          return obj._id === campaign.domainId
        }))

        if (this.oldDomainObj && this.getSaveLink.is_default_domain && !domainObj && (this.oldDomainObj.url !== this.getDefaultDomainEnv.link.url)) {
          return this.getSaveLink.domain = this.oldDomainObj
        }

        if (!domainObj && this.getDefaultDomainEnv) {
          this.getSaveLink.domain = {
            netloc: this.getDefaultDomainEnv.link.netloc,
            registered_domain: this.getDefaultDomainEnv.link.registered_domain,
            scheme: this.getDefaultDomainEnv.link.scheme,
            url: this.getDefaultDomainEnv.link.url
          }
          return
        }
        if (domainObj && domainObj.url.substr(-1) !== '/') {
          domainObj.url = domainObj.url + '/'
        }

        this.getSaveLink.domain = domainObj
      },

      checkURLState () {
        const url = this.getSaveLink.url ? this.getSaveLink.url.trim() : ''
        if (url.length > 0) {
          this.getSaveLink.url = this.getSaveLink.url.trim()
          if (!this.isValidURL(this.getSaveLink.url)) {
            return 'error_state'
          } else if (this.isBridgeCTACampaign()) {
            return 'success_state'
          } else if (this.computeIframeErrorPrompt()) {
            if(!this.metaFetchStatus) return 'warning_state'
            return 'success_state'
          } else if (!this.getLinksLoaders.link_preview && !this.getLinks.previewStatus && this.isTypeCTA(this.getSaveLink.call_to_action_id)) {
            return 'warning_state'
          } else if (!this.getLinksLoaders.link_preview && this.getLinks.previewStatus && this.isTypeCTA(this.getSaveLink.call_to_action_id)) {
            return 'success_state'
          } else if (this.validations.urlLength) {
            return 'input-field-error'
          }
        }
      },
      checkMetaImageURL () {
        if (this.getSaveLink.seo && this.getSaveLink.seo.image && this.getSaveLink.seo.image.length > 0 && !this.isValidURL(this.getSaveLink.seo.image)) {
          return 'error_state_meta'
        }
      },
      async updateExistingLink () {
        let res = await this.store()
        if (res && res.data.status) {
          this.$router.push({name: 'links'})
        } else {
          this.getSaveLink.shorten_url = this.originalUrl
        }
      },
      async saveShortenUrl () {
        this.isShowShortenUrl = false
        this.updateExistingLink()
      },
      getLinkExpiry (mounted = false) {
        const expiry = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry
        const expiry_time = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry_time
        const expiry_datetime = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry_datetime
        if (mounted && this.getSaveLink._id && (expiry && expiry_time && expiry_datetime)) {
          this.getSaveLink.showLinkExpiry = true
        } else if (mounted && this.getSaveLink._id && (expiry && expiry_time && expiry_datetime)) {
          this.getSaveLink.showLinkExpiry = true
        } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.link_expire_attributes) {
          this.getSaveLink.link_expire_attributes = {
            expiry: '',
            expiry_time: '00:00:00',
            expiry_datetime: '',
            link_expiry_logo: ''
          }
          this.getSaveLink.showLinkExpiry = true
        } else if (!mounted && this.getSaveLink.link_expire_attributes) {
          this.getSaveLink.showLinkExpiry = !this.getSaveLink.showLinkExpiry
          this.getSaveLink.link_expire_attributes = {
            expiry: '',
            expiry_time: '00:00:00',
            expiry_datetime: '',
            link_expiry_logo: ''
          }
        }
      },
      updateExpiryDate () {
        if (this.getSaveLink.link_expire_attributes.expiry != null) {
          this.invalidExpiryDate = false
        } else {
          this.invalidExpiryDate = true
        }
      },
      async getSeoState (mounted = false) {
        const seoExists = this.getSaveLink.seo && (
          (this.getSaveLink.seo.title && this.getSaveLink.seo.title.length) ||
          (this.getSaveLink.seo.description && this.getSaveLink.seo.description.length) ||
          (this.getSaveLink.seo.image && this.getSaveLink.seo.image.length)
        )
        if (mounted && this.getSaveLink._id && !seoExists) {
          this.getSaveLink.showSeo = false
        } else if (mounted && this.getSaveLink._id && seoExists) {
          this.getSaveLink.showSeo = true
        } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.seo) {
          // setting default value for seo if the key doesn't exist
          this.getSaveLink.seo = {
            title: null,
            description: null,
            image: null
          }
          this.getSaveLink.showSeo = true
        } else if (!mounted && this.getSaveLink.seo) {
          this.getSaveLink.showSeo = !this.getSaveLink.showSeo
        }
        if (this.getSaveLink.showSeo && !this.getSaveLink._id) {
          await this.setCustomizeLinkPreview()
        }
      },
      getFaviconState (mounted = false) {
        if (mounted && this.getSaveLink._id && !this.getSaveLink.custom_favicon_url) {
          this.getSaveLink.showFavicon = false
        } else if (mounted && this.getSaveLink._id && this.getSaveLink.custom_favicon_url) {
          this.getSaveLink.showFavicon = true
        } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.custom_favicon_url) {
          this.getSaveLink.custom_favicon_url = ''
          this.getSaveLink.showFavicon = !this.getSaveLink.showFavicon
        } else if (!mounted && (this.getSaveLink.custom_favicon_url || this.getSaveLink.custom_favicon_url.length >= 0)) {
          this.getSaveLink.showFavicon = !this.getSaveLink.showFavicon
          this.getSaveLink.custom_favicon_url = ''
        }
      },
      closeTag () {
        this.getLinks.focus = false
      },
      async uploadMetaImage (event) {
        await this.uploadCustomMetaImage(event)
        this.verifyImageResolution(this.getSaveLink.seo.image)
      },
      editShortenUrl () {
        this.isShowShortenUrl = !this.isShowShortenUrl
        setTimeout(() => {
          this.$refs.editSlugField.focus()
        })
      }
    },
    watch: {
      'getSaveLink.seo.title' (value) {
        this.customizeLinkPreviewValidations.title = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.titleLength = !this.maxLength(value, 500)
      },
      'getSaveLink.seo.description' (value) {
        this.customizeLinkPreviewValidations.description = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(value, 500)
      },
      'getSaveLink.custom_favicon_url' (value) {
        this.invalidFavicon = value && value.length > 0 && !this.isValidURL(value)
      },
      'getSaveLink.seo.image' (value) {
        this.customizeLinkPreviewValidations.url = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.urlLength = !this.maxLength(value, 500)
        this.customMetaImageUrl()
      },
      'getSaveLink._id' (value) {
        if (value) {
          this.addNoteStatus = true
        }
      },
      'getSaveLink.call_to_action_id' (value) {
        const isCTA = this.isTypeCTA(this.getSaveLink.call_to_action_id)
        if (!isCTA || this.isBridgeCTACampaign()) {
          return
        }
        this.getSaveLink.is_deep_link_enabled = false
        this.getSaveLink.is_deep_link_allowed = false
        this.linkPreview()
      },
      'getSaveLink.shorten_url' (value) {
        if (value) {
          this.validations.shorten_url_max_length = this.checkSlugLength(value)
        }
      },
      'getLinksLoaders.link_preview' (value) {

        if(this.getSaveLink.showSeo) {
          return;
        }

        // if fallback enabled and link not allowed for iframe
        if (this.computeIframeErrorPrompt() || !this.metaFetchStatus) {
          this.getSaveLink.showSeo = true
          this.getSaveLink.showFavicon = true
          this.setCustomizeLinkPreview()
          return
        }

        if (this.getSaveLink.showSeo) {
          this.setCustomizeLinkPreview()
          return
        }
      }
    }
  })
</script>

<style lang="less">
/*.cta_main_view .view_inner .view_center .create_link_block span {*/
/*    min-width: none;*/
/*}*/
.another_link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

[type="date"] {
  background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}

.input_expiry {
  border: 1px solid #ccc !important;
  border-radius: 10px;
  padding: 5px;
  padding-right: 30px;
}

.upload-meta-image {
  border: 2px dashed #c7c7c7;
  padding: 10px !important;
  border-radius: 7px;
  cursor: pointer;

  i {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #3988fe;
  }

  span {
    font-size: 12px;
  }
}

.upload-meta-preview {
  background-color: #ebf2fb;
  /*border: 2px dashed #c7c7c7;*/
  /*padding: 10px;*/
  border-radius: 7px;
  cursor: pointer;
  float: right;
  padding: 10px 20px;

  i {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #3988fe;
  }

  span {
    font-size: 12px;
  }
}

.error_state_meta {
  border-bottom: 2px solid #f76a60 !important;
  transition: 1s;
}

.modal_basic .modal-dialog .modal-body .close_modal {
  float: right;
  right: 28px;
  top: 20px;
}

.modal_basic_seo_preview {
  .modal-dialog {
    max-width: 600px !important;
    transform: translateY(50%);
  }

  .modal-body {
    padding: 30px !important;
  }

  .modal_content {
    /*padding-top: 36px;*/
    border-radius: 10px !important;

    .heading {
      margin-bottom: 5px !important;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #1F216E;
      /*padding: 5px 0px;*/
      padding-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .description {
      font-size: 13px;
      color: #1C2233;
      /*padding: 5px 0px;*/
      padding-top: 15px;
      padding-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    img {
      width: 100%;
      height: 300px;
      border-radius: 10px;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #1F216E;
}

.data-picker, .time-picker {
  label {
    padding-top: 0.5rem !important;
    font-size: smaller !important;
    color: rgba(49, 58, 81, 0.6) !important;
  }

  button {
    svg {
      font-size: 110% !important;
    }
  }
}

.ellipsis_slug {
  width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
