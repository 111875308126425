import { mapActions, mapGetters } from 'vuex'

const brandsMixin = {

  computed: {

    ...mapGetters([
      'getBrands',
      'getBrand',
      'getComponentBrandList',
      'getProfile',
      'getSubscription',
      'getCampaignAdd',
      'getComponentCampaignList'
    ])

  },
  methods: {

    ...mapActions([
      'setBrands', 'setBrandsTotalItems', 'setBrandsLimit', 'setBrandsPage',
      'setBrandsTotalPages', 'setAllBrands', 'setAllBrandsFetchStatus', 'setLinksFetchStatus', 'setAllCallToActionsFetchStatus',
      'setBrandArchiveStatus', 'setBrandDate', 'setArchiveStatus', 'setModalData', 'setModalStatus'

    ]),
    // Here i am replacing '/assets/img/profile-default.png' center center / cover;'
    // with https://storage.googleapis.com/replug-assets/web_app/b7a56101-f0fb-4b9d-a3e5-8d0dc32e43f5.jpg
    brandImageLink (avatar) {
      if (avatar && (avatar.trim()).length > 0) {
        return 'background: url(' + avatar + ') center center / cover;'
      } else {
        return 'background: url(' + 'https://storage.googleapis.com/replug-assets/web_app/b7a56101-f0fb-4b9d-a3e5-8d0dc32e43f5.jpg' + ') center center / cover;'
      }
    },

    getBrandById (brandId) {
      return this.getComponentBrandList.find(item => item._id === brandId)
    },

    referralLink () {
      return 'https://replug.io/?ref=' + this.getProfile.referral_id
    },
    /**
     *  getting the selected brand name
     * @returns {string|*|string}
     */
    getSelectedBrandObj (brandId = '') {

      if (!brandId) {
        return {
          name: '',
          avatar: ''
        }
      }

      // while editing rss so ge campaign name from list if not then get from rss feed list
      const brandName = this.getBrandById(brandId)
      if (!brandName) {
        const brandObj = this.getComponentCampaignList.find(item => brandId === item.brand_id)
        return {
          name: brandObj && brandObj.name ? brandObj.name : '',
          avatar: brandObj && brandObj.avatar ? brandObj.avatar : null
        }
      }

      // is selected campaign exists then simple returs its name
      return {
        name: brandName && brandName.name ? brandName.name : '',
        avatar: brandName && brandName.avatar ? brandName.avatar : null
      }
    }
  }
}

export { brandsMixin }
