import requests from "../../mixins/http-lib";
import {API_URL} from "../../constants";
import {subscriptionTypes} from "./mutation-types";

export default ({
  state: {
    subscriptions: {},
    paddle: {
      subscriptions: {},
      products: {},
    }
  },
  mutations: {
    [subscriptionTypes.SET_SUBSCRIPTIONS_LIST](state, value) {
      state.subscriptions = value
    },
    [subscriptionTypes.SET_PADDLE_PRODUCTS_LIST](state, value) {
      state.paddle.products = value
    },
    [subscriptionTypes.SET_PADDLE_SUBSCRIPTIONS_LIST](state, value) {
      state.paddle.subscriptions = value
    }
  },
  actions: {
    fetchSubscriptionsList(context) {
      requests.post(`${API_URL}subscriptions/list`,).then(res => {
        if (res.data.status) {
          context.commit(subscriptionTypes.SET_SUBSCRIPTIONS_LIST, res.data.data)
        }
      }).catch(err => {

      })
    },
    async fetchPaddleSubscriptionList(context) {
      await requests.post(`${API_URL}subscriptions/paddle`,).then(res => {
        if (res.data.data) {
          context.commit(subscriptionTypes.SET_PADDLE_PRODUCTS_LIST, res.data.data.products)
          context.commit(subscriptionTypes.SET_PADDLE_SUBSCRIPTIONS_LIST, res.data.data.subscriptions)
        }
      }).catch(err => {

      })
    }
  },
  getters: {
    getSubscriptionsList: state => {
      return state.subscriptions
    },
    getPaddlePlans: state=>{
      return state.paddle
    }
  }
})
