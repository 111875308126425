<template>
  <div>
    <is-offline></is-offline>
    <div class="setpassword">
      <div class="setpassowrd_inner container">
        <div class="top_content mb-5">
          <h2>Welcome! Almost done setting up your account</h2>
          <p>To keep your account easily accessable and fully secured,</p>
          <p>we'd love if you'd be up for adding and email and password.</p>

        </div>

        <!--v-if="getProfile.signup == 'twitter'">-->

        <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

          <div class="content">
            <div class="url">Email address</div>
            <div class="name">
              <div class="validation_input">
                <input type="text" autocomplete="false" v-model="email" placeholder="Email">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

          <div class="content">
            <div class="url">Password</div>
            <div class="name">
              <div class="validation_input">
                <input type="password" autocomplete="false" v-model="password" placeholder="Password">
              </div>
            </div>
          </div>
        </div>


        <div class=" mt-3 text-center btn_sec">
          <button class="btn---cta btn-blue btn-round " @click.prevent="setEmailPassword()">
            <span>Save</span>

          </button>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {
  setPasswordURL, setEmailPasswordURL
} from '@/config/config.js'
import IsOffline from '@/misc/IsOffline'

import { mapGetters, mapActions } from 'vuex'

export default (
  {
    components: {
      IsOffline
    },
    data () {
      return {
        password: '',
        email: ''
        //                    confirmPassword: '',
      }
    },
    created () {
      this.getUserProfile()
    },
    computed: {
      ...mapGetters(['getProfile'])
    },
    methods: {

      setPassword () {
        if (this.password === '') {
          this.alertMessage('Please enter password.', 'error')
        }
          //                    else if (this.confirmPassword == "") {
          //                        this.alertMessage('Please confirm your password.', 'error');
          //                    } else if (this.password != this.confirmPassword) {
          //                        this.alertMessage('password and confirm password is not matched.','error');
        //                    }
        else {
          this.$http.post(setPasswordURL, {
            password: this.password
          }).then(
            response => {
              if (response.data.tokenError && response.data.tokenError === true) {
                this.alertMessage(response.data.message, 'error')
              } else {
                if (response.data.status === false) {
                  this.alertMessage(response.data.message, 'error')
                } else {
                  this.alertMessage('Password is successfully set.', 'success')
                  this.$router.push({
                    name: 'dashboard'
                  })
                }
              }
            },
            response => {
              this.alertMessage('Unknown error occurred, support has been notified.', 'error')
            })
        }
      },
      setEmailPassword () {
        if (this.password == '') {
          this.alertMessage('Please enter password.', 'error')
        } else if (this.email == '') {
          this.alertMessage('Please enter email.', 'error')
        } else {
          this.$http.post(setEmailPasswordURL, {
            password: this.password,
            email: this.email
          }).then(
            response => {
              if (response.data.tokenError && response.data.tokenError == true) {
                this.alertMessage(response.data.message, 'error')
              } else {
                if (response.data.status == true) {
                  this.alertMessage('Password and email is successfully set.', 'success')
                  this.$router.push({
                    name: 'dashboard'
                  })
                } else {
                  this.alertMessage(response.data.message, 'error')
                }
              }
            },
            response => {
              this.alertMessage('Unknown error occurred, support has been notified.', 'error')
            })
        }
      }
    }
  }
)
</script>
