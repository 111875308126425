export default ({
  methods: {
    /**
     * input sanitization, checking the value exists.
     * @param value
     * @returns {boolean}
     */
    requiredCheck (value) {
      console.debug('requiredCheck object type ', typeof value, value)
      if (typeof value === 'boolean') {
        return value
      }
      if (typeof value === 'string') {
        if (value === null) {
          return false
        }
        if (!value) {
          return false
        }
        if (value.length === 0) {
          return false
        }
        return true
      }
      if (typeof value === 'object') {
        if (!value) return false
      }
    }
  }
})
