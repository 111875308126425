<template>
  <div class="setting_form clear">
    <b-modal modal-class="modal_basic addBlacklistIpModal sm-modal" id="addBlacklistIpModal"
             ref="add_blacklist_ip_modal" hide-footer hide-header>
      <img @click="$bvModal.hide('addBlacklistIpModal')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            Add your IP Address
          </h3>
        </div>

        <p v-if="yourIp" class="ip d-flex">* Your IP Address is
          <span class="ml-1">({{ yourIp }})</span>
          <i class="fal fa-clipboard pointer ml-1" v-tooltip="'Copy IP to clipboard'" @click="copyText(yourIp)"
             aria-hidden="true"></i>
        </p>
        <div :class="{'input-field-error': validations.ip_address || validations.ip_address_valid }"
             class=" mt-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">IP Address</div>
            <div class="name">
              <div class="validation_input">
                <input type="text"
                       placeholder="e.g 34.70.69.10"
                       v-model="getAddBlacklistIp.ip_address" data-cy="p-name"
                       @keyup.enter="validateAndStoreIp()">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="validations.ip_address">
                  {{ messages.ip_address }}
                </span>
        <span class="input-error" v-else-if="validations.ip_address_valid">
                  {{ messages.ip_address_valid }}
                </span>

        <div class=" btn_block text-left">

          <button @click.prevent="validateAndStoreIp()" :disabled="addIpLoader"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>{{ getAddBlacklistIp._id ? 'Update' : 'Add' }}</span>
            <clip-loader v-if="addIpLoader" :size="'14px'" :color="'#ffffff'"></clip-loader>
          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('addBlacklistIpModal')"><span>Close</span>
          </button>
        </div>

      </div>
    </b-modal>
    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head">
          <div class="d-flex align-items-center">
            <div class="left">
              <h2>Blacklist IPs</h2>

            </div>
            <div class="right ml-auto">
              <button @click.prevent="showIpAddModal"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add IP Address</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>

            </div>
          </div>
          <div class="left mt-3">
            <p>The visits coming from blocked IPs are simply excluded from the statistics,
              our engine continues to serve tracking links and their options also to visits coming from blocked IPs,
              with the only difference that nothing will be displayed on the statistics.</p>
          </div>

        </div>

        <div class="box_content">
          <div class="table_container">
            <table class="table   list-content">
              <thead class=" ">
              <tr>
                <th class="" style="width: 230px;">IP Address</th>
                <th style="width: 200px;text-align: right;">Actions</th>
              </tr>
              </thead>
              <tbody class="list-content-body">

              <tr v-if="getBlacklistIpsLoaders.get">
                <td colspan="5">
                  <clip-loader
                    :size="'20px'"
                    :color="color"
                  ></clip-loader>
                </td>
              </tr>
              <tr v-if="!getBlacklistIpsLoaders.get && getBlacklistIpList.length == 0">
                <td colspan="5">
                  <p class="text-center p-4">You have not added any IP Address</p>
                </td>
              </tr>
              <template v-else>
                <tr v-for="(blacklist,index) in getBlacklistIpList" :key="index">
                  <td style="text-align: left;">
                    {{ blacklist.ip_address }}
                  </td>

                  <td class="table-action ">
                    <i v-tooltip="'Edit IP Address'" class="far fa-pencil  "
                       @click.prevent="editIp(blacklist)" aria-hidden="true"></i>
                    <i v-tooltip="'Remove IP Address'" class="far fa-trash "
                       @click.prevent="deleteIp(blacklist._id)" aria-hidden="true"></i>
                  </td>
                </tr>
              </template>

              </tbody>

            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { DARK_PURPLE_LOADER_COLOR } from '../../../common/constants'
import { blacklistIp } from '../../../state/modules/mutation-types'

export default ({
  components: {},
  data () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      validations: {
        ip_address: false,
        ip_address_valid: false
      },
      messages: {
        ip_address: 'IP Address cannot be empty',
        ip_address_valid: 'Invalid IP Address'
      },
      addIpLoader: false,
      yourIp: null,
      userIpLoader: false
    }
  },
  async created () {
    this.getSiteDetails
    this.yourIp = await this.getServerIp()
    await this.fetchBlacklistIp()
  },
  methods: {
    ...mapMutations([blacklistIp.SET_BLACKLIST_IP]),
    ...mapActions(['fetchBlacklistIp', 'storeBlacklistIp', 'deleteIp', 'getServerIp']),
    async validateAndStoreIp () {
      this.validations.ip_address = this.requiredCheck(this.getAddBlacklistIp.ip_address)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.addIpLoader = true
        await this.storeBlacklistIp()
        this.addIpLoader = false
        this.$bvModal.hide('addBlacklistIpModal')
        this.fetchBlacklistIp()
      }
    },
    editIp (ip) {
      this[blacklistIp.SET_BLACKLIST_IP](JSON.parse(JSON.stringify(ip)))
      this.$bvModal.show('addBlacklistIpModal')
    },
    showIpAddModal () {
      this.$store.commit(blacklistIp.RESET_BLACKLIST_IP)
      this.$bvModal.show('addBlacklistIpModal')
    },
    copyText (ip) {
      this.alertMessage('Your IP has been copied to clipboard', 'success')
      navigator.clipboard.writeText(ip)
    }
  },
  computed: {
    ...mapGetters([
      'getBlacklistIpsLoaders',
      'getBlacklistIpList',
      'getAddBlacklistIp'
    ])
  },

  watch: {
    'getAddBlacklistIp.ip_address' (value) {
      if (value && value.length > 0) {
        this.validations.ip_address = false
        this.validations.ip_address_valid = !this.validateIpAddress(value)
      }
    },
  }

})
</script>
<style scoped lang="less">
.ip {
  color: #1f216e;

  span {
    color: #1f216e;
  }

  i {
    margin-top: 3px;
  }
}
</style>
