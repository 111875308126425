<template>
  <div class="authentication-component container d-flex flex-column">
    <VueRecaptcha
      :sitekey="getClientSideRecaptchaKey()"
      ref="recaptcha"
      size="invisible"
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired"
      @error="onCaptchaError"
    >
    </VueRecaptcha>
    <div class="row position-relative w-100 m-auto d-flex align-items-center justify-content-between">
      <div class="col-md-12 left_sec p-0 col-lg-12 col-xl-5">
        <div class="authentication-component-inner position-relative m-auto">
          <div class=" clear">
            <div class="alert-box  danger" v-if="authorizeMessage">
              <div class="inner_box">
                <p class="text-left">{{ authorizeMessage }}</p>
              </div>
            </div>
          </div>
          <div class="white_box  ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center justify-content-center">
                <div class="left">
                  <h2>Get started instantly for free</h2>
                </div>
              </div>
              <div class="box_content">
                <!--                <div class="row d-flex align-items-center">-->
                <!--                  <div class="mb-2 social-account-btn col-md-12 col-sm-12 col-xl-6 col-lg-12">-->
                <!--                    <a class="twitter__btn social_btn text-center" :href="getLink('twitter')">-->

                <!--                      <i class="fab fa-twitter position-relative d-inline-block"></i>-->
                <!--                      <span class="pl-2">Sign in with Twitter</span>-->
                <!--                    </a>-->
                <!--                  </div>-->
                <!--                  <div class="mb-2 social-account-btn col-md-12 col-sm-12 col-xl-6 col-lg-12">-->
                <!--                    <a class="facebook__btn social_btn  text-center" :href="getLink('facebook')">-->

                <!--                      <i class="fab fa-facebook position-relative d-inline-block"></i>-->
                <!--                      <span class="pl-2">Sign in with Facebook</span>-->
                <!--                    </a>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="or_separator_container text-center">-->
                <!--                  <div class="or_separator">-->
                <!--                    <span class="sep_text">OR</span>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                      <div class="avatar">
                        <i class="fal fa-user"></i>
                      </div>
                      <div class="content">
                        <div class="url">First Name</div>
                        <div class="name">
                          <div class="validation_input">
                            <input autocomplete="false" type="text" class="form-control"
                                   v-model="register.first_name" name="text" id="first_name"
                                   placeholder="First Name"
                                   :class="{'input-field-error': validations.first_name}"
                            >
                            <span class="input-error" v-if="validations.first_name">
                                               {{ messages.first_name }}
                                            </span>
                            <span class="input-error" v-if="validations.first_name_valid">
                                               {{ messages.first_name_valid }}
                                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                      <div class="avatar">
                        <i class="fal fa-user"></i>
                      </div>
                      <div class="content">
                        <div class="url">Last name</div>
                        <div class="name">
                          <div class="validation_input">

                            <input autocomplete="false" type="text" class="form-control"
                                   v-model="register.last_name" name="text" id="last_name"
                                   placeholder="Last Name"
                                   :class="{'input-field-error': validations.last_name}"
                            >
                            <span class="input-error" v-if="validations.last_name">
                                                {{ messages.last_name }}
                                            </span>
                            <span class="input-error" v-if="validations.last_name_valid">
                                                {{ messages.last_name_valid }}
                                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                  <div class="avatar">
                    <i class="fal fa-envelope"></i>
                  </div>
                  <div class="content">
                    <div class="url">Email</div>
                    <div class="name">
                      <div class="validation_input">

                        <input autocomplete="false" type="text" class="form-control"
                               v-model="register.email"
                               name="email" id="email"
                               placeholder="Your username or email address"
                               :class="{'input-field-error': validations.email}"
                        >
                        <span class="input-error" v-if="validations.email">
                                                {{ messages.email }}
                                            </span>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                  <div class="avatar">
                    <i class="fal fa-lock"></i>
                  </div>
                  <div class="content">
                    <div class="url">Password</div>
                    <div class="name">
                      <div class="validation_input">

                        <input @keyup.enter="validateRegister" :type="showPassword ? 'text' : 'password'"
                               class="form-control" v-model="register.password"
                               name="password" id="password"
                               placeholder="Your account password"
                               :class="{'input-field-error': validations.password}"
                        >
                        <span class="input-error" v-if="validations.password">
                                               {{ messages.password }}
                                            </span>
                      </div>
                    </div>
                  </div>
                  <div @click="showPasswordFunc()" class="avatar cursor_pointer m-auto">
                    <i class="fal" :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"></i>
                  </div>
                </div>
                <div class="mt-2 ml-3">
                              <span data-cy="password_strength" class="password_strength clear" :class="{
                              'danger': password_state === 'danger',
                              'warning': password_state === 'warning',
                              'green': password_state === 'green'
                              }"
                                    v-if="password_strength_message">{{ password_strength_message }}</span>
                </div>

                <div class=" mt-3 input_icon_text d-flex align-items-center profile__box">
                  <div class="content">
                    <div class="url">What best describes you?</div>
                    <div class="name">
                      <div class="validation_input">
                        <select class="bg-transparent" v-model="register.business_type">
                          <option value="blogger">Blogger</option>
                          <option value="solopreneur">Solopreneur</option>
                          <option value="agency">Agency</option>
                          <option value="saas_business">SaaS Business</option>
                          <option value="marketing_team">Marketing Team</option>
                          <option value="enterprise">Enterprise</option>
                          <option value="affiliate">Affiliate</option>
                          <option value="other">Other</option>
                        </select>
                        <span class="input-error" v-if="validations.business_type">{{ messages.business_type }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-2 bottom_link text-center">
                  <p style="    font-size: 14px;" class="small_text">By signing up, you agree to our
                    <a class="login_lin" target="_blank" href="https://replug.io/terms">terms of
                      service</a> and <a class="login_lin" target="_blank"
                                         href="https://replug.io/privacy">privacy policy</a>.</p>
                </div>

                <div class="mt-4 btn_block txt-center">

                  <button class="btn---cta btn-blue btn-round btn--loader loader--right"
                          :disabled="loaders.register"
                          @click.prevent="validateRegister">
                    <span>Sign up</span>
                    <clip-loader class="clip-loader" v-if="loaders.register" :color="color" :size="size"></clip-loader>
                  </button>

                </div>
                <div class="bottom_link mt-3 text-center">

                  Already got an account?

                  <router-link v-if="$route.query.ref"
                               style="width: auto !important;padding-left: 3px !important;"
                               :to="{name: 'login', query: { ref:$route.query.ref }}"
                               class="  login_lin">
                    Sign in

                  </router-link>
                  <router-link v-else style="width: auto !important;padding-left: 3px !important;"
                               :to="{name: 'login'}"
                               class="  login_lin ">
                    Sign in

                  </router-link>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-7 col-lg-12">
        <Carousel/>
      </div>
    </div>

  </div>
</template>

<style lang="less">
.danger {
  color: #ea7070 !important;
}

.warning {
  color: #efa340 !important;
}

.green {
  color: #399017 !important;
}
</style>

<script>
import { baseURL, cookieDomain } from '../../constants'
import { registerFormURL } from '@/config/config'
import { authenticationTypes } from '@/state/modules/mutation-types'
import { APIURL } from '@/config/config'
import { mapActions, mapGetters } from 'vuex'
import { trackSignUpEvent } from '../../common/methods'
import { VueRecaptcha } from 'vue-recaptcha'
import vueCookie from 'vue-cookie'

export default ({
  'name': 'signup',
  components: {
    Carousel: () => import('@/ui/ui-kit/Carousel'),
    VueRecaptcha
  },
  async created () {
    this.register.email = this.$route.query.email
    if (this.$route.query.error_message) {
      this.authorizeMessage = this.$route.query.error_message
    }
    if (this.$route.query.error === 'login') {
      this.authorizeMessage = 'Unable to connect through social media, please use a below form'
    }
    await this.fetchWhitelabelAppSettings()
  },
  computed: {
    ...mapGetters(['getWhitelabel'])
  },
  data () {
    return {
      size: '16px',
      color: '#ffffff',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      authorizeMessage: false,
      password_strength_message: '',
      password_state: '',
      register: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        recaptcha_token: '',
        business_type: ''
      },
      validations: {
        first_name: false,
        first_name_valid: false,
        last_name: false,
        last_name_valid: false,
        email: false,
        password: false,
        business_type: false
      },
      messages: {
        first_name: 'Please enter first name.',
        first_name_valid: 'Name must contain only alphabets.',
        last_name: 'Please enter last name.',
        last_name_valid: 'Name must contain only alphabets.',
        email: 'Please enter email address.',
        password: 'Please enter password.',
        business_type: 'Please select business type.'
      },
      loaders: {
        register: false
      },
      showPassword: false
    }
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    /**
     * getting the google recaptcha client side key
     * @returns {string}
     */
    getClientSideRecaptchaKey () {
      return process.env.VUE_APP_GOOGLE_V2_RECAPTCHA_CLIENT_KEY
    },
    onCaptchaVerified: function (recaptchaToken) {
      if (recaptchaToken && recaptchaToken.length) {
        this.register.recaptcha_token = recaptchaToken
        this.loaders.register = true
        this.registerAccount()
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    },
    onCaptchaError: function (error) {
      console.warn('onCaptchaError =>', error)
    },
    getLink (type) {
      if (this.$route.query.ref) {
        return APIURL + 'social/refer/' + type + '/' + this.$route.query.ref
      } else if (this.$route.query.oauth && this.$route.query.oauth == 'authorize' &&
        this.$route.query.client_id && this.$route.query.redirect_uri &&
        this.$route.query.response_type && this.$route.query.scope) {
        return APIURL + 'social/' + type +
          '/?client_id=' + this.$route.query.client_id +
          '&redirect_uri=' + this.$route.query.redirect_uri +
          '&response_type=' + this.$route.query.response_type +
          '&scope=' + this.$route.query.scope
      } else {
        return APIURL + 'social/' + type
      }
    },
    /**
     * validating the register form and executing the recaptcha
     */
    validateRegister () {
      this.validations.first_name = this.requiredCheck(this.register.first_name)
      this.validations.last_name = this.requiredCheck(this.register.last_name)
      this.validations.email = this.requiredCheck(this.register.email)
      this.validations.password = this.requiredCheck(this.register.password)
      this.validations.business_type = this.requiredCheck(this.register.business_type)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.$refs.recaptcha.execute()
      }
    },
    registerAccount () {
      let ref = null
      try {
        this.register.ref = this.$route.query.ref
      } catch (m) {
        console.log(m)
      }

      this.register['fp_tid'] = vueCookie.get('_fprom_tid')

      this.register.subscription_name = (this.$route.query.subscription_name) ? this.$route.query.subscription_name : 'trial-personal'
      this.$http.post(registerFormURL, this.register).then(
        async res => {

          this.onCaptchaExpired()

          if (res.data.tokenError && res.data.tokenError === true) {
            this.alertMessage(res.data.message, 'error')
            return
          }

          if (res.data.status) {
            await trackSignUpEvent(res.data.user_id, this.register.email, this.register.first_name, this.register.last_name, this.register.subscription_name)

            this.alertMessage(res.data.message, 'success')
            window.localStorage.setItem('rplg_logged_jwt_token', res.data.token)
            vueCookie.set('rplg_logged_jwt_token', res.data.token, { expires: '1Y', domain: cookieDomain })
            this.$store.commit(authenticationTypes.SET_JWT_TOKEN, res.data.token)
            this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
            let stateObject = this
            setTimeout(function () {
              stateObject.$router.push({ 'name': 'dashboard' })
            }, 2000)
            gtag('event', 'conversion', { 'send_to': 'AW-474110986/WEQ5CMHUjfMBEIq4ieIB' })
            // Event snippet for Sign-up conversion page
            gtag('event', 'conversion', { 'send_to': 'AW-10788423991/Zl9ECMrI__oCELeSqZgo' })

            return
          }

          this.alertMessage(res.data.message, 'error')
          this.loaders.register = false

        }, err => {
          if (err.status === 422) {
            this.alertMessage(err.data.message, 'error')
          }
          this.loaders.register = false
        })
    },
    showPasswordFunc () {
      this.showPassword = !this.showPassword
    }

  },
  watch: {
    'register.first_name' (value) {
      if (value && value.trim().length > 0) {
        this.validations.first_name = false
      }

      const regex = new RegExp(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/)
      var result = regex.test(value)
      if (!result) {
        this.validations.first_name_valid = true
      } else {
        this.validations.first_name_valid = false
      }
    },
    'register.last_name' (value) {
      if (value && value.trim().length > 0) {
        this.validations.last_name = false
      }

      const regex = new RegExp(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/)
      var result = regex.test(value)
      if (!result) {
        this.validations.last_name_valid = true
      } else {
        this.validations.last_name_valid = false
      }
    },
    'register.email' (value) {
      if (value && value.trim().length > 0) {
        this.validations.email = false
      }
    },
    'register.password' (value) {
      if (value && value.trim().length > 0) {
        this.validations.password = false
      }

      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length <= 8) {
          this.password_strength_message = 'Password must be eight characters or more'
          this.password_state = 'danger'
          return
        }
        if (value.length >= 6 && value.length <= 12) {
          this.password_strength_message = 'Password could be more secure'
          this.password_state = 'warning'
          return
        }
        if (value.length >= 13 && value.length <= 17) {
          this.password_strength_message = 'Password is okay'
          this.password_state = 'green'
          return
        }
        if (value.length >= 17) {
          this.password_strength_message = 'Password is strong'
          this.password_state = 'green'
          return
        }
        this.password_state = ''
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
    'register.business_type' (value) {
      if (value && value.trim().length > 0) {
        this.validations.business_type = false
      }
    }
  }

})
</script>
