const ActiveCampaignConnect = () => import('@/views/pages/integrations/ActiveCampaignConnect.vue')
const GetResponseConnect = () => import('@/views/pages/integrations/GetResponseConnect.vue')
const ConvertFoxConnect = () => import('@/views/pages/integrations/ConvertFoxConnect.vue')
const MauticConnect = () => import('@/views/pages/integrations/MauticConnect.vue')
const MailerliteConnect = () => import('@/views/pages/integrations/MailerliteConnect.vue')
const ConvertKitConnect = () => import('@/views/pages/integrations/ConvertKitConnect.vue')
const SendLaneConnect = () => import('@/views/pages/integrations/SendLaneConnect.vue')
const SendPulseConnect = () => import('@/views/pages/integrations/SendPulseConnect.vue')
const MoosendConnect = () => import('@/views/pages/integrations/MoosendConnect.vue')
const SendyConnect = () => import('@/views/pages/integrations/SendyConnect.vue')
const SendinBlueConnect = () => import('@/views/pages/integrations/SendinBlueConnect.vue')
const BenchMarkConnect = () => import('@/views/pages/integrations/BenchMarkConnect.vue')
const FreshMailConnect = () => import('@/views/pages/integrations/FreshMailConnect.vue')

export const integrationRoutes = [
  {
    'path': '/activecampaign',
    'name': 'activecampaign',
    component: ActiveCampaignConnect,
    meta: {
      'title': 'Connect Your ActiveCampaign Account'
    }
  },
  {
    'path': '/getresponse',
    'name': 'getresponse',
    component: GetResponseConnect,
    meta: {
      'title': 'Connect Your GetResponse Account'
    }
  },
  {
    'path': '/convertfox',
    'name': 'convertfox',
    component: ConvertFoxConnect,
    meta: {
      'title': 'Connect Your Gist Account'
    }
  },
  {
    'path': '/mautic',
    'name': 'mautic',
    component: MauticConnect,
    meta: {
      'title': 'Connect Your Mautic Account'
    }
  },
  {
    'path': '/mailerlite',
    'name': 'mailerlite',
    component: MailerliteConnect,
    meta: {
      'title': 'Connect Your MailerLite Account'
    }
  },
  {
    'path': '/convertkit',
    'name': 'convertkit',
    component: ConvertKitConnect,
    meta: {
      'title': 'Connect Your ConvertKit Account'
    }
  },
  {
    'path': '/sendlane',
    'name': 'sendlane',
    component: SendLaneConnect,
    meta: {
      'title': 'Connect Your SendLane Account'
    }
  },
  {
    'path': '/sendPulse',
    'name': 'sendPulse',
    component: SendPulseConnect,
    meta: {
      'title': 'Connect Your SendPulse Account'
    }
  },
  {
    'path': '/moosend',
    'name': 'moosend',
    component: MoosendConnect,
    meta: {
      'title': 'Connect Your Moosend Account'
    }
  },
  {
    'path': '/sendy',
    'name': 'sendy',
    component: SendyConnect,
    meta: {
      'title': 'Connect Your Sendy Account'
    }
  },
  {
    'path': '/sendinblue',
    'name': 'sendinblue',
    component: SendinBlueConnect,
    meta: {
      'title': 'Connect Your SendinBlue Account'
    }
  },
  {
    'path': '/benchmark',
    'name': 'benchmark',
    component: BenchMarkConnect,
    meta: {
      'title': 'Connect Your BenchMark Account'
    }
  },
  {
    'path': '/freshmail',
    'name': 'freshmail',
    component: FreshMailConnect,
    meta: {
      'title': 'Connect Your FreshMail Account'
    }
  },
]
