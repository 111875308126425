<template>
  <div class="top_banner" :style="bannerStyle">
    <span>{{ text }}</span>
    <a :style="hrefStyle" @click="showUpgradePlanModal">{{ hrefText }}</a>
    <i type="button" @click="hideTopBanner" :style="iconStyle" class="far fa-times"></i>
  </div>
</template>
<script>
export default {
  name: 'UiTopBanner',
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    bgColor: {
      type: String,
      default: '#3988fe'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    fontSize: {
      type: String,
      default: '1rem'
    },
    hrefText: {
      type: String,
      default: ''
    },
    hrefColor: {
      type: String,
      default: '#ffffff'
    },
    hrefFontSize: {
      type: String,
      default: '1rem'
    },
    hrefLink: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: '#ffffff'
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    bannerStyle() {
      return {
        '--background': this.bgColor,
        '--color': this.color,
        '--border': '1px solid' + this.borderColor,
        '--font-size': this.fontSize
      }
    },
    hrefStyle() {
      return {
        '--hrefColor': this.hrefColor,
        '--hrefFontSize': this.hrefFontSize
      }
    },
    iconStyle() {
      return {
        '--iconColor': this.iconColor
      }
    }
  },
  created() {
  },
  methods: {
   async showUpgradePlanModal(){
      if (!this.isBruceCampaign()) {
        this.$bvModal.show(this.hrefLink)
      }
    },
    hideTopBanner() {
      this.$parent.hideTopBanner(this.type)
    }
  }
}
</script>
<style lang="less" scoped>
.top_banner {
  background: var(--background);
  border: var(--border);
  font-size: var(--font-size);
  padding: 19px;
  text-align: center;

  span {
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    color: var(--color);
    font-weight: bold;
  }

  a {
    color: var(--hrefColor);
    hrefFontSize: var(--hrefFontSize);
    display: inline-block;
    font-weight: bold;
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }

  i {
    color: var(--iconColor);
    font-weight: bold;
    margin-left: 30px;
    cursor: pointer;
  }
}
</style>
