import http from '@/mixins/http-lib.js'
import {
  deleteCustomWidgetURL,
  previewCustomWidgetURL,
  showCustomWidgetURL,
  storeCustomWidgetURL
} from '../../../config/api'
import { widgetTypes } from '../mutation-types'
import { customCodeBaseURL } from '../../../config/urls/customJs/customJs'
import { swalDefaultValues } from '@/mixins/attributes'

const defaultCustomWidgetAdd = () => {
  return {
    name: '',
    js: ``,
    html: ``,
    trigger_type: 'time',
    trigger_seconds: 5,
    position: 'bottom_left',
    _id: null,
    addCustomLoader: false,
    preview: false,
    addCustomCodeCheck: false
  }
}

export default {
  state: {
    widget: {
      list: []
    },
    customWidgetAdd: defaultCustomWidgetAdd(),
    retrieveLoader: false
  },
  getters: {
    getCustomWidgetAdd: state => state.customWidgetAdd,
    getCustomWidgetList: state => state.widget.list,
    getCustomWidgetLoader: state => state.retrieveLoader
  },
  actions: {
    async storeWidget ({ commit, getters, dispatch }, type = 'settings') {
      getters.getCustomWidgetAdd.addCustomLoader = true
      const payload = getters.getCustomWidgetAdd
      const res = await http.post(storeCustomWidgetURL, payload).then(res => {
        if (res.data.status) {
          // update existing row
          if (payload.hasOwnProperty('_id') && payload._id) {
            let index = getters.getCustomWidgetList.findIndex(item => {
              return item._id === payload._id
            })
            getters.getCustomWidgetList.splice(index, 1, res.data.data)
          } else {
            // new row to add
            commit(widgetTypes.ADD_WIDGET_TO_LIST, res.data.data)
          }
          commit(widgetTypes.RESET_WIDGET)
          return res.data.data
        } else {
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
          return false
        }
        getters.getCustomWidgetAdd.addCustomLoader = false
      }).catch(() => {

      })
      return res
    },
    fetchWidgets ({ commit, getters, dispatch }, payload) {
      commit(widgetTypes.SET_CUSTOM_WIDGET_LOADER, true)
      return http.post(showCustomWidgetURL, payload).then(res => {
        if (res.data.status) {
          commit(widgetTypes.SET_WIDGET_LIST, res.data.data.data)
          commit(widgetTypes.SET_CUSTOM_WIDGET_LOADER, false)
          return res.data.data
        }
      }).catch(() => {

      })
    },
    deleteWidget ({ commit, getters, dispatch }, { id, index }) {
      swal(swalDefaultValues(),
        function (isConfirm) {
          if (isConfirm) {
            http.post(deleteCustomWidgetURL, { _id: id }).then(res => {
              if (res.data.status) {
                getters.getCustomWidgetList.splice(index, 1)
              }
            }).catch(() => {

            })
          } else {
            swal()
          }
        })
    },
    previewWidget ({ commit, getters, dispatch }, ids) {
      http.post(previewCustomWidgetURL, { ids: ids }).then(res => {
        if (res.data.status) {
          window.open(customCodeBaseURL + 'codepreview/' + res.data.data, '_blank')
        }
      }).catch(() => {

      })
    }

  },
  mutations: {
    [widgetTypes.ADD_WIDGET_TO_LIST] (state, value) {
      state.widget.list.unshift(value)
    },
    [widgetTypes.SET_WIDGET_LIST] (state, value) {
      state.widget.list = value
    },
    [widgetTypes.RESET_WIDGET] (state) {
      state.customWidgetAdd = defaultCustomWidgetAdd()
    },
    [widgetTypes.SET_CUSTOM_WIDGET_ADD] (state, value) {
      state.customWidgetAdd = JSON.parse(JSON.stringify(value))
      state.customWidgetAdd.preview = false
    },
    [widgetTypes.SET_ADD_CUSTOM_WIDGET_FROM_CTA] (state, value) {
      state.customWidgetAdd.addCustomCodeCheck = value
    },
    [widgetTypes.SET_CUSTOM_WIDGET_LOADER] (state, value) {
      state.retrieveLoader = value
    }
  }
}
