import { mapGetters, mapActions } from 'vuex'
import { campaignTypes } from '@/state/modules/mutation-types'
// import $store from '../../store';

let guidedTourMixin = {
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['getGuidedTour'])
  },
  mounted () {
    // this.setGuidedTourOverlay(false)
    // this.$store.commit('setGuidedTourOverlay',false)
  },
  methods: {

    ...mapActions(['setGuidedTourOverlay']),

    stepCall (direction, step) {
      console.debug('Method::stepCall', direction, '---', step)

      // If click on next button
      if (direction == 'next') {
        if (step == 'one') {
          this.getGuidedTour.step1 = false
          this.getGuidedTour.step2 = true
        } else if (step == 'two') {
          this.getGuidedTour.step2 = false
          this.getGuidedTour.step3 = true

          // open dropdown --
          $('.profile_menu .head__section').trigger('click')
        } else if (step == 'three') {
          this.getGuidedTour.step3 = false
          this.getGuidedTour.step4 = true

          // close dropdown --
          $('.profile_menu .head__section').trigger('click')
          this.$router.push({ name: 'brands' })
        } else if (step == 'four') {
          // $('#modal-create-brand').modal('show')
          this.$bvModal.show('modal-create-brand')

          this.getGuidedTour.step4 = false
          this.getGuidedTour.step5 = true
        } else if (step == 'five') {
          // $('#modal-create-brand').modal('hide')
          this.$bvModal.hide('modal-create-brand')
          this.$router.push({ name: 'campaigns' })

          this.getGuidedTour.step5 = false
          this.getGuidedTour.step6 = true
        } else if (step == 'six') {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, '5a70705a0ff6c37149449d4b')

          this.$router.push({ name: 'call_to_action' })

          this.getGuidedTour.step6 = false
          this.getGuidedTour.step7 = true
        } else if (step == 'seven') {
          this.$router.push({ name: 'links' })

          this.getGuidedTour.step7 = false
          this.getGuidedTour.step8 = true
        } else if (step == 'eight') {
          this.$router.push({ 'name': 'link' })

          this.getGuidedTour.step8 = false
          this.getGuidedTour.step9 = true
        } else if (step == 'nine') {
          this.$router.push({ 'name': 'team' })

          this.getGuidedTour.step9 = false
          this.getGuidedTour.step10 = true
        } else if (step == 'ten') {
          this.$router.push({ 'name': 'integrations' })

          this.getGuidedTour.step10 = false
          this.getGuidedTour.step11 = true
        } else if (step == 'eleven') {
          this.$router.push({ 'name': 'custom_domains' })

          this.getGuidedTour.step11 = false
          this.getGuidedTour.step12 = true
        }
      }

      // If click on back  button

      else if (direction == 'back') {
        if (step == 'two') {
          this.getGuidedTour.step1 = true
          this.getGuidedTour.step2 = false
        } else if (step == 'three') {
          this.getGuidedTour.step3 = false
          this.getGuidedTour.step2 = true

          // close dropdown --
          $('.profile_menu .head__section').trigger('click')
        } else if (step == 'four') {
          this.$router.push({ name: 'dashboard' })

          this.getGuidedTour.step4 = false
          this.getGuidedTour.step3 = true

          // open dropdown --
          $('.profile_menu .head__section').trigger('click')
        } else if (step == 'five') {
          // $('#modal-create-brand').modal('hide')
          this.$bvModal.hide('modal-create-brand')
          this.getGuidedTour.step5 = false
          this.getGuidedTour.step4 = true
        } else if (step == 'six') {
          this.$router.push({ name: 'brands' })

          setTimeout(() => {
            // $('#modal-create-brand').modal('show')
            this.$bvModal.show('modal-create-brand')
          }, 200)

          this.getGuidedTour.step6 = false
          this.getGuidedTour.step5 = true
        } else if (step == 'seven') {
          this.$router.push({ name: 'campaigns' })

          this.getGuidedTour.step7 = false
          this.getGuidedTour.step6 = true
        } else if (step == 'eight') {
          this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, '5a70705a0ff6c37149449d4b')
          this.$router.push({ name: 'call_to_action' })

          this.getGuidedTour.step8 = false
          this.getGuidedTour.step7 = true
        } else if (step == 'nine') {
          this.$router.push({ name: 'links' })

          this.getGuidedTour.step9 = false
          this.getGuidedTour.step8 = true
        } else if (step == 'ten') {
          this.$router.push({ name: 'link' })

          this.getGuidedTour.step10 = false
          this.getGuidedTour.step9 = true
        } else if (step == 'eleven') {
          this.$router.push({ name: 'team' })

          this.getGuidedTour.step11 = false
          this.getGuidedTour.step10 = true
        } else if (step == 'twelve') {
          this.$router.push({ 'name': 'integrations' })

          this.getGuidedTour.step12 = false
          this.getGuidedTour.step11 = true
        }
      }

      // If click on Finish(last step) or skip  button
      else if (direction == 'finish' || direction == 'skip') {
        // make false all values
        let getGuidedTour = this.getGuidedTour
        for (var index in getGuidedTour) {
          getGuidedTour[index] = false
        }
        // remove back overlay
        this.setGuidedTourOverlay(false)
      }
    },

    // getting started method...
    gettingStarted (type) {
      console.debug('Method:gettingStarted', type)

      if (type == 'createBrand') {
        this.$router.push({ name: 'brands' })
      } else if (type == 'createCTA') {
        this.$router.push({ name: 'campaigns' })
      } else if (type == 'createLink') {
        this.$router.push({ name: 'links' })
      } else if (type == 'createBioLink') {
        this.$router.push({ name: 'bio_links' })
      }
    }
  }

}

export { guidedTourMixin }
