import { mapGetters } from 'vuex'
import { shortenDomainURL } from '../config/api'

export default ({
  computed: {
    ...mapGetters(['getDomains'])
  },
  methods: {

    copyUrl (code, domainURL = null) {

      if (domainURL) {
        if (domainURL.substr(-1) === '/') {
          domainURL = domainURL.substr(0, domainURL.length - 1)
        }
        return domainURL + '/' + code
      } else {
        return shortenDomainURL + code
      }
    },

    getDomainUrl (domainURL = null, domainName) {
      if (domainURL) {
        domainURL = domainURL.url
        if (domainURL.substr(-1) === '/') {
          domainURL = domainURL.substr(0, domainURL.length - 1)
        }
        return domainURL + '/'
      } else {
        return domainName
      }
    },
    sanitizeDomainURL (domainURL) {
      if (domainURL.substr(-1) === '/') {
        domainURL = domainURL.substr(0, domainURL.length - 1)
      }
      return domainURL + '/'
    },
    getDomainURLById (domainId = null, domainName) {
      let domainURL = domainName
      if (domainId) {
        let domain = this.getDomains.items.find(item => item._id === domainId)
        if (domain && domain.url) {
          domainURL = this.sanitizeDomainURL(domain.url)
        }
      }
      return domainURL
    },

    socialUrl (code, domainURL) {
      return (domainURL) ? `${domainURL.replace(/\/$/, "") }/${code}` : `${shortenDomainURL}${code}`
    },

    getLinkById (id, links) {
      return links.find(item => item._id === id)
    }
  }
})
