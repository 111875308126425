<template>
  <Button
    class="add-btn"
    :class="{'btn--effect-pulse': pulseEffect}"
    :style="btnStyle"
    :disabled="disabled"
    v-on:click="$emit('click')"
    v-if="type == 'add'"
  >
    <span>{{ text }}</span>
    <span
      v-if="icon"
      :class="{'icon_right': iconPosition == 'right', 'icon_default': iconPosition == 'default', 'icon_left': iconPosition == 'left'}"
    >
      <i
        class="d-flex align-items-center justify-content-center"
        :class="icon"
        :style="{'background': iconBackground, 'border-radius': iconBorderRadius, 'font-size': iconFontSize}"
      ></i>
    </span>

    <clip-loader v-if="showLoader" :color="loaderColor" :size="loaderSize" class="loader"></clip-loader>
  </Button>
</template>

<script>
export default {
  name: 'rp-btn',
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'add'
    },
    color: {
      type: String,
      default: '#3988fe'
    },
    bgColor: {
      type: String,
      default: '#3988fe'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'default'
    },
    iconBackground: {
      type: String,
      default: 'transparent'
    },
    iconBorderRadius: {
      type: String,
      default: '0'
    },
    iconFontSize: {
      type: String,
      default: '12px'
    },
    pulseEffect: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    loaderColor: {
      type: String,
      default: '#168eea'
    },
    loaderSize: {
      type: String,
      default: '14px'
    }
  },
  computed: {
    btnStyle () {
      return {
        '--background': this.bgColor,
        '--font-size': this.fontSize,
        '--border': '1px solid ' + this.borderColor,
        '--color': this.color
      }
    }
  },
  created () {
  },
  methods: {
    rgba2hex (orig) {
      // var a
      var rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
      // var alpha = ((rgb && rgb[4]) || '').trim()
      var hex = rgb
        ? (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : orig

      // if (alpha !== '') {
      //   a = alpha
      // } else {
      //   a = 1
      // }
      // multiply before convert to HEX
      // a = ((a * 255) | 1 << 8).toString(16).slice(1)
      // hex = hex + a

      return hex
    }
  }
}
</script>

<style lang="less" scoped>
.add-btn {
  background: var(--background);
  color: var(--color);
  font-size: var(--font-size);
  border: var(--border);
  border-radius: 30px;
  background-clip: padding-box;
  transition: all 0.2s linear !important;
  cursor: pointer;
  font-weight: 600;
  min-width: 75px;
  padding: 9px 20px;
  outline: none !important;
  // &:hover {
  //   background: var(--color) !important;
  //   color: var(--background) !important;
  // }
  span {
    color: inherit;
    display: inline-block;
    vertical-align: middle;

    i {
      color: inherit;
      margin-right: -8px;
      width: 23px;
      height: 23px;
      background-clip: padding-box;
      font-size: 12px;
      line-height: 15px;
      vertical-align: middle;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
    }
  }

  // icons
  .icon_right {
    margin-left: 8px;
    // color: #ffffff;
  }

  .icon_default {
    // color: #ffffff;
  }

  .loader {
    display: inline-block;
    margin-left: 5px;
  }
}
</style>
