import http from '@/mixins/http-lib'
import {
  changeRSSStatusURL,
  deleteRSSURL,
  getRssFeedBioLinks,
  previewRSSURL,
  recrawlRSSURL,
  showRSSURL,
  storeRSSURL
} from '../../config/api'
import { bioLinkTypes, rssTypes } from './mutation-types'
import { swalDefaultValues } from '@/mixins/attributes'
import { trackRSSAutomationCreated } from '../../common/methods'

const getDefaultRSSAdd = () => {
  return {
    cta_id: '',
    url: null,
    _id: null
  }
}

export default {
  state: {
    saveRss: {
      ctaId: '',
      url: '',
      // autoFetch:false,
      id: ''
    },
    rssLoader: false,

    loaders: {
      preview: false,
      show: false,
      store: false,
      recrawl: false
    },
    rss: {
      add: getDefaultRSSAdd(),
      fetchRssLoader: false,
      fetchRss: false,
      search: '',
      page: 1,
      limit: 10,
      sort: 'created_desc',
      total_pages: 0,
      total_items: 0,
      items: []
    },
    preview: {
      loader: false,
      data: [],
      search: '',
      original_data: []
    }
  },
  getters: {
    getRSS: state => state.rss,
    getRSSLoaders: state => state.loaders,
    getRSSAdd: state => state.rss.add,
    getRSSPreview: state => state.preview

  },
  actions: {
    async fetchRSSList ({ commit, getters, dispatch }, page = 1) {
      const payload = getters.getRSS
      commit(rssTypes.SET_RSS_LOADER_RETRIEVE, true)
      await http.post(showRSSURL, {
        search: payload.search,
        page: page
      }).then(res => {
        if (res.data.status) {
          commit(rssTypes.SET_RSS_LIST, res.data.data)
        }
        return res
      }).catch(() => {
        return null
      })
      commit(rssTypes.SET_RSS_LOADER_RETRIEVE, false)
    },
    async storeRSS ({ commit, getters, dispatch }) {
      // prepare payload and set the loader
      let payload = getters.getRSSAdd
      commit(rssTypes.SET_RSS_LOADER_STORE, true)

      // send the request
      const index = getters.getRSS.items.findIndex(item => item._id === payload._id)
      let res = await http.post(storeRSSURL, payload).then(async res => {
        if (res.data.status) {
          // update scenario
          if (payload.hasOwnProperty('_id') && payload._id && payload._id.length > 3) {
            getters.getRSS.items.splice(index, 1, res.data.data)
          } else {
            // new item scenario
            await trackRSSAutomationCreated(getters.getProfile)
            getters.getRSS.items.unshift(res.data.data)
          }
        } else {
          // toast notification but don't hide the modal
          getters.getRSS.items.splice(index, 1, res.data.data)
          const message = (res.data.message) ? res.data.message : 'An error occurred while storing an RSS feed, please try again.'
          dispatch('toastNotification', { message: message, type: 'error' })
        }
        return res
      }).catch(() => {
        return null
      })
      commit(rssTypes.SET_RSS_LOADER_STORE, false)
      return res
    },
    changeRSSStatus ({ commit, getters, dispatch }, { feedId, status, index }) {
      http.post(changeRSSStatusURL, {
        _id: feedId,
        status: status
      }).then(res => {
        if (res.data.status) {
          getters.getRSS.items[index]['pause'] = status
          dispatch('fetchRSSList')
        } else {
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
        }
      }).catch(() => {

      })
    },
    async recrawlRSS ({ commit, getters, dispatch }, { feedId, index }) {

      if (getters.getRSSLoaders.recrawl) {
        return
      }

      commit(rssTypes.SET_RSS_LOADER_RECRAWL, true)
      const res = await http.post(recrawlRSSURL, {
        _id: feedId
      }).then(res => {
        if (res.data.status) {
          getters.getRSS.items[index] = res.data.data
          dispatch('toastNotification', { message: res.data.message })
        } else {
          getters.getRSS.items[index] = res.data.data
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
        }
        return res
      }).catch((e) => {
        console.warn(e)
      })
      commit(rssTypes.SET_RSS_LOADER_RECRAWL, false)
    },

    async previewRSS ({ commit, getters, dispatch }, url) {
      $('#rssFeedModal').modal('show')
      // url = 'https://blog.contentstudio.io/feed' // for the testing purpose locally
      commit(rssTypes.SET_RSS_LOADER_PREVIEW, true)
      let res = await http.post(previewRSSURL, { url: url }).then(res => {
        if (res.data.status) {
          commit(rssTypes.SET_RSS_PREVIEW_DATA, res.data.data)
          commit(rssTypes.SET_RSS_PREVIEW_ORIGINAL_DATA, res.data.data)
        } else {
          const message = (res.data.message) ? res.data.message : 'Unable to get the preview for this feed'
          dispatch('toastNotification', { message: message, type: 'error' })
          $('#rssFeedModal').modal('hide')
        }
      }).catch(() => {
        $('#rssFeedModal').modal('hide')
        return null
      })
      commit(rssTypes.SET_RSS_LOADER_PREVIEW, false)
    },
    async deleteRSS ({ commit, getters, dispatch }, { feedId, index }) {
      const confirmation = swalDefaultValues(true)
      confirmation.text = `
        <p style="color:gray !important; font-size: small !important;font-weight: bold !important;" class="mb-2 mt-2">By removing this RSS Feed,</p>
        <div style="background: #FFF8D1; border:1px solid #F8EACD" class="p-3 rounded">
             <ul class="ml-2" style="list-style-type: disc !important; display:list-item !important;color:#967e4b !important;">
            <li style="color:#AA976C !important;" class="mb-1">RSS feed links will also be removed.</li>
            </ul>
        </div>
        <p style="color:gray !important; font-size: small !important;font-weight: bold !important;" class="mb-2 text-center mt-2">This action cannot be undone.</p>
      `
      swal(confirmation,
        function (isConfirm) {
          if (isConfirm) {
            http.post(deleteRSSURL, {
              _id: feedId
            }).then(res => {
              if (res.data.status) {
                getters.getRSS.items.splice(index, 1)
                dispatch('toastNotification', { message: res.data.message })
              } else {
                dispatch('toastNotification', { message: res.data.message, type: 'error' })
              }
            }).catch(() => {
            })
          } else {
            swal()
          }
        })
    },
    async getRssFeedBioLink ({ commit, getters, dispatch }, url) {
      return await http.post(getRssFeedBioLinks, { url: url }).then(res => {
        return res.data
      })
    }
  },
  mutations: {

    [rssTypes.SET_RSS_ADD_DEFAULT] (state) {
      state.rss.add = getDefaultRSSAdd()
    },
    [rssTypes.SET_RSS_ADD_VALUE] (state, value) {
      state.rss.add = JSON.parse(JSON.stringify(value))
    },
    [rssTypes.SET_RSS_SHOW_PREVIEW] (state, value) {
      state.loaders.preview = value
    },
    [rssTypes.SET_RSS_LOADER_RETRIEVE] (state, value) {
      state.loaders.show = value
    },
    [rssTypes.SET_RSS_LOADER_PREVIEW] (state, value) {
      state.loaders.preview = value
    },
    [rssTypes.SET_RSS_LIST] (state, value) {
      state.rss.items = value.data
      state.rss.page = value.current_page
      state.rss.limit = value.per_page
      state.rss.total_items = value.total
      state.rss.total_pages = value.last_page
    },
    [rssTypes.SET_RSS_LOADER_STORE] (state, value) {
      state.loaders.store = value
    },
    [rssTypes.SET_RSS_LOADER_RECRAWL] (state, value) {
      state.loaders.recrawl = value
    },
    [rssTypes.SET_RSS_PREVIEW_DATA] (state, value) {
      state.preview.data = value
    },
    [rssTypes.SET_RSS_PREVIEW_ORIGINAL_DATA] (state, value) {
      state.preview.original_data = value
    }

  }
}
