<template>

  <div class="authentication-component container d-flex flex-column">
    <div class="row position-relative w-100 m-auto d-flex align-items-center justify-content-between">
      <div class="col-md-12 left_sec p-0 col-lg-12" :class="!getSiteDetails.isWhiteLabel ? 'col-xl-5' : 'col-xl-12'">
        <div class="authentication-component-inner position-relative m-auto">
          <div class="header_content clear">
            <div class="  alert-box  danger" v-if="authorizeMessage">
              <div class="inner_box">
                <p class="text-left">{{ authorizeMessage }}</p>
              </div>
            </div>

          </div>
          <div class="white_box text-center">
            <img width="15%" v-if="getSiteDetails.isWhiteLabel" class="mb-5" :src="getSiteDetails.login_logo"/>
            <div class="box_inner">
              <div class="box_head d-flex align-items-center justify-content-center">
                <div class="left">
                  <h2>Sign-in to your account</h2>
                </div>

              </div>
              <div class="box_content" @keyup.enter="loginAuthenticate($event)">
<!--                <div class="row d-flex align-items-center"-->
<!--                     v-if="!getSiteDetails.isWhiteLabel">-->
<!--                  <div class="mb-2 social-account-btn col-md-6">-->
<!--                    <a-->
<!--                      class="twitter__btn social_btn text-center d-inline-flex align-items-center justify-content-center"-->
<!--                      :href="getLink('twitter')">-->

<!--                      <i class="fab fa-twitter position-relative d-inline-block"></i>-->
<!--                      <span class="pl-2">Sign in with Twitter</span>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <div class="mb-2 social-account-btn col-md-6">-->
<!--                    <a-->
<!--                      class="facebook__btn social_btn text-center d-inline-flex align-items-center justify-content-center"-->
<!--                      :href="getLink('facebook')">-->

<!--                      <i class="fab fa-facebook position-relative d-inline-block"></i>-->
<!--                      <span class="pl-2">Sign in with Facebook</span>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="or_separator_container text-center"-->
<!--                     v-if="!getSiteDetails.isWhiteLabel">-->
<!--                  <div class="or_separator">-->
<!--                    <span class="sep_text">OR</span>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                  <div class="avatar">
                    <i class="fal fa-envelope"></i>
                  </div>
                  <div class="content">
                    <div class="url">Email</div>
                    <div class="name">
                      <div class="validation_input">
                        <input autocomplete="false" type="text" class="form-control"
                               v-model="username" name="email"
                               id="email" data-cy="username"
                               placeholder="Your username or email address" data-vv-rules="required">

                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3 text-right">
                  <router-link style="color: #3988fe;" :to="{path: '/forgot_password'}">
                    Forgot your password?
                  </router-link>
                </div>

                <div class="mt-2 input_icon_text d-flex align-items-center profile__box">

                  <div class="avatar">
                    <i class="fal fa-lock"></i>
                  </div>
                  <div class="content">
                    <div class="url">Password</div>
                    <div class="name">
                      <div class="validation_input">

                        <input autocomplete="false" :type="showPassword ? 'text' : 'password'" class="form-control"
                               v-model="password"
                               data-vv-rules="required"
                               name="password" id="password" data-cy="password" placeholder="Your account password">
                      </div>
                    </div>
                  </div>
                  <div @click="showPasswordFunc()" class="avatar cursor_pointer m-auto">
                    <i class="fal" :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"></i>
                  </div>
                </div>
                <div class="mt-3 text-left">
                  <div class="checkbox_input_image checkbox_remember">
                    <input
                      id="remember_me"
                      type="checkbox"
                      v-model="rememberMe"
                    >
                    <label
                      for="remember_me"
                      class="checkbox_left"
                    >Remember me</label>
                  </div>
                </div>

                <div class="mt-4 btn_block txt-center d-flex justify-content-center">

                  <button
                    class="btn---cta btn-blue btn-round d-flex align-items-center justify-content-center btn--loader loader--right"
                    data-cy="submit"
                    :disabled="isDisableSignIn"
                    @click.prevent="loginAuthenticate($event)">
                    <span>Sign In</span>
                    <clip-loader class="clip-loader" v-if="loginLoader" :color="color" :size="size"></clip-loader>
                  </button>

                </div>
                <div class="bottom_link mt-3 text-center" v-if="!getSiteDetails.isWhiteLabel">
                  <router-link v-if="$route.query.ref"
                               :to="{path: '/register', query: { ref:$route.query.ref } }"
                               class="login_lin">
                    Create an account
                  </router-link>
                  <router-link v-else :to="{path: '/register'}" class="login_lin">
                    Create an account
                  </router-link>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="!getSiteDetails.isWhiteLabel" class="col-md-12 col-xl-7 col-lg-12">
        <Carousel/>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

.checkbox_input_image {
  margin-left: 0.4rem;

  .remember_me {
    font-size: 13px;
  }
}

.checkbox_remember {
  label {
    color: #989eb5 !important;
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:before {
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:after {
    color: #989eb5 !important;
    font-size: 16px !important;
  }
}

@-moz-document url-prefix() {

  .authentication-component .authentication-component-inner .twitter__btn span,
  .authentication-component .authentication-component-inner .facebook__btn span {
    font-size: 13px !important;
    padding-left: 0px !important;
    padding-right: 15px !important;
  }
  .twitter__btn i, .facebook__btn i {
    min-width: 40px;
  }
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex'
import {APIURL} from '@/config/config'
import {API_URL_v3} from '@/constants'
import http from '@/mixins/http-lib'
import {getWhitelabelDetailsFromDomain} from '../../config/config'
import {userTypes} from '../../state/modules/mutation-types'

export default ({
  'name': 'Login',
  components: {
    Carousel: () => import('@/ui/ui-kit/Carousel')
  },
  data: function () {
    return {
      username: '',
      password: '',
      loginFormStatus: false,
      authorizeMessage: null,
      size: '16px',
      color: '#ffffff',
      loginLoader: false,
      isDisableSignIn: false,
      rememberMe: false,
      whitelabel: null,
      showPassword: false
    }
  },
 async created () {
   await this.fetchWhitelabelAppSettings()
  },
  computed: {
    ...mapGetters(['getWhitelabel'])
  },
  methods: {
    ...mapActions(['authenticate', 'fetchBrandList', 'fetchWhitelabelAppSettings']),
    getLink (type) {
      if (this.$route.query.ref) {
        return APIURL + 'social/refer/' + type + '/' + this.$route.query.ref
      } else if (this.$route.query.oauth && this.$route.query.oauth === 'authorize' &&
        this.$route.query.client_id && this.$route.query.redirect_uri &&
        this.$route.query.response_type && this.$route.query.scope) {
        return APIURL + 'social/' + type +
          '/?client_id=' + this.$route.query.client_id +
          '&redirect_uri=' + this.$route.query.redirect_uri +
          '&response_type=' + this.$route.query.response_type +
          '&scope=' + this.$route.query.scope
      } else {
        return APIURL + 'social/' + type
      }
    },
    loginAuthenticate () {
      this.isDisableSignIn = true
      this.loginLoader = true
      const postData = {
        'email': this.username,
        'password': this.password,
        'remember_me': this.rememberMe
      }
      if (this.$route.query.source) {
        postData['source'] = this.$route.query.source
      }

      if (this.$route.query.redirect) {
        postData['redirect'] = this.$route.query.redirect
      }

      http.post(`${API_URL_v3}auth/authenticate`, postData).then(
        response => {
          /**
           * In case of SSO(Single Sign-On, we will be redirecting user back to where they came from
           * Current implementation caters Frill.co redirection
           */
          if (response.data.redirect) {
            window.location.href = response.data.redirect
          }
          this.setSiteCookies(response.data.data.token)
          setTimeout(() => {
            this.loginLoader = false
            this.isDisableSignIn = false

            if (this.$route.query.client_id && this.$route.query.redirect_uri &&
              this.$route.query.response_type && this.$route.query.scope) {
              this.$router.push({
                name: 'oauth', query: {
                  response_type: this.$route.query.response_type,
                  redirect_uri: this.$route.query.redirect_uri,
                  client_id: this.$route.query.client_id,
                  scope: this.$route.query.scope
                }
              })
              return
            }
            if (this.$route.query.redirect_url) {
              const routeNameList = this.$route.query.redirect_url.split('/')
              this.$router.push({'name': routeNameList[routeNameList.length - 1]})
              return
            }
            this.$router.push({'name': 'dashboard'})

          }, 1000)

        }, error => {
          this.isDisableSignIn = false
          this.loginLoader = false
          this.$store.dispatch('toastNotification', {
            message: error.response.data.error.split('_').join(' '),
            type: 'error'
          })
        })
    },
    showPasswordFunc () {
      this.showPassword = !this.showPassword
    }
  }

})
</script>
