<template>
    <div ref="dropdownMenu"  class="dropdown__menu" :class="positionClasses">
        <div @click="visible = !visible"  class="head__section">
            <slot name="dropdown__head" >

            </slot>
            <div class="caret" :class="{rotate : visible }"></div>
        </div>
        <transition name="slide-y">
            <div v-if="visible" class="dropdown__body" :class="{'activeTour' : getGuidedTour.step3}">
                <slot name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: [
    'position',
    'align',
    'visible'
  ],
  data () {
    return {
      positionClasses: 'position_bottom align_center',
      dropdownPosition: {
        dropdownPosition: 'bottom',
        dropdownAlign: 'center'
      }
    }
  },

  computed: {
    ...mapGetters(['getGuidedTour'])

  },

  methods: {
    getAlignState (positionDD, alignDD) {
      if (positionDD === 'top' || positionDD === 'bottom') {
        if (alignDD === 'left') {
          return 'align_left'
        } else if (alignDD === 'center') {
          return 'align_center'
        } else if (alignDD === 'right') {
          return 'align_right'
        } else {
          return 'align_center'
        }
      } else if (positionDD === 'left' || positionDD === 'right') {
        if (alignDD === 'top') {
          return 'align_top'
        } else if (alignDD === 'center') {
          return 'align_center'
        } else if (alignDD === 'bottom') {
          return 'align_bottom'
        } else {
          return 'align_center'
        }
      }
    },
    documentClick (e) {
      let el = this.$refs.dropdownMenu
      let guidedOverlay = document.getElementById('guided_overlay_black')
      let target = e.target
      if ((el !== target) && !el.contains(target) && !guidedOverlay) {
        this.visible = false
      }
    }
  },
  created () {
    this.visible = false
    // add event for close dropdown
    this.$root.$on('close-dropdown', (value) => {
      this.visible = value
    })
    document.addEventListener('click', this.documentClick)

    // check prop position according to alignment ..........
    let position_dd = this.position
    let align_dd = this.align

    switch (position_dd) {
      case 'left': {
        this.dropdownPosition.dropdownPosition = 'position_left'
        this.dropdownPosition.dropdownAlign = this.getAlignState(position_dd, align_dd)
        break
      }
      case 'right': {
        this.dropdownPosition.dropdownPosition = 'position_right'
        this.dropdownPosition.dropdownAlign = this.getAlignState(position_dd, align_dd)
        break
      }
      case 'top': {
        this.dropdownPosition.dropdownPosition = 'position_top'
        this.dropdownPosition.dropdownAlign = this.getAlignState(position_dd, align_dd)
        break
      }
      case 'bottom': {
        this.dropdownPosition.dropdownPosition = 'position_bottom'
        this.dropdownPosition.dropdownAlign = this.getAlignState(position_dd, align_dd)
        break
      }
      default:
        this.dropdownPosition.dropdownPosition = 'position_bottom'
        this.dropdownPosition.dropdownAlign = this.getAlignState('align_center')
        break
    }

    this.positionClasses = this.dropdownPosition.dropdownPosition + ' ' + this.dropdownPosition.dropdownAlign
  },
  destroyed () {
    // important to clean up!!
    document.removeEventListener('click', this.documentClick)
    document.removeEventListener('click', this.documentClick)
    this.$root.$off('close-dropdown')
  }

}
</script>

<style>

</style>
