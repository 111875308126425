import { reportTypes } from './mutation-types'
import http from '@/mixins/http-lib'
import {
  archiveReportsURL,
  deleteReportsURL, dispatchManualReportsURL,
  showEmailReportsURL,
  showReportsURL,
  storeReportsURL
} from '../../config/api'
import { swalDefaultValues, swalDispatchReportsValues } from '@/mixins/attributes'
import {trackReportCreated} from "../../common/methods";

const getDefaultReportsAdd = () => {
  return {
    brands: [],
    campaigns: [],
    campaignsOption: [],
    name: '',
    daily: true,
    weekly: false,
    monthly: false,
    emails: [],
    emailsOption: [],
    loader: false,
    _id: null,
    emailNotAllowed: false
  }
}

const getDefaultReports = () => {
  return {
    items: [],
    loader: true,
    fetchReports: false,
    page: 1,
    limit: 10,
    total_pages: 0,
    total_items: 0,
    archiveStatus: false,
    search: ''
  }
}

export default {
  state: {
    reports: getDefaultReports(),
    reportSavingDetail: getDefaultReportsAdd(),
    add: getDefaultReportsAdd(),
    loaders: {
      store: false,
      retrieve: false
    },
    emailReports: []
  },
  getters: {
    getReportsSavingDetail: state => state.reportSavingDetail,
    getReports: state => state.reports,
    getReportsAdd: state => state.add,
    getReportsLoaders: state => state.loaders,
    getEmailReports: state => state.emailReports
  },
  actions: {
    async storeReport ({ commit, getters, dispatch }) {
      commit(reportTypes.SET_REPORTS_LOADER_STORE, true)
      let payload = getters.getReportsAdd
      return await http.post(storeReportsURL, payload).then(async res => {
        if (res.data.status) {
          // $('#addReport').modal('hide')
          if (payload.hasOwnProperty('_id') && payload._id && payload._id.length > 3) {
            const index = getters.getReports.items.findIndex(item => item._id === payload._id)
            getters.getReports.items.splice(index, 1, res.data.data)
          } else {
            await trackReportCreated(getters.getProfile)
            getters.getReports.items.unshift(res.data.data)
          }
          commit(reportTypes.SET_REPORTS_LOADER_STORE, false)
          return res
        } else {
          const message = (res.data.message) ? res.data.message : 'An error occurred, please try again.'
          dispatch('toastNotification', { message: message, type: 'error' })
        }
      }).catch(res => {
        return null
      })
    },
    async fetchReports ({ commit, getters }, page = 1) {
      commit(reportTypes.SET_REPORTS_RETRIEVE_STORE, true)
      const filters = getters.getReports
      let res = await http.post(showReportsURL, {
        page: page,
        archive: filters.archiveStatus,
        search: filters.search
      }).then(res => {
        if (res.data.status) {
          commit(reportTypes.SET_REPORTS_LIST, res.data.data)
        }
        return res
      }).catch(
        () => {
          return null
        }
      )
      commit(reportTypes.SET_REPORTS_RETRIEVE_STORE, false)
    },
    async fetchEmailReports ({ commit, getters }, reportId = null) {
      return await http.post(showEmailReportsURL, { report_id: reportId }).then(res => {
        if (res.data.status) {
          commit(reportTypes.SET_EMAIL_REPORTS, res.data.data)
          return res.data.data
        }
        return res
      }).catch(
        () => {
          return null
        }
      )
    },
    async dispatchManualReports ({ commit, getters }, reportId) {
      swal(swalDispatchReportsValues(),
        function (isConfirm) {
          if (isConfirm) {
            http.post(dispatchManualReportsURL, { report_id: reportId }).then(res => {
              if (res.data.status) {
                this.alertMessage('Your reports will be sent to your email when it\'s done.', 'success')
              }
            }).catch(
              () => {
                return null
              }
            )
          } else {
            swal()
          }
        })
    },
    async deleteReport ({ commit, getters, dispatch }, { reportId, index }) {
      swal(swalDefaultValues(),
        function (isConfirm) {
          if (isConfirm) {
            http.post(deleteReportsURL, {
              _id: reportId
            }).then(res => {
              if (res.data.status) {
                getters.getReports.items.splice(index, 1)
              } else {
                dispatch('toastNotification', { message: res.data.message, type: 'error' })
              }
            }).catch(() => {

            })
          } else {
            swal()
          }
        })
    },
    async changeArchive ({ commit, getters, dispatch }, { reportId, index, status }) {
      let swalValues = swalDefaultValues()
      swalValues.confirmButtonText = (status) ? 'Yes, archive it.' : 'Yes, unarchive it.'
      swal(swalValues,
        function (isConfirm) {
          if (isConfirm) {
            http.post(archiveReportsURL, {
              _id: reportId,
              status: status
            }).then(res => {
              if (res.data.status) {
                getters.getReports.items.splice(index, 1)
              } else {
                dispatch('toastNotification', { message: res.data.message, type: 'error' })
              }
            }).catch(() => {

            })
          } else {
            swal()
          }
        })
    }

  },
  mutations: {
    [reportTypes.SET_REPORTS_ADD_CAMPAIGNS_OPTION] (state, value) {
      state.add.campaigns = value
    },
    [reportTypes.SET_REPORTS_ADD_CAMPAIGNS_SELECTION] (state, value) {
      state.add.campaignsOption = value
    },
    [reportTypes.SET_REPORTS_ADD_EMAIL_OPTIONS] (state, value) {
      state.add.emailsOption = value
    },
    [reportTypes.SET_REPORTS_ADD_EMAIL_SELECTION] (state, value) {
      state.add.emails = value
    },
    [reportTypes.SET_REPORTS_LOADER_STORE] (state, value) {
      state.loaders.store = value
    },
    [reportTypes.SET_REPORTS_RETRIEVE_STORE] (state, value) {
      state.loaders.retrieve = value
    },
    [reportTypes.SET_EMAIL_REPORTS] (state, value) {
      state.emailReports = value
    },
    [reportTypes.SET_REPORTS_LIST] (state, value) {
      state.reports.items = value.data
      state.reports.page = value.current_page
      state.reports.limit = value.per_page
      state.reports.total_items = value.total
      state.reports.total_pages = value.last_page
    },
    [reportTypes.SET_REPORTS_ADD_DEFAULT_VALUE] (state) {
      state.add = getDefaultReportsAdd()
    },
    [reportTypes.SET_REPORTS_ARCHIVE_STATUS] (state, value) {
      state.reports.archiveStatus = value
    },
    [reportTypes.SET_REPORTS_SEARCH_VALUE] (state, value) {
      state.reports.search = value
    }
  }
}
